import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';
import type { ITemplate } from '@/components/Settings/Template/components';
import type { UtilsStoreState } from '@/miscellaneous/store/utilsStore/utilsStore';
import useUtilsStore from '@/miscellaneous/store/utilsStore/utilsStore';
import type { ZustandState } from '@/miscellaneous/store/zustand_store';
import useZustandStore from '@/miscellaneous/store/zustand_store';
import colors from '@/styles/scss/abstracts/_variables.module.scss';
import useCompany from '@/utils/hooks/company/useCompany';
import useMutations from '@/utils/hooks/mutations/useMutations';
import { useActiveTemplatesQuery } from '@/utils/hooks/template/useTemplateHooks';
import type { Integration } from '@/utils/hooks/useIntegrations/useIntegrations';
import useIntegrations from '@/utils/hooks/useIntegrations/useIntegrations';
import DeletePopup from '../DeletePopup/DeletePopup';
import type { StepObject } from '../GenericStagePopup/GenericStagePopup';
import GenericStagePopup from '../GenericStagePopup/GenericStagePopup';
import OnboardingPopupBody, { initialCompanyState } from './OnboardingPopupBody';
import type { CompanyState } from './onboardingPopupTypes';
type OnboardingPopupProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

/**
 * The `OnboardingPopup` component is responsible for rendering a popup that guides the user through the onboarding process for a company.
 *
 * The component uses the `GenericStagePopup` component to handle the overall popup structure, including the steps, overlay, and popup styles. It also manages the state of the onboarding process, including the active step, the company state, and the ability to reset the process.
 *
 * The component uses the `useEffect` hooks to update the steps and the company state based on the active company's onboarding data. It also updates the steps based on whether the company is new or existing.
 *
 * @param {OnboardingPopupProps} props - The props for the `OnboardingPopup` component.
 * @param {boolean} props.open - Whether the popup is open or not.
 * @param {(open: boolean) => void} props.setOpen - A function to set the open state of the popup.
 * @returns {JSX.Element} - The rendered `OnboardingPopup` component.
 */
const OnboardingPopup = ({
  open,
  setOpen
}: OnboardingPopupProps) => {
  const {
    t: translate
  } = useTranslation('company');
  const {
    updateCompany
  } = useMutations();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    data: integrations
  } = useIntegrations();
  const {
    activeCompany
  } = useZustandStore(useShallow((state: ZustandState) => ({
    activeCompany: state.activeCompany
  })));
  const {
    activeTemplates
  } = useActiveTemplatesQuery();
  const {
    setAppLoader
  } = useUtilsStore(useShallow((state: UtilsStoreState) => ({
    setAppLoader: state.setAppLoader
  })));

  // Define the styles for the popup
  const popupStyles = {
    borderRadius: '20px',
    padding: '24px',
    gap: '16px',
    width: '1100px',
    height: '608px'
  };

  // Define the styles for the overlay
  const overlayStyles = {
    backgroundColor: colors.modalOcerlayColor
  };

  // Define the initial steps for the onboarding process
  const initialSteps: StepObject[] = [{
    label: translate('company_website'),
    route: 'Company Website'
  }, {
    label: translate('company_name'),
    route: 'Company Name'
  }, {
    label: translate('integrations'),
    route: 'Integrations'
  }, {
    label: translate('company_details'),
    route: 'Company Details'
  }, {
    label: translate('details_confirmation'),
    route: 'Details Confirmation'
  }, {
    label: translate('company_goals'),
    route: 'Company Goals'
  }, {
    label: translate('company_products'),
    route: 'Company Products'
  }, {
    label: translate('your_custom_plan'),
    route: 'Your Custom Plan'
  }];
  const [allSteps] = useState<StepObject[]>(initialSteps);
  const {
    userCompanies
  } = useCompany();
  const [complatedStepCount, setComplatedStepCount] = useState<number>(0);
  const [activeStep, setActiveStep] = useState<string>(((allSteps[0] as StepObject).route as string));
  const [companyState, setCompanyState] = useState<CompanyState>(() => {
    return {
      'Company Name': {
        companyName: (activeCompany.name as string) || initialCompanyState['Company Name'].companyName
      },
      Integrations: {
        connectedIntegrations: (integrations as Integration[]) || initialCompanyState.Integrations.connectedIntegrations
      },
      'Company Website': {
        website: (activeCompany.website as string) || initialCompanyState['Company Website'].website
      },
      'Details Confirmation': {
        teamLocation: (activeCompany.location as string) || initialCompanyState['Details Confirmation'].teamLocation,
        companySectors: activeCompany.sectors?.split('|') || initialCompanyState['Details Confirmation'].companySectors,
        howMuchFunding: (activeCompany.funding_raise as string) || initialCompanyState['Details Confirmation'].howMuchFunding,
        companyStage: (activeCompany.company_stage as string) || initialCompanyState['Details Confirmation'].companyStage
      },
      'Company Goals': {
        howManyEmployees: (activeCompany.target_employees_in_one_year as string) || initialCompanyState['Company Goals'].howManyEmployees,
        revenueAYearFromNow: (activeCompany.target_revenue_in_one_year as string) || initialCompanyState['Company Goals'].revenueAYearFromNow,
        whenYouRaiseFunding: (activeCompany.raise_next_round_date as string) || initialCompanyState['Company Goals'].whenYouRaiseFunding,
        howMuchFunding: (activeCompany.target_round_funding as string) || initialCompanyState['Company Goals'].howMuchFunding
      },
      'Company Products': {
        products: activeCompany.products?.map(product => ({
          name: product.name,
          price: product.price,
          sold_last_month: product.amount_sold_last_m,
          sold_1_year_ago: product.amount_sold_y_ago,
          subscription_type: product.subscription_type,
          cac: product.CAC
        })) || initialCompanyState['Company Products'].products
      },
      'Company Details': {
        text: (activeCompany.description as string) || initialCompanyState['Company Details'].text
      },
      'Your Custom Plan': {
        chosenTemplates: activeTemplates?.map((template: ITemplate) => template)
      }
    };
  });

  // Define the function to reset the step to the first step
  const resetStep = () => {
    setActiveStep((allSteps[0] as StepObject).label);
    setOpen(false);
    setCompanyState(initialCompanyState);
  };
  const closePopup = (isClosePopup?: boolean) => {
    if (activeStep === 'Your Custom Plan' || isClosePopup) {
      resetStep();
      return;
    }
    setIsModalOpen(true);
  };

  /**
   * This effect is responsible for updating the state of the onboarding process based on the active company's onboarding step and other related data.
   *
   * If an active company exists and has an `onboarding_step` property, the effect will:
   * - Set the `activeStep` to the route of the corresponding step in the `allSteps` array.
   * - Update the `companyState` object with the following properties from the active company:
   *   - `isNewCompany` (from `is_new_idea`)
   *   - `website` (from `website`)
   *   - `teamLocation` (from `location`)
   *   - `companySectors` (from `sectors`)
   *   - `howMuchFunding` (from `funding_raise`)
   *   - `companyStage` (from `company_stage`)
   *   - `howManyEmployees` (from `target_employees_in_one_year`)
   *   - `revenueAYearFromNow` (from `target_revenue_in_one_year`)
   *   - `whenYouRaiseFunding` (from `raise_next_round_date`)
   *   - `howMuchFunding` (from `target_round_funding`)
   */
  useEffect(() => {
    if (activeCompany && activeCompany.onboarding_step) {
      setActiveStep(
      // @ts-ignore
      (allSteps[activeCompany.onboarding_step || 0] as StepObject)?.route);
      setComplatedStepCount(activeCompany.onboarding_step || 0);
      setCompanyState({
        ...companyState,
        'Company Name': {
          companyName: (activeCompany.name as string)
        },
        Integrations: {
          connectedIntegrations: (integrations as Integration[])
        },
        'Company Website': {
          website: (activeCompany.website as string)
        },
        'Details Confirmation': {
          teamLocation: (activeCompany.location as string),
          companySectors: activeCompany.sectors?.split('|') || [],
          howMuchFunding: (activeCompany.funding_raise as string),
          companyStage: (activeCompany.company_stage as string)
        },
        'Company Goals': {
          howManyEmployees: (activeCompany.target_employees_in_one_year as string),
          revenueAYearFromNow: (activeCompany.target_revenue_in_one_year as string),
          whenYouRaiseFunding: (activeCompany.raise_next_round_date as string),
          howMuchFunding: (activeCompany.target_round_funding as string)
        },
        'Company Products': {
          ...companyState['Company Products'],
          products: activeCompany.products?.map(product => ({
            name: product.name,
            price: product.price,
            sold_last_month: product.amount_sold_last_m,
            sold_1_year_ago: product.amount_sold_y_ago,
            subscription_type: product.subscription_type,
            cac: product.CAC
          })) || []
        },
        'Company Details': {
          text: (activeCompany.description as string)
        },
        'Your Custom Plan': {
          chosenTemplates: activeTemplates?.map((template: ITemplate) => template)
        }
      });
    }
  }, [activeCompany]);
  const navigateToStep = (step: StepObject) => {
    setActiveStep(step.route || step.label);
  };
  return <>
      <GenericStagePopup title={translate('create_new_company')} steps={allSteps} overlayStyles={overlayStyles} poupStyles={popupStyles} setActiveStep={setActiveStep} activeStep={activeStep} resetStep={closePopup} setStageState={state => setCompanyState((state as CompanyState))} stageState={companyState} stageComponent={OnboardingPopupBody} isModal open={open} hideCloseButton={userCompanies.length === 1} navigateToStep={navigateToStep} complatedStepCount={complatedStepCount || activeCompany.onboarding_step} isOnboarding data-sentry-element="GenericStagePopup" data-sentry-source-file="OnboardingPopup.tsx" />
      <DeletePopup isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} title={translate('company:are_you_sure_close_popup')} sumbitTitle={translate('company:close')} closeModal={() => setIsModalOpen(false)} onDelete={() => {
      setAppLoader(true);
      updateCompany.mutate({
        id: activeCompany?.id,
        slug: activeCompany?.slug,
        // actual date of onboarding finish
        onboarding_finished_date: moment().utc().toDate()
      }, {
        onSuccess: () => {
          resetStep();
          setIsModalOpen(false);
          setAppLoader(false);
        }
      });
    }} data-sentry-element="DeletePopup" data-sentry-source-file="OnboardingPopup.tsx" />
    </>;
};
export default OnboardingPopup;