/* eslint-disable no-param-reassign */
import type { UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useEffect } from 'react';
import { useShallow } from 'zustand/react/shallow';

import ApiUtils from '@/api/ApiUtils';
import { QUERYKEYS } from '@/miscellaneous/constant/reactQueryKeyConfig';
import type { CalculationsStoreState } from '@/miscellaneous/store/CalculationsStore';
import CalculationsStore from '@/miscellaneous/store/CalculationsStore';
import type { OutputValuesMap } from '@/miscellaneous/store/calulcationsStoreHelper';
import { addOutputValues } from '@/miscellaneous/store/calulcationsStoreHelper';
import type { DataStoreState } from '@/miscellaneous/store/DataStore';
import DataStore from '@/miscellaneous/store/DataStore';
import type { ZustandState } from '@/miscellaneous/store/zustand_store';
import useZustandStore from '@/miscellaneous/store/zustand_store';

import type { Output } from '../Outputs/useOutputs';
import {
  baseOutputIdToSign,
  mapBaseOutputIds,
} from '../Outputs/useOutputsHelper';
import {
  memoizedSetOutputtotalValuesToMap,
  ProcessFormulaEntries,
} from './entriesHelper';

export interface Entry {
  id: number;
  created_at: string;
  timestamp: string;
  to_account: number;
  branch_id: number;
  updated_at: string;
  amount: number;
  formula_id: number;
}

export interface AccountValues {
  // mapping data from the formula entries to the outputs by date
  output?: Output | undefined;
  [date: string]: number | undefined | Output; // output was added to prevent the typescript error
}

export const useEntries = () => {
  const { startDate, endDate, isLoggedIn, activeCompanyId } = useZustandStore(
    useShallow((state: ZustandState) => ({
      startDate: state.startDate,
      endDate: state.endDate,
      isLoggedIn: Boolean(state.isLoggedIn),
      activeCompanyId: state.activeCompany?.id ?? null,
    })),
  );

  const { data, isLoading, error, refetch }: UseQueryResult<Entry[], Error> =
    useQuery({
      queryKey: [QUERYKEYS.ENTRIES, activeCompanyId],
      queryFn: () => ApiUtils.getAllEntries(activeCompanyId as number),
      enabled: !!activeCompanyId && !!isLoggedIn,
    });

  const {
    branchOutputs,
    accountTrees,
    getAllOutputChildren,
    branchTopLevelOutputs,
  } = DataStore(
    useShallow((state: DataStoreState) => ({
      branchOutputs: state.branchOutputs,
      accountTrees: state.accountTrees,
      getAllOutputChildren: state.getAllOutputChildren,
      branchTopLevelOutputs: state.branchTopLevelOutputs,
    })),
  );

  const { formulaEntries, HPformulaEntries, setForecastDataMap } =
    CalculationsStore(
      useShallow((state: CalculationsStoreState) => ({
        formulaEntries: state.formulaEntries,
        HPformulaEntries: state.HPformulaEntries,
        setForecastDataMap: state.setForecastDataMap,
      })),
    );

  /**
   * This function updates the banks output with all the children of base outputs into banks to get the total cashflow values into the bank account
   * @param newMap the new map to be added to the store
   */
  function setBanksOutputValues(newMap: OutputValuesMap) {
    const banksBaseID = mapBaseOutputIds.banks;
    const bankOutput = branchTopLevelOutputs.find(
      (output) => output.base_output_id === banksBaseID,
    );

    let banksMap = bankOutput ? newMap[bankOutput.id] : undefined;
    if (banksMap !== undefined) {
      branchTopLevelOutputs.forEach((topLevelOutput) => {
        if (topLevelOutput.base_output_id === banksBaseID) return; // skipping banks
        const baseOutputId = topLevelOutput?.base_output_id || 0;
        const sign =
          baseOutputIdToSign[baseOutputId as keyof typeof baseOutputIdToSign]; // getting the bank sign from the base output id
        const children = getAllOutputChildren(topLevelOutput.id);

        children.forEach((child) => {
          const map = newMap[child];
          // ignoring the total values copy without total values data
          const mapWithoutTotalValues = {
            output: map?.output,
            dateValues: map?.dateValues || {},
            culmutativeDateValues: map?.culmutativeDateValues || {},
          };
          if (mapWithoutTotalValues && banksMap) {
            banksMap = addOutputValues(mapWithoutTotalValues, banksMap, sign);
          }
        });

        // then we handle adding the top level output to add entries pointing to the bank
        const topLevelMap = newMap[topLevelOutput.id];
        if (topLevelMap && banksMap) {
          banksMap = addOutputValues(topLevelMap, banksMap, sign);
        }
      });
    }
    if (bankOutput?.id && banksMap) newMap[bankOutput?.id] = banksMap;
  }

  // Filter entries by branch
  useEffect(() => {
    if (branchOutputs && formulaEntries) {
      const newMap: OutputValuesMap = ProcessFormulaEntries(
        { ...formulaEntries, ...HPformulaEntries },
        branchOutputs,
      );
      // for each account now add the totalOutputValues to the map
      memoizedSetOutputtotalValuesToMap(accountTrees, newMap);
      setBanksOutputValues(newMap);
      setForecastDataMap(newMap);
    }
  }, [
    accountTrees,
    formulaEntries,
    HPformulaEntries,
    startDate,
    endDate,
    branchOutputs,
    branchTopLevelOutputs,
  ]);

  return {
    data,
    branchEntries: formulaEntries,
    isLoading,
    error,
    refetch,
  };
};
