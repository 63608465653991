import type { ReactNode } from 'react';
import React, { createContext, useContext, useMemo, useState } from 'react';
interface TableContextType {
  overRowState: any;
  setOver: (value: any) => void;
  draggedItem: any;
  setDraggedItem: (value: any) => void;
  position: 'top' | 'middle' | 'bottom' | null;
  setPosition: (value: 'top' | 'middle' | 'bottom' | null) => void;
}
const TableContext = createContext<TableContextType | undefined>(undefined);
export const TableDNDProvider: React.FC<{
  children: ReactNode;
}> = ({
  children
}) => {
  const [overRowState, setOver] = useState<any>(null);
  const [draggedItem, setDraggedItem] = useState<any>(null);
  const [position, setPosition] = useState<'top' | 'middle' | 'bottom' | null>(null);
  const contextValues = useMemo(() => ({
    overRowState,
    setOver,
    draggedItem,
    setDraggedItem,
    position,
    setPosition
  }), [overRowState, setOver, draggedItem, setDraggedItem, position, setPosition]);
  return <TableContext.Provider value={contextValues} data-sentry-element="unknown" data-sentry-component="TableDNDProvider" data-sentry-source-file="TableDNDContext.tsx">
      {children}
    </TableContext.Provider>;
};
export function useTableContext() {
  const context = useContext(TableContext);
  if (!context) {
    console.error('useTableContext must be used within a TableProvider');
    // throw new Error('useTableContext must be used within a TableProvider');
    return ({
      overRowState: null,
      setOver: () => {},
      draggedItem: null,
      setDraggedItem: () => {},
      position: null,
      setPosition: () => {}
    } as TableContextType);
  }
  return context;
}