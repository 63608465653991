import classNames from 'classnames';
import type { FinancialsDrawerState } from '@/components/financialReports/FinancialDrawer/FinancialDrawerContent/FinancialDrawerContent';
import styles from '@/components/table/shared/table/GeneralTable/styles/baseTableStyles.module.scss';
import type { ZustandState } from '@/miscellaneous/store/zustand_store';
import useZustandStore from '@/miscellaneous/store/zustand_store';
import { getRevertedDatesFromColumns } from '@/utils/dateUtils';
import flattenArray from '@/utils/helpers/flattenArray';
// eslint-disable-next-line import/no-cycle

/**
 * A React component that renders a financial view cell in a table.
 *
 * @param {object} props - The component props.
 * @param {any} props.cell - The cell data.
 * @param {any} props.children - The child elements to render inside the cell.
 * @returns {JSX.Element} The rendered financial view cell.
 */
const FinancialViewCellComponent = ({
  cell,
  children
}: {
  cell: any;
  children: JSX.Element;
}) => {
  const {
    setIsSidebarDrawerOpen,
    setSidebarDrawerContent,
    startDate,
    endDate
  } = useZustandStore((state: ZustandState) => state);

  /**
   * Represents the state of the financials drawer for a specific cell.
   * @property {string} id - The unique identifier of the cell.
   * @property {string} column_name - The name of the column the cell belongs to.
   * @property {string} accessorKey - The accessor key used to retrieve the cell's data.
   * @property {string} Header - The header text for the column the cell belongs to.
   */

  /**
   * Determines whether the current cell should be ignored for the purpose of opening the financial view drawer.
   * A cell should be ignored if it has subrows, is a total row, or is not a financial view column.
   *
   * @param cell - The current cell object.
   * @returns `true` if the cell should be ignored, `false` otherwise.
   */
  const ignoreCell = cell?.isTotalRow && cell.id.split('-')[0] === 'section';
  const onClick = () => {
    if (ignoreCell) return; // disabled for now to allow edit report to work
    const rowObject = cell.data.original.original_row_object;
    const financialsDrawerContent: FinancialsDrawerState = {
      tab: 'transactions',
      state: {
        id: cell.id,
        column_name: cell.column_name,
        accessorKey: cell.accessorKey,
        Header: cell.Header,
        data: flattenArray(cell.data.original.subRows),
        // Flatten the subrows to display in the drawer
        original_row_object: rowObject,
        value: cell.value,
        dates: getRevertedDatesFromColumns(cell.accessorKey, (startDate as string), (endDate as string))
      }
    };
    setIsSidebarDrawerOpen(true);
    setSidebarDrawerContent(financialsDrawerContent);
  };
  return <button className={classNames('absolute right-0 top-0 cursor-default', {
    [(styles.hoverStylesDrawerCell as string)]: !ignoreCell
  })} onClick={onClick} type="button" data-sentry-component="FinancialViewCellComponent" data-sentry-source-file="FinancialViewCellComponent.tsx">
      {children}
    </button>;
};
export default FinancialViewCellComponent;