// Importing the necessary React and third-party libraries.
// React is used for building UI components.
// react-modern-drawer provides a modern drawer component.
import React from 'react';
import Drawer from 'react-modern-drawer';

// Importing custom components and style modules.
// ChatWindow represents the chat interface.
// styles contain specific styles for the chat drawer.
import ChatWindow from '@/components/common/CopilotChat/CopilotChatComponents/ChatWindow';
import styles from '@/styles/scss/copilotChat.module.scss';
// Importing custom hooks for managing chat state.
// useChatStore provides functionality to open and close the chat window.
import { useChatStore } from '@/utils/hooks/useChatStore';

// ChatDrawer function component defines the chat drawer.
// This drawer contains the chat window and slides in from the right.
const ChatDrawer = () => {
  // Retrieving chat state and actions from the chat store.
  // isChatOpen dictates whether the chat window should be visible.
  // closeChatWindow is a function to close the chat window.
  const {
    isChatOpen,
    closeChatWindow
  } = useChatStore();
  return (
    // The Drawer component provides a sliding panel from the right.
    <Drawer enableOverlay={false} // Disables the overlay click to close the drawer.
    open={isChatOpen} // Controls if the drawer is open based on the chat state.
    onClose={closeChatWindow} // Handles closing the drawer when needed.
    direction="right" // Sets the drawer to slide in from the right side.
    className={styles.drawer} // Applies custom styles from the module.
    data-sentry-element="Drawer" data-sentry-component="ChatDrawer" data-sentry-source-file="ChatDrawer.tsx">
      {/* ChatWindow component - where users will interact with the chat. */}
      {/* It receives closeChatWindow to manage closing the chat from within. */}
      <ChatWindow closeChatWindow={closeChatWindow} data-sentry-element="ChatWindow" data-sentry-source-file="ChatDrawer.tsx" />
    </Drawer>
  );
};

// Exporting ChatDrawer to be used elsewhere in the application.
export default ChatDrawer;