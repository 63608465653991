'use client';

import { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import LoaderWrapper from '@/components/common/Loaders/Loaders';
import { parseUTCDateObject } from '@/utils/dateUtils';
import DialogButton from '../DateRangeSelector/subcomponents/DialogButton';
import DialogPopover from '../DateRangeSelector/subcomponents/DialogPopover';
interface GenericDatePickerProps {
  startDate: string;
  endDate: string;
  handleDateChange: (startDate: Date, endDate: Date) => void;
  buttonClassName?: string;
  startTitle?: string;
  endTitle?: string;
  additionalElement?: React.ComponentType<{
    close: () => void;
  }>;
}
const today = parseUTCDateObject();
function GenericDatePicker({
  startDate,
  endDate,
  handleDateChange,
  buttonClassName,
  startTitle,
  endTitle,
  additionalElement
}: GenericDatePickerProps) {
  const [fromDate, setFromDate] = useState<Date>(startDate ? parseUTCDateObject(startDate) : today);
  const [toDate, setToDate] = useState<Date>(endDate ? parseUTCDateObject(endDate) : today);
  useEffect(() => {
    setFromDate(startDate ? parseUTCDateObject(startDate) : today);
    setToDate(endDate ? parseUTCDateObject(endDate) : today);
  }, [startDate, endDate]);

  /**
   * Handles the change of the fromDate.
   * Updates the state and calls the handleDateChange prop.
   *
   * @param {Date} date - The new fromDate.
   */
  const handleFromDateChange = (date: Date) => {
    setFromDate(date);
    handleDateChange(date, toDate);
  };

  /**
   * Handles the change of the toDate.
   * Updates the state and calls the handleDateChange prop.
   *
   * @param {Date} date - The new toDate.
   */
  const handleToDateChange = (date: Date) => {
    setToDate(date);
    handleDateChange(fromDate, date);
  };
  return <LoaderWrapper content="dateRangeSelector" width={190} height={40} data-sentry-element="LoaderWrapper" data-sentry-component="GenericDatePicker" data-sentry-source-file="GenericDatePicker.tsx">
      <Popup trigger={<span>
            <DialogButton fromDate={fromDate} toDate={toDate} buttonClassName={buttonClassName} />
          </span>} arrow={false} position="bottom left" contentStyle={{
      padding: '0px',
      border: 'none',
      borderRadius: '0px',
      width: 'auto',
      backgroundColor: 'transparent',
      boxShadow: 'none',
      marginTop: '10px'
    }} nested data-sentry-element="Popup" data-sentry-source-file="GenericDatePicker.tsx">
        {
      // @ts-ignore
      (close: any) => <DialogPopover fromDate={fromDate} toDate={toDate} setFromDate={handleFromDateChange} setToDate={handleToDateChange} handleDateChange={handleDateChange} startTitle={startTitle} endTitle={endTitle} close={close} additionalElement={additionalElement} />}
      </Popup>
    </LoaderWrapper>;
}
export default GenericDatePicker;