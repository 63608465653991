/* eslint-disable no-param-reassign */
import type { UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useShallow } from 'zustand/react/shallow';

import ApiUtils from '@/api/ApiUtils';
import { QUERYKEYS } from '@/miscellaneous/constant/reactQueryKeyConfig';
import type { DataStoreState } from '@/miscellaneous/store/DataStore';
import DataStore from '@/miscellaneous/store/DataStore';
import type { ZustandState } from '@/miscellaneous/store/zustand_store';
import useZustandStore from '@/miscellaneous/store/zustand_store';
import sortByDepthMemoized from '@/utils/sortByDepth';

import {
  baseOutputIDs,
  generateOutputIDToBaseOutputAncestorID,
  memoizedCreateAccountTrees,
} from './useOutputsHelper';

export interface Output {
  branch_id: number;
  created_at: string;
  name: string;
  id: number;
  updated_at: string;
  parent_output: number | null;
  is_top_level: boolean;
  base_output_id: number | null;
  emoji: null;
  sort_order: string;
  ledger_account_id: number | null;
  integration_id?: number;
  balance: number;
}

export interface AccountTree {
  output: Output;
  children?: AccountTree[];
}

const useOutputs = () => {
  const {
    activeBranchId,
    isLoggedIn,
    activeCompanyId,
    setOutputIDToBaseOutputAncestorID,
  } = useZustandStore(
    useShallow((state: ZustandState) => ({
      activeBranchId: state.activeBranch?.id ?? null,
      isLoggedIn: Boolean(state.isLoggedIn),
      activeCompanyId: state.activeCompany?.id ?? null,
      setOutputIDToBaseOutputAncestorID:
        state.setOutputIDToBaseOutputAncestorID,
    })),
  );
  const { setBranchOutputs, setAccountTrees, setBranchTopLevelOutputs } =
    DataStore((state: DataStoreState) => state);

  const {
    data,
    isLoading,
    isFetching,
    error,
    refetch,
  }: UseQueryResult<Output[], Error> = useQuery({
    queryKey: [QUERYKEYS.GETALLOUTPUTS, activeCompanyId],
    queryFn: () => ApiUtils.getAllOutputs(activeCompanyId as number),
    enabled: !!activeCompanyId && !!isLoggedIn,
  });

  useEffect(() => {
    if (data && data instanceof Array) {
      const newBranchOutputs = data.filter(
        (output) => output.branch_id === activeBranchId,
      );
      const branchTopLevelOutputs = newBranchOutputs.filter(
        (output) =>
          output?.base_output_id &&
          baseOutputIDs.includes(output?.base_output_id),
      );
      setBranchTopLevelOutputs(branchTopLevelOutputs);
      sortByDepthMemoized(newBranchOutputs, 'parent_output'); // sorting by depth
      setBranchOutputs(newBranchOutputs);
      const newAccountTrees = memoizedCreateAccountTrees(newBranchOutputs);
      setOutputIDToBaseOutputAncestorID(
        generateOutputIDToBaseOutputAncestorID(newAccountTrees),
      );

      setAccountTrees(newAccountTrees);
    }
  }, [activeBranchId, data, baseOutputIDs]);

  return {
    data,
    isLoading,
    isFetching,
    error,
    refetch,
  };
};

export default useOutputs;
