import 'animate.css';
import _ from 'lodash';
import { useEffect } from 'react';

/**
 * A React component that applies a "swoop" animation effect to its children.
 *
 * @param {Object} props - The component props.
 * @param {string} [props.direction='left'] - The direction of the swoop animation, can be 'left', 'right', 'up', or 'down'.
 * @param {React.ReactNode} props.children - The content to be animated.
 * @returns {React.ReactElement} - The animated content wrapped in a div with the appropriate CSS classes.
 */
const SwoopAnimation = ({
  direction = 'left',
  run,
  onSwoopEnd,
  children
}: {
  direction?: 'left' | 'right' | 'up' | 'down';
  run?: boolean;
  onSwoopEnd: () => void;
  children: React.ReactNode;
}) => {
  useEffect(() => {
    if (run) {
      setTimeout(() => {
        onSwoopEnd();
      }, 4000);
    }
  }, [run]);
  const generateEffect = `fadeIn${_.startCase(_.toLower(direction))}`;
  return <div className={run ? `animate__animated animate__${generateEffect}` : ''} data-sentry-component="SwoopAnimation" data-sentry-source-file="SwoopAnimation.tsx">
      {children}
    </div>;
};
export default SwoopAnimation;