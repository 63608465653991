import { useShallow } from 'zustand/react/shallow';
import { CalculateFormulas } from '@/utils/hooks/formulas/useFormulaCalculations';
import useZustandStore, { type ZustandState } from '../store/zustand_store';
const FormulaProvider = () => {
  const {
    isLoggedIn,
    activeCompany
  } = useZustandStore(useShallow((state: ZustandState) => ({
    isLoggedIn: state.isLoggedIn,
    activeCompany: state.activeCompany
  })));
  return !!isLoggedIn && activeCompany?.id && <CalculateFormulas /> || null;
};
export { FormulaProvider };