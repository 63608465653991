import { useEffect, useState } from 'react';

import type { ZustandState } from '@/miscellaneous/store/zustand_store';
import useZustandStore from '@/miscellaneous/store/zustand_store';
import type { Prompt } from '@/utils/types/AITypes';

export const usePrompts = () => {
  const [hiringPrompts, setHiringPrompts] = useState<Prompt[]>([]); // state to store hiring prompts employees
  const { prompts } = useZustandStore((state: ZustandState) => state);

  useEffect(() => {
    const filtered = (prompts as Prompt[])?.filter(
      (prompt: Prompt) => prompt.type === 'hiring-plan',
    );
    setHiringPrompts(filtered);
  }, [prompts, prompts?.length]);

  return {
    prompts,
    hiringPrompts,
  };
};
