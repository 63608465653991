import { deleteCookie, getCookie, setCookie } from 'cookies-next';

import { variables } from './styles/variables/constant';
import type { Branch } from './utils/hooks/branches/useBranches';
import type { Company } from './utils/hooks/company/useCompany';

async function createCookies(key: string, data: string) {
  await setCookie(key, data);
}

function parseCookieString(cookieStr: string): any {
  // Remove leading and trailing quotes if they exist
  // Check for and remove leading and trailing quotes if they exist
  const unquotedStr =
    cookieStr.startsWith('"') && cookieStr.endsWith('"')
      ? cookieStr.slice(1, -1)
      : cookieStr;

  // Replace common escape sequences
  const parsedStr = unquotedStr
    .replace(/\\"/g, '"') // Unescape double quotes
    .replace(/\\\\/g, '\\') // Unescape backslashes
    .replace(/\\n/g, '\n') // Unescape newlines
    .replace(/\\t/g, '\t') // Unescape tabs
    .replace(/\\r/g, '\r') // Unescape carriage returns
    .replace(/\\b/g, '\b') // Unescape backspaces
    .replace(/\\f/g, '\f'); // Unescape formfeeds

  // Parse the JSON string
  try {
    const result = JSON.parse(parsedStr);
    return result;
  } catch (e) {
    console.error('Error parsing JSON string:', e);
    return null;
  }
}

function deleteCookies() {
  deleteCookie(variables.lucid_user);
  deleteCookie(variables.lucid_user_companies);
  deleteCookie(variables.lucid_user_active_company);
  deleteCookie(variables.lucid_user_branches);
  deleteCookie(variables.lucid_user_active_branch);
}

/**
 * Checks if the necessary cookies for the active company, branch, and user exist.
 *
 * @returns {boolean} `true` if the cookies exist, `false` otherwise.
 */
const checkCookiesExist = (activeBranch: Branch, activeCompany: Company) => {
  const companyCookie = getCookie(variables.lucid_user_active_company);
  const branchCookie = getCookie(variables.lucid_user_active_branch);
  const userCookie = getCookie(variables.lucid_user);

  if (
    activeBranch &&
    Object.keys(activeBranch).length > 0 &&
    activeCompany &&
    Object.keys(activeCompany).length > 0 &&
    !userCookie &&
    !companyCookie &&
    !branchCookie
  ) {
    return false;
  }

  return true;
};

export { checkCookiesExist, createCookies, deleteCookies, parseCookieString };
