/* eslint-disable @typescript-eslint/no-shadow */
import classNames from 'classnames';
import { ChevronDown } from 'lucide-react';
import numeral from 'numeral';
import type { DropdownIndicatorProps } from 'react-select';
import Select, { components } from 'react-select';
import { EditCell, EditFloatCell, ViewCell } from '@/components/table/shared/cells/baseCells';
import CurrencyCell from '@/components/table/shared/cells/currencyCell';
import { ModelsSelectStyle } from '@/styles/SelectComponentStyle/select';
import type { ProductStageColumn, ProductStageData } from '../CompanyProductsStage';
import styles from '../companyProductsStage.module.scss';

/**
 * A custom DropdownIndicator component that renders a ChevronDown icon inside the dropdown indicator.
 *
 * @param props - The props passed to the DropdownIndicator component.
 * @returns A React component that renders the custom DropdownIndicator.
 */
const DropdownIndicator = (props: DropdownIndicatorProps) => {
  return <components.DropdownIndicator {...props} data-sentry-element="unknown" data-sentry-component="DropdownIndicator" data-sentry-source-file="ProductStageInputs.tsx">
      <ChevronDown size={16} data-sentry-element="ChevronDown" data-sentry-source-file="ProductStageInputs.tsx" />
    </components.DropdownIndicator>;
};

// Select input component
/**
 * Renders a select input component with customized styles and behavior.
 *
 * @param options - An array of options to display in the select dropdown, where each option has a `label` and `value` property.
 * @param defaultValue - The default selected option, if any.
 * @param value - The currently selected option.
 * @param onChange - A callback function that is called when the selected option changes, passing the new selected option as an argument.
 * @returns A React component that renders the select input.
 */
const SelectInput = ({
  options,
  defaultValue,
  value,
  onChange,
  translate
}: {
  options: {
    label: string;
    value: string;
  }[];
  defaultValue?: {
    label: string;
    value: string;
  };
  value: {
    label: string;
    value: string;
  } | null;
  onChange: (selectedOption: {
    label: string;
    value: string;
  }) => void;
  translate: (key: string) => string;
}) => {
  return <Select menuPlacement="top" options={options} components={{
    IndicatorSeparator: () => null,
    // @ts-ignore
    DropdownIndicator
  }} placeholder={translate('choose_an_option')} value={value} defaultValue={defaultValue}
  // @ts-ignore
  styles={{
    ...ModelsSelectStyle,
    valueContainer: styles => ({
      ...styles,
      padding: '0 0 0 5px'
    }),
    dropdownIndicator: styles => ({
      ...styles,
      padding: '0'
    }),
    container: styles => ({
      // @ts-ignore
      ...ModelsSelectStyle.container(styles),
      height: '100%',
      width: '100%'
    }),
    control: styles => ({
      // @ts-ignore
      ...ModelsSelectStyle.control(styles),
      paddingLeft: '5px',
      fontSize: '12px'
    }),
    option: (styles, {
      isSelected
    }) => ({
      // @ts-ignore
      ...ModelsSelectStyle.option(styles, {
        isSelected
      }),
      fontSize: '12px'
    })
  }} menuPosition="fixed" onChange={selectedOption => {
    onChange((selectedOption as {
      label: string;
      value: string;
    }));
  }} data-sentry-element="Select" data-sentry-component="SelectInput" data-sentry-source-file="ProductStageInputs.tsx" />;
};

/**
 * Renders the input components for the product stage in the company onboarding popup.
 *
 * @param column - The column configuration for the product stage.
 * @param product - The product stage data.
 * @param productIndex - The index of the product in the product stage.
 * @param translate - A function to translate strings.
 * @param onChangeHandler - A function to handle changes to the product data.
 * @returns The rendered input component for the product stage.
 */
const renderProductStageInputs = (column: ProductStageColumn, product: ProductStageData, productIndex: number, translate: (key: string) => string, onChangeHandler: (productIndex: number, productField: string, newValue: string | number) => void, hasError: boolean) => {
  // Field name for the product object
  const fieldName = column.title.replace('#', '').replace(/ /g, '_').replace(/\n/g, '_').toLowerCase();
  const value = (product[(fieldName as keyof ProductStageData)] as string | number | null);

  // Update the product data when the input value changes
  const updateHandler = (_e: any, type?: string) => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const {
      value
    } = _e.target;
    let newValue = !numeral(value).value() ? value : (numeral(value).value() as number);
    if (type === 'number') {
      newValue = Math.round(newValue);
    }
    if (type === 'text') {
      newValue = value.toString();
    }
    onChangeHandler(productIndex, fieldName, newValue);
  };

  // Handler functions for input components
  const handlerFunctions = (type?: string) => {
    return {
      onChange: () => (_e: any) => {
        updateHandler(_e, type);
      },
      onBlur: () => (_e: any) => {
        updateHandler(_e, type);
      }
    };
  };

  // Cell object for input components
  const cell = {
    getValue: () => value
  };
  switch (column.type) {
    case 'view':
      return <ViewCell cell={cell} className={classNames(styles.input, {
        [(styles.errorInput as string)]: hasError
      })} columnSpec={{
        style: {
          padding: '0 5px',
          fontWeight: 'bold'
        }
      }} />;
    case 'text':
      return <EditCell columnSpec={{
        type: 'text',
        handlerFunctions: {
          ...handlerFunctions('text'),
          placeholder: () => translate('company:type_here')
        }
      }} cell={cell} className={classNames(styles.input, {
        [(styles.errorInput as string)]: hasError
      })} />;
    case 'currency':
      return <CurrencyCell columnSpec={{
        handlerFunctions: handlerFunctions()
      }} cell={cell} defaultValue={(value as string)} className={(classNames(styles.input, {
        [(styles.errorInput as string)]: hasError
      }) as string)} />;
    case 'number':
      return <EditFloatCell cell={cell} className={(classNames(styles.input, {
        [(styles.errorInput as string)]: hasError
      }) as string)} columnSpec={{
        type: 'number',
        handlerFunctions: {
          ...handlerFunctions('number'),
          placeholder: () => '0',
          maxLength: () => 7
        }
      }} />;
    case 'select':
      return <SelectInput options={(column?.options?.map(option => ({
        label: option,
        value: option
      })) as {
        label: string;
        value: string;
      }[])} value={value ? {
        label: (value as string),
        value: (value as string)
      } : null} onChange={val => onChangeHandler(productIndex, fieldName, val.value)} translate={translate} />;
    default:
      return null;
  }
};
export { renderProductStageInputs, SelectInput };