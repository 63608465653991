/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */
import 'regenerator-runtime/runtime';
import { Mic, WandSparkles } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Oval } from 'react-loader-spinner';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { Tooltip } from 'react-tooltip';
import { useDebouncedCallback } from 'use-debounce';
import { useShallow } from 'zustand/react/shallow';
import StatusIndicator from '@/components/common/StatusIndicator/StatusIndicator';
import type { ZustandState } from '@/miscellaneous/store/zustand_store';
import useZustandStore from '@/miscellaneous/store/zustand_store';
import colors from '@/styles/scss/abstracts/_variables.module.scss';
import useMutations from '@/utils/hooks/mutations/useMutations';
import { errorToaster } from '@/utils/toaster/toasters';
import { areStringsEqualIgnoreWhitespaceAndCase } from '../../onBoardingPopupBodyFunctions';
import type { CompanyState, Question } from '../../onboardingPopupTypes';
import styles from './companyDetailsStage.module.scss';

/**
 * Renders a question box component with a status indicator and the question text.
 *
 * @param {Object} props - The component props.
 * @param {string} props.question - The question text to display.
 * @param {boolean} props.isChecked - Indicates whether the question has been checked.
 * @param {boolean} [props.fetching] - Indicates whether the question is being fetched.
 * @returns {JSX.Element} - The rendered question box component.
 */
export const QuestionBox = ({
  question,
  isChecked,
  fetching,
  iconSize
}: {
  isChecked: boolean;
  question?: string;
  fetching?: boolean;
  iconSize?: number;
}) => {
  return <div className={styles.questionBoxHeader} data-sentry-component="QuestionBox" data-sentry-source-file="CompanyDetailsStage.tsx">
      <span className="flex items-center gap-2">
        <StatusIndicator fetching={(fetching as boolean)} isChecked={isChecked} iconSize={iconSize} data-sentry-element="StatusIndicator" data-sentry-source-file="CompanyDetailsStage.tsx" />
        {question && <span>{question}</span>}
      </span>
    </div>;
};

/**
 * Renders the Company Details stage of the Company Onboard Popup.
 *
 * @param {Object} props - The component props.
 * @param {function} props.onChange - A callback function to update the company details data.
 * @param {CompanyDetails} props.data - The current company details data.
 * @param {Question[]} props.companyDetailsQuestions - The list of company details questions.
 * @param {function} props.setCompanyDetailsQuestions - A function to update the list of company details questions.
 * @param {function} props.resetNextStepData - A function to reset the data for the next step.
 * @returns {JSX.Element} - The rendered Company Details stage.
 */
const CompanyDetailsStage = ({
  companyDetailsQuestions,
  setCompanyDetailsQuestions,
  companyState,
  setCompanyState,
  companyDescriptionRef
}: {
  companyDetailsQuestions: Question[];
  setCompanyDetailsQuestions: (questions: Question[]) => void;
  companyState: CompanyState;
  setCompanyState: (state: CompanyState) => void;
  companyDescriptionRef: any;
}) => {
  const {
    t: translate
  } = useTranslation(['company']);
  const [text, setText] = useState(companyState['Company Details'].text);
  const {
    validateQuestionsAndAnswers,
    aiExpandText
  } = useMutations();
  const [answersFetching, setAnswersFetching] = useState(false);
  const [textIsExpanding, setTextIsExpanding] = useState(false);
  const {
    activeCompany
  } = useZustandStore(useShallow((state: ZustandState) => ({
    activeCompany: state.activeCompany
  })));
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();
  useEffect(() => {
    // Handeling detection in checking wether or not company information changed
    if (!areStringsEqualIgnoreWhitespaceAndCase(text, activeCompany?.description || '')) {
      companyDescriptionRef.current = activeCompany.description;
    } else {
      companyDescriptionRef.current = text;
    }
  }, [text]);

  /**
   * Updates the `isChecked` property of a specific `Question` in the `companyDetailsQuestions` array.
   *
   * @param {Question} question - The `Question` object to update.
   * @param {boolean} isChecked - The new value for the `isChecked` property.
   * @returns {void}
   */
  const updateQuestionIsChecked = (question: Question, isChecked: boolean) => {
    const newQuestions = [...companyDetailsQuestions];
    // @ts-ignore
    newQuestions[companyDetailsQuestions.findIndex(q => q?.question === question?.question)].isChecked = isChecked;
    setCompanyDetailsQuestions(newQuestions);
  };

  /**
   * A debounced callback function that validates the questions and answers based on the provided free text.
   *
   * @param {string} freetext - The free text to validate against the questions.
   * @returns {void}
   */
  const debouncedValidateQuestionsAndAnswers = useDebouncedCallback((freetext: string) => {
    setAnswersFetching(true);
    validateQuestionsAndAnswers.mutate({
      freetext,
      questions: companyDetailsQuestions?.map(question => question?.question)
    }, {
      onSuccess: (response: {
        [key: string]: boolean;
      }) => {
        setAnswersFetching(false);
        Object.keys(response)?.forEach((question: any) => {
          updateQuestionIsChecked((companyDetailsQuestions.find(q => q?.question === question) as Question), (response[question] as boolean));
        });
      },
      onError: () => {
        setAnswersFetching(false);
      }
    });
  }, 500, {
    maxWait: 2000
  });

  /**
   * Handles the change event for the text area input.
   *
   * @param e - The React change event for the text area.
   * @returns void
   */
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (e.target.value.length > 2000) return;
    setText(e.target.value);
    setCompanyState({
      ...companyState,
      'Company Details': {
        text: e.target.value
      }
    });
    debouncedValidateQuestionsAndAnswers(e.target.value);
  };

  /**
   * Expands the text area by fetching an expanded response from the AI.
   *
   * @returns {void}
   */
  const onExpandText = () => {
    setTextIsExpanding(true);
    aiExpandText.mutate({
      freetext: text,
      questions: companyDetailsQuestions.map(question => question.question)
    }, {
      onSuccess: (response: any) => {
        setTextIsExpanding(false);
        setText(response.response);
        debouncedValidateQuestionsAndAnswers(response.response);
        setCompanyState({
          ...companyState,
          'Company Details': {
            text: response.response
          }
        });
      },
      onError: () => {
        setTextIsExpanding(false);
        errorToaster('Error happened during onboarding our team has been notified');
      }
    });
  };

  /**
   * Starts or stops the speech recognition listener based on the `listening` state.
   *
   * This effect is triggered whenever the `listening` state changes. If `listening` is true, it starts the speech recognition listener to continuously capture the user's speech. If `listening` is false, it stops the speech recognition listener.
   */
  useEffect(() => {
    if (listening) {
      SpeechRecognition.startListening({
        continuous: true
      });
    }
  }, [listening]);

  /**
   * Stops the speech recognition listener, updates the text with the current transcript, and resets the transcript.
   *
   * This function is called when the user clicks the "Stop Listening" button. It stops the speech recognition listener, appends the current transcript to the text, and resets the transcript.
   *
   * @returns {void}
   */
  const handleStopListening = () => {
    setText(prevText => prevText + transcript);
    debouncedValidateQuestionsAndAnswers(text + transcript);
    SpeechRecognition.stopListening();
    resetTranscript();
  };
  useEffect(() => {
    if (!companyDetailsQuestions.every(question => question.isChecked) && text) {
      // run one time when the component is mounted
      debouncedValidateQuestionsAndAnswers(text);
    }
  }, []);
  return <div className={styles.companyDetailsStage} data-testid="company-details" data-sentry-component="CompanyDetailsStage" data-sentry-source-file="CompanyDetailsStage.tsx">
      <Tooltip id="my-tooltip" data-sentry-element="Tooltip" data-sentry-source-file="CompanyDetailsStage.tsx" />
      <h1 className={styles.companyDetailsStageTitle}>
        {translate('company_details_title')}
      </h1>
      {companyDetailsQuestions.map(item => <div key={item.question} className={styles.questionBox}>
          <QuestionBox question={item.question} isChecked={item.isChecked} fetching={answersFetching} />
        </div>)}
      <div className={styles.answerBoxContainer}>
        <textarea placeholder={translate('please_fill_out_the_questions_above')} className={`${styles.answerBox} ${text.length >= 500 && styles.successState}  ${text.length > 2499 && styles.errorState}`} rows={6} value={listening ? text + transcript : text} onChange={e => handleChange(e)} disabled={textIsExpanding} />
        <div className={`${styles.answerBoxCounter} 
            ${text.length >= 500 && styles.greenCircle}
        `}>
          {text.length}/500
        </div>
      </div>
      <div className={styles.actionButtons}>
        <button aria-label="Start or stop listening" data-tooltip-id="my-tooltip" data-tooltip-content={!browserSupportsSpeechRecognition ? translate('speech_to_text_not_supported') : translate('speech_to_text')} className={`${styles.recordButton} ${listening && styles.activeRecord}`} disabled={!browserSupportsSpeechRecognition || textIsExpanding} type="button" onClick={() => {
        if (!browserSupportsSpeechRecognition) return;
        if (listening) {
          handleStopListening();
        } else {
          SpeechRecognition.startListening({
            continuous: true
          });
        }
      }}>
          <Mic data-sentry-element="Mic" data-sentry-source-file="CompanyDetailsStage.tsx" />
        </button>
        <button disabled={listening || textIsExpanding || text.length === 0} className={styles.aiButton} type="button" onClick={onExpandText}>
          {textIsExpanding ? <Oval visible height="24" width="24" color={colors.lightGrey} secondaryColor={colors.white} ariaLabel="oval-loading" wrapperStyle={{}} wrapperClass="" /> : <>
              <WandSparkles size={16} />
              <span>{translate('expand_ai')}</span>
            </>}
        </button>
      </div>
    </div>;
};
export default CompanyDetailsStage;