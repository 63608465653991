/* eslint-disable react-hooks/exhaustive-deps */
import { useMembers, useSpace } from '@ably/spaces/react';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import dynamicRoute from '@/miscellaneous/constant';
import useTrackCursor from '../hooks/useTrackCursor';
import { colours } from '../utils/helpers';
import { mockNames } from '../utils/mockNames';
import type { Member } from '../utils/types';
import { MemberCursors } from './Cursors';
import styles from './LiveCursors.module.css';

/** 💡 Select a mock name to assign randomly to a new user that enters the space💡 */
const mockName = () => mockNames[Math.floor(Math.random() * mockNames.length)];
const LiveCursors = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const name = useMemo(mockName, []);
  const router = useRouter();
  /** 💡 Select a color to assign randomly to a new user that enters the space💡 */
  const userColors = useMemo(() => colours[Math.floor(Math.random() * colours.length)], []);

  /** 💡 Get a handle on a space instance 💡 */
  const {
    space
  } = useSpace();
  useEffect(() => {
    if (!space) return;
    space?.enter({
      name,
      userColors
    });
  }, [name, space, userColors]);
  const {
    self
  } = useMembers();
  const [memberIsActive, setMemberIsActive] = useState(false);
  useEffect(() => {
    if (space === null || !space || self === null || !self?.isConnected) {
      setMemberIsActive(false);
    } else {
      setMemberIsActive(true);
    }
  }, [space, self]);
  useEffect(() => {
    if (!memberIsActive) return;
    const {
      pathname
    } = router;
    const {
      href
    } = window.document.location;
    const isAuthenticatedRoute = !dynamicRoute.unAuthenticatedRoutes.includes(pathname);
    const location = isAuthenticatedRoute ? href : String(self?.connectionId) + uuidv4();
    space?.locations.set({
      location
    });
  }, [router, self?.connectionId, space, window.document.location, memberIsActive]);
  const liveCursors = useRef(null);
  useTrackCursor((_data: any) => {}, liveCursors, memberIsActive); // adding listeners on the cursor of the user

  return memberIsActive ? <div id="live-cursors" ref={liveCursors} className={`example-container ${styles.liveCursorsContainer}`} data-sentry-component="LiveCursors" data-sentry-source-file="LiveCursors.tsx">
      {children}
      <MemberCursors currentUser={(self as Member)} data-sentry-element="MemberCursors" data-sentry-source-file="LiveCursors.tsx" />
    </div> :
  // eslint-disable-next-line react/jsx-no-useless-fragment
  <>{children}</>;
};
export default LiveCursors;