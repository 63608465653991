/* eslint-disable @typescript-eslint/no-shadow */
import Link from 'next/link';
import { BsThreeDotsVertical } from 'react-icons/bs';
import Popup from 'reactjs-popup';
import { truncateLabel } from '@/utils/helpers/formattingHelper';
import OptionsMenu from '../../OptionsMenu/OptionsMenu';
import styles from '../LinkDropdown.module.scss'; // Make sure to create a corresponding CSS module for styling

const DropdownItem = ({
  item,
  checkedId,
  handleLinkClick,
  getMenuItems,
  link
}: {
  item: {
    label: string;
    href: string;
    id: number;
    isNotDeletable?: boolean;
    isNotEditable?: boolean;
  };
  checkedId?: number;
  handleLinkClick: (item: {
    label: string;
    href: string;
    id: number;
  }) => void;
  getMenuItems: (id: number, label: string, isNotEditable?: boolean, isNotDeletable?: boolean) => {
    label: string;
    id: number;
    icon: JSX.Element;
    onClick: () => void;
  }[]; // We will use a React component for the icon
  link?: boolean;
}) => {
  /**
   * Conditionally renders a Link or Button component for the dropdown item
   * based on whether the link prop is true or false.
   * Renders a Link if link is true, renders a Button if link is false.
   * Passes the item data and click handler to the rendered component.
   * Applies active styling if the item ID matches the checkedId.
   */

  const renderDropdownItem = (link: any, item: any, handleLinkClick: any, checkedId: any) => {
    return link ? <Link href={item.href} className={checkedId === item.id ? `${styles.linkOption} ${styles.active}` : styles.linkOption} onClick={() => {
      handleLinkClick(item);
    }} data-sentry-element="Link" data-sentry-component="renderDropdownItem" data-sentry-source-file="DropdownItem.tsx">
        {item.icon}
        {truncateLabel(item.label, 37)}
      </Link> : <button type="button" className={checkedId === item.id ? `${styles.linkBtn} ${styles.active}` : styles.linkBtn} onClick={() => {
      handleLinkClick(item);
    }} data-sentry-component="renderDropdownItem" data-sentry-source-file="DropdownItem.tsx">
        {item.icon}
        {truncateLabel(item.label, 26)}
      </button>;
  };
  const popupContentStyle = {
    width: 'fit-content'
  };
  return <>
      {renderDropdownItem(link, item, handleLinkClick, checkedId)}

      <Popup trigger={<span style={{
      display: item.isNotDeletable && item.isNotEditable ? 'none' : 'inline-block'
    }} className="icon">
            <BsThreeDotsVertical width={15} color="black" />
          </span>} position="right top" arrow={false} contentStyle={popupContentStyle} data-sentry-element="Popup" data-sentry-source-file="DropdownItem.tsx">
        {
      // @ts-ignore
      (close: any) => <div className={styles.dropdownOptions}>
              <OptionsMenu items={getMenuItems(item.id, item.label, item.isNotEditable, item.isNotDeletable)} close={close} />
            </div>}
      </Popup>
    </>;
};
export default DropdownItem;