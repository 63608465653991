import React, { createContext } from 'react';
import useCreateMutations from '@/utils/hooks/mutations/useCreateAllMutations';
export const MutationsContext = createContext<any | null>(null);
type HooksLayoutProps = {
  children: React.ReactNode;
};

// this layout is to create a context for the mutations
const HooksLayout: React.FC<HooksLayoutProps> = ({
  children
}) => {
  const mutations = useCreateMutations();
  return <MutationsContext.Provider value={mutations} data-sentry-element="unknown" data-sentry-component="HooksLayout" data-sentry-source-file="HooksLayout.tsx">
      {children}
    </MutationsContext.Provider>;
};
export default HooksLayout;