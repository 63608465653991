import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import ApiUtils from '@/api/ApiUtils';
import { QUERYKEYS } from '@/miscellaneous/constant/reactQueryKeyConfig';
import type { DataStoreState } from '@/miscellaneous/store/DataStore';
import DataStore from '@/miscellaneous/store/DataStore';
import type { ZustandState } from '@/miscellaneous/store/zustand_store';
import useZustandStore from '@/miscellaneous/store/zustand_store';
import { FuzzySearch } from '@/utils/SearchUtils';

import useMutations from '../mutations/useMutations';

export type HiringBranchSettings = {
  id?: number;
  branch_id?: number | null;
  payroll_output_id?: number;
  payroll_tax?: number;
  bonus_payout_month?: number;
  bonus_type?: string;
  salary_increase_month?: number;
  annual_raise_percentage?: number;
  minimum_employment_months?: number;
};

/**
 * Custom hook to manage hiring branch settings.
 *
 * Fetches hiring settings for the active branch from API.
 * Creates default hiring settings if none exist for branch.
 * Provides state and methods to update hiring settings.
 *
 * @returns
 * - branchHiringSetting - The hiring settings object for active branch
 * - updateBranchHiringSettings - Method to update settings via API
 * - refetch - Query refetch method
 * - isLoading - Loading state of query
 * - error - Any error with query
 */
const useHiringBranchSettings = () => {
  const { activeBranch, activeCompany } = useZustandStore(
    (state: ZustandState) => state,
  );
  const [branchHiringSetting, setBranchHiringSetting] =
    useState<HiringBranchSettings>();
  const { createBranchHiringSettings } = useMutations();
  const { data, isLoading, error, refetch } = useQuery<HiringBranchSettings>({
    queryKey: [QUERYKEYS.BRANCHESHIRINGSETTINGS, activeCompany?.id],
    queryFn: () =>
      ApiUtils.getBranchHiringSettings(activeCompany?.id as number),
  });
  const { branchOutputs } = DataStore((state: DataStoreState) => state);
  function createBranchSettings() {
    const payrollOutput = FuzzySearch(branchOutputs || [], 'name', [
      'payroll',
      'salary',
    ])?.[0]; // get result for output whose name contains 'payroll' or 'salary'
    createBranchHiringSettings.mutate({
      branch_id: activeBranch?.id,
      payroll_output_id: payrollOutput?.id || 0,
      payroll_tax: 0,
      bonus_payout_month: 1,
      bonus_type: 'Currency',
      salary_increse_month: 1,
      annual_raise_precentage: 5,
      minimum_employment_months: 1,
    });
  }

  useEffect(() => {
    if (data && data instanceof Array && activeCompany && activeBranch) {
      const newbranchHiringSetting = data?.find(
        (item: any) => item.branch_id === activeBranch?.id,
      );

      if (newbranchHiringSetting && newbranchHiringSetting?.branch_id) {
        setBranchHiringSetting(newbranchHiringSetting);
      } else if (
        // if we dont have a branch settings create one
        activeBranch?.id &&
        branchOutputs?.length > 0 &&
        activeCompany?.id
      )
        createBranchSettings();
    }
  }, [data, activeCompany, activeBranch?.id]);

  return {
    branchHiringSetting,
    refetch,
    isLoading,
    error,
  };
};

export default useHiringBranchSettings;
