/**
 * InteractionIcons Component
 *
 * Description:
 * The InteractionIcons component renders a set of interactive icons that allow users to engage
 * with a message. These icons provide functionality for liking, disliking, modifying the message's
 * response, and copying message text to the clipboard.
 */

'use client';

import 'reactjs-popup/dist/index.css';
import 'react-simple-toasts/dist/theme/dark.css';
import type { FC } from 'react';
import React from 'react';
import toast, { toastConfig } from 'react-simple-toasts';
import useClipboard from 'react-use-clipboard';
import Popup from 'reactjs-popup';
import ActionMenu from '@/components/common/CopilotChat/CopilotChatComponents/ActionMenu';
import CopyButtonSvg from '@/miscellaneous/Images/CopilotIcons/CopyButtonSvg';
import DisLikeButtonSvg from '@/miscellaneous/Images/CopilotIcons/DisLikeButtonSvg';
import LikeButtonSvg from '@/miscellaneous/Images/CopilotIcons/LikeButtonSvg';
import ModifyButtonSvg from '@/miscellaneous/Images/CopilotIcons/ModifyButtonSvg';
import styles from '@/styles/scss/copilotChat.module.scss';
import { useChatStore } from '@/utils/hooks/useChatStore';
interface IInteractionIconsProps {
  /**
   * Function to handle adding an option to the chat.
   * @param option - The selected option.
   * @param id - The ID of the message to replace with the option.
   */
  addOptionToChat?: (option: string, id: string) => void;

  /**
   * The unique identifier of the message.
   */
  id: string;

  /**
   * The text content of the message.
   */
  text?: string;
}

// Configuration for the toast notifications
toastConfig({
  theme: 'dark'
});

/**
 * InteractionIcons component responsible for rendering interaction buttons for a chat message.
 * @param addOptionToChat - Function to handle adding an option to the chat.
 * @param id - The unique identifier of the message.
 * @param text - The text content of the message.
 * @returns The InteractionIcons component.
 */
const InteractionIcons: FC<IInteractionIconsProps> = ({
  addOptionToChat,
  id,
  text
}) => {
  const {
    addRate,
    messages
  } = useChatStore();

  // Style for the Popup component. cannot be moved to scss file because of library Popup configuration
  const popUpStyle = {
    width: '160px',
    borderRadius: '8px',
    background: 'rgba(255, 255, 255, 0.80)',
    boxShadow: '0 8px 20px 0 rgba(19, 25, 35, 0.15)',
    backdropFilter: 'blur(25px)',
    border: 'none',
    padding: '8px',
    zIndex: 9999
  };

  /**
   * Finds a message in the chat history based on its ID.
   * @param id - The ID of the message to find.
   * @returns The found message.
   */
  const findMessage = () => {
    return messages.find(message => message.id === id);
  };

  // react-use-clipboard hook for copying text to the clipboard
  const [, setCopied] = useClipboard(text || '');

  /**
   * Handles the like action for a message.
   */
  const handleLike = () => {
    addRate(id, 'like');
  };

  /**
   * Handles the dislike action for a message.
   */
  const handleDisLike = () => {
    addRate(id, 'dislike');
  };

  // @ts-ignore
  return <div className={styles.InteractionIcons} data-sentry-component="InteractionIcons" data-sentry-source-file="InteractionIcons.tsx">
      <div className={styles.actionButtons}>
        <span onClick={handleLike}>
          <LikeButtonSvg className={findMessage()?.rate === 'like' ? styles.likeButton : undefined} data-sentry-element="LikeButtonSvg" data-sentry-source-file="InteractionIcons.tsx" />
        </span>
        <span onClick={handleDisLike}>
          <DisLikeButtonSvg className={findMessage()?.rate === 'dislike' ? styles.dislikeButton : undefined} data-sentry-element="DisLikeButtonSvg" data-sentry-source-file="InteractionIcons.tsx" />
        </span>

        <Popup trigger={<span>
              <ModifyButtonSvg />
            </span>} position={['bottom left', 'top left', 'top right', 'bottom right']} arrow={false} contentStyle={popUpStyle} repositionOnResize closeOnDocumentClick lockScroll data-sentry-element="Popup" data-sentry-source-file="InteractionIcons.tsx">
          {
        // @ts-ignore
        (close: () => void) => <ActionMenu id={id} addOptionToChat={addOptionToChat} close={close} />}
        </Popup>
      </div>

      <span onClick={() => {
      setCopied();
      toast('Copied to clipboard!', 1000);
    }}>
        <CopyButtonSvg data-sentry-element="CopyButtonSvg" data-sentry-source-file="InteractionIcons.tsx" />
      </span>
    </div>;
};
export default InteractionIcons;