/* eslint-disable */
import { WidgetWrapper } from "./wrapper";
import DropdownWidget from "./DropdownWidget";
import NoDropdownWidget from "./NoDropdownWidget";

/**
 * Tag Widget function that creates and returns a new instance of the WidgetWrapper with MyWidget.
 *
 * @param {any} props - The properties passed to the Tag Widget.
 * @returns {WidgetWrapper} A new instance of WidgetWrapper containing MyWidget.
 * @memberof module:Widgets
 */
export const WidgetWithDropdown = (props: any) => {
  // This is an example of rendering the TagWidget Creator.
  // It's a function that receives props and returns a new MyWidget wrapped in WidgetWrapper.
  return new WidgetWrapper(DropdownWidget, props);
};

/**
 * Tag Widget function that creates and returns a new instance of the WidgetWrapper with MyWidget.
 *
 * @param {any} props - The properties passed to the Tag Widget.
 * @returns {WidgetWrapper} A new instance of WidgetWrapper cont aining MyWidget.
 * @memberof module:Widgets
 */
export const WidgetWithoutDropdown = (props: any) => {
  // This is an example of rendering the TagWidget Creator.
  // It's a function that receives props and returns a new MyWidget wrapped in WidgetWrapper.
  return new WidgetWrapper(NoDropdownWidget, props);
};
