// FIXME: Update this configuration file based on your project information

export const vercelEnv =
  process.env.VERCEL_ENV || process.env.NEXT_PUBLIC_VERCEL_ENV;

export const BASE_URL =
  vercelEnv === 'production'
    ? process.env.NEXT_PUBLIC_BACKEND_SERVER_URL_LIVE
    : process.env.NEXT_PUBLIC_BACKEND_SERVER_URL_DEV;

export const AppConfig = {
  site_name: 'Lucid Dreams',
  title: 'Lucid Dreams',
  description: '',
  locale: 'en',
};
