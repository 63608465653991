export const mockNames = [
  'John Smith',
  'Jane Doe',
  'Michael Johnson',
  'Emily Davis',
  'James Brown',
  'Olivia Wilson',
  'Robert Jones',
  'Sophia Martinez',
  'William Garcia',
  'Isabella Anderson',
  'David Thomas',
  'Mia Taylor',
  'Joseph Lee',
  'Charlotte Harris',
  'Charles Clark',
  'Amelia Lewis',
  'Thomas Walker',
  'Harper Young',
  'Christopher Hall',
  'Evelyn King',
  'Daniel Allen',
  'Abigail Wright',
  'Matthew Scott',
  'Ella Green',
  'Anthony Adams',
  'Avery Baker',
  'Mark Nelson',
  'Scarlett Hill',
  'Paul Carter',
  'Grace Mitchell',
];

export const getMemberName = () =>
  mockNames[Math.floor(Math.random() * mockNames.length)];
