import { Settings } from 'lucide-react';
import type { MouseEventHandler, ReactNode } from 'react';
import { getOptionsFromHiringPlan, updateHiringPlanWithOrder } from '@/components/genericToggleMenu/subComponents/toggleHiringPlanUtils';
import type { ZustandState } from '@/miscellaneous/store/zustand_store';
import useZustandStore from '@/miscellaneous/store/zustand_store';
import useMutations from '@/utils/hooks/mutations/useMutations';
import useEmloyeeColumns from '@/utils/hooks/settings/useEmployeeColumns';
import type { TogglePopoverProps } from './GenericToggleMenu';
import TogglePopover from './GenericToggleMenu';
import type { Option } from './subComponents/ToggleOptions';
function getPopoverProps(options: Option[], departmentOptions: Option[], renderReference: (onClick: () => void) => ReactNode): TogglePopoverProps {
  return {
    sections: [
    // {
    //   title: 'Row Options',
    //   options: [
    //     {
    //       name: 'Inactive Employees',
    //       enabled: true, // Assume initial state is true
    //       onSelectionChange: () => console.log('Toggled Inactive Employees'),
    //       isDraggable: false,
    //     },
    //   ],
    // },

    {
      title: 'Department Options',
      options: departmentOptions.map((option: Option) => {
        return {
          ...option,
          name: option.name.replace('n', '&').toUpperCase()
        };
      })
    }, {
      title: 'Column Options',
      options
    }],
    reportSettingsLabel: 'Report Settings',
    // hrefSettings: '/settings',
    renderReference
  };
}

/**
 * A function that uses TogglePopover to generate setting section for hiring plan
 * @returns Settings Cog icon and popover for hiring plan
 */
export const HiringSettingPopover = () => {
  const {
    updateHiringPlan
  } = useMutations();
  const {
    branchHiringPlans,
    activeHiringPlan
  } = useZustandStore((state: ZustandState) => state);
  const {
    fields
  } = useEmloyeeColumns();
  const firstHiringPlan = branchHiringPlans[0]; // TODO needed otherwise it is not fecthing hiring plans, and throws hydration error
  if (!firstHiringPlan || firstHiringPlan === undefined) {
    // check that there ar hiring plans otherwise return p tag
    return <p id="popover" />;
  }

  // this is the list of options that are not draggable for example the ['inactive employees','name']. you can add more to this list
  const isNotDraggableOptions = [''];
  const [hiringPlanOptions, departmentOptions] = getOptionsFromHiringPlan(activeHiringPlan, updateHiringPlan, fields, isNotDraggableOptions);

  /**
   * Updates the sort order of the active hiring plan.
   *
   * @param order - The new sort order string to update the active hiring plan with.
   */
  const updateSortOrder = (order: string) => {
    updateHiringPlanWithOrder(activeHiringPlan, updateHiringPlan, order);
  };
  const renderReference = (onClick // rendering the cog icon button to display the settings above
  : MouseEventHandler<HTMLButtonElement> | undefined) => <button type="button" onClick={onClick} className="button" aria-label="Settings" data-sentry-component="renderReference" data-sentry-source-file="HiringPlanSettingTogglePopup.tsx">
      <Settings size={20} data-sentry-element="Settings" data-sentry-source-file="HiringPlanSettingTogglePopup.tsx" />
    </button>;
  return <TogglePopover {...getPopoverProps(hiringPlanOptions ? ((hiringPlanOptions as unknown) as Option[]) : [], departmentOptions ? ((departmentOptions as unknown) as Option[]) : [], renderReference)} isDraggable updateSortOrder={updateSortOrder} data-sentry-element="TogglePopover" data-sentry-component="HiringSettingPopover" data-sentry-source-file="HiringPlanSettingTogglePopup.tsx" />;
};