import { PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import type { Output } from '@/utils/hooks/Outputs/useOutputs';
import type { TableRow, TableRowType } from '../../types';

// this is the row type to feed sortable layout of the table
export interface FlattenedRow {
  id: string;
  original: any;
  depth: number;
  parentId?: string;
}

// shared function to get sensorrs on the same configuration
export function useGetSensors() {
  const sensors = useSensors(useSensor(PointerSensor, {
    activationConstraint: {
      distance: 10
    }
  }));
  return sensors;
}
export type DropPosition = 'top' | 'middle' | 'bottom';
export const logDND = false;
export const outPosition = 'top'; // we keep this dynamic so we allow to change the outpostion of the drop. If we drop an item on the top of the other row we expect it to be above and out of the row.
function removePipeFromStartAndEnd(str: string): string {
  return str.replace(/^\||\|$/g, '');
}
// Define the handler function type
export type OverRowHandler = (over: TableRow, dropped: TableRow, position: DropPosition) => void;
// type DroppedRowHandler = (dropped: any, position: DropPosition) => void;
export function updateSortOrder(sortOrder: string, position: DropPosition, oldId: string | number, newId: string | number, defaultCaseAddToStart: boolean = true): string {
  if (!newId) return sortOrder;
  const oldIdString = oldId.toString();
  const newIdString = newId.toString();
  const orderArray = sortOrder.split('|');
  // Remove existing newId from its current position
  const newIndex = orderArray.findIndex(id => id === newIdString);
  if (newIndex !== -1) {
    orderArray.splice(newIndex, 1);
  }
  // Recalculate oldIndex after removing newId
  const oldIndex = orderArray.findIndex(id => id === oldIdString);
  if (oldIndex === -1) {
    // If oldId is not found, append newId to the start or end by default
    if (defaultCaseAddToStart) {
      orderArray.unshift(newIdString);
    } else {
      orderArray.push(newIdString);
    }
  } else {
    const insertIndex = position === 'top' ? oldIndex : oldIndex + 1;
    orderArray.splice(insertIndex, 0, newIdString);
  }
  // Remove duplicates from order array, no duplicates should be there
  const uniqueOrderArray = Array.from(new Set(orderArray));
  const result = removePipeFromStartAndEnd(uniqueOrderArray.join('|'));
  return result;
}
// function that removes an id from sort order
export function removeIdFromSortOrder(sortOrder: string, idToRemove: string): string {
  if (!idToRemove) return sortOrder;
  const idString = idToRemove.toString();
  const orderArray = sortOrder.split('|');
  const index = orderArray.findIndex(item => item === idString);
  if (index === -1) {
    return sortOrder;
  }
  orderArray.splice(index, 1);
  return removePipeFromStartAndEnd(orderArray.join('|'));
}
export function handleError(over: TableRow, dropped: TableRow) {
  if (logDND) {
    console.error('🚀 ~ invalid drop on row ~ over:', over);
    console.error('🚀 ~ invalid drop on row ~ dropped:', dropped);
  }
}
/**
 * A map that take a tableRowType and returns the types that can be dropped into it.
 * This is handleing DND for Models table and for Report table.
 */
const tableRowValidDroppableTypes: Record<TableRowType, TableRowType[]> = {
  formula: ['section', 'formula', 'formulaGroup'],
  // section be above or below
  section: ['section', 'accountTree', 'formula'],
  // sections can accept sections, accountTree, formulas
  accountTree: ['output', 'accountTree', 'section'],
  // accountTree can accept output only, and sort account trees up and below
  output: ['output'],
  // output can accept output only
  total: ['section'],
  // section be above or below
  gap: [],
  button: [],
  formulaGroup: ['formula', 'formulaGroup'],
  // accepts above below and mid formulaGroup and formulas
  department: ['department'],
  employee: ['employee', 'department']
};
export function isReportItem(item: any) {
  // check if the item is instance of Report
  return Object.prototype.hasOwnProperty.call(item, 'report_id');
}
export function isFormula(item: any) {
  // check if the item is instance of Report
  return Object.prototype.hasOwnProperty.call(item, 'model_id');
}
export type DraggableItem = {
  id?: number;
  parent_id?: number | null;
  sort_order?: string;
  is_group?: boolean;
};
export type HandleDropProps<T extends DraggableItem> = {
  overItem: T;
  droppedItem: T;
  position: DropPosition;
  createItem: (item: T) => Promise<T>;
  checkIsAncestor: (overItem: T, droppedItem: T) => boolean;
  updateItem: (item: T, changes: Partial<T>) => void;
  activeItems: T[];
  activeContainer: any; // Can be Model, Report, etc.
};
export function isValidDrop(over: TableRow, dropped: TableRow) {
  if (!over?.type || !dropped?.type || !tableRowValidDroppableTypes[over?.type].includes(dropped.type)) {
    // row cannot be dropped on this row.
    handleError(over, dropped);
    return false;
  }
  return true;
}
export function logDrop(position: any, dropped: any, over: any, draggedItem?: any, droppedItem?: any) {
  console.log('🚀 ~ useDndTableLogic ~ position:', position);
  console.log('🚀 ~ useDndTableLogic ~ dropped:', dropped);
  console.log('🚀 ~ useDndTableLogic ~ over:', over);
  console.log('🚀 ~ useDndTableLogic ~ draggedItem:', draggedItem);
  console.log('🚀 ~ useDndTableLogic ~ droppedItem:', droppedItem);
}
export function handleOutputOnOutputDrop(over: Output, dropped: Output, position: DropPosition, updateOutput: {
  mutate: (output: any) => void;
}, branchOutputs: Output[]) {
  const overRowObject = over;
  const droppedRowObject = dropped;
  const isOverRowAParent = branchOutputs.find(
  // is the over row a parent of some other output
  (item: Output) => item?.parent_output === overRowObject.id);
  // if the position is middle, or its a top level output or its some parent output, then set the new parent as the overRowObject. Otherwise take over row parent.
  const newParent = position === 'middle' || !overRowObject.parent_output || isOverRowAParent ? overRowObject : branchOutputs.find((item: Output) => item?.id === overRowObject.parent_output);
  const droppedParentObject = droppedRowObject?.parent_output ? branchOutputs.find((item: Output) => item?.id === droppedRowObject.parent_output) : null;

  // handle updating previous parent.
  if (droppedParentObject) {
    if (droppedParentObject?.sort_order && droppedParentObject?.sort_order.includes(droppedRowObject?.id.toString())) {
      updateOutput.mutate({
        ...droppedParentObject,
        sort_order: removeIdFromSortOrder(droppedParentObject?.sort_order || '', droppedRowObject?.id?.toString() || '')
      });
    }
  }

  // update dropped object
  updateOutput.mutate({
    ...droppedRowObject,
    parent_output: newParent?.id
  });
  const idForSorting = overRowObject?.id;
  const parentNewSortOrder = updateSortOrder(newParent?.sort_order || '', position, idForSorting, droppedRowObject?.id || '');
  updateOutput.mutate({
    ...newParent,
    sort_order: parentNewSortOrder
  });
  return 1;
}
export function handleInvalidDrop(over: TableRow, dropped: TableRow, position: DropPosition) {
  if (logDND) logDrop(position, dropped, over);
}
export function checkIsAncestor<T extends {
  id: string | number;
  parent_id?: string | number | null;
}>(over: T, dropped: T, activeItems: T[]): boolean {
  if (over.id === dropped.id) return true;
  if (over.parent_id === dropped.id) return true;
  if (!over.parent_id) return false;
  const parent = activeItems.find((item: T) => item.id === over.parent_id);
  if (!parent) return false;
  return checkIsAncestor(parent, dropped, activeItems);
}