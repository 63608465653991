import type { Report } from '@/utils/hooks/reports/reportTypes';

// /**
//  * Generates a new name for the given name by removing the "show" prefix and adding "Show" before the remaining text.
//  *
//  * @param name - The original name to generate a new name for
//  * @returns The new name with "Show" prefix instead of "show"
//  */
// const generateNewName = (name: string) => {
//   const newName = name.split('show')[name.split('show').length - 1];

//   return `Show ${newName}`;
// };

const displayNames: {
  [key: string]: string;
} = {
  showMonths: 'Months',
  showQuarters: 'Quarter Summaries',
  showYears: 'Annual Summaries',
  showTotal: 'Overall Total',
  showEmptyAccounts: 'Empty Accounts'
};

// Function to get the display name from the dictionary
function getDisplayName(key: string): string {
  // Convert from camelCase to the key used in the displayNames dictionary if needed
  // Example: showMonths -> showMonths (no conversion needed in this case)
  return displayNames[key] ?? '';
}

/**
 * Generates an array of column option objects from the given report object.
 *
 * Maps over the report settings and returns an array of objects with keys:
 * - name: The setting key with "Show" prefix instead of "show"
 * - enabled: The setting value
 * - onSelectionChange: A function to update the setting when toggled
 * - isDraggable: false
 *
 * The last setting is excluded from the returned array.
 */
const getOptionsFromReports = (activeReport: Report | undefined, updateReport: {
  mutate: (arg0: any) => void;
}): any => {
  if (!activeReport || !activeReport.settings) {
    return [];
  }
  const {
    settings
  } = activeReport;

  // sort the report settings so months is first, quarter second, annual third, overall total fourth, empty accounts last
  const settingsKeys = {
    showMonths: settings.showMonths,
    showQuarters: settings.showQuarters,
    showYears: settings.showYears,
    showTotal: settings.showTotal,
    showEmptyAccounts: settings.showEmptyAccounts
  };
  const columnOptions = Object.entries(settingsKeys).map(([key, value]) => {
    return {
      name: getDisplayName(key),
      enabled: value,
      onSelectionChange: (toggleValue: boolean) => {
        return updateReport.mutate({
          ...activeReport,
          settings: {
            ...activeReport.settings,
            [key]: toggleValue
          }
        });
      },
      isDraggable: false
    };
  });
  return columnOptions;
};
export { getOptionsFromReports };