import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';
import { useOpenVault } from '@/components/authComponents/useOpenVault';
import DeletePopup from '@/components/common/DeletePopup/DeletePopup';
import { IntegrationBox } from '@/components/Settings/Integrations/Integrations';
import type { UtilsStoreState } from '@/miscellaneous/store/utilsStore/utilsStore';
import useUtilsStore from '@/miscellaneous/store/utilsStore/utilsStore';
import useMutations from '@/utils/hooks/mutations/useMutations';
import type { ApiDeckConnector } from '@/utils/hooks/useGetConnectors/useGetConnectors';
import useGetConnectors from '@/utils/hooks/useGetConnectors/useGetConnectors';
import type { Integration } from '@/utils/hooks/useIntegrations/useIntegrations';
import useIntegrations from '@/utils/hooks/useIntegrations/useIntegrations';
import queryClient from '@/utils/queryClient';
import type { CompanyState } from '../../onboardingPopupTypes';
import styles from './companyNameOrIntegrationsStage.module.scss';

/**
 * Renders the Integrations stage of the CompanyOnboardPopup.
 * This component displays the available accounting and payroll integrations
 * that the user can connect to their company.
 *
 * The component uses the `useGetConnectors` and `useGetVaultToken` hooks
 * to fetch the available integrations and the vault token, respectively.
 * It then renders the available integrations in separate sections for
 * accounting and payroll.
 *
 * The `onConnectIntegration` function is called when the user clicks
 * on an integration to connect it. This function opens the Apideck Vault
 * with the necessary parameters to authenticate the user and connect
 * the integration.
 */
export const IntegrationsStage = ({
  onChange
}: {
  onChange: (data: {
    companyName: string;
    connectedIntegrations: Integration[];
  }) => void;
}) => {
  const {
    t: translate
  } = useTranslation(['company', 'integrations']);
  const {
    setAppLoader
  } = useUtilsStore(useShallow((state: UtilsStoreState) => ({
    setAppLoader: state.setAppLoader
  })));
  const [accountingIntegrations, setAccountingIntegrations] = useState<ApiDeckConnector[]>([]);
  const [payrollIntegrations, setPayrollIntegrations] = useState<ApiDeckConnector[]>([]);
  const [activeIntegrations, setActiveIntegrations] = useState<Integration[]>([]);
  const [disconntectedIntegration, setDisconnectedIntegration] = useState<Integration | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    removeIntegration
  } = useMutations();
  const {
    connectorsData,
    isLoading
  } = useGetConnectors();
  const {
    data: integrations
  } = useIntegrations();
  const openVault = useOpenVault();

  // Filters the connectors data based on the 'accounting' and 'payroll' unified_apis
  useEffect(() => {
    if (connectorsData) {
      const accountingIntegrationsData = connectorsData.filter((integration: ApiDeckConnector) => integration.unified_api === 'accounting');
      const payrollIntegrationsData = connectorsData.filter((integration: ApiDeckConnector) => integration.unified_api === 'hris');
      setAccountingIntegrations(accountingIntegrationsData);
      setPayrollIntegrations(payrollIntegrationsData);
    }
  }, [connectorsData]);

  /**
   * Initializes the `activeIntegrations` state and updates the `onChange` callback with the initial integrations data.
   *
   * This effect is triggered whenever the `integrations` data changes. It sets the `activeIntegrations` state to the current
   * integrations data and calls the `onChange` callback with the updated integrations data.
   *
   * @param {Integration[]} integrations - The current integrations data.
   * @param {(data: { companyName: string; connectedIntegrations: Integration[]; }) => void} onChange - The callback function to update the parent component with the current integrations data.
   */
  useEffect(() => {
    if (integrations) {
      setActiveIntegrations(integrations);
      onChange({
        companyName: '',
        connectedIntegrations: integrations
      });
    }
  }, [integrations]);

  /**
   * Checks if the given API Deck connector is already connected as an active integration.
   *
   * @param integration - The API Deck connector to check.
   * @returns `true` if the integration is already connected, `false` otherwise.
   */
  const checkConnectedIntegrations = (integration: ApiDeckConnector) => {
    return activeIntegrations.some((item: Integration) => item.service_id === integration.service_id);
  };

  /**
   * Removes the specified integration from the active integrations.
   *
   * @param integration - The integration to be removed.
   */

  const onDisconnectIntegration = (integration: Integration) => {
    removeIntegration.mutate({
      id: integration.id
    }, {
      onSuccess: () => {
        queryClient.invalidateQueries();
      }
    });
  };

  // Handles the logic for connecting an integration
  const onConnectIntegration = (item: ApiDeckConnector | Integration) => {
    if (checkConnectedIntegrations((item as ApiDeckConnector))) {
      const selectedIntegration = activeIntegrations.find((integration: Integration) => integration.service_id === (item as ApiDeckConnector).service_id);
      setDisconnectedIntegration((selectedIntegration as Integration));
      setIsModalOpen(true);
      return;
    }
    openVault((item as ApiDeckConnector).service_id, (item as ApiDeckConnector).unified_api);
  };

  /**
   * Handles the loading state of the application.
   *
   * This effect sets the `appLoader` state to `true` when the `isLoading` state is `true`, and sets it to `false` when `isLoading` is `false`.
   * This is typically used to show or hide a loading spinner or indicator in the UI while the application is loading data or performing some operation.
   */
  useEffect(() => {
    if (isLoading) setAppLoader(true);else setAppLoader(false);
  }, [isLoading]);
  return <div className="h-full w-full overflow-y-auto" data-testid="integrations-stage" data-sentry-component="IntegrationsStage" data-sentry-source-file="CompanyNameOrIntegrationsStage.tsx">
      <div className={styles.integrationsPanel}>
        <div className={styles.integrationsPanelInner}>
          <h1>{translate('connect_integrations')}</h1>
          <p>{translate('connect_integrations_subtext')}</p>
        </div>
        <div className={styles.integrationsPanelContent}>
          {accountingIntegrations.length > 0 && <div>
              <div className={styles.integrationsPanelSubTitle}>
                <h1>{translate('integrations:accounting')}</h1>
              </div>
              <div className={styles.integrationsPanelSubContent}>
                {accountingIntegrations.map(integration => <IntegrationBox key={integration.service_id} item={integration} isIntegrationPopUpOpen onConnectIntegration={onConnectIntegration} disableSettingsIcon isConnected={checkConnectedIntegrations(integration)} />)}
              </div>
            </div>}

          {payrollIntegrations.length > 0 && <div>
              <div className={styles.integrationsPanelSubTitle}>
                <h1>{translate('integrations:payroll')}</h1>
              </div>
              <div className={styles.integrationsPanelSubContent}>
                {payrollIntegrations.map(integration => <IntegrationBox key={integration.service_id} item={integration} isIntegrationPopUpOpen onConnectIntegration={onConnectIntegration} disableSettingsIcon isConnected={checkConnectedIntegrations(integration)} />)}
              </div>
            </div>}
        </div>

        <DeletePopup isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} title={translate('integrations:disconnect-data-alert')} sumbitTitle={translate('integrations:disconnect-data')} closeModal={() => setIsModalOpen(false)} onDelete={() => {
        onDisconnectIntegration((activeIntegrations.find((integration: Integration) => integration.service_id === disconntectedIntegration?.service_id) as Integration));
        setIsModalOpen(false);
      }} data-sentry-element="DeletePopup" data-sentry-source-file="CompanyNameOrIntegrationsStage.tsx" />
      </div>
    </div>;
};

/**
 * Renders the CompanyNameStage component, which allows the user to input and update the company name.
 *
 * @param onChange - A function that is called when the company name is updated, with the new company name as the argument.
 * @param data - An object containing the current company name data.
 * @param nextStep - An optional function that is called when the user presses the Enter key and the company name is at least 3 characters long.
 */
export const CompanyNameStage = ({
  onChange,
  data,
  nextStep
}: {
  onChange: (data: CompanyState['Company Name']) => void;
  data: CompanyState['Company Name'];
  nextStep?: () => void;
}) => {
  const [selectedName, setSelectedName] = useState<string>(data.companyName);
  const {
    t: translate
  } = useTranslation(['company']);

  // Handles the change of the company name
  const handleNameChange = (e: any) => {
    setSelectedName(e.target.value);
    onChange({
      companyName: e.target.value
    });
  };
  useEffect(() => {
    // Checking wether or not company name was updated
    if (data.companyName) {
      setSelectedName(data.companyName);
    }
  }, [data]);
  return <div className="h-full w-full" data-testid="company-name-stage" data-sentry-component="CompanyNameStage" data-sentry-source-file="CompanyNameOrIntegrationsStage.tsx">
      <div className="relative h-full w-full">
        <input type="text" className={styles.addNameInput} placeholder={translate('company_name')}
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus value={selectedName} onChange={e => handleNameChange(e)} onKeyDown={e => {
        if (e.key === 'Enter' && selectedName.length >= 3) {
          nextStep?.();
        }
      }} />
      </div>
    </div>;
};