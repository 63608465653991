// utils/queryClient.js

import { QueryClient } from '@tanstack/react-query';

import { queryCacheTime } from '@/miscellaneous/constant/queryConstant';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: true,
      refetchOnWindowFocus: true,
      refetchIntervalInBackground: true,
      // set 4 hours for refetch interval
      refetchInterval: 4 * 60 * 60 * 1000,
      staleTime: queryCacheTime.staleTime,
      gcTime: queryCacheTime.staleTime,
    },
  },
});

export default queryClient;
