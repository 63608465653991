import memoize from 'lodash/memoize';

type ParentField<T> = keyof T;
type IdField<T> = keyof T;

// function that sorts items by depth, returns the deepest nodes first, the shallowest roots last.
function sortByDepth<T>(
  items: T[],
  parentField: ParentField<T>,
  idField: IdField<T> = 'id' as IdField<T>,
): void {
  const depthMap = new Map<string, number>();

  function getDepth(item: T, curDepth: number = 0): number {
    const itemId = String(item[idField]);
    if (curDepth > 20) {
      // blocking endless loop
      return curDepth;
    }

    if (depthMap.has(itemId)) {
      return depthMap.get(itemId)!;
    }

    if (
      item[parentField] === null ||
      item[parentField] === undefined ||
      item[parentField] === item[idField] // handeling self referencing items
    ) {
      depthMap.set(itemId, 0);
      return 0;
    }

    const parentItem = items.find(
      (i) => String(i[idField]) === String(item[parentField]),
    );
    if (!parentItem) {
      depthMap.set(itemId, 0);
      return 0;
    }

    const parentDepth = getDepth(parentItem, curDepth + 1);
    const depth = parentDepth + 1;
    depthMap.set(itemId, depth);
    return depth;
  }

  items.forEach((item) => {
    getDepth(item);
  });

  items
    .sort((a, b) => {
      const aDepth = depthMap.get(String(a[idField]))!;
      const bDepth = depthMap.get(String(b[idField]))!;

      if (aDepth === bDepth) {
        const aParentId = a[parentField] ? String(a[parentField]) : '';
        const bParentId = b[parentField] ? String(b[parentField]) : '';

        if (aParentId === bParentId) {
          return String(a[idField]).localeCompare(String(b[idField]));
        }

        return aParentId.localeCompare(bParentId);
      }

      return aDepth - bDepth;
    })
    .reverse();
}

const sortByDepthMemoized = memoize(
  sortByDepth,
  (items, parentField, idField) =>
    `${JSON.stringify(items)}-${String(parentField)}-${String(idField)}`,
);

export default sortByDepthMemoized;
