import Link from 'next/link';
import type { ReactNode } from 'react';
import React from 'react';
import Popup from 'reactjs-popup';
import styles from './genericToggle.module.scss';
import DragAndDropComponent from './subComponents/DragAndDropComponent';
import OptionList from './subComponents/OptionList';
import type { Option } from './subComponents/ToggleOptions';
const dynamicRenderSectionBody = ({
  options,
  isDraggable,
  updateSortOrder
}: {
  options: Option[];
  isDraggable: boolean;
  updateSortOrder?: (order: string) => void;
}) => {
  return isDraggable ? <DragAndDropComponent options={options} updateSortOrder={updateSortOrder} data-sentry-element="DragAndDropComponent" data-sentry-component="dynamicRenderSectionBody" data-sentry-source-file="GenericToggleMenu.tsx" /> : <OptionList options={options} data-sentry-element="OptionList" data-sentry-component="dynamicRenderSectionBody" data-sentry-source-file="GenericToggleMenu.tsx" />;
};

// Represents the properties of a section containing multiple toggle options.
export type SectionProps = {
  title: string;
  options: Option[];
  updateSortOrder?: (order: string) => void;
  isDraggable?: boolean;
};

// Section component representing a group of toggle options under a common title.
const Section: React.FC<SectionProps> = ({
  title,
  options,
  updateSortOrder,
  isDraggable
}) => {
  return <div className={styles.section} data-sentry-component="Section" data-sentry-source-file="GenericToggleMenu.tsx">
      <h3 className={styles.sectionTitle}>{title}</h3>
      {dynamicRenderSectionBody({
      options,
      isDraggable: isDraggable || false,
      updateSortOrder
    })}
    </div>;
};

/**
 * PopUpContent component renders the content inside the toggle popover.
 * It maps over the sections prop to render Section components.
 * It also renders a Link to the settings page.
 * Takes in sections, labels, hrefs, and other props to configure the content.
 */
const PopUpContent = ({
  sections,
  reportSettingsLabel,
  hrefSettings,
  updateSortOrder,
  isDraggable
}: {
  sections: SectionProps[];
  reportSettingsLabel: string;
  hrefSettings?: string;
  updateSortOrder?: (order: string) => void;
  isDraggable?: boolean;
}) => {
  return <div className={styles.popover} id="popover" data-sentry-component="PopUpContent" data-sentry-source-file="GenericToggleMenu.tsx">
      <div className={styles.sectionsContainer}>
        {sections.map((section, index: number) => <Section
      // eslint-disable-next-line react/no-array-index-key
      key={`section-${section?.title?.split(' ')?.join('')?.toLocaleLowerCase()}-${index}`} {...section} title={(section?.title as string)} updateSortOrder={updateSortOrder} isDraggable={isDraggable} />)}
      </div>
      {/* Only render the link if hrefSettings is not empty */}
      {hrefSettings && <Link href={hrefSettings} className={styles.settingsContainer}>
          <img src="/assets/icons/settings.svg" alt="settings" />
          <span className={styles.settingsLabel}>{reportSettingsLabel}</span>
        </Link>}
    </div>;
};

// Properties for the TogglePopover component.
export type TogglePopoverProps = {
  sections: SectionProps[];
  reportSettingsLabel: string;
  hrefSettings?: string;
  renderReference: (onClick: () => void) => ReactNode;
  updateSortOrder?: (order: string) => void;
  isDraggable?: boolean;
};

// Main component representing the toggle popover with sections and settings link.
const TogglePopover: React.FC<TogglePopoverProps> = ({
  sections,
  reportSettingsLabel,
  hrefSettings,
  renderReference,
  updateSortOrder,
  isDraggable
}) => {
  /**
   * Renders the trigger element that opens the popover when clicked.
   * Uses the renderReference callback to render the trigger element.
   */
  const trigger = <span>{renderReference(() => {})}</span>;

  /**
   * Styles for the popup component containing the toggle menu content.
   * Sets padding, border, margin, background, and box shadow to achieve a seamless
   * transition from trigger to content.
   */
  const popupStyle = {
    padding: '0',
    border: 'none',
    marginTop: '-5px',
    backgroundColor: 'transparent',
    boxShadow: 'none'
  };
  return <Popup trigger={trigger} position="bottom left" arrow={false} contentStyle={popupStyle} data-sentry-element="Popup" data-sentry-component="TogglePopover" data-sentry-source-file="GenericToggleMenu.tsx">
      <PopUpContent isDraggable={isDraggable} sections={sections} reportSettingsLabel={reportSettingsLabel} hrefSettings={hrefSettings} updateSortOrder={updateSortOrder} data-sentry-element="PopUpContent" data-sentry-source-file="GenericToggleMenu.tsx" />
    </Popup>;
};
export default TogglePopover;