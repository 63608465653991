import { Plus } from 'lucide-react';
import React from 'react';
import { Collapse } from 'react-collapse';
import { MdArrowDropDown, MdArrowRight } from 'react-icons/md';
import styles from '../FinancialDrawerContent/financialDrawerContentStyles.module.scss';

/**
 * Renders a collapsible section in the financial reports drawer.
 *
 * @param title - The title of the section.
 * @param addClickHandler - A callback function to handle clicks on the "Add" button.
 * @param children - The content to be displayed inside the collapsible section.
 * @returns A React component that renders the collapsible section.
 */
const DrawerBodySection = ({
  title,
  addClickHandler = () => {},
  children
}: {
  title: string;
  addClickHandler?: () => void;
  children: React.ReactNode;
}) => {
  const [isOpened, setIsOpened] = React.useState(true);
  return <div className={styles.drawerBodySection} data-sentry-component="DrawerBodySection" data-sentry-source-file="DrawerBodySection.tsx">
      <div className={styles.drawerBodySectionHeader} onClick={() => setIsOpened(!isOpened)}>
        <div className="flex items-center gap-2">
          {isOpened ? <MdArrowDropDown size={24} /> : <MdArrowRight size={24} />}
          <span>{title}</span>
        </div>
        <div className="hidden">
          <Plus onClick={addClickHandler} size={18} data-sentry-element="Plus" data-sentry-source-file="DrawerBodySection.tsx" />
        </div>
      </div>
      <Collapse isOpened={isOpened} data-sentry-element="Collapse" data-sentry-source-file="DrawerBodySection.tsx">
        <div>{children}</div>
      </Collapse>
    </div>;
};
export default DrawerBodySection;