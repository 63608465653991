import { CircleCheck, CircleX } from 'lucide-react';
import React from 'react';
import { Oval } from 'react-loader-spinner';
import colors from '@/styles/scss/abstracts/_variables.module.scss';
interface StatusIndicatorProps {
  fetching: boolean;
  isChecked: boolean;
  iconSize?: number;
}

/**
 * A React functional component that renders a status indicator based on the provided props.
 *
 * @param {StatusIndicatorProps} props - The props for the StatusIndicator component.
 * @param {boolean} props.fetching - Indicates whether the component is in a fetching state.
 * @param {boolean} props.isChecked - Indicates whether the component should display a checked state.
 * @returns {React.ReactElement} - The rendered StatusIndicator component.
 */
const StatusIndicator: React.FC<StatusIndicatorProps> = ({
  fetching,
  isChecked,
  iconSize = 16
}) => {
  return fetching ? <Oval visible height={iconSize} width={iconSize} color={colors.slateGray} secondaryColor={colors.midBlue} ariaLabel="oval-loading" wrapperStyle={{}} wrapperClass="" data-sentry-element="Oval" data-sentry-component="StatusIndicator" data-sentry-source-file="StatusIndicator.tsx" /> : isChecked ? <CircleCheck size={iconSize} style={{
    color: colors.shamrockGreen
  }} /> : <CircleX size={iconSize} style={{
    color: colors.red
  }} />;
};
export default StatusIndicator;