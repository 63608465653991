// templateUtils.ts

import { QUERYKEYS } from '@/miscellaneous/constant/reactQueryKeyConfig';

import { parseUTCDateObject } from '../dateUtils';
import type { Company } from '../hooks/company/useCompany';
import queryClient from '../queryClient';

// Define types for the objects used in the code
interface Template {
  id: any;
  model_ids: any[];
  models: any[];
  dashboard_ids: any[];
  dashboards: any[];
  graphs: any[];
  formulas: any[];
  start_date: Date;
}

interface Model {
  name: any;
  model_type: any;
  branch_id: any;
  template_id: any;
  company_id: number;
}

interface Dashboard {
  name: any;
  branch_id: any;
  description: any;
  model_id: any;
  company_id: any;
  type: string;
}

interface Graph {
  graph_data: {
    name: any;
    dashboard_id: any;
    type: any;
    is_forecast_vs_actuals: any;
    is_single_scenario: any;
    is_monthly: any;
    is_quarterly: any;
    is_annually: any;
    is_annualy: any;
  };
  options: any[];
  layout_data: any[];
  data_groups: any[];
}

interface Option {
  graph_id: any;
  variable_ids: any[];
  planned_vs_actuals: any;
  time_scale: any;
  all_branches: any;
  max_value: any;
  total_label: any;
  row_count: any;
}

interface Layout {
  graph_id: any;
  lg: number;
  sm: number;
}

interface DataGroup {
  data: {
    graph_id: any;
    is_data_highlights: any;
    id: any;
  };
  data_points: any[];
}

interface DataPoint {
  data_group_id: any;
  sign: any;
  color: any;
  graph_id: any;
  output_id: any;
}

interface Formula {
  group: any;
  input_type: any;
  model_id: any;
  name: any;
  expression_string: any;
  emoji: any;
  branch_id: any;
}

function buildModelObject(model: any, companyId: number): Model {
  return {
    name: model.name,
    model_type: model.model_type,
    branch_id: model.branch_id,
    template_id: model.template_id,
    company_id: companyId,
  };
}

function buildDashboardObject(dashboard: any): Dashboard {
  return {
    name: dashboard.name,
    branch_id: dashboard.branch_id,
    description: dashboard.description,
    model_id: dashboard.model_id,
    company_id: dashboard.company_id,
    type:
      (dashboard.type || dashboard.name) === 'Hiring'
        ? 'HiringPlan'
        : dashboard.type || dashboard.name,
  };
}

function buildOptionObject(option: any): Option {
  return {
    graph_id: option.graph_id,
    variable_ids: option.variable_ids,
    planned_vs_actuals: option.planned_vs_actuals,
    time_scale: option.time_scale,
    all_branches: option.all_branches,
    max_value: option.max_value,
    total_label: option.total_label,
    row_count: option.row_count,
  };
}

function buildLayoutObject(layout: any): Layout {
  return {
    graph_id: layout.graph_id,
    lg: Number(layout.lg) || 0,
    sm: Number(layout.sm) || 0,
  };
}

function buildDataPointObject(dataPoint: any): DataPoint {
  return {
    data_group_id: dataPoint.data_group_id,
    sign: dataPoint.sign,
    color: dataPoint.color,
    graph_id: dataPoint.graph_id,
    output_id: dataPoint.output_id,
  };
}

function buildFormulaObject(formula: any): Formula {
  return {
    group: formula.group,
    input_type: formula.input_type,
    model_id: formula.model_id,
    name: formula.name,
    expression_string: formula.expression_string,
    emoji: formula.emoji,
    branch_id: formula.branch_id,
  };
}

function buildDataGroupObject(dataGroup: any): DataGroup {
  return {
    data: {
      graph_id: dataGroup.data.graph_id,
      is_data_highlights: dataGroup.data.is_data_highlights,
      id: dataGroup.data.id,
    },
    data_points: dataGroup.data_points.map(buildDataPointObject),
  };
}

function buildGraphObject(graph: any): Graph {
  return {
    graph_data: {
      name: graph.graph_data.name,
      dashboard_id: graph.graph_data.dashboard_id,
      type: graph.graph_data.type,
      is_forecast_vs_actuals: graph.graph_data.is_forecast_vs_actuals,
      is_single_scenario: graph.graph_data.is_single_scenario,
      is_monthly: graph.graph_data.is_monthly,
      is_quarterly: graph.graph_data.is_quarterly,
      is_annually: graph.graph_data.is_annually,
      is_annualy: graph.graph_data.is_annualy,
    },
    options: graph.options.map(buildOptionObject),
    layout_data: graph.layout_data.map(buildLayoutObject),
    data_groups: graph.data_groups.map(buildDataGroupObject),
  };
}

export function buildTemplateRequestBody(
  template: Template,
  companyId: any,
): any {
  return {
    ...template,
    template_id: template.id,
    company_id: companyId,
    model_ids: template.model_ids,
    models: template.models.map((el: any) => buildModelObject(el, companyId)),
    dashboard_ids: template.dashboard_ids,
    dashboards: template.dashboards.map(buildDashboardObject),
    graphs: template.graphs.map(buildGraphObject),
    formulas: template.formulas.map(buildFormulaObject),
    start_date: parseUTCDateObject(template.start_date),
  };
}

/**
 * Invalidates the cached queries for various data related to the active company.
 * This function is used to ensure that the UI is updated with the latest data
 * after changes are made to the active company's templates, models, formulas,
 * dashboards, or graphs.
 */
export const invalidateTemplateRelatedQueries = (activeCompany: Company) => {
  const queryKeysToInvalidate = [
    [QUERYKEYS.ACTIVETEMPLATES, activeCompany.id],
    [QUERYKEYS.ALLMODELS, activeCompany.id],
    [QUERYKEYS.ALLFORMULAS, activeCompany.id],
    [QUERYKEYS.DASHBOARDSBYCOMPANY, activeCompany.id],
    [QUERYKEYS.GRAPHSBYCOMPANYID, activeCompany.id],
  ];

  queryKeysToInvalidate.forEach((key: any) => {
    queryClient.invalidateQueries({
      queryKey: key,
    });
  });
};
