import { convertToKAndM, stackedComboMinMax } from '@/utils/ChartUtils';
import type {
  ChartOptionsType,
  SeriesItem,
} from '@/utils/types/DashboardAndChartsTypes';

import styles from '../../../components/Graphs/graphs.module.scss';

/**
 * Generates configuration options for a combination bar chart.
 * @param {ChartOptionsType} minValue - The minimum value for the y-axis.
 * @param {ChartOptionsType} maxValue - The maximum value for the y-axis.
 * @param {SeriesItem[]} dynamicSeries - An array of dynamic series items.
 * @returns {Object} - An object containing the configuration options for the combination bar chart.
 */
export const stackedComboConfigOptions = (
  { dynamicSeries }: ChartOptionsType,
  signForGraph: string,
) => {
  return {
    chart: {
      id: 'basic-bar',
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    stroke: {
      width: dynamicSeries?.map((series) =>
        (series as SeriesItem)?.type === 'line' ? 5 : 1,
      ),
    },
    plotOptions: {
      bar: {
        columnWidth: '90%', // Adjust this value to control the width of bars
        horizontal: false,
        borderRadius: 10,
        borderRadiusApplication: 'end', // "around" / "end"
        borderRadiusWhenStacked: 'last', // "all"/"last"
        colors: {
          backgroundBarOpacity: 1,
        },
      },
    },
    yaxis: {
      min: stackedComboMinMax(dynamicSeries).minValue,
      max: stackedComboMinMax(dynamicSeries).maxValue,
      tickAmount: 5,
      labels: {
        maxWidth: 160,
        align: 'left',
        formatter(value: number) {
          return convertToKAndM(value, signForGraph);
        },
        style: {
          padding: '10px',
          fontFamily: styles.fontFamily,
          fontSize: '14px',
          fontWeight: '400',
        },
      },
    },
  };
};
