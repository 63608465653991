import type { StylesConfig } from 'react-select';

import colors from '@/styles/scss/abstracts/_variables.module.scss';

const SelectStyle: StylesConfig = {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  control: (styles: any) => ({
    ...styles,
    backgroundColor: 'white',
    border: `1px solid ${colors.selectBorderColor}`,
    borderRadius: '6px',
    height: '40px',
    boxShadow: 'none',
    WebkitBorderRadius: '6px',
    color: colors.black,
    fontSize: '14px',
    fontWeight: 400,
    minHeight: '45px',
    padding: '0 5px',

    ':hover': {
      border: `1px solid ${colors.selectBorderColor}`,
    },
  }),

  menu: (styles: any) => ({
    ...styles,
    zIndex: 9999,
  }),

  // eslint-disable-next-line @typescript-eslint/no-shadow
  option: (styles: any, { isSelected }: any) => {
    return {
      ...styles,
      backgroundColor: colors.white,
      color: colors.black,
      cursor: 'pointer',
      fontSize: '14px',
      fontWeight: isSelected ? 600 : 400,
      padding: '10px 15px',
      ':active': {
        backgroundColor: colors.lightGrey,
      },
      ':hover ': {
        backgroundColor: colors.lightGrey,
      },
    };
  },
};

/**
 * Styles configuration for React Select component.
 * Defines styles for the container, control, menu and option components.
 */
const ModelsSelectStyle: StylesConfig = {
  valueContainer: (styles) => ({
    ...styles,
    padding: '0 0 0 5px',
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    padding: '0',
  }),
  container: (styles: any) => ({
    ...styles,
    width: 'auto',
    height: '100%',
  }),
  // eslint-disable-next-line @typescript-eslint/no-shadow
  control: (styles: any) => ({
    ...styles,
    backgroundColor: 'transparent',
    width: '170px',
    minWidth: '130px',
    height: '100%',
    boxShadow: 'none',
    WebkitBorderRadius: '6px',
    color: colors.black,
    fontSize: '12px',
    fontWeight: 400,
    padding: '0 5px',
    cursor: 'pointer',
    paddingLeft: '5px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),

  menu: (styles: any) => ({
    ...styles,
    zIndex: 9999,
    width: 'fit-content',
  }),

  // eslint-disable-next-line @typescript-eslint/no-shadow
  option: (styles: any, { isSelected }: any) => {
    return {
      ...styles,
      backgroundColor: colors.white,
      color: colors.black,
      cursor: 'pointer',
      fontSize: '12px',
      fontWeight: isSelected ? 600 : 400,

      ':active': {
        backgroundColor: colors.lightGrey,
      },
      ':hover ': {
        backgroundColor: colors.lightGrey,
      },
    };
  },
};

export { ModelsSelectStyle, SelectStyle };
