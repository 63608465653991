/* eslint-disable import/no-cycle */

import type { Option } from '@/components/genericToggleMenu/subComponents/ToggleOptions';
import { sortToggleMenuOptions } from '@/components/genericToggleMenu/toggleMenuUtils';
import type { HiringPlan } from '@/utils/types/HiringTypes';

/**
 * Extracts a readable label from a field key.
 *
 * This handles some common mappings like:
 * - field_is_enabled_name -> full_name
 * - field_is_enabled_salary_yearly -> yearly_salary
 *
 * For other keys, it simply removes the "field_is_enabled_" prefix.
 */
const getKeyLabel = (key: string) => {
  const keyTruncated = key.replace('field_is_enabled_', '');
  if (keyTruncated === 'name') {
    return 'full_name';
  }
  if (keyTruncated === 'salary_yearly') {
    return 'yearly_salary';
  }
  if (keyTruncated === 'bonus_yearly') {
    return 'bonus';
  }
  return keyTruncated;
};

/**
 * Updates the sort order string based on whether a key is enabled or disabled.
 *
 * If the key is enabled and not already in the order, it will be added.
 * If disabled, it will be removed.
 *
 * Returns the updated sort order string or undefined if the order is now empty.
 */
const updateSortOrder = (
  sortOrder: string | undefined,
  keyLabel: string,
  toggleValue: boolean,
): string | undefined => {
  let sortOrderList = sortOrder?.split('|') || [];

  if (toggleValue) {
    // If toggleValue is true, add keyLabel to sortOrderList if it's not already included
    if (!sortOrderList.includes(keyLabel)) {
      sortOrderList.push(keyLabel);
    }
  } else {
    // If toggleValue is false, remove keyLabel from sortOrderList
    sortOrderList = sortOrderList.filter((item) => item !== keyLabel);
  }

  if (sortOrderList.length === 0) {
    // If sortOrderList is empty, return undefined to indicate that the sort_order key should be deleted
    return undefined;
  }

  // Return the updated sortOrder as a string joined by '|'
  return sortOrderList.join('|');
};

/**
 * Creates a mutated copy of the hiringPlan object with the
 * specified key toggled to the provided value.
 *
 * Also updates the sort_order property if needed.
 */
const mutateBody = (toggleValue: boolean, key: any, hiringPlan: any) => {
  const result = {
    ...hiringPlan,
    [key]: toggleValue,
  };

  const keyLabel = getKeyLabel(key);
  const updatedSortOrder = updateSortOrder(
    hiringPlan.sort_order,
    keyLabel,
    toggleValue,
  );

  if (updatedSortOrder === undefined) {
    // If updatedSortOrder is undefined, delete the sort_order key from result
    delete result.sort_order;
  } else {
    // Set the updated sort_order
    result.sort_order = updatedSortOrder;
  }

  return result;
};

// sort options according to activeHiringPlan.sort_order

const sortOrderList = (hiringPlan: any) => {
  return (
    hiringPlan.sort_order?.split('|').map((order: string) => {
      if (order === 'full_name') return 'name';
      if (order === 'yearly_salary') return 'salary yearly';
      if (order === 'bonus') return 'bonus yearly';

      return order.split('_').join(' ');
    }) || []
  );
};

/**
 * Updates the hiring plan's sort order.
 *
 * @param hiringPlan - The hiring plan object to update
 * @param updateHiringPlan - The mutation function to call to update the hiring plan
 * @param order - The new sort order string
 */
const updateHiringPlanWithOrder = (
  hiringPlan: HiringPlan,
  updateHiringPlan: { mutate: (arg0: any) => void },
  order: string,
) => {
  updateHiringPlan.mutate({
    ...hiringPlan,
    sort_order: order,
  });
};

// Function to extract the label from the field name
const extractLabelWithPrefix = (fieldName: string, prefix: string): string => {
  const prefixPattern = new RegExp(`^${prefix}(.*)$`);
  const matches = fieldName.match(prefixPattern);
  if (matches && matches[1]) {
    // Replace underscores with spaces and capitalize the first letter of each word for the label
    return matches[1]
      .replace(/_/g, ' ')
      .replace(/\b\w/g, (l) => l.toUpperCase());
  }
  return '';
};

const getOptionsFromHiringPlan = (
  hiringPlan: any,
  updateHiringPlan: { mutate: (arg0: any) => void },
  fields: any,
  notDraggableOptions?: string[],
): Option[] => {
  if (!hiringPlan || !fields) {
    return [];
  }

  /**
   * Maps an array of fields to an array of objects indicating whether each field is active or not.
   *
   * @param {Array} fields - An array of field objects, each containing a 'name' property.
   * @param {Object} hiringPlan - An object representing the hiring plan.
   * @param {string} hiringPlan.sort_order - A string containing the active field names separated by '|'.
   * @returns {Array} An array of objects, where each object has a dynamic key based on the field name and a boolean value indicating whether the field is active or not.
   *
   * @example
   * const fields = [
   *   { name: 'First Name' },
   *   { name: 'Last Name' },
   *   { name: 'Email' }
   * ];
   * const hiringPlan = {
   *   sort_order: 'first_name|email'
   * };
   *
   * const newFieldsOptions = mapFieldsToActiveStatus(fields, hiringPlan);
   * console.log(newFieldsOptions);
   * Output: [
   *    { field_is_enabled_first_name: true },
   *    { field_is_enabled_last_name: false },
   *    { field_is_enabled_email: true }
   *  ]
   */
  const newFieldsOptions = fields.map((field: any) => {
    const isActive = hiringPlan.sort_order
      ?.split('|')
      ?.includes(field.name.split(' ').join('_').toLowerCase());
    const key = `field_is_enabled_${field.name
      .split(' ')
      .join('_')
      .toLowerCase()}`;
    return {
      [key]: isActive,
    };
  });

  const newHiringPlan: any = {};

  /**
   * Maps the 'field_is_enabled_{field_name}' options from
   * newFieldsOptions into a new hiringPlan object.
   */
  newFieldsOptions.forEach((option: any) => {
    Object.entries(option).forEach(([key, value]: any) => {
      newHiringPlan[key] = value;
    });
  });

  /**
   * Maps an array of field objects to an array containing only
   * the lowercase field names.
   *
   * @param {Array} fields - An array of field objects, each containing a 'name' property.
   * @returns {Array} An array containing only the lowercase field names.
   */
  const customFieldsNames = fields.map((field: any) =>
    field.name.toLowerCase(),
  );

  const columnOptions = Object.entries({
    ...newHiringPlan,
    ...hiringPlan,
  })
    .filter(
      ([key, value]) =>
        key.startsWith('field_is_enabled') &&
        (typeof value === 'boolean' || value === null),
    )
    .map(([key, value]) => {
      // defineing the mutation body for the API Call to mutate hiring plan

      return {
        name: extractLabelWithPrefix(key, 'field_is_enabled_'),
        enabled: value as boolean, // Casting since we already filtered by boolean type
        onSelectionChange: (toggleValue: boolean) => {
          updateHiringPlan.mutate(mutateBody(toggleValue, key, hiringPlan));
        },
        isDraggable:
          notDraggableOptions &&
          !notDraggableOptions.includes(
            extractLabelWithPrefix(key, 'field_is_enabled_').toLowerCase(),
          ), // if the key is not in the notDraggableOptions array, then it is draggable
      };
    }) as Option[];

  const departmentOptions = Object.entries(hiringPlan)
    .filter(
      ([key, value]) =>
        key.startsWith('department_is_enabled') && typeof value === 'boolean',
    )
    .map(([key, value]) => {
      return {
        name: extractLabelWithPrefix(key, 'department_is_enabled_'),
        enabled: value as boolean,
        /**
         * Handles the selection change event for a toggle switch.
         * Updates the hiring plan with the new toggle value if the key is not a custom field.
         *
         * @param {boolean} toggleValue - The new value of the toggle switch.
         * @param {string} key - The key of the hiring plan field being updated.
         * @param {string[]} customFieldsNames - An array of custom field names.
         * @param {HiringPlan} hiringPlan - The current hiring plan object.
         * @param {UseMutationResult} updateHiringPlan - The mutation function to update the hiring plan.
         *
         * @example
         * Example usage:
         * const customFieldsNames = ['department_is_enabled_sales', 'department_is_enabled_marketing'];
         * const hiringPlan = {
         *   id: 1,
         *   name: 'Example Hiring Plan',
         *   is_active: true,
         *   department_is_enabled_sales: false,
         *   department_is_enabled_marketing: true,
         * };
         * const updateHiringPlan = useMutation(updateHiringPlanAPI);
         *
         * onSelectionChange(true, 'is_active', customFieldsNames, hiringPlan, updateHiringPlan);
         * Updates the 'is_active' field of the hiring plan to true.
         *
         * onSelectionChange(false, 'department_is_enabled_sales', customFieldsNames, hiringPlan, updateHiringPlan);
         * Does not update the hiring plan because 'department_is_enabled_sales' is a custom field.
         */
        onSelectionChange: (toggleValue: boolean) => {
          if (
            customFieldsNames.includes(
              extractLabelWithPrefix(
                key,
                'department_is_enabled_',
              ).toLowerCase(),
            )
          ) {
            return;
          }
          updateHiringPlan.mutate({
            ...hiringPlan,
            [key]: toggleValue,
          });
        },
        isDraggable: false,
      };
    }) as Option[];

  // department_is_enabled_

  const sortedColumnOptions = sortToggleMenuOptions(
    columnOptions,
    sortOrderList,
    hiringPlan,
  );

  return [sortedColumnOptions, departmentOptions];
};

export {
  extractLabelWithPrefix,
  getKeyLabel,
  getOptionsFromHiringPlan,
  mutateBody,
  sortOrderList,
  updateHiringPlanWithOrder,
  updateSortOrder,
};
