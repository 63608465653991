import React from 'react';
import ChartComponent from '@/components/Graphs/SubComponents/ChartComponent';
import { graphCellComponentOptions } from '@/miscellaneous/constant/chartConfig';
import styles from './graphCellStyles.module.scss';

/**
 * Renders a graph cell component that displays an area chart for the data in the current row.
 *
 * @param row - The current row data object.
 * @returns A React component that renders the graph cell.
 */
const GraphCellComponent = ({
  row
}: {
  row: any;
}) => {
  /**
   * Extracts date values from an object.
   *
   * @param data - An object containing key-value pairs, where the keys are expected to be dates in the format 'YYYY-MM-DD'.
   * @returns An object containing only the date values from the input object.
   */
  const extractDateValues = (data: any[]) => {
    const datePattern = /^\d{4}-\d{2}-\d{2}$/;
    const dateValues: any = {};
    const invalidPattern = /[^0-9.,]/g;
    Object.entries(data).forEach(([key, value]) => {
      if (datePattern.test(key)) {
        dateValues[key] = value.toString().replaceAll(invalidPattern, '');
      }
    });
    return dateValues;
  };
  return <div className={styles.graphCell} data-sentry-component="GraphCellComponent" data-sentry-source-file="GraphCellComponent.tsx">
      <div className={styles.graphContainer}>
        <ChartComponent type="area" options={graphCellComponentOptions} series={[{
        name: 'Formula Data',
        data: Object.values(extractDateValues(row.original))
      }]} width={145} height={80} data-sentry-element="ChartComponent" data-sentry-source-file="GraphCellComponent.tsx" />
      </div>
    </div>;
};
export default GraphCellComponent;