import type { Range } from '@codemirror/state';
import { StateField } from '@codemirror/state';
import { Decoration, EditorView } from '@codemirror/view';

import config from './config';
// Importing the data array
import functions from './Functions';
// Importing the data array
import { WidgetWithDropdown, WidgetWithoutDropdown } from './widgets';
import { loadSelectedValue } from './widgets/utility';

/**
 * Handles the case where a formula cell contains an unknown variable.
 *
 * If the matched text is not a number, it creates a `Decoration.replace` with a `WidgetWithoutDropdown` widget that displays "Unknown Variable" with a specific color.
 *
 * @param decorations - The array of decorations to be added to the editor.
 * @param match - The matched text in the formula cell.
 * @param start - The start index of the matched text.
 * @param end - The end index of the matched text.
 * @param matchedText - The text that was matched.
 * @param updateEditorContent - A function to update the editor content.
 */
const handleUnkownVariable = (
  decorations: Range<Decoration>[],
  match: any,
  start: any,
  end: any,
  matchedText: any,
  updateEditorContent: any,
) => {
  if (
    matchedText &&
    matchedText.length > 0 &&
    !Number.isNaN(Number(matchedText))
  ) {
    decorations.push(
      Decoration.replace({
        widget: WidgetWithoutDropdown({
          content: 'Unknown Variable',
          color: 'emptyFormula',
          match,
          updateEditorContent,
          value: loadSelectedValue(match),
        }),
      }).range(start, end),
    );
  }
};
/**
 * Sorts an array of Decoration objects based on their start position and side.
 * The sorting is done in the following order:
 * 1. Ascending by the `from` property of the Decoration.
 * 2. Ascending by the `startSide` property of the Decoration.
 * This ensures that the decorations are rendered in the correct order within the editor.
 *
 * @param a - The first Decoration object to compare.
 * @param b - The second Decoration object to compare.
 * @returns -1 if `a` should be before `b`, 1 if `a` should be after `b`, or 0 if they are equal.
 */
const sortDecorations = (a: any, b: any) => {
  if (a.from < b.from) return -1;
  if (a.from > b.from) return 1;
  if (a.startSide < b.startSide) return -1;
  if (a.startSide > b.startSide) return 1;
  return 0;
};
/**
 * Generates decorations for a document based on formula and variable placeholders.
 *
 * This function takes a document, a list of variables, and a function to update the editor content.
 * It then iterates through the document, finding matches for formula and variable placeholders.
 * For each match, it creates a decoration with a custom widget that displays the label of the matching
 * formula or variable. If a match does not correspond to a known formula or variable, it creates
 * a decoration with a "Unknown Variable" widget.
 *
 * The decorations are sorted and returned as a Decoration set.
 *
 * @param doc - The document to generate decorations for.
 * @param variables - The list of variables to match against.
 * @param updateEditorContent - A function to update the editor content.
 * @returns A Decoration set with the generated decorations.
 */
function tagDecoration(
  doc: any,
  variables: any,
  updateEditorContent: any,
  goToFormulaById?: (formulaId: number) => void,
) {
  const decorations: Range<Decoration>[] = [];
  const range = doc.toString();
  const { formulaRegex, variableRegex } = config;
  const regexes = [variableRegex, formulaRegex];
  regexes.forEach((regex) => {
    for (const match of range.matchAll(regex)) {
      const { index: start, 0: matchText, 1: matchedText } = match;
      const end = start + matchText.length; // End index of the match
      const options = [...variables, ...functions]; // Combine variables and formulas
      const matchingData = options.find(
        (item) => item.label === matchedText || `${item.id}` === matchedText, // Match by label or id
      );
      if (matchingData) {
        // If a match is found
        const { type } = matchingData;
        const isCalculated = type === 'calculations'; // Check if the match is a calculation
        const WidgetComponent = isCalculated // Check if the match is a calculation
          ? WidgetWithDropdown
          : WidgetWithoutDropdown;
        const color = isCalculated // Check if the match is a calculation
          ? 'variable'
          : type === 'inputs'
            ? 'function'
            : 'formula';
        decorations.push(
          // Add the decoration to the array
          Decoration.replace({
            widget: WidgetComponent({
              // Create a widget with the matching data
              content: matchingData.label, // Display the label of the matching data
              color, // Set the color of the widget
              match, // Pass the match object
              updateEditorContent, // Pass the updateEditorContent function
              value: loadSelectedValue(match), // Load the selected value
              goToFormulaById,
            }),
          }).range(start, end),
        );
      } else {
        handleUnkownVariable(
          // Handle unknown variables
          decorations,
          match,
          start,
          end,
          matchedText,
          updateEditorContent,
        );
      }
    }
  });
  decorations.sort(sortDecorations); // Sort the decorations
  return Decoration.set(decorations.filter(Boolean)); // Return the decorations
}

export default tagDecoration;

/**
 * State field responsible for managing and updating tag decorations within the editor.
 *
 * @type {StateField}
 * @memberof module:Decorations
 */
export const tagExtension = (
  variables: any,
  updateEditorContent: any,
  goToFormulaById?: (formulaId: number) => void,
) => {
  return StateField.define({
    create(view: any) {
      return tagDecoration(
        view.doc,
        variables,
        updateEditorContent,
        goToFormulaById,
      );
    },

    update(decorations: any, tr: any) {
      if (tr.docChanged) {
        return tagDecoration(
          tr.state.doc,
          variables,
          updateEditorContent,
          goToFormulaById,
        );
      }
      return decorations;
    },

    provide: (field: any) => EditorView.decorations.from(field),
  });
};
