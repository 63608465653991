import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useShallow } from 'zustand/react/shallow';

import ApiUtils from '@/api/ApiUtils';
import { QUERYKEYS } from '@/miscellaneous/constant/reactQueryKeyConfig';
import type { ZustandState } from '@/miscellaneous/store/zustand_store';
import useZustandStore from '@/miscellaneous/store/zustand_store';

import useShallowRoute from '../useShallowRoute';
import type { Report, ReportItem, ReportQueryResponse } from './reportTypes';

const activeReportHandler = (
  branchReports: any,
  query: any,
  setActiveReportState: any,
  shallowRoute: any,
) => {
  const firstReport =
    branchReports && branchReports.length > 0 ? branchReports?.[0] : null;

  const { report } = query;

  const urlReport = branchReports?.find(
    (branchReport: any) => branchReport.slug === report,
  );

  if (report && urlReport) {
    setActiveReportState(urlReport);
    return urlReport.id;
  }
  if (!report) {
    setActiveReportState(firstReport);
  } else {
    setActiveReportState(firstReport);
    shallowRoute({
      report: firstReport?.slug,
    });
  }

  return firstReport.id;
};

const useReports = () => {
  const {
    activeCompanyId,
    activeReport,
    setActiveReport,
    branchReports,
    setBranchReports,
    setActiveReportItems,
    activeBranchId,
    branchReportItems,
    setBranchReportItems,
  } = useZustandStore(
    useShallow((state: ZustandState) => ({
      activeCompanyId: state.activeCompany?.id ?? null,
      activeReport: state.activeReport,
      setActiveReport: state.setActiveReport,
      branchReports: state.branchReports,
      setBranchReports: state.setBranchReports,
      setActiveReportItems: state.setActiveReportItems,
      activeBranchId: state.activeBranch?.id ?? null,
      branchReportItems: state.branchReportItems,
      setBranchReportItems: state.setBranchReportItems,
    })),
  );

  const {
    data: allReports,
    isLoading,
    error,
  } = useQuery<ReportQueryResponse>({
    queryKey: [QUERYKEYS.REPORTS, activeCompanyId],
    queryFn: () => ApiUtils.getReports(0, activeCompanyId as number), // Assumed to return Promise<ReportQueryResponse>
    enabled: !!activeCompanyId,
  });

  const router = useRouter();
  const { query } = router;

  const shallowRoute = useShallowRoute();

  useEffect(() => {
    // settings branch Reports
    if (activeBranchId && allReports?.reports && !isLoading) {
      const newBranchReports = allReports?.reports?.filter(
        (report: Report) => report.branch_id === activeBranchId,
      );

      const reportIds = newBranchReports.map((report: any) => report.id);
      const filtredItems = allReports?.report_items?.filter(
        (reportItem: ReportItem) => reportIds.includes(reportItem.report_id),
      );

      setBranchReports(newBranchReports);
      setBranchReportItems(filtredItems);
    }
  }, [allReports, activeReport, isLoading, activeBranchId]);

  useEffect(() => {
    if (
      !isLoading &&
      branchReports?.length > 0 &&
      branchReportItems?.length > 0 &&
      activeBranchId
    ) {
      const activeReportID = activeReportHandler(
        branchReports,
        query,
        setActiveReport,
        shallowRoute,
      );

      const newReportItems = branchReportItems?.filter(
        (reportItem: any) => reportItem.report_id === activeReportID,
      );
      setActiveReportItems(newReportItems);
    }
  }, [activeBranchId, query, isLoading, branchReportItems]);

  return {
    reports: allReports || [],
    activeReport,
    isLoading,
    error,
  };
};

export default useReports;
