import { isArray } from 'lodash';
import { CalendarDays, DollarSign, Hash, Percent } from 'lucide-react';
import { FaRegFolder } from 'react-icons/fa';
import { useShallow } from 'zustand/react/shallow';
import useZustandStore from '@/miscellaneous/store/zustand_store';
import styles from '@/styles/table.module.scss';
import { getDateStringFormat, parseUTCDateObject } from '@/utils/dateUtils';
import useMutations from '@/utils/hooks/mutations/useMutations';
import { errorToaster } from '@/utils/toaster/toasters';
import type { Formula } from '@/utils/types/formulaTypes';
import { getDateFormulaString } from '../../ModelTableUtils';

/**
 * Renders the change type options component for a formula row.
 *
 * Allows updating the formula type by clicking an option. Tracks the currently
 * selected type with CSS classes.
 */
const ChangeTypeOptions = ({
  row,
  closePopup
}: any) => {
  const {
    updateFormula
  } = useMutations();
  const {
    activeModelFormulas
  } = useZustandStore(useShallow(state => ({
    activeModelFormulas: state.activeModelFormulas
  })));

  /**
   * Updates the formula type for the given row by calling the updateBulkFormula mutation.
   *
   * @param {string} type - The new formula type
   */
  const updateFormulaType = (type: string) => {
    if (row.originalSubRows && row.originalSubRows.length > 0) {
      errorToaster('Row type cannot be changed if it has subrows');
      return;
    }
    const {
      original
    } = row;
    // get the current expression string parsed to get the formula values
    const curExpressions = JSON.parse(original.expression_string);
    // get the first expression string
    const curExpression = isArray(curExpressions) ? curExpressions[0] : {
      formula: '0'
    };
    // set the new formula value based on the type
    let curValue = curExpression?.formula;
    // if previous value is date reset it.
    if (original.input_type === 'Date' && type !== 'Date') {
      curValue = '0';
    }
    const newExpressionString = {
      start: '',
      end: '',
      formula: curValue // setting current value as default for all non date types
    };
    if (type === 'Date') {
      const currentDate = getDateStringFormat(parseUTCDateObject());
      const dateString = getDateFormulaString(currentDate);
      newExpressionString.formula = dateString;
    }
    const expressionString = JSON.stringify([newExpressionString]);
    const newObject = {
      ...row?.original?.original_row_object,
      input_type: type,
      expression_string: expressionString
    };
    updateFormula.mutate(newObject);
    closePopup();
  };

  /**
   * Toggles the input type of a formula between 'Group' and 'Number'.
   * If the formula has child formulas and the new input type is 'Number', an error is displayed.
   *
   * @param {Formula} original - The original formula object.
   */
  const toggleFormulaGroup = (original: Formula) => {
    const newInputType = original?.input_type === 'Group' ? 'Number' : 'Group';
    const childFormulas = activeModelFormulas.filter((formula: Formula) => formula.parent_id === original.id);
    if (newInputType === 'Number' && childFormulas.length > 0) {
      errorToaster('Row type cannot be changed if it has subrows');
      return;
    }
    updateFormula.mutate({
      ...original,
      input_type: newInputType
    });
  };
  const isCalculationsGroup = row?.original?.group === 'Calculations';
  const originalFormula = (row.original.original_row_object as Formula);
  return <div className={styles.typeOptionsContainer} data-sentry-component="ChangeTypeOptions" data-sentry-source-file="ChangeTypeOptions.tsx">
      <div onClick={() => updateFormulaType('Number')} className={styles.typeOption + (row?.original?.input_type === 'Number' // Check if the row's input type is 'Number'
    ? ` ${styles.activeTypeOption}` : '')}>
        <Hash size={20} data-sentry-element="Hash" data-sentry-source-file="ChangeTypeOptions.tsx" />
      </div>
      <div onClick={() => updateFormulaType('Percentage')} className={styles.typeOption + (row?.original?.input_type === 'Percentage' // Check if the row's input type is 'Percentage'
    ? ` ${styles.activeTypeOption}` : '')}>
        <Percent size={16} data-sentry-element="Percent" data-sentry-source-file="ChangeTypeOptions.tsx" />
      </div>
      <div onClick={() => updateFormulaType('Currency')} className={styles.typeOption + (row?.original?.input_type === 'Currency' // Check if the row's input type is 'Currency'
    ? ` ${styles.activeTypeOption}` : '')}>
        <DollarSign size={20} data-sentry-element="DollarSign" data-sentry-source-file="ChangeTypeOptions.tsx" />
      </div>
      <div onClick={() => isCalculationsGroup ? null : updateFormulaType('Date')} className={`${styles.typeOption + (row?.original?.input_type === 'Date' // Check if the row's input type is 'Date'
    ? ` ${styles.activeTypeOption}` : '')}
        ${isCalculationsGroup ? ' cursor-not-allowed' : ''}
        `}>
        <CalendarDays size={20} className={`${isCalculationsGroup ? ' cursor-not-allowed' : ''}`} color={isCalculationsGroup ? 'gray' : 'black'} data-sentry-element="CalendarDays" data-sentry-source-file="ChangeTypeOptions.tsx" />
      </div>
      <div onClick={() => toggleFormulaGroup(originalFormula)} className={`${styles.typeOption + (row?.original?.input_type === 'Group' // Check if the row's input type is 'Boolean'
    ? ` ${styles.activeTypeOption}` : '')}`}>
        <FaRegFolder color="black" size={23} data-sentry-element="FaRegFolder" data-sentry-source-file="ChangeTypeOptions.tsx" />
      </div>
    </div>;
};
export default ChangeTypeOptions;