import { Eye, EyeOff } from 'lucide-react';
import type { Option } from '@/components/genericToggleMenu/subComponents/ToggleOptions';
import styles from './tableOptionsSection.module.scss';

/**
 * Renders a button with an eye icon that toggles the enabled state of a table option.
 *
 * @param {Object} props - The component props.
 * @param {Option} props.option - The table option to be displayed.
 * @param {boolean} props.isLastOption - A flag indicating whether the current option is the last one in the list.
 * @returns {JSX.Element} - The rendered toggle eye option button.
 */
const ToggleEyeOption = ({
  option,
  isLastOption
}: {
  option: Option;
  isLastOption: boolean;
}) => {
  const renderEyeIcon = option.enabled ? <Eye size={16} /> : <EyeOff size={16} />;
  const renderClassName = option.enabled ? `${styles.toggleEyeOption}` : `${styles.toggleEyeOption} ${styles.disabledOption}`;
  const onClickHandler = () => {
    if (!isLastOption) {
      option.onSelectionChange(!option.enabled);
    } else {
      option.onSelectionChange(true);
    }
  };
  return <button className={renderClassName} type="button" onClick={onClickHandler} data-sentry-component="ToggleEyeOption" data-sentry-source-file="TableOptionsSection.tsx">
      {renderEyeIcon}
      <span>{option.name}</span>
    </button>;
};

/**
 * Renders a section of table options, where each option is represented by a button with an eye icon that toggles the option's enabled state.
 *
 * @param {Object} props - The component props.
 * @param {Option[]} props.options - An array of options to be displayed.
 * @param {boolean} props.isLastOption - A flag indicating whether the current option is the last one in the list.
 * @returns {JSX.Element} - The rendered table options section.
 */
const TableOptionsSection = ({
  options,
  isLastOption
}: {
  options: Option[];
  isLastOption: boolean;
}) => {
  return <div className={styles.tableOptionsSection} id="toggleOptionsSection" data-sentry-component="TableOptionsSection" data-sentry-source-file="TableOptionsSection.tsx">
      {options?.map((option: Option) => {
      return <ToggleEyeOption key={option.name} option={option} isLastOption={isLastOption} />;
    })}
    </div>;
};
export default TableOptionsSection;