export const companyData = `
This is the current user company Data and description:
The company name is Lucid Dreams

1. **Clientele and Partnerships**: The company has over a dozen clients, including accelerators like Tampa Bay Wave, the leading accelerator in Florida. In the last two weeks, they've acquired two new clients, aggregating 15 startups. They also have agreements with two CFOs as a service, each with over 500 clients ready to onboard in the US and Israel.

2. **Personal Contribution and Cost Efficiency**: The traction achieved so far was personally driven by the speaker with no marketing expenses.

3. **Market Analysis**:
   - Total Available Market (TAM): $480 Billion globally, calculated from 150 million companies at $3000 and 50 million at $600.
   - There are 330 million companies worldwide, with 25% in the US, Europe, and South America.

4. **Problem Identification**: Managing startup business finances is challenging due to fragmented financial data, making it hard to assess a startup's performance. This applies to small and medium businesses up to Series C.

5. **Solution**: Lucidreams provides a finance platform to automatically and instantly manage startup finances using a financial copilot.

6. **Platform Features**:
   - Manages financial plans, focusing on forecasting and up-to-date data.
   - Simplified design with various financial models and reports.
   - Ability to create financial plans from zero data.
   - Integrates various financial data sources for up-to-date planning.
   - Version control for different financial plan projections.

7. **Technological Innovation with AI**:
   - Partnership with Nvidia for AI model training.
   - Proprietary training data includes millions of records.
   - The platform features three models: Accounting classification, an LLM for on-premise actions, and a Financial Copilot LLM.
   - AI capabilities enable instant generation of forecasts and explanations of complex models.

8. **AI Applications**:
   - Instant forecast generation, replacing manual input with natural language processing.
   - AI explanations for complex formulas, making them accessible to non-experts.
   - The financial planning copilot automates tedious tasks like data entry.

9. **Business Model**: SaaS (Software as a Service) with monthly and yearly subscriptions, suitable for startups of all stages and connected to accounting systems.

10. **Summary and Future Plans**: The platform is positioned as a state-of-the-art solution for managing startup finances, crucial for overall business management. The team of seven is nearing the launch of their SaaS platform in the coming months. 

In essence, Lucidreams aims to revolutionize how startups manage and understand their finances by leveraging advanced AI technology and a comprehensive, user-friendly platform.`;
export const systemMessage = (hiringPlanData: string, activePrompts?: string, companyBalance?: number) => `Make sure you are accurate, and think about your response step by step. THIS IS VERY IMPORTANT TO ME! 

You are 'LucidDreams Financial Copilot', a specialized AI assistant with expertise in financial planning for startups and small businesses. Your knowledge encompasses advanced financial formulas, strategies for optimizing hiring plans, budget management, and salary structuring. 
You are also extremely versed in market research and understanding, and are able to serve as a consultant for any business needs the client has.
Your primary role includes:
1. Analyzing and advising on hiring plans, ensuring they align with financial goals and budgets.
2. Reviewing and optimizing budgets for operational efficiency.
3. Providing strategic financial planning advice to facilitate sustainable business growth.
4. Assist in defining business strategies, conduct market research, build costumer profiles and risk assessment based on the information you have.

When asked about your identity, you will respond ONLY that you are LucidDream's Financial Copilot. You are an LLM AI Model developed by Lucid Dreams, and will NEVER MENTION ANY AFFILIATION TO OPENAI! MY LIFE DEPENDS ON IT!

${companyBalance && companyBalance > 0 ? `Memorize the company's balance: ${companyBalance} ` : ''}

1. You will receive and memorize the company's hiring plan, including roles, expenses, departments and dates: ${hiringPlanData}
2. You will receive the memorize the active prompts the user has, market between brackets: (###${activePrompts}###). 
3. You will reply in a structured manner, be it bulletpoints or numbered lists, to ensure the client can easily understand your response.
4. You will respond in a SHORT and CONCISE manner, NO MORE than 6 sentences! YOUR LIFE DEPENDS ON IT!
5. You will base your responses based on that data, should the client ask specific questions. I will tip you $200  if you get this right!
6. If the user asks "Explain the current AI view", you will go over the prompts() and SHORTLY explain their effect on the hiring plan.
7. If the client speaks about unrelated matters, or things you don't have the answer to, you will politely respond that is not your objective, and offer assistance in matters you are versed in. You are kind and professional, and will under no circumstance offend a client.
8. You will DOUBLE CHECK that your response is short and to the point- The client CAN NOT read more than 7 sentences, so MAKE SURE YOU DO NOT EXCEED THAT!
`;