import type { UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';

import ApiUtils from '@/api/ApiUtils';
import { QUERYKEYS } from '@/miscellaneous/constant/reactQueryKeyConfig';
import type { CalculationsStoreState } from '@/miscellaneous/store/CalculationsStore';
import CalculationsStore from '@/miscellaneous/store/CalculationsStore';
import type { OutputValuesMap } from '@/miscellaneous/store/calulcationsStoreHelper';
import type { DataStoreState } from '@/miscellaneous/store/DataStore';
import DataStore from '@/miscellaneous/store/DataStore';
import type { ZustandState } from '@/miscellaneous/store/zustand_store';
import useZustandStore from '@/miscellaneous/store/zustand_store';

import {
  getOutputBalanceEntries,
  memoizedSetOutputtotalValuesToMap,
  processLiveEntries,
} from './entriesHelper';

export interface LiveEntry {
  output_id: number;
  company_id: number;
  deleted_at: null | string;
  created_at: string;
  date: string;
  name: string;
  reference_type: string;
  department_id: null | number;
  id: number;
  updated_at: string;
  amount: number;
  reference_id?: number;
  branch_id: number;
  formula_id: number; // to cast to combined entry
  integration_id?: number;
  memo?: string | null;
  account_type?: string;
  split_entry_id?: number;
  to_output_id?: number;
}

export function useLiveEntriesQuery(): UseQueryResult<LiveEntry[], Error> {
  const { isLoggedIn, activeCompany } = useZustandStore(
    useShallow((state: ZustandState) => ({
      isLoggedIn: state.isLoggedIn,
      activeCompany: state.activeCompany,
    })),
  );

  return useQuery({
    queryKey: [QUERYKEYS.LIVEENTRIES, activeCompany?.id],
    queryFn: () => ApiUtils.getLiveEntries(activeCompany?.id as number),
    enabled: !!activeCompany && !!activeCompany?.id && !!isLoggedIn,
  });
}

export const useLiveEntries = () => {
  const { startDate, endDate, activeBranchId } = useZustandStore(
    useShallow((state: ZustandState) => ({
      activeBranchId: state.activeBranch?.id ?? null,
      startDate: state.startDate,
      endDate: state.endDate,
    })),
  );

  const { setLiveDataMap } = CalculationsStore(
    (state: CalculationsStoreState) => state,
  );
  const { accountTrees } = DataStore((state: DataStoreState) => state);

  const {
    data,
    isLoading,
    error,
    refetch,
    isSuccess,
  }: UseQueryResult<LiveEntry[], Error> = useLiveEntriesQuery();
  const { activeCompany } = useZustandStore(
    useShallow((state: ZustandState) => ({
      activeCompany: state.activeCompany,
    })),
  );

  const [branchLiveEntries, setBranchLiveEntries] = useState<LiveEntry[]>([]);

  useEffect(() => {
    if ((data?.length ?? 0) > 0 && isSuccess) {
      const NewBranchLiveEntries = data.filter(
        (entry) => entry.branch_id === activeBranchId,
      );
      const newEntries =
        NewBranchLiveEntries.length > 0 ? NewBranchLiveEntries : data; // need to remove this if condition when branch id is updated.
      setBranchLiveEntries(newEntries);
    } else {
      setBranchLiveEntries([]);
    }
  }, [data, activeBranchId, isSuccess]);

  // Filter entries by branch
  useEffect(() => {
    if (branchLiveEntries && isSuccess) {
      const outputBalanceEntries = getOutputBalanceEntries(
        activeCompany,
        accountTrees,
        activeBranchId,
      );
      const newMap: OutputValuesMap = processLiveEntries([
        ...outputBalanceEntries,
        ...branchLiveEntries,
      ]);

      memoizedSetOutputtotalValuesToMap(accountTrees, newMap);
      setLiveDataMap(newMap);
    }
  }, [branchLiveEntries, endDate, startDate, isSuccess, accountTrees]);

  return {
    liveEntries: data,
    branchLiveEntries,
    isLoading,
    error,
    refetch,
  };
};
