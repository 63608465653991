import dynamicRoute from '@/miscellaneous/constant';

export type ActiveDashboardType =
  | 'Financial'
  | 'HiringPlan'
  | 'Model'
  | 'Assumptions';

export interface Dashboard {
  branch_id: number | null;
  company_id: number;
  created_at?: string;
  description: string;
  id?: number;
  model_id?: number;
  name: string;
  type?: ActiveDashboardType;
  updated_at?: string;
}
const PAGE_NAME_INDEX = 3; // The index of 'page-name' in the URL path segments

const dashboardMap: { [key: string]: ActiveDashboardType } = {
  [dynamicRoute.financials]: 'Financial',
  [dynamicRoute.hiringPlan]: 'HiringPlan',
  [dynamicRoute.models]: 'Model',
  [dynamicRoute.assumptions]: 'Assumptions',
};

export function getDashboardType(pathname: string): ActiveDashboardType | null {
  const segments = pathname?.split('/');
  const pageName = segments?.[PAGE_NAME_INDEX] || ''; // Safely handle cases where the index might not exist
  return dashboardMap[pageName] || null; // Use the default if the page name is not in the map
}

// Create a new dashboard if the active dashboard is not found
export function createActiveDashboard(
  dashboardType: ActiveDashboardType,
  createDashboardMutation: any,
  activeBranchID: any,
  activeCompanyID: any,
  modelId?: number,
) {
  if (!dashboardType) {
    console.error('createActiveDashboard Invalid dashboard type');
    return;
  }
  const newDashboardBody = {
    name: `${dashboardType} Dashboard`,
    branch_id: activeBranchID,
    company_id: activeCompanyID,
    type: dashboardType,
  };
  if (modelId) {
    // add model key to the new dashboard body
    (newDashboardBody as any).model_id = modelId;
  }
  createDashboardMutation.mutate(newDashboardBody);
}

// function createFirstGraph(
//   outputID: number,
//   dashboardID: number,
//   branchID: number,
//   createGraphMutation: any,
// ) {
//   const body = {
//     graph_data: {
//       name: 'Banks Monthly',
//       dashboard_id: dashboardID,
//       branch_id: branchID,
//       type: 'Bar',
//       is_forecast_vs_actuals: false,
//       is_single_scenario: true,
//       is_monthly: true,
//       is_quarterly: false,
//       is_annualy: false,
//     },
//     options: [
//       {
//         variable_ids: '1',
//         planned_vs_actuals: false,
//         time_scale: 'string',
//         all_branches: false,
//         max_value: 0,
//         total_label: true,
//         row_count: 0,
//       },
//     ],
//     layout_data: [
//       {
//         lg: 615,
//         sm: 355,
//       },
//     ],
//     data_groups: [
//       {
//         data: {
//           is_data_highlights: false,
//         },
//         data_points: [
//           {
//             sign: 0,
//             color: '#7c8bff',
//             output_id: outputID,
//           },
//         ],
//       },
//       {
//         data: {
//           is_data_highlights: true,
//         },
//         data_points: [],
//       },
//     ],
//   };
//   createGraphMutation.mutate(body);
// }
