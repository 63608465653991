/* eslint-disable import/no-extraneous-dependencies */

import { autocompletion } from '@codemirror/autocomplete';
import { createRoot } from 'react-dom/client';

// eslint-disable-next-line import/no-cycle
import { generateIconsByType } from './formulaCellUtils';
import functions from './Functions';

/**
 * Sets up autocompletion behavior in the editor based on a selected idiom.
 * Fetches options from a JSON file corresponding to the idiom and configures autocompletion.
 * @param {EditorView} editor - The CodeMirror EditorView instance.
 * @param {Compartment} autocompleteCompartment - The compartment responsible for autocompletion.
 * @param {NonNullable<Toptions['idiom']>} newIdiom - The new idiom to be used for autocompletion.
 * @returns {Promise<any>} - A promise resolving upon successful autocompletion configuration.
 * @throws {Error} - Throws an error if fetching the options fails.
 */
export async function setAutocompletionIdiom(editor: any, autocompleteCompartment: any, variables: any, currentFormulaID: number) {
  // Function to render additional content for the autocompletion options
  function renderAdditionalContent(_completion: any, _state: any, _view: any) {
    const container = document.createElement('div');
    // add class to the container
    container.classList.add('tooltip-icons');
    const root = createRoot(container); // Create a root for the container.

    root.render(generateIconsByType(_completion.description) // Render the React component within the container.
    ); // Render the React component within the container.

    return container;
  }

  // Function to render additional content for the autocompletion options
  function renderAdditionalContentRight(_completion: any, _state: any, _view: any) {
    const container = document.createElement('div');
    const root = createRoot(container);
    const isFormulaType = _completion.type === 'calculations' || _completion.type === 'inputs';
    container.classList.add('tooltip-right-content');
    container.setAttribute('data-tooltip-id', 'formula-suggestion-tooltip');
    container.setAttribute('data-tooltip-content', isFormulaType && _completion.modelName);

    // eslint-disable-next-line react/jsx-no-useless-fragment
    root.render(<>{isFormulaType && _completion.modelName}</>);
    return container;
  }

  // Autocompletion configuration with additional options
  const additionalOptions = [{
    position: 100,
    render: (completion: any, state: any, view: any) => renderAdditionalContent(completion, state, view)
  }, {
    position: 100,
    render: (completion: any, state: any, view: any) => renderAdditionalContentRight(completion, state, view)
  }];

  // Define completions for autocompletion based on the fetched options
  function completions(context: any) {
    const word = context.matchBefore(/.*/);
    // const content = context.matchBefore(/[a-zA-Z0-9 ]+$/g);

    const wordString = word && word.text ? word.text : '';
    const newWordbyRegex = wordString.match(/[a-zA-Z0-9][a-zA-Z&0-9 ]*$/g);
    const newFrom = word.to - (newWordbyRegex ? newWordbyRegex[0].length : 0);

    // Return null if the current context is not explicitly triggered and doesn't match a word
    if (word && word.from === word.to && !context.explicit) {
      return null;
    }

    // Provide completion options based on the fetched data
    return {
      from: newFrom,
      options: [...functions, ...variables].map((option: any) => {
        const monthBack = option.id === currentFormulaID ? '-1' : ''; // this is here to handle the case where the user wants to reference the current formula
        const apply =
        // this is the definition of the apply property
        option.type === 'calculations' || option.type === 'inputs' ? `{{${option.id},y,x${monthBack}}}` // if the option is a formula (calculations or input), add the braces
        : `${option.insert}`; // if the option is a function, single braces and add the insert text

        return {
          label: option.label,
          type: option.type,
          apply,
          detail: option.type,
          description: option.description,
          modelName: option.modelName
        };
      })
    };
  }

  /**
   * Dispatches autocompletion configuration to the editor, updating the autocompletion behavior.
   *
   * @param {EditorView} editor - The EditorView instance where autocompletion is configured
   * @param {Compartment} autocompleteCompartment - The Compartment managing autocompletion configurations
   * @param {any[]} additionalOptions - Additional options to include in the autocompletion
   * @param {any[]} completions - List of completions to override the default autocomplete options
   * @returns {void}
   */
  editor.dispatch({
    effects: autocompleteCompartment.reconfigure(autocompletion({
      /**
       * The maximum number of options to render in the autocomplete dropdown.
       * @type {number}
       */
      maxRenderedOptions: 20,
      /**
       * Determines whether the autocomplete dropdown should close on blur or not.
       * @type {boolean}
       */
      closeOnBlur: false,
      /**
       * Additional options to append to the default autocompletion options.
       * @type {any[]}
       */
      addToOptions: additionalOptions,
      /**
       * List of completions to override the default autocomplete options.
       * @type {any[]}
       */
      override: [completions],
      /**
       * Function providing a tooltip class for autocomplete options.
       * @returns {string} - The class name for the tooltip
       */
      tooltipClass: () => 'myTooltip',
      /**
       * Function providing an option class based on the option type.
       * @param {any} option - The autocomplete option
       * @returns {string} - The class name for the autocomplete option
       */
      optionClass: (option: any) => option.type === 'secondary' ? 'cm-secondaryCompletion' : 'cm-custom-main'
    }))
  });
}