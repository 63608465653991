import { useQuery } from '@tanstack/react-query';
import { getCookie, setCookie } from 'cookies-next';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useShallow } from 'zustand/react/shallow';

import ApiUtils from '@/api/ApiUtils';
import dynamicRoute from '@/miscellaneous/constant';
import { QUERYKEYS } from '@/miscellaneous/constant/reactQueryKeyConfig';
import type { ZustandState } from '@/miscellaneous/store/zustand_store';
import useZustandStore from '@/miscellaneous/store/zustand_store';
import { variables } from '@/styles/variables/constant';
import {
  createAdjustedUTCDate,
  generateMonthlyDates,
  getDateStringFormat,
  parseUTCDateObject,
} from '@/utils/dateUtils';
import useShallowRoute from '@/utils/hooks/useShallowRoute';

export type Company = {
  id: number;
  slug: string;
  name: string;
  company_id?: number;
  start_date?: string | number | Date;
  end_date?: string | number | Date;
  current_date?: string | number | Date;
  logo_url?: string;
  owner_id?: number;
  onboarding_finished_date?: string | null;
  onboarding_step?: number;
  is_new_idea?: boolean;
  website?: string;
  location?: string;
  sectors?: string;
  funding_raise?: string;
  company_stage?: string;
  target_employees_in_one_year?: string;
  target_revenue_in_one_year?: string;
  raise_next_round_date?: string;
  target_round_funding?: string;
  description?: string;
  products?:
    | {
        name: string;
        price: number;
        amount_sold_last_m: number;
        amount_sold_y_ago: number;
        subscription_type: string;
        CAC: number;
      }[]
    | null;
  timeline_end_date?: string;
  timeline_start_date?: string;
};

const useCompany = () => {
  const {
    userMe,
    activeCompany,
    setActiveCompany: setZustandActiveCompany,
    isLoggedIn,
    setStartDate,
    setEndDate,
    setCurrentDate,
    setFullTimelineMonthlyDates,
    setTimelineStartDate,
    setTimelineEndDate,
  } = useZustandStore(
    useShallow((state: ZustandState) => ({
      isLoggedIn: state.isLoggedIn,
      userMe: Boolean(state.userMe),

      activeCompany: state.activeCompany,
      setStartDate: state.setStartDate,
      setEndDate: state.setEndDate,
      setCurrentDate: state.setCurrentDate,
      setActiveCompany: state.setActiveCompany,
      setFullTimelineMonthlyDates: state.setFullTimelineMonthlyDates,
      setTimelineStartDate: state.setTimelineStartDate,
      setTimelineEndDate: state.setTimelineEndDate,
    })),
  );
  const { query, pathname } = useRouter();
  const shallowRoute = useShallowRoute();

  /**
   * Gets the auth token from the lucid_user cookie if it exists,
   * otherwise returns an empty object.
   */
  const user = getCookie(variables.lucid_user)
    ? JSON.parse(getCookie(variables.lucid_user) as string)
    : {};

  const userName = user?.username;

  const { data, isLoading, error, refetch, isSuccess } = useQuery({
    queryKey: [QUERYKEYS.ALLCOMPANIES, userName], // We add username to the queryKey to ensure that the query is invalidated when the user logs in or out
    queryFn: () => ApiUtils.getAllCompanies(),
    enabled: !!isLoggedIn && Boolean(userMe),
  });

  // Function to set the active company, updating both Zustand store and cookies
  const setActiveCompany = (company: Company | undefined) => {
    if (company) {
      setStartDate(
        (company.start_date as string) ||
          createAdjustedUTCDate({
            adjustment: { value: -6, unit: 'months' },
          }).getTime(),
      );
      setEndDate(
        (company.end_date as string) ||
          createAdjustedUTCDate({
            adjustment: { value: 18, unit: 'months' },
          }).getTime(),
      );
      setCurrentDate((company.current_date as string) || parseUTCDateObject());
      setTimelineStartDate(
        company.timeline_start_date ||
          getDateStringFormat(
            createAdjustedUTCDate({
              adjustment: { value: -1, unit: 'years' },
              month: 0,
            }),
          ),
      );
      setTimelineEndDate(
        company.timeline_end_date ||
          getDateStringFormat(
            createAdjustedUTCDate({
              adjustment: { value: 5, unit: 'years' },
              month: 0,
            }),
          ),
      );
    }

    setZustandActiveCompany(company as Company);
    if (
      company?.slug &&
      company.slug !== query.company &&
      !pathname.includes(dynamicRoute.login)
    )
      // update only if the slug is different
      shallowRoute({ company: company?.slug });

    // set the active company in cookies , so that it can be used in the axios interceptor , company name, id and slug
    setCookie(variables.lucid_user_active_company, {
      id: company?.id,
      name: company?.name,
      slug: company?.slug,
    });
  };

  // Static dates if timeline dates are not available
  const oneYearAgo = createAdjustedUTCDate({
    adjustment: { value: -1, unit: 'years' },
  });

  const twoYearsFromNow = createAdjustedUTCDate({
    adjustment: { value: 2, unit: 'years' },
  });

  useEffect(() => {
    if (!activeCompany?.id) return;
    const startDate = activeCompany?.timeline_start_date
      ? parseUTCDateObject(activeCompany?.timeline_start_date)
      : oneYearAgo;
    // move start date 1 month back
    startDate.setMonth(startDate.getMonth() - 1);
    const endDate = activeCompany?.timeline_end_date
      ? parseUTCDateObject(activeCompany?.timeline_end_date)
      : twoYearsFromNow;
    const dates = generateMonthlyDates(startDate, endDate);
    // handle the dates
    setFullTimelineMonthlyDates(dates);
  }, [activeCompany]);

  return {
    activeCompany,
    userCompanies: data || [],
    isLoading,
    error,
    refetchCompany: refetch,
    setActiveCompany,
    isSuccess,
  };
};

export default useCompany;
