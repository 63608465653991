import { useRouter } from 'next/router';
import { useRef, useState } from 'react';
import { PiFunctionBold } from 'react-icons/pi';
import { getFormulasParsed } from '@/components/modelCalculations/modelUtils/modelUtils';
import type { CalculationsStoreState } from '@/miscellaneous/store/CalculationsStore';
import CalculationsStore from '@/miscellaneous/store/CalculationsStore';
import type { ZustandState } from '@/miscellaneous/store/zustand_store';
import useZustandStore from '@/miscellaneous/store/zustand_store';
import styles from '@/styles/scss/formulaCell.module.scss';
import type { Branch } from '@/utils/hooks/branches/useBranches';
import type { Models } from '@/utils/hooks/models/useModels';
import useMutations from '@/utils/hooks/mutations/useMutations';
import type { Formula } from '@/utils/types/formulaTypes';
import { FormulaCellPopup, formulaCellPopupStyle, goToFormulaById, onLoadEditor, toggleEditorVisibility, updateFormulaWrapper } from './formulaCellUtils';
import TagDisplay from './subComponents/TagDisplay';

/**
 * Renders a cell component that displays the count of formulas.
 *
 * @param {Object} props - The component props.
 * @param {number} props.count - The number of formulas to display.
 * @returns {JSX.Element} - The formula count cell component.
 */
export const FormulaCountCell = ({
  count
}: {
  count: number;
}) => {
  return <>
      <PiFunctionBold size={16} data-sentry-element="PiFunctionBold" data-sentry-source-file="index.tsx" />
      <span>{count} formulas</span>
    </>;
};
const FormulaCellComponent = ({
  row
}: any) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const {
    original
  } = row;
  const currentFormulaObject = (original?.original_row_object as Formula);
  const {
    updateFormula
  } = useMutations();

  // Retrieves the parsed formulas from the original expression string.
  const currentFormulas = getFormulasParsed(original?.expression_string);
  const wrapperRef = useRef(null);
  const [hidden, setHidden] = useState<any>(true);
  const editorRef = useRef<HTMLDivElement>(null);
  const router = useRouter();
  const {
    activeBranch,
    branchFormulas,
    activeBranchModels
  } = useZustandStore((state: ZustandState) => state);
  const {
    HPformulas
  } = CalculationsStore((state: CalculationsStoreState) => state);

  // const isSingleFormulaAndNotTotalRange =
  //   currentFormulas.length === 1 &&
  //   currentFormulas[0]?.start !== '' &&
  //   currentFormulas[0]?.end !== '';

  /**
   * Initializes the editor state with the first formula from the current formulas, or an empty string if there are multiple formulas.
   */
  const [editorState, setEditorState] = useState<string>(currentFormulas.length > 1 ? '' : currentFormulas[0]?.formula ?? '');

  /**
   * Defines the styles for a popup component, with a width that is calculated to be the full width of the screen minus 363 pixels.
   */
  const popupStyle = {
    ...formulaCellPopupStyle,
    width: 'calc(100% - 523px)'
  };

  /**
   * Handles closing the formula editor popup and updating the formula in the database.
   * - Hides the formula editor popup by setting the `hidden` state to `true`.
   * - Updates the formula in the database using the `updateFormula` mutation and the current `editorState`.
   * - Clears the content of the `rowEditorRef` element.
   * - Generates a new row editor using the `generateRowEditor` function and the `rowEditorRef`.
   */
  const onClosePopup = () => {
    setHidden(true);
    if (editorRef.current) {
      editorRef.current.innerHTML = '';
    }
    const isSingleFormula = currentFormulas.length <= 1;
    const editorChanged = editorState !== currentFormulas[0]?.formula;
    if (
    // if there is only one formula and the editor is not empty, update the formula
    isSingleFormula && editorChanged ||
    // if the formula has changed from the original current formula
    !isSingleFormula && editorState !== '' // if the first cell is edited, update the formula
    ) {
      updateFormulaWrapper(updateFormula, original, JSON.stringify([{
        start: '',
        end: '',
        formula: editorState
      }]));
    }
  };

  /**
   * Renders a button that displays the count of formulas, or a button with a div for the row editor if there is only one formula.
   * @returns {JSX.Element} The formula count cell or the row editor button.
   */
  const popupTrigger = () => {
    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {currentFormulas?.length > 1 ?
        // eslint-disable-next-line jsx-a11y/control-has-associated-label
        <button type="button" className={styles.formulaCountCell}>
            <FormulaCountCell count={currentFormulas.length} />
          </button> : <button type="button" aria-label="button" data-testid="formula-cell-button" className={styles.formulaCellButton}>
            <TagDisplay state={editorState} />
          </button>}
      </>
    );
  };
  const onEditorLoad = () => {
    let currentState = editorState;
    if (currentFormulas?.length > 1) {
      // this condition is here to prevent loading the previous state of the editor when there are multiple formulas
      setEditorState('');
      currentState = ''; // passing it here because it is not manageing to update state on time
    }
    onLoadEditor(currentState, editorRef, setEditorState, [...(branchFormulas as Formula[]), ...HPformulas], (activeBranch as Branch), (activeBranchModels as Models[]), () => toggleEditorVisibility(setHidden, editorRef), setHidden, (currentFormulaObject as Formula), (id: number) => goToFormulaById(id, router));
  };
  /**
   * Renders a shared popup component with a trigger button that displays the count of formulas, or a button with a div for the row editor if there is only one formula.
   * The popup component is responsible for loading the formula editor, managing the visibility of the popup, and handling the closing of the popup.
   * When the popup is closed, the function updates the formula in the database and generates a new row editor.
   */
  return <FormulaCellPopup trigger={popupTrigger} onLoadEditor={onEditorLoad} hidden={hidden} wrapperRef={wrapperRef} editorRef={editorRef} onClosePopup={onClosePopup} popupStyle={popupStyle} data-sentry-element="FormulaCellPopup" data-sentry-component="FormulaCellComponent" data-sentry-source-file="index.tsx" />;
};
export default FormulaCellComponent;