/* eslint-disable react/no-array-index-key */
import { Plus } from 'lucide-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Oval } from 'react-loader-spinner';
import { Tooltip } from 'react-tooltip';
import { useShallow } from 'zustand/react/shallow';
import useZustandStore from '@/miscellaneous/store/zustand_store';
import colors from '@/styles/scss/abstracts/_variables.module.scss';
import useMutations from '@/utils/hooks/mutations/useMutations';
import type { CompanyProducts } from '../../onboardingPopupTypes';
import styles from './companyProductsStage.module.scss';
import { ProductStageTableBody, ProductStageTableHeader } from './ProducStageSubComponents/ProducStageSubComponents';
import { renderProductStageInputs } from './ProductStageInputs/ProductStageInputs';
export type ProductStageColumn = {
  title: string;
  type: string;
  width: number;
  options?: string[];
};
export type ProductStageData = {
  name: string;
  price: number;
  sold_last_month: number;
  sold_1_year_ago: number;
  subscription_type: string;
  cac: number;
};

// Column definitions
const columns = [{
  title: '#',
  type: 'view',
  width: 55
}, {
  title: 'Name',
  type: 'text',
  width: 145
}, {
  title: 'Price',
  type: 'currency',
  width: 85
}, {
  title: 'Subscription Type',
  type: 'select',
  width: 185,
  options: ['Monthly & Yearly Subscription', 'One Time Purchase', 'Monthly Subscription', 'Yearly Subscription']
}, {
  title: '#Sold\nLast Month',
  type: 'number',
  width: 90
}, {
  title: '#Sold\n1 Year Ago',
  type: 'number',
  width: 80
}, {
  title: 'CAC',
  type: 'currency',
  width: 70
}];
export type ProductStageColumnOptions = {
  columnHeight: number;
  borderColor: string;
  backgroundColors: string;
  borderCollapse: string;
  minHeight: number;
};

// Column options for styling
const columnsOptions: ProductStageColumnOptions = {
  columnHeight: 48,
  borderColor: (colors.lavendeGray as string),
  backgroundColors: (colors.white as string),
  borderCollapse: 'collapse',
  minHeight: 48
};

// Common style for table cells
const commonCellStyle = {
  height: columnsOptions.columnHeight,
  border: `1px solid ${columnsOptions.borderColor}`,
  backgroundColor: columnsOptions.backgroundColors,
  borderCollapse: (columnsOptions.borderCollapse as 'collapse'),
  minHeight: columnsOptions.minHeight,
  maxHeight: columnsOptions.minHeight,
  overflow: 'hidden'
};

// Main component for company products stage
const CompanyProductsStage = ({
  data,
  onChange,
  activeStepIndex
}: {
  data: CompanyProducts;
  onChange: (data: CompanyProducts) => void;
  activeStepIndex: number;
}) => {
  const {
    t: translate
  } = useTranslation('company');
  const {
    predictCompanyProducts
  } = useMutations();
  const [loading, setLoading] = React.useState(false);
  const {
    activeCompany
  } = useZustandStore(useShallow(state => ({
    activeCompany: state.activeCompany
  })));

  /**
   * Initializes the `productData` state with the products from the `data` prop.
   *
   * This code block is responsible for setting the initial state of the `productData` variable.
   * If the `data.products` array is provided, it maps over the products and creates a new object
   * for each product, adding a sequential row number (`''`) to the object. If `data.products`
   * is not provided, it initializes `productData` to an empty array.
   *
   * @param data - The `data` prop containing the company products data.
   * @returns The initial state of the `productData` variable.
   */
  const [productData, setProductData] = React.useState(() => {
    if (data.products) {
      return data.products.map((product, index) => ({
        '': index + 1,
        ...product
      }));
    }
    return [];
  });

  /**
   * Updates the row numbers for all products in the `productData` state.
   *
   * This function maps over the `productData` array and updates the empty key (`''`) of each product
   * object to the current index plus one. This ensures that the row numbers are always sequential
   * and match the order of the products in the data.
   */
  const addProductNumber = () => {
    setProductData(prev => prev.map((product, index) => ({
      ...product,
      '': index + 1
    })));
  };

  // Add a new product row
  /**
   * Adds a new product row to the product data.
   *
   * This function creates a new product object with default values and adds it to the
   * `productData` state. It also calls the `addProductNumber` function to update the
   * row numbers for all products.
   */
  const onAddProduct = () => {
    // @ts-ignore
    setProductData(prev => [...prev, {
      '': 1,
      name: null,
      price: 0,
      sold_last_month: null,
      sold_1_year_ago: null,
      subscription_type: null,
      cac: 0
    }]);
    addProductNumber();
  };

  // Delete a product row
  /**
   * Deletes a product row from the product data.
   *
   * @param index - The index of the product to delete from the product data array.
   */
  const onDeleteProduct = (index: number) => {
    setProductData(prev => {
      const newProductData = [...prev];
      newProductData.splice(index, 1);
      return newProductData;
    });
    addProductNumber();
  };

  // Handle changes in the product data
  /**
   * Updates the product data in the state with the new value for the specified product field.
   *
   * @param productIndex - The index of the product in the product data array.
   * @param productField - The name of the field to update in the product data.
   * @param newValue - The new value to set for the specified product field.
   */
  const onChangeHandler = (productIndex: number, productField: string, newValue: string | number) => {
    const updatedProduct = {
      ...productData[productIndex],
      [productField]: newValue
    };
    setProductData(prev => {
      const newProductData = [...prev];
      // @ts-ignore
      newProductData.splice(productIndex, 1, updatedProduct);
      return newProductData;
    });
  };
  React.useEffect(() => {
    if (activeCompany.onboarding_step && activeCompany.onboarding_step <= activeStepIndex && data.products.length === 0) {
      setLoading(true);
      predictCompanyProducts.mutate({}, {
        onSuccess: (response: {
          products: {
            amount_sold_last_m: number;
            amount_sold_y_ago: number;
            CAC: number;
            name: string;
            price: number;
            subscription_type: string;
          }[];
        }) => {
          setLoading(false);
          const newProducts = [...response.products.map((product, index) => ({
            '': index + 1,
            name: product.name,
            price: product.price,
            sold_last_month: product.amount_sold_last_m,
            sold_1_year_ago: product.amount_sold_y_ago,
            cac: product.CAC,
            subscription_type: product.subscription_type
          }))];
          setProductData(newProducts);
        },
        onError: (error: any) => {
          setLoading(false);
          console.error('Error fetching company products', error);
        }
      });
    }
  }, []);

  // Update the parent component with the new product data
  React.useEffect(() => {
    onChange({
      products: productData
    });
  }, [productData]);
  return <div className={styles.container} data-sentry-component="CompanyProductsStage" data-sentry-source-file="CompanyProductsStage.tsx">
      <Tooltip id="delete-tooltip" style={{
      zIndex: 9999
    }} data-sentry-element="Tooltip" data-sentry-source-file="CompanyProductsStage.tsx" />
      <Tooltip id="input" style={{
      zIndex: 9999,
      marginTop: 20
    }} delayShow={1000} data-sentry-element="Tooltip" data-sentry-source-file="CompanyProductsStage.tsx" />
      <h1 className={styles.stageTitle}>
        {translate('company:pricing_tiers_products_services')}
      </h1>
      <button className={styles.addProductButton} type="button" onClick={onAddProduct}>
        <Plus size={16} data-sentry-element="Plus" data-sentry-source-file="CompanyProductsStage.tsx" />
        {translate('company:add_product')}
      </button>
      <div className={styles.table}>
        <ProductStageTableHeader columns={columns} columnsOptions={columnsOptions} commonCellStyle={commonCellStyle} data-sentry-element="ProductStageTableHeader" data-sentry-source-file="CompanyProductsStage.tsx" />
        {loading && <div className={styles.loader}>
            <div>
              <Oval color={colors.white} secondaryColor={colors.midBlue} height={30} width={30} strokeWidth={4} />
            </div>
          </div>}
        <ProductStageTableBody columns={columns} productData={productData} columnsOptions={columnsOptions} commonCellStyle={commonCellStyle} onDeleteProduct={onDeleteProduct} renderInput={(column: ProductStageColumn, product: ProductStageData, productIndex: number, hasError: boolean) => renderProductStageInputs(column, product, productIndex, translate, onChangeHandler, hasError)} data-sentry-element="ProductStageTableBody" data-sentry-source-file="CompanyProductsStage.tsx" />
      </div>
    </div>;
};
export default CompanyProductsStage;