/**
 * Loads the selected value based on the matched text in the editor document.
 *
 * If the matched text is 'y-1' and 'x', returns '1 Year Ago'.
 * If the matched text is 'x-1' and 'y', returns 'Last Month'.
 * If the matched text is 'x' and 'y', returns 'This Month'.
 * If the matched text contains a year, returns the year in the format '{year} Years Ago'.
 * If the matched text contains a month, returns the month in the format '{month} Months Ago'.
 * If none of the above conditions are met, returns 'This Month'.
 *
 * @returns {string} The selected value based on the matched text.
 */
export const loadSelectedValue = (match: any) => {
  if (match[2] === 'y-1' && match[3] === 'x') return '1 Year Ago'; // If the matched text is 'y-1' and 'x'
  if (match[3] === 'x-1' && match[2] === 'y') return 'Last Month'; // If the matched text is 'x-1' and 'y'
  if (match[3] === 'x' && match[2] === 'y') return 'This Month'; // If the matched text is 'x' and 'y'

  const year = match[2]?.split('-')[1]; // Extract the year from the matched text
  const month = match[3]?.split('-')[1]; // Extract the month from the matched text

  if (year) return `${year} Years Ago`; // If the matched text contains a year
  if (month) return `${month} Months Ago`; // If the matched text contains a month

  return 'This Month'; // Default value
};
