import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';
import styles from '@/styles/scss/formulaCell.module.scss';
const NoDropdownWidget = ({
  content,
  color,
  match,
  goToFormulaById
}: {
  content?: string;
  color?: string;
  match?: any;
  goToFormulaById?: (formulaId: number) => void;
}) => {
  const formulaId = match && +match[1];
  const handleClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    if (formulaId && goToFormulaById) {
      event.preventDefault();
      goToFormulaById(formulaId);
    }
  };
  const {
    t: translate
  } = useTranslation('models');
  return <div style={{
    display: 'inline-flex',
    alignItems: 'center'
  }} data-sentry-component="NoDropdownWidget" data-sentry-source-file="NoDropdownWidget.tsx">
      <Tooltip id="variable-tooltip" place="top" positionStrategy="fixed" noArrow data-sentry-element="Tooltip" data-sentry-source-file="NoDropdownWidget.tsx" />
      <span onClick={handleClick} className={`${styles.tag} ${styles[(color as string)]}`} data-tooltip-id="variable-tooltip" data-tooltip-content={formulaId ? translate('ctrl_click_message') : ''} data-tooltip-place="top">
        {content}
      </span>
    </div>;
};
export default NoDropdownWidget;