import classNames from 'classnames';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useShallow } from 'zustand/react/shallow';
import type { ZustandState } from '@/miscellaneous/store/zustand_store';
import useZustandStore from '@/miscellaneous/store/zustand_store';
import type { Models } from '@/utils/hooks/models/useModels';
import useMutations from '@/utils/hooks/mutations/useMutations';
import styles from '../DateRangeSelector/DateRangeSelector.module.scss';
import GenericDatePicker from '../GenericDatePicker/GenericDatePicker';
const ApplyToAllSection = ({
  close
}: {
  close: () => void;
}) => {
  const {
    activeModel,
    setActiveModel,
    activeBranchModels,
    setActiveBranchModels
  } = useZustandStore(useShallow((state: ZustandState) => ({
    activeModel: state.activeModel,
    setActiveModel: state.setActiveModel,
    activeBranchModels: state.activeBranchModels,
    setActiveBranchModels: state.setActiveBranchModels
  })));
  const {
    t: translate
  } = useTranslation('models');
  const {
    updateBulkModels
  } = useMutations();

  // Bulk model date update
  const handleBulkModelDateUpdate = () => {
    close();
    const startDate = activeModel.start_date;
    const endDate = activeModel.end_date;
    const MStartDate = moment(startDate).format();
    const MEndDate = moment(endDate).format();
    toast.info('Applying new dates to all models...');
    updateBulkModels.mutate(activeBranchModels.map(model => ({
      ...model,
      start_date: MStartDate,
      end_date: MEndDate
    })), {
      onSuccess: (data: Models[]) => {
        toast.success('Dates applied to all models!');
        setActiveBranchModels(data);
        const findActiveModel = data.find(model => model.id === activeModel?.id);
        if (findActiveModel) {
          setActiveModel(findActiveModel);
        }
      },
      onError: () => {
        toast.error('Failed to apply dates to all models!');
      }
    });
  };
  return <div className=" mt-2 flex  h-fit  cursor-default items-center border-t pt-2" data-sentry-component="ApplyToAllSection" data-sentry-source-file="ModelsDateRangePicker.tsx">
      <div className="flex w-full items-center justify-between">
        <span>{translate('apply_to_all_dates_message')}</span>
        <button type="button" className={classNames(styles.applyButton)} onClick={() => {
        handleBulkModelDateUpdate();
      }}>
          {translate('apply_to_all')}
        </button>
      </div>
    </div>;
};
function ModelsDateRangePicker() {
  const {
    activeModel,
    setActiveModel
  } = useZustandStore(useShallow((state: ZustandState) => ({
    activeModel: state.activeModel,
    setActiveModel: state.setActiveModel
  })));
  const {
    updateModels
  } = useMutations();

  // Single model date update
  const handleDateUpdate = (startDate: Date, endDate: Date) => {
    const MStartDate = moment(startDate).format();
    const MEndDate = moment(endDate).format();
    if (MStartDate !== activeModel?.start_date || MEndDate !== activeModel?.end_date) {
      updateModels.mutate({
        ...activeModel,
        start_date: MStartDate,
        end_date: MEndDate
      }, {
        onSuccess: (data: Models) => {
          setActiveModel(data);
        }
      });
    }
  };
  return <GenericDatePicker startDate={activeModel?.start_date} endDate={activeModel?.end_date} handleDateChange={handleDateUpdate} buttonClassName="grayDialogButton" startTitle="Model Start" endTitle="Model End" additionalElement={ApplyToAllSection} data-sentry-element="GenericDatePicker" data-sentry-component="ModelsDateRangePicker" data-sentry-source-file="ModelsDateRangePicker.tsx" />;
}
export default ModelsDateRangePicker;