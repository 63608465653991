/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */

// eslint-disable-next-line import/no-cycle
import { formulaIDToRowID } from '@/components/table/shared/types';
import CalculationsStore from '@/miscellaneous/store/CalculationsStore';
import { getDateStringFormat, parseUTCDateObject } from '@/utils/dateUtils';
import { HPFormulaDateRange, SystemFormulaIdsRange } from '@/utils/hooks/formulas/SystemFormulas';
import type { Formula, ISymbol } from '@/utils/types/formulaTypes';
const enableLogging = false;
export function isNumeric(str: string): boolean {
  return !Number.isNaN(parseFloat(str)) && Number.isFinite((str as any));
}
export function isValidNumber(value: any): boolean {
  return typeof value === 'number' && (Number.isFinite(value) || value === 0);
}
export const failValue = NaN;
export interface FormulaEntry {
  to_account: number;
  [date: string]: number;
}
export interface FormulaEntries {
  [formulaId: number]: FormulaEntry;
}
export class FormulaExpressionByDate {
  start: string;
  end: string;
  formula: string;
  constructor(start: string, end: string, formula: string) {
    this.start = start;
    this.end = end;
    this.formula = formula;
  }
}

// this function checkZ
export const getFormulaValueAt = ({
  formulaId,
  dateValue
}: {
  formulaId: number;
  dateValue: string;
}) => {
  // Directly access the state from the Zustand store
  const {
    formulaEntries,
    HPformulaEntries
  } = CalculationsStore.getState();
  let currentEntries = formulaEntries;
  if (formulaId < SystemFormulaIdsRange) {
    currentEntries = HPformulaEntries;
  }

  // Assuming 'Entry' is a known type that you've defined elsewhere
  const formulaEntry = currentEntries[formulaId];
  if (!formulaEntry) {
    if (enableLogging) console.error('Formula not in entries id:', formulaId);
    return NaN; // Return 0 if formulaEntry is not found
  }
  try {
    // Assuming the formula entry has a structure where dateValue is a key to the actual value
    const valueAtDate = formulaEntry[dateValue];
    if (valueAtDate !== undefined) {
      return valueAtDate;
    }
    if (enableLogging) console.error(`Date not available in formula ${formulaId} at date ${dateValue}`, formulaEntry);
    return 0;
  } catch (e) {
    if (enableLogging) console.error('Error accessing date in formula entry:', e);
    return NaN;
  }
};

// Substracts years and months from a date
function subtractDate(y: string, m: string, date: string) {
  // Split the date into month and year
  const [year, month] = date.split('-');
  if (!year || !month) {
    console.error('Invalid year and month assignment on function subtractDate :', {
      year,
      month,
      date
    });
    return '';
  }
  const dateObject = parseUTCDateObject(date);
  // reduct y years and m months from dateObject
  dateObject.setFullYear(dateObject.getFullYear() - parseInt(y, 10));
  dateObject.setMonth(dateObject.getMonth() - parseInt(m, 10));
  return getDateStringFormat(dateObject);
}
/**
 * Helper function to convert date string to a comparable value. returns month * 100 + year
 *  */
export const dateToNumber = (date: string) => {
  const [year, month] = date.split('-').map(Number).slice(0, 2);
  if (!year || !month) {
    if (enableLogging) console.error('Invalid date:', date);
    return 0;
  }
  return year * 100 + month; // Converts date to a number like 2021*12+12 for December 2021
};
// this function takes a list of formula expressions by date and a date and returns the formula expression that matches the date
export const getFormulaExpressionForDate = (formulasByDate: FormulaExpressionByDate[], currentDate: string): string | null => {
  if (formulasByDate.length === 1 && formulasByDate[0]?.formula && formulasByDate[0]?.start === '' && formulasByDate[0]?.end === '') {
    return formulasByDate[0].formula;
  }
  const currentDateValue = dateToNumber(currentDate);
  for (const entry of formulasByDate) {
    const start = entry.start ? dateToNumber(entry.start) : -Infinity;
    const end = entry.end ? dateToNumber(entry.end) : Infinity;
    if (currentDateValue >= start && currentDateValue < end) {
      return entry.formula;
    }
  }
  return null; // Return null if no formula matches the current date
};

// tihs parses the formulas from the string to an array of FormulaExpressionByDate
export function getFormulasParsed(formula: string): FormulaExpressionByDate[] {
  if (!formula) {
    return [];
  }
  try {
    return JSON.parse(formula).map((item: any) => new FormulaExpressionByDate(item.start, item.end, item.formula));
  } catch (e) {
    if (enableLogging) console.error('Error parsing formula :', e);
    return [];
  }
}

// This function takes the string of the date reduction needed to preform and changes the date accordingly
// for example takes y=1,x=4 and date = 2021-12-01 and returns 2020-08-01
export const getModifiedDateForFormulaRef = (date: string, symbol: ISymbol) => {
  let formulaStr;
  let month;
  let year;
  // eslint-disable-next-line prefer-const
  [formulaStr, year, month] = symbol.symbolName!.split(',');
  if (!year || !month || !formulaStr) {
    return Error('Invalid formula');
  }
  let YearReduce = '0';
  let MonthReduce = '0';
  if (year && year.includes('-')) {
    YearReduce = year.split('-')[year.split('-').length - 1] || YearReduce;
  }
  if (month.includes('-')) {
    MonthReduce = month.split('-')[month.split('-').length - 1] || MonthReduce;
  }
  return subtractDate((YearReduce as string), (MonthReduce as string), date);
};

// This function takes a symbol and a date and returns the value of the formula at that date according to the entries(recursive) or getFormulaValueAt
export function getFormulaValueFromSymbol(symbol: ISymbol, date: string, currentRowFormula: Formula, formulaEntries?: FormulaEntries) {
  const formulaId = parseInt(symbol.symbolName.split(',')[0] ?? '', 10);
  const modifiedDate = (getModifiedDateForFormulaRef(date, symbol) as string); // processing the date change on formula reference
  const {
    HPformulaEntries,
    getSystemFormulaValueAt
  } = CalculationsStore.getState();
  let currentEntries = formulaEntries;
  if (formulaId < SystemFormulaIdsRange) {
    if (formulaId < HPFormulaDateRange) {
      currentEntries = HPformulaEntries;
    } else {
      // handle system formula calculation.
      return getSystemFormulaValueAt({
        formulaId,
        dateValue: modifiedDate,
        formulaEntries,
        currentRowFormula
      }) || 0;
    }
  }
  // check if modified date is Error
  const curValue = currentEntries?.[formulaId]?.[modifiedDate];
  if (!formulaId || !modifiedDate || (modifiedDate as any) instanceof Error) {
    return failValue; // this is here to handle cases where the formula reference is invalid in some way
  }
  return curValue ?? 0;
}
// this function takes an epxression string and handles inco
export function correctArithmeticExpression(expr: string): string {
  // Regular expression to find patterns like --, +-, -+, ++
  const regex = /--|\+-|-+\+|\+\+/g;
  return expr.replace(regex, match => {
    switch (match) {
      case '--':
        return '+';
      case '+-':
      case '-+':
        return '-';
      case '++':
        return '+';
      default:
        // This default should never be reached because all cases are covered
        return match;
    }
  });
}

// handle edge cases with formula value. converting true fals to 1,0
export function handleEdgeCases(evalResult: any) {
  if (evalResult === true) {
    return 1;
  }
  if (evalResult === false) {
    return 0;
  }
  return evalResult;
}

/**
 * returns a list of row id strings of formulas that are parents of the given formula including the given formula
 * @param branchFormulas
 * @param formula
 * @returns list of row ids of formulas that are parents of the given formula including the given formula
 */
export function getFormulaAnscestorsRowIds(formulaIDToFormulaMap: Record<number, Formula>, formula: Formula | number) {
  let currentFormula: Formula;
  if (typeof formula === 'number') {
    const ff = formulaIDToFormulaMap[formula];
    if (!ff) {
      return [];
    }
    currentFormula = ff;
  } else {
    currentFormula = formula;
  }
  const parentRowIds: string[] = [];
  const formulaParentID = currentFormula.parent_id;
  if (formulaParentID) {
    const parentFormula = formulaIDToFormulaMap[formulaParentID];
    if (parentFormula) {
      parentRowIds.push(formulaIDToRowID(parentFormula.id, parentFormula.group));
      parentRowIds.push(...getFormulaAnscestorsRowIds(formulaIDToFormulaMap, parentFormula));
    }
  }
  // convert to set to remove duplicates and back to array
  return Array.from(new Set(parentRowIds));
}