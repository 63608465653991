import type { Dispatch, SetStateAction } from 'react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoSparklesOutline } from 'react-icons/io5';
import { useShallow } from 'zustand/react/shallow';
import { companySectorsData } from '@/miscellaneous/detailsConfirmationData/companySectors';
import { companyStagesData } from '@/miscellaneous/detailsConfirmationData/companyStages';
import { countriesData } from '@/miscellaneous/detailsConfirmationData/countries';
import { employeeData } from '@/miscellaneous/detailsConfirmationData/employees';
import { fundingStagesData } from '@/miscellaneous/detailsConfirmationData/fundingStages';
import type { UtilsStoreState } from '@/miscellaneous/store/utilsStore/utilsStore';
import useUtilsStore from '@/miscellaneous/store/utilsStore/utilsStore';
import type { ZustandState } from '@/miscellaneous/store/zustand_store';
import useZustandStore from '@/miscellaneous/store/zustand_store';
import { createAdjustedUTCDate, getDateStringFormat } from '@/utils/dateUtils';
import useCompany from '@/utils/hooks/company/useCompany';
import useMutations from '@/utils/hooks/mutations/useMutations';
import type { Integration } from '@/utils/hooks/useIntegrations/useIntegrations';
import useShallowRoute from '@/utils/hooks/useShallowRoute';
import { isValidUrl } from '@/utils/regex';
import DeletePopup from '../DeletePopup/DeletePopup';
import type { Steps } from '../GenericStagePopup/GenericStagePopup';
import styles from './OnboardingPopup.module.scss';
import { buttonDisabledHandler, fastOnboardingProcess, getNextButtonText, getStepComponent, goToNextStep } from './onBoardingPopupBodyFunctions';
import type { CompanyDetails, CompanyGoals, CompanyProducts, CompanyState, CompanyWebsite, Question, StepConfig, TemplateGeneration } from './onboardingPopupTypes';
import CompanyDetailsStage from './Stages/CompanyDetailsStage/CompanyDetailsStage';
import { CompanyNameStage, IntegrationsStage } from './Stages/CompanyNameOrIntegrationsStage/CompanyNameOrIntegrationsStage';
import CompanyProductsStage from './Stages/CompanyProductsStage/CompanyProductsStage';
import CompanyWebsiteStage from './Stages/CompanyWebsiteStage/CompanyWebsiteStage';
import DetailsConfirmationStage from './Stages/DetailsConfirmationStage/DetailsConfirmationStage';
import TemplateGenerationStage from './Stages/TemplateGenerationStage/TemplateGenerationStage';

// Define the initial state for the company information being entered
export const initialCompanyState = {
  'Company Name': {
    companyName: ''
  },
  Integrations: {
    connectedIntegrations: []
  },
  'Company Website': {
    website: ''
  },
  'Company Details': {
    text: ''
  },
  'Details Confirmation': {
    teamLocation: 'United States of America (USA)',
    companySectors: [],
    howMuchFunding: 0,
    companyStage: ''
  },
  'Company Goals': {
    howManyEmployees: '',
    revenueAYearFromNow: 0,
    whenYouRaiseFunding: getDateStringFormat(createAdjustedUTCDate({
      adjustment: {
        value: 3,
        unit: 'months'
      }
    })),
    howMuchFunding: 0
  },
  'Company Products': {
    products: []
  },
  'Your Custom Plan': {
    chosenTemplates: []
  }
};

/**
 * Renders the body of the OnboardingPopup component, which contains the various steps of the onboarding process.
 *
 * The body component manages the state of the company information being entered by the user, and renders the appropriate step component based on the active step. It also handles the logic for navigating between steps and disabling the "Next" button when required.
 *
 * @param {Object} props - The component props.
 * @param {string} props.activeStep - The currently active step in the onboarding process.
 * @param {function} props.setActiveStep - A function to set the active step.
 * @param {function} props.close - A function to close the onboarding popup.
 * @param {Object} props.stageState - The current state of the company information.
 * @param {function} props.setStageState - A function to update the state of the company information.
 */
const OnboardingPopupBody = ({
  activeStep,
  setActiveStep,
  close,
  stageState,
  setStageState,
  steps
}: {
  activeStep: keyof CompanyState;
  setActiveStep: Dispatch<SetStateAction<string>>;
  close: () => void;
  stageState: CompanyState;
  setStageState: (state: object) => void;
  steps?: Steps;
}) => {
  const {
    t: translate
  } = useTranslation('company');
  const [companyState, setCompanyState] = useState<CompanyState>(stageState);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Define the questions for the company details stage
  const [companyDetailsQuestions, setCompanyDetailsQuestions] = useState<Question[]>([{
    question: translate('what_are_you_building'),
    isChecked: false
  }, {
    question: translate('who_are_you_building_it_for'),
    isChecked: false
  }, {
    question: translate('when_and_where_will_it_launch'),
    isChecked: false
  }, {
    question: translate('how_will_it_be_delivered'),
    isChecked: false
  }]);
  const {
    activeCompany,
    setActiveCompany
  } = useZustandStore(useShallow((state: ZustandState) => ({
    activeCompany: state.activeCompany,
    setActiveCompany: state.setActiveCompany
  })));
  const companyDescriptionRef = useRef<string | null>(null);
  const {
    updateCompany,
    finishOnboarding,
    scrapeCompany,
    fastOnboarding
  } = useMutations();
  const shallowRoute = useShallowRoute();
  const {
    userCompanies
  } = useCompany();
  const [modalContent, setModalContent] = useState<string>('');
  const {
    setAppLoader
  } = useUtilsStore(useShallow((state: UtilsStoreState) => ({
    setAppLoader: state.setAppLoader
  })));
  useEffect(() => {
    if (stageState) {
      setCompanyState(stageState);
    }
  }, [stageState]);

  /**
   * Defines the configuration for the different steps in the onboarding process.
   * Each step has a corresponding component, an onChange handler to update the company state, and initial data.
   * The configuration also includes additional data specific to each step, such as select options and question arrays.
   */
  const stepConfig: Record<string, StepConfig<any>> = {
    'Company Website': {
      component: CompanyWebsiteStage,
      onChange: (data: CompanyWebsite) => {
        setCompanyState({
          ...stageState,
          'Company Website': data
        });
      },
      data: companyState['Company Website'].website,
      nextStep: () => {
        goToNextStep(activeStep, (companyState as CompanyState), isModalOpen, setIsModalOpen, setActiveStep, stepConfig, close, updateCompany, shallowRoute, finishOnboarding, activeCompany, setAppLoader, setActiveCompany, setModalContent, translate, scrapeCompany);
      }
    },
    'Company Name': {
      component: CompanyNameStage,
      onChange: (data: {
        companyName: string;
      }) => {
        setCompanyState({
          ...stageState,
          'Company Name': data
        });
      },
      data: companyState['Company Name'],
      nextStep: () => {
        goToNextStep(activeStep, (companyState as CompanyState), isModalOpen, setIsModalOpen, setActiveStep, stepConfig, close, updateCompany, shallowRoute, finishOnboarding, activeCompany, setAppLoader, setActiveCompany, setModalContent, translate, scrapeCompany);
      }
    },
    Integrations: {
      component: IntegrationsStage,
      onChange: (data: {
        connectedIntegrations: Integration[];
      }) => {
        setCompanyState({
          ...stageState,
          Integrations: data
        });
      }
    },
    'Company Details': {
      component: CompanyDetailsStage,
      onChange: (data: CompanyDetails) => {
        setCompanyState({
          ...stageState,
          'Company Details': data
        });
      },
      data: companyState['Company Details'],
      companyDetailsQuestions,
      setCompanyDetailsQuestions,
      companyState,
      setCompanyState,
      companyDescriptionRef
    },
    'Details Confirmation': {
      component: DetailsConfirmationStage,
      onChange: (data: {
        teamLocation: string;
        companySectors: string[];
        howMuchFunding: string;
        companyStage: string;
      }) => {
        setCompanyState({
          ...stageState,
          'Details Confirmation': data
        });
      },
      data: companyState['Details Confirmation'],
      type: 'Details Confirmation',
      selectData: [{
        question: translate('team_location'),
        isMultiSelect: false,
        data: countriesData,
        key: 'teamLocation',
        type: 'string'
      }, {
        question: translate('company_sectors'),
        isMultiSelect: true,
        data: companySectorsData,
        key: 'companySectors',
        selectLimit: 3,
        type: 'string'
      }, {
        question: translate('how_much_cash_do_you_have'),
        isMultiSelect: false,
        data: fundingStagesData,
        key: 'howMuchFunding',
        type: 'currency'
      }, {
        question: translate('what_is_the_company_stage'),
        isMultiSelect: false,
        data: companyStagesData,
        key: 'companyStage',
        type: 'string'
      }],
      companyState,
      activeStepIndex: steps?.findIndex(step => (step.route || step.label) === activeStep),
      companyDescriptionRef
    },
    'Company Goals': {
      component: DetailsConfirmationStage,
      onChange: (data: CompanyGoals) => {
        setCompanyState({
          ...stageState,
          'Company Goals': data
        });
      },
      data: companyState['Company Goals'],
      type: 'Company Goals',
      selectData: [{
        question: translate('how_many_employees'),
        isMultiSelect: false,
        data: employeeData,
        key: 'howManyEmployees',
        type: 'number'
      }, {
        question: translate('revenue_a_year_from_now'),
        isMultiSelect: false,
        data: fundingStagesData,
        key: 'revenueAYearFromNow',
        type: 'currency'
      }, {
        question: translate('when_you_raise_funding'),
        isMultiSelect: false,
        data: fundingStagesData,
        key: 'whenYouRaiseFunding',
        type: 'date'
      }, {
        question: translate('how_much_capital_plan'),
        isMultiSelect: false,
        data: fundingStagesData,
        key: 'howMuchFunding',
        type: 'currency'
      }],
      companyState,
      companyDescriptionRef
    },
    'Company Products': {
      component: CompanyProductsStage,
      data: companyState['Company Products'],
      onChange: (data: CompanyProducts) => {
        setCompanyState({
          ...stageState,
          'Company Products': data
        });
      },
      activeStepIndex: steps?.findIndex(step => (step.route || step.label) === activeStep)
    },
    'Your Custom Plan': {
      component: TemplateGenerationStage,
      onChange: (data: TemplateGeneration) => {
        setCompanyState({
          ...stageState,
          'Your Custom Plan': data
        });
      },
      data: companyState['Your Custom Plan']
    }
  };

  /**
   * Renders a "Fast Plan" button if certain conditions are met, which triggers a fast onboarding process when clicked.
   *
   * The button is only shown if:
   * - The user has more than one company
   * - The active step is "Company Website"
   *
   * The button is disabled if:
   * - The active step is "Company Website"
   * - The company website URL is not valid
   *
   * When the button is clicked, the `fastOnboardingProcess` function is called, passing in the necessary parameters.
   */
  const fastPlanButton = () => {
    // Check if the user has more than one company and the active step is "Company Website"
    const isShowButton = userCompanies && userCompanies.length > 1 && activeStep === 'Company Website';

    // Check if the company website URL is not valid
    const isButtonActive = activeStep === 'Company Website' && !isValidUrl(companyState['Company Website'].website);
    return isShowButton ? <button type="button" aria-label="button" className={styles.fastPlanButton} disabled={isButtonActive} onClick={() => {
      fastOnboardingProcess(fastOnboarding, (companyState as CompanyState), close, shallowRoute, setActiveCompany);
    }} data-sentry-component="fastPlanButton" data-sentry-source-file="OnboardingPopupBody.tsx">
        <IoSparklesOutline data-sentry-element="IoSparklesOutline" data-sentry-source-file="OnboardingPopupBody.tsx" /> <span>Fast Plan</span>
      </button> : null;
  };
  return <div className={styles.onboardingPopupBody} data-sentry-component="OnboardingPopupBody" data-sentry-source-file="OnboardingPopupBody.tsx">
      <div className={styles.onboardingPopupBodyContent}>
        {getStepComponent(activeStep, stepConfig)}
      </div>
      <div className={styles.onboardingPopupBodyFooter}>
        {fastPlanButton()}
        <button disabled={buttonDisabledHandler(activeStep, (companyState as CompanyState))} className={
      // Check if the next button text starts with "Next" and if it does, add the "continue" class
      (getNextButtonText(activeStep, stepConfig, translate) as string).toString()?.split(' ')[0] !== 'Next' ? styles.continue : ''} onClick={() => {
        goToNextStep(activeStep, (companyState as CompanyState), isModalOpen, setIsModalOpen, setActiveStep, stepConfig, close, updateCompany, shallowRoute, finishOnboarding, activeCompany, setAppLoader, setActiveCompany, setModalContent, translate, scrapeCompany);
        setStageState(companyState);
      }} type="button">
          {getNextButtonText(activeStep, stepConfig, translate)}
        </button>
      </div>

      <DeletePopup isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} title={modalContent} sumbitTitle={translate('company:skip_this_step')} closeModal={() => setIsModalOpen(false)} onDelete={() => {
      goToNextStep(activeStep, (companyState as CompanyState), isModalOpen, setIsModalOpen, setActiveStep, stepConfig, close, updateCompany, shallowRoute, finishOnboarding, activeCompany, setAppLoader, setActiveCompany, setModalContent, translate, scrapeCompany);
      setIsModalOpen(false);
    }} data-sentry-element="DeletePopup" data-sentry-source-file="OnboardingPopupBody.tsx" />
    </div>;
};
export default OnboardingPopupBody;