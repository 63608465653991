import type { ReportItem } from '@/utils/hooks/reports/reportTypes';
import type { FormulaGroupType } from '@/utils/types/formulaTypes';

// Enum defining different cell types
export enum BaseCellType {
  Number = 'number',
  Text = 'text',
  Date = 'date',
  Currency = 'currency',
  View = 'view',
  Checkbox = 'checkbox',
  Formula = 'formula',
  Output = 'output',
  Percentage = 'percentage',
  FormulaView = 'formulaView',
  FinancialView = 'financialView',
  Graph = 'graph',
}

// Enum defining different sidebar cell types
export enum SidebarCellType {
  HiringPlan = 'HiringPlan',
  FinancialTable = 'FinancialTable',
  ModelsTable = 'ModelsTable',
  GenericFlatTable = 'GenericFlatTable',
}

/**
 * An enum representing column types in a table.
 * Includes month, year, quarter, and total column types.
 */
export enum ColumnType {
  Month,
  Year,
  Quarter,
  Total,
}
export type TableRowType = 'formula' | 'section' | 'accountTree' | 'output' | 'gap' | 'total' | 'button' | 'formulaGroup' | 'department' | 'employee';
export interface TableRow {
  id?: string;
  sidebar?: string;
  name?: string | null;
  emptyCellsRow?: boolean;
  displayCellDataWhenExpanded?: boolean; // custom config for rows that should display data when expanded
  rowStyle?: Record<string, any>;
  original_row_object?: any; // this should hold the original object of the row, employee, formula or output
  subRows?: TableRow[];
  type?: TableRowType;
  [key: string]: any;
  baseRowItemParent?: ReportItem;
  depth?: number;
  level?: number; // is used to specify the accountTree depthh only.
}
export const formulaIDToRowID = (formulaID: number, group: FormulaGroupType) => {
  return `${String(formulaID)}-${group}`;
};
export function formulaRowIDtoFormulaID(rowID: string) {
  return Number(rowID.split('-')[0]);
}

// Interface defining the structure of an additional column in your settings
export interface AdditionalColumn {
  accessorKey: string;
  header: string;
  enableEditing: boolean;
  type: BaseCellType;
  style?: Record<string, string | number>; // Optional style property
}

// Interface defining the structure of an individual style configuration
export interface IndividualStyleConfig {
  accessorKey: string;
  style: Record<string, string | number>; // Style for a specific accessorKey
}

// Interface defining the structure of the gap configuration
export interface GapConfig {
  depth: number;
  count: number;
}
export interface HeaderOptions {
  icon: JSX.Element;
  actionHandler: () => void;
}

// Interface defining the structure of the settings object
export interface Settings {
  expanded: Boolean;
  ignoreColumns: string[];
  additionalColumns: AdditionalColumn[];
  disableEditingOnColumns: string[];
  styles?: {
    shared: Record<string, string | number>; // Shared styles for all columns
    individual: IndividualStyleConfig[]; // Individual styles for specific columns
  };
  gapConfig?: {
    at: GapConfig[];
  };
  rowTypesToExpand?: string[];
  getHeaderOptions?: () => HeaderOptions[];
}
const defintions = {
  images: {
    copy: '/assets/icons/copy.png',
    down: '/assets/icons/down.png',
    dots: '/assets/icons/dots.png'
  }
};
export default defintions;