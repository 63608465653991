export const companyStagesData = [
  'Idea Stage',
  'Early Stage',
  'Growth Stage',
  'Seed Stage',
  'Series A',
  'Series B',
  'Series C',
  'Series D+',
  'Exit Stage',
];
