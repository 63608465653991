/**
 * MessageList Component
 *
 * Description:
 * The `MessageList` component is responsible for rendering a list of message components. It is a part
 * of the chat interface that displays all the messages exchanged with ChatGPT. Each message is wrapped
 * within the `ChatGPTMessage` component which handles the display logic for individual messages.
 */

import type { FC } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ThreeDotsAnimation from '@/components/AIComponents/ThreeDotsAnimation/ThreeDotsAnimation';
import Message from '@/components/common/CopilotChat/CopilotChatComponents/Message';
import styles from '@/styles/scss/copilotChat.module.scss';
import type { IMessage } from '@/utils/hooks/useChatStore';
interface IMessageListProps {
  /**
   * An array of message objects representing the chat history.
   */
  messages: IMessage[];

  /**
   * Function to handle adding an option to the chat.
   * @param option - The selected option.
   * @param id - The ID of the message to replace with the option.
   */
  addOptionToChat: (option: string, id: string) => void;

  /**
   * Optional function to set the disabled state of the chat input.
   * @param isDisabled - Boolean value indicating whether the chat input should be disabled.
   */
  setIsDisabled?: (isDisabled: boolean) => void;

  /**
   * Error state for the chat.
   */
  error: any;

  /**
   * Thinking state for the chat.
   */
  thinking: boolean;

  /**
   * Reference to the end of the chat messages.
   */
  messagesEndRef: React.RefObject<HTMLDivElement>;
}

/**
 * MessageList component responsible for rendering the list of messages in the chat.
 * @param messages - An array of message objects representing the chat history.
 * @param addOptionToChat - Function to handle adding an option to the chat.
 * @param setIsDisabled - Optional function to set the disabled state of the chat input.
 * @returns The MessageList component.
 */
const MessageList: FC<IMessageListProps> = ({
  messages,
  addOptionToChat,
  setIsDisabled,
  error,
  thinking,
  messagesEndRef
}) => {
  const {
    t
  } = useTranslation(['copilot']);
  return <div className={styles.MessageList} data-sentry-component="MessageList" data-sentry-source-file="MessageList.tsx">
      {messages.map((message: IMessage) => {
      return <Message key={message.id} id={message.id} sender={message.sender} text={message.text} addOptionToChat={addOptionToChat} setIsDisabled={setIsDisabled} messagesEndRef={messagesEndRef} />;
    })}

      {(error as any) && (<h1 className={styles.chatError}>
            Something went wrong please try again
          </h1> as JSX.Element)}

      <div className="px-2 pt-1">
        {!error && thinking && <ThreeDotsAnimation text={t('copilot:animation_text_thinking')} className={styles.thinkingAnimation} />}
      </div>
    </div>;
};
export default MessageList;