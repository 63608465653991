import { useQuery } from '@tanstack/react-query';

import ApiUtils from '@/api/ApiUtils';
import { QUERYKEYS } from '@/miscellaneous/constant/reactQueryKeyConfig';
import type { ZustandState } from '@/miscellaneous/store/zustand_store';
import useZustandStore from '@/miscellaneous/store/zustand_store';

export interface ApiDeckConnector {
  name: string;
  service_id: string;
  icon: string;
  logo: string;
  website: string;
  unified_api: string;
  state: string;
  api_deck_uid: string;
  authorize_url: string;
  revoke_url: string;
}

const useGetConnectors = () => {
  const { activeCompany } = useZustandStore((state: ZustandState) => state);
  const {
    data: connectorsData,
    isLoading,
    error,
    refetch,
  } = useQuery<ApiDeckConnector[], Error>({
    queryKey: [QUERYKEYS.API_DECK_CONNECTORS, activeCompany?.id],
    queryFn: () => ApiUtils.getApideckConnectors(activeCompany?.id),
    enabled: !!activeCompany?.id,
  });

  return {
    connectorsData,
    isLoading,
    error,
    refetch,
  };
};

export default useGetConnectors;
