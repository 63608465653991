/* eslint-disable import/no-cycle */
// eslint-disable-next-line import/no-extraneous-dependencies
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { useShallow } from 'zustand/react/shallow';
import DrawerAISuggestion from '@/components/financialReports/FinancialDrawer/SubComponents/DrawerAISuggestion/DrawerAISuggestion';
import CalculationsStore from '@/miscellaneous/store/CalculationsStore';
import type { CombinedEntry } from '@/miscellaneous/store/calulcationsStoreHelper';
import DataStore from '@/miscellaneous/store/DataStore';
import type { ZustandState } from '@/miscellaneous/store/zustand_store';
import useZustandStore from '@/miscellaneous/store/zustand_store';
import { baseOutputIdToSign } from '@/utils/hooks/Outputs/useOutputsHelper';
import type { Output } from '@/utils/types/DashboardAndChartsTypes';
import DrawerContentHeader from '../SubComponents/DrawerContentHeader';
import Transactions from '../SubComponents/Transactions';
import EditReportDrawerContent from './EditReportDrawerContent';
import styles from './financialDrawerContentStyles.module.scss';

/**
 * Defines the possible tab locations for the financial drawer content.
 * @property {string} 'edit-report' - The tab for editing the financial report.
 * @property {string} 'transactions' - The tab for viewing transactions.
 */
type TabLocation = 'edit-report' | 'transactions';

/**
 * Defines the shape of the state for the sidebar drawer, including the currently selected row and column.
 *
 * @property {Row<Formula>} row - The currently selected row in the sidebar drawer.
 * @property {object} column - The currently selected column in the sidebar drawer.
 * @property {object} column.columnDef - The definition of the currently selected column.
 * @property {string} column.columnDef.Header - The header text for the currently selected column.
 * @property {string} column.columnDef.accessorKey - The accessor key for the currently selected column.
 */
export interface FinancialsDrawerState {
  tab: 'transactions' | 'edit-report';
  state: {
    id: number;
    column_name: string;
    accessorKey: string;
    Header: string;
    data: object[];
    value: number | undefined;
    original_row_object: Output | null;
    dates: string[];
  } | null;
}

/**
 * This function creates a mape of dates to entries from given outpus and dates. It takes all the children of an outputs fetches all their entries for the selected
 * date range and creates the map to display in the drawer.
 * @param outputID the output id that is the root, row selected in the table.
 * @param dates the date range selected in the table.
 * @returns  map from dates to relevant entries
 */
export function getDatesToEntriesMap(outputID: number, dates: string[], generateSignForEntryFunction: ((entry: CombinedEntry) => CombinedEntry) | null = null, dataType: 'live' | 'forecast' | 'both' = 'both'): {
  [date: string]: CombinedEntry[];
} {
  const {
    getAccountValuesByDateRange
  } = CalculationsStore.getState();
  const {
    getAllOutputChildren
  } = DataStore.getState();
  const dateToEntries = ({} as {
    [date: string]: CombinedEntry[];
  });
  const allOutputChildren = getAllOutputChildren(outputID);
  allOutputChildren.push(outputID);
  const allDateValues = allOutputChildren.map((currentOutputID: number) => {
    const accountValus = getAccountValuesByDateRange({
      outputID: currentOutputID,
      dataType
    });
    return accountValus?.dateValues;
  });
  const allEntriesByDate = (date: string): CombinedEntry[] => {
    const entries = ([] as CombinedEntry[]);
    allDateValues.forEach(dateValues => {
      if (dateValues) {
        const dateValue = dateValues[date];
        if (dateValue) {
          let curEntries = dateValue?.entries;
          if (generateSignForEntryFunction) {
            curEntries = dateValue.entries.map(generateSignForEntryFunction);
          }
          entries.push(...curEntries);
        }
      }
    });
    // sort the entries by date lexicographically without useing Date object

    // Sort entries lexicographically by date property, handling undefined dates
    return entries.sort((a, b) => {
      const dateA = a.date || '';
      const dateB = b.date || '';
      return dateA < dateB ? -1 : dateA > dateB ? 1 : 0;
    });
  };

  // create an object that maps date to allEntriesByDate
  dates?.forEach(date => {
    const entriesByDate = allEntriesByDate(date);
    dateToEntries[date] = entriesByDate;
  });
  return dateToEntries;
}

/**
 * Renders the content of the financial drawer, including a header, search, and tabs for different financial report sections.
 *
 * @param content - An object containing information about the current row and column in the financial report.
 * @param content.row.original.id - The ID of the current row.
 * @param content.column.columnDef.Header - The header text for the current column.
 * @param content.column.columnDef.accessorKey - The accessor key for the current column.
 * @returns The rendered financial drawer content.
 */
const FinancialDrawerContent = () => {
  const {
    setIsSidebarDrawerOpen,
    sidebarDrawerContent: content
  } = useZustandStore((state: ZustandState) => state);
  const {
    outputIDToBaseOutputAncestorID
  } = useZustandStore(useShallow((state: ZustandState) => ({
    outputIDToBaseOutputAncestorID: state.outputIDToBaseOutputAncestorID
  })));

  // Generates the sign for a given entry based on the output ID, basically we are adding the sign of the Base output ancestor to the entry because we want it to match to the display on the table.
  const generateSignForEntry = (entry: CombinedEntry): CombinedEntry => {
    const isLiveEntry = !!entry.reference_type;
    if (isLiveEntry) {
      // we only apply this on formula entries
      return entry;
    }
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const entryBaseOutputID = outputIDToBaseOutputAncestorID[entry.output_id];
    const entrySign = entryBaseOutputID ? (baseOutputIdToSign as Record<number, number>)[entryBaseOutputID] ?? 1 : 1;
    return {
      ...entry,
      amount: entry.amount * entrySign
    };
  };
  const {
    t
  } = useTranslation(['financialsDrawer']);
  const [searchValue, setSearchValue] = React.useState<string>('');

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const original_row_object = (content?.state?.original_row_object as Output);
  const dates = (content?.state?.dates as string[]);
  const outputID = original_row_object?.id;
  const transactionScrollContainer = useRef<HTMLDivElement>(null);
  const allEntries = getDatesToEntriesMap(outputID, dates, generateSignForEntry);
  const [activeTab, setActiveTab] = React.useState<TabLocation>(content?.tab || 'transactions');
  const [filteredEntriesByDate, setFilteredTransactions] = React.useState<{
    [date: string]: CombinedEntry[];
  }>({});
  const [entriesByDate, setEntriesByDate] = React.useState<{
    [date: string]: CombinedEntry[];
  }>(allEntries);

  // resets drawer values on change of outputID or dates
  useEffect(() => {
    setSearchValue('');
    setFilteredTransactions(allEntries);
    setEntriesByDate(allEntries);

    // reset the scroll position of the transaction panel
    if (transactionScrollContainer.current) {
      transactionScrollContainer.current.scrollTop = 0;
    }
  }, [outputID, dates, activeTab]);
  useEffect(() => {
    if (!content) return;
    setActiveTab(content.tab);
  }, [content]);
  useEffect(() => {
    if (!searchValue) {
      setFilteredTransactions(allEntries);
    } else {
      // filter each entries for each key on filteredTransactions
      const NewFilteredTransactions = ({} as {
        [date: string]: CombinedEntry[];
      });
      Object.keys(allEntries).forEach((date: string) => {
        const entries = allEntries[date];
        NewFilteredTransactions[date] = entries?.filter((entry: CombinedEntry) => {
          return entry?.name && entry?.name.toLowerCase().includes(searchValue.toLowerCase());
        }) || [];
      });
      setFilteredTransactions(NewFilteredTransactions); // Update the filtered transactions
    }
  }, [outputID, searchValue]);
  const nonZeroEntriesCount = Object.keys(filteredEntriesByDate).reduce((acc, date) => {
    const entries = filteredEntriesByDate[date];
    // count all entries whose sum is not 0
    const nonZerocount = entries?.filter(entry => entry.amount !== 0);
    return acc + (nonZerocount?.length ?? 0);
  }, 0) || 0;
  const renderDrawerHeaderContent = (tabLocation: TabLocation) => tabLocation === 'transactions' ? <>
        {nonZeroEntriesCount} {t('financialsDrawer:transactions')}
      </> : null;
  const generateHeaderTitleByTab = (tabLocation: TabLocation) => tabLocation === 'transactions' ? t('financialsDrawer:transactions') : t('financialsDrawer:edit_report_drawer_title');
  const showSuggestions = dates && dates.length > 0 && Object.values(entriesByDate)?.flat().length > 0;

  // ADIL - Search values should also be passed to edit report drawer content and filter display of outputs/formuas
  return <div className={styles.financialDrawerContent} data-sentry-component="FinancialDrawerContent" data-sentry-source-file="FinancialDrawerContent.tsx">
      <DrawerContentHeader title={generateHeaderTitleByTab(activeTab)} searchValue={searchValue} setSearchValue={setSearchValue} setIsSidebarDrawerOpen={setIsSidebarDrawerOpen} content={() => renderDrawerHeaderContent(activeTab)} data-sentry-element="DrawerContentHeader" data-sentry-source-file="FinancialDrawerContent.tsx" />
      <div>
        <Tabs selectedIndex={activeTab === 'transactions' ? 0 : 1} onSelect={index => setActiveTab(index === 0 ? 'transactions' : 'edit-report')} className={`${styles.financialDrawerTabs} `} data-sentry-element="Tabs" data-sentry-source-file="FinancialDrawerContent.tsx">
          <TabList className="flex items-start justify-between" data-sentry-element="TabList" data-sentry-source-file="FinancialDrawerContent.tsx">
            <Tab tabIndex="transactions" className={styles.financialDrawerTab} data-sentry-element="Tab" data-sentry-source-file="FinancialDrawerContent.tsx">
              {t('financialsDrawer:transactions')}
            </Tab>
            <Tab tabIndex="edit-report" className={styles.financialDrawerTab} data-sentry-element="Tab" data-sentry-source-file="FinancialDrawerContent.tsx">
              {t('financialsDrawer:edit_report')}
            </Tab>
          </TabList>
          <TabPanel data-sentry-element="TabPanel" data-sentry-source-file="FinancialDrawerContent.tsx">
            <div className={styles.financialDrawerTabPanelContainer} ref={transactionScrollContainer}>
              {showSuggestions && <DrawerAISuggestion dates={dates} outputID={outputID} outputName={original_row_object.name} generateSignForEntryFunction={generateSignForEntry} />}
              <Transactions entriesByDate={filteredEntriesByDate} transactionScrollContainer={transactionScrollContainer} data-sentry-element="Transactions" data-sentry-source-file="FinancialDrawerContent.tsx" />
            </div>
          </TabPanel>
          <TabPanel data-sentry-element="TabPanel" data-sentry-source-file="FinancialDrawerContent.tsx">
            <Tabs className={`${styles.financialDrawerSubTabs} `} data-sentry-element="Tabs" data-sentry-source-file="FinancialDrawerContent.tsx">
              <TabList className="flex items-start gap-2" data-sentry-element="TabList" data-sentry-source-file="FinancialDrawerContent.tsx">
                <Tab data-sentry-element="Tab" data-sentry-source-file="FinancialDrawerContent.tsx">{t('financialsDrawer:groups')}</Tab>
                <Tab data-sentry-element="Tab" data-sentry-source-file="FinancialDrawerContent.tsx">{t('financialsDrawer:outputs')}</Tab>
                <Tab className="pointer-events-none hidden cursor-not-allowed" data-sentry-element="Tab" data-sentry-source-file="FinancialDrawerContent.tsx">
                  {t('financialsDrawer:calculations')}
                </Tab>
              </TabList>

              <TabPanel data-sentry-element="TabPanel" data-sentry-source-file="FinancialDrawerContent.tsx">
                <EditReportDrawerContent type="groups" searchValue={searchValue} data-sentry-element="EditReportDrawerContent" data-sentry-source-file="FinancialDrawerContent.tsx" />
              </TabPanel>
              <TabPanel data-sentry-element="TabPanel" data-sentry-source-file="FinancialDrawerContent.tsx">
                <EditReportDrawerContent type="outputs" searchValue={searchValue} data-sentry-element="EditReportDrawerContent" data-sentry-source-file="FinancialDrawerContent.tsx" />
              </TabPanel>
              <TabPanel data-sentry-element="TabPanel" data-sentry-source-file="FinancialDrawerContent.tsx">
                <EditReportDrawerContent type="calculations" searchValue={searchValue} data-sentry-element="EditReportDrawerContent" data-sentry-source-file="FinancialDrawerContent.tsx" />
              </TabPanel>
            </Tabs>
          </TabPanel>
        </Tabs>
      </div>
    </div>;
};
export default FinancialDrawerContent;