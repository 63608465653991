import moment from 'moment';
import { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import arrow2 from '@/public/assets/icons/Alt Arrow Right.svg';
import styles from '@/styles/hiringplan.module.scss';
import { dateFormatter, parseUTCDateObject } from '@/utils/dateUtils';
import MonthYearPicker from './MonthYearPicker';
export const CurrentDatePicker = ({
  currentDate,
  setCurrentDate,
  startDate,
  endDate,
  className,
  enableOutOfRange = false,
  style,
  textStyle,
  ignoreMaxAndMinDate = false
}: {
  currentDate: Date | string;
  setCurrentDate: (e: Date) => void;
  startDate?: Date;
  endDate?: Date;
  className?: string;
  enableOutOfRange?: boolean;
  style?: React.CSSProperties;
  textStyle?: string;
  ignoreMaxAndMinDate?: boolean;
}) => {
  const [date, setDate] = useState<Date>(parseUTCDateObject(currentDate));
  const today = parseUTCDateObject();
  useEffect(() => {
    setDate(parseUTCDateObject(currentDate));
  }, [currentDate]);
  const currentDateOutOfRange = (currentData: Date) => {
    if (enableOutOfRange) return false;
    return currentData < (startDate as Date) || currentData > today || currentData > (endDate as Date);
  };
  const handleDateChange = (value: number) => {
    let newDate;
    if (value !== 0) {
      if (!date) return;
      newDate = moment(date).add(value, 'months').utc().toDate();
    } else {
      newDate = value;
    }
    if (currentDateOutOfRange((newDate as Date))) return;
    setDate((newDate as Date));
    setCurrentDate((newDate as Date));
  };

  /**
   * Function to set the class name for calendar tiles.
   * Checks if the view is 'year' and the newDate matches the component's
   * date state, to set the 'active-month' class.
   */
  const tileClassName = ({
    date: newDate,
    view
  }: {
    date: Date;
    view: string;
  }) => {
    if (view === 'year') {
      if (newDate.getTime() === date?.getTime()) {
        return 'active-month';
      }
    }
  };
  function getMaxDate(): Date | undefined {
    if (enableOutOfRange) return parseUTCDateObject((endDate as Date));
    return parseUTCDateObject() < parseUTCDateObject((endDate as Date)) ? parseUTCDateObject() : parseUTCDateObject((endDate as Date));
  }
  return <div className={className ? `${className} ${styles.datePickerForDonut} ` : `${styles.datePickerForDonut}`} style={style} data-sentry-component="CurrentDatePicker" data-sentry-source-file="CurrentDatePicker.tsx">
      <span onClick={() => {
      handleDateChange(-1);
    }} className={currentDateOutOfRange(moment(currentDate).subtract(1, 'month').startOf('month').utc().toDate()) ? styles.disabledArrow : ''}>
        <img className={styles.leftArrow} src={arrow2.src} alt="arrow" />
      </span>
      <Popup trigger={<div className={textStyle || styles.dateText}>
            {dateFormatter(date)}
          </div>} position="bottom center" data-sentry-element="Popup" data-sentry-source-file="CurrentDatePicker.tsx">
        <MonthYearPicker maxDate={ignoreMaxAndMinDate ? undefined : getMaxDate()} // Current Day
      minDate={ignoreMaxAndMinDate ? undefined : parseUTCDateObject((startDate as Date))} value={date} onChange={(dateValue: Date) => {
        if (currentDateOutOfRange(dateValue)) return;
        setCurrentDate(dateValue);
      }} className="calendar-design" tileClassName={tileClassName} data-sentry-element="MonthYearPicker" data-sentry-source-file="CurrentDatePicker.tsx" />
      </Popup>
      <span onClick={() => {
      handleDateChange(1);
    }} className={currentDateOutOfRange(moment(currentDate).add(1, 'month').startOf('month').utc().toDate()) ? styles.disabledArrow : ''}>
        <img src={arrow2.src} alt="arrow" />
      </span>
    </div>;
};