import { Search, X } from 'lucide-react';
import styles from '../FinancialDrawerContent/financialDrawerContentStyles.module.scss';

/**
 * Renders a search input component for the financial drawer.
 *
 * @param searchValue - The current value of the search input.
 * @param setSearchValue - A function to update the search value.
 * @returns The rendered search input component.
 */
const DrawerSearchInput = ({
  searchValue,
  setSearchValue
}: {
  searchValue: string;
  setSearchValue: (value: string) => void;
}) => {
  return <div className={styles.financialDrawerContentHeaderSearch} data-sentry-component="DrawerSearchInput" data-sentry-source-file="DrawerContentHeader.tsx">
      <Search data-sentry-element="Search" data-sentry-source-file="DrawerContentHeader.tsx" />
      <input type="text" placeholder="Search" value={searchValue} onChange={e => setSearchValue(e.target.value)} />
    </div>;
};

/**
 * Renders the header content for the financial drawer component.
 *
 * @param title - The title to display in the header.
 * @param searchValue - The current value of the search input.
 * @param setSearchValue - A function to update the search value.
 * @param setIsSidebarDrawerOpen - A function to toggle the visibility of the sidebar drawer.
 * @param t - A translation function.
 * @param entriesCount - The number of entries to display.
 * @returns The rendered header content.
 */
const DrawerContentHeader = ({
  title,
  searchValue,
  setSearchValue,
  setIsSidebarDrawerOpen,
  content
}: {
  title: string;
  searchValue: string;
  setSearchValue: (value: string) => void;
  setIsSidebarDrawerOpen: (value: boolean) => void;
  content: () => React.ReactNode;
}) => {
  return <div className={styles.financialDrawerContentHeaderWrapper} data-sentry-component="DrawerContentHeader" data-sentry-source-file="DrawerContentHeader.tsx">
      <div className={styles.financialDrawerContentHeader}>
        <div className=" flex items-center justify-between">
          <h1>{title}</h1>
          <X className="cursor-pointer" onClick={() => {
          console.log('close');
          setIsSidebarDrawerOpen(false);
        }} data-sentry-element="X" data-sentry-source-file="DrawerContentHeader.tsx" />
        </div>
        {content()}
        <DrawerSearchInput searchValue={searchValue} setSearchValue={setSearchValue} data-sentry-element="DrawerSearchInput" data-sentry-source-file="DrawerContentHeader.tsx" />
      </div>
    </div>;
};
export default DrawerContentHeader;