import React, { useEffect, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';
import type { UtilsStoreState } from '@/miscellaneous/store/utilsStore/utilsStore';
import useUtilsStore from '@/miscellaneous/store/utilsStore/utilsStore';
import styles from './appLoader.module.scss';
const list = ['AI accountants are punching numbers.', 'Your forecast is brewing.', 'Spreadsheet cells are snapping into place.', 'Revenue’s growing, costs under control.', 'Data’s stretching and aligning perfectly.', 'Cash flow is getting fine-tuned.', 'Hiring plan’s in full sprint.', 'KPIs are shining up.', 'P&L’s suiting up.', 'Projections are locking in for precision.'];
const TextRotator = () => {
  const [index, setIndex] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setIndex(prevIndex => (prevIndex + 1) % list.length);
    }, 4000);
    return () => clearInterval(interval);
  }, []);
  return <div className={styles.textRotator} data-sentry-component="TextRotator" data-sentry-source-file="AppLoader.tsx">
      <p className={styles.fade}>{list[index]}</p>
    </div>;
};
const AppLoader: React.FC = () => {
  const {
    displayCounter,
    countLoader
  } = useUtilsStore(useShallow((state: UtilsStoreState) => ({
    displayCounter: state.displayCounter,
    countLoader: state.countLoader
  })));
  const [counter, setCounter] = useState(1);
  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
    if (displayCounter && countLoader) {
      interval = setInterval(() => {
        setCounter(prevCounter => {
          if (prevCounter >= 100) {
            clearInterval(interval!);
            return 100;
          }
          let increment = 1;

          // Slow down the increment as counter approaches 50% and above
          if (prevCounter >= 50 && prevCounter < 75) {
            increment = 1;
          } else if (prevCounter >= 75 && prevCounter < 90) {
            increment = 0.5;
          } else if (prevCounter >= 90) {
            increment = 0.25;
          }
          return Math.min(prevCounter + increment, 100);
        });
      }, 1000); // Increment every second initially
    }
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [countLoader, displayCounter]);
  useEffect(() => {
    if (!countLoader && counter < 100 && displayCounter) {
      const fastForwardInterval = setInterval(() => {
        setCounter(prevCounter => {
          if (prevCounter >= 100) {
            clearInterval(fastForwardInterval);
            return 100;
          }
          return Math.min(prevCounter + 5, 100); // Speed up to quickly reach 100, ensuring it does not exceed 100
        });
      }, 10); // Fast forward interval time

      return () => clearInterval(fastForwardInterval);
    }
  }, [countLoader, counter, displayCounter]);
  return <div className={styles.appLoader} data-sentry-component="AppLoader" data-sentry-source-file="AppLoader.tsx">
      <div className={styles.loaderContainer}>
        <div className={styles.spinner} />
        {displayCounter && <div className={styles.counter}>{Math.floor(counter)}%</div>}
      </div>
      <TextRotator data-sentry-element="TextRotator" data-sentry-source-file="AppLoader.tsx" />
    </div>;
};
export default AppLoader;