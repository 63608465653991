import Image from 'next/image';
import { BrowserView, MobileView } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
const DeviceLayout = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const {
    t: translate
  } = useTranslation(['common']);
  return <>
      <BrowserView data-sentry-element="BrowserView" data-sentry-source-file="DeviceLayout.tsx">{children}</BrowserView>
      <MobileView data-sentry-element="MobileView" data-sentry-source-file="DeviceLayout.tsx">
        <div className="flex h-screen flex-col items-center justify-center p-5">
          <div className=" relative">
            <Image src="/assets/images/laptop-mockup.webp" alt="Mobile" className="mt-5" width={250} height={250} data-sentry-element="Image" data-sentry-source-file="DeviceLayout.tsx" />
            <Image src="/assets/images/logo-fig.png" alt="Mobile" className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" width={150} height={150} data-sentry-element="Image" data-sentry-source-file="DeviceLayout.tsx" />
          </div>
          <h1 className="mt-5 text-center text-xl font-bold">
            {translate('common:mobile_device_message')}
          </h1>
        </div>
      </MobileView>
    </>;
};
export default DeviceLayout;