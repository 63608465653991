import { convertToKAndM, disableGraphClick } from '@/utils/ChartUtils';
import type { ChartOptionsType } from '@/utils/types/DashboardAndChartsTypes';

import styles from '../../../components/Graphs/graphs.module.scss';

/**
 * Generates configuration options for a donut chart.
 * @param {ChartOptionsType} labels - The labels for the data.
 * @param {string[]} graphColors - The colors for the data.
 * @param {any} commonOptions - Common options for the chart.
 * @returns {Object} - An object containing the configuration options for the donut chart.
 */
export const donutChartOptions = ({
  labels,
  graphColors,
  commonOptions,
}: ChartOptionsType) => {
  return {
    ...disableGraphClick,
    chart: {
      type: 'donut',
    },
    fill: { colors: graphColors },
    colors: graphColors,
    labels,
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 0,
    },
    tooltip: {
      ...commonOptions?.tooltip,
      y: {
        formatter(value: number) {
          return convertToKAndM(value);
        },
      },
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
        donut: {
          size: '70%',
          labels: {
            show: true,
            name: {
              show: true,
              fontFamily: styles.fontFamily,
              fontSize: '20px',
              fontWeight: '600',
            },
            value: {
              show: true,
              fontFamily: styles.fontFamily,
              fontSize: '20px',
              fontWeight: '600',
            },
            total: {
              show: true,
              showAlways: true,
              fontFamily: styles.fontFamily,
              fontSize: '18px',
              fontWeight: '600',
              formatter(w: any) {
                const result = w.globals.seriesTotals.reduce(
                  (a: any, b: any) => {
                    return a + b;
                  },
                  0,
                );
                return convertToKAndM(result);
              },
            },
          },
        },
      },
    },
  };
};
