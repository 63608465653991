import { Sigma } from 'lucide-react';
import React, { useEffect } from 'react';
import { allChildrenFormulasIDAvgFormula, allChildrenFormulasIDSumFormula } from '@/utils/hooks/formulas/SystemFormulas';
import useMutations from '@/utils/hooks/mutations/useMutations';
import type { ExpressionType, Formula } from '@/utils/types/formulaTypes';
import styles from './FolderCellComponent.module.scss';
const emptyExpression: ExpressionType = {
  formula: '',
  start: '',
  end: ''
};
const availableExpressions = [{
  value: 'AVG',
  icon: () => <div className={styles.avgIcon}>
        <span>AVG</span>
      </div>,
  expression: allChildrenFormulasIDAvgFormula
}, {
  value: 'SUM',
  icon: Sigma,
  expression: allChildrenFormulasIDSumFormula
}];
interface FolderCellComponentProps {
  row: any;
}
const FolderCellComponent: React.FC<FolderCellComponentProps> = ({
  row
}) => {
  const rowFormula = (row.original.original_row_object as Formula);
  const {
    updateFormula
  } = useMutations();
  const [curExpression, setCurExpression] = React.useState<ExpressionType>(emptyExpression);
  useEffect(() => {
    try {
      const parsedExpression = JSON.parse(rowFormula.expression_string);
      if (Array.isArray(parsedExpression) && parsedExpression.length > 0) {
        const expression = parsedExpression[0];
        setCurExpression(expression);
      } else {
        // Handle case where parsedExpression is not an array or is empty
        setCurExpression(emptyExpression);
      }
    } catch (error) {
      console.error('Error parsing expression string:', error);
      setCurExpression(emptyExpression); // Fallback to null or default value if parsing fails
    }
  }, [rowFormula?.expression_string]);
  const updateFolderCalculation = (original: Formula, expression: ExpressionType) => {
    console.log('Updating folder calculation:', expression);
    updateFormula.mutate({
      ...original,
      expression_string: JSON.stringify([expression])
    });
  };
  const handleButtonClick = (expression: ExpressionType) => {
    if (curExpression.formula === expression.formula) {
      setCurExpression(emptyExpression);
      updateFolderCalculation(rowFormula, emptyExpression);
    } else {
      setCurExpression(expression);
      updateFolderCalculation(rowFormula, expression);
    }
  };
  return <div className={styles.folderCalculationContainer} data-sentry-component="FolderCellComponent" data-sentry-source-file="FolderCellComponent.tsx">
      {availableExpressions.map(expr => <button type="button" aria-label="Folder Calculation" key={expr.value} onClick={() => handleButtonClick(expr.expression)} className={`${styles.buttonStyle} ${curExpression.formula === expr.expression.formula ? styles.activeButton : ''}`}>
          {typeof expr.icon === 'function' && expr.icon.name !== 'Sigma' ? <expr.icon /> : <expr.icon size={16} />}
        </button>)}
    </div>;
};
export default FolderCellComponent;