import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { useTableContext } from './TableDNDContext';

interface UseDragAndDropProps {
  id: string;
  row: any;
  isOverEnabled?: boolean;
}

const useDragAndDrop = ({
  id,
  row,
  isOverEnabled = true,
}: UseDragAndDropProps) => {
  const sortable = useSortable({ id });

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
    over,
  } = sortable;

  const { setDraggedItem, setOver, draggedItem } = useTableContext();

  if (over?.id === id && isOverEnabled) {
    setOver(over);
  }
  if (isDragging) {
    setDraggedItem(row);
  }

  const style = {
    ...(isDragging
      ? {
          zIndex: 9999,
          transform: CSS.Transform.toString(transform),
          transition,
        }
      : {}),
  };

  return {
    ...sortable,
    style,
    attributes,
    listeners,
    setNodeRef,
    isDragging,
    draggedItem,
  };
};

export default useDragAndDrop;
