import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import type { TableRow } from '@/components/table/shared/types';
import type { AccountTree, Output } from '@/utils/hooks/Outputs/useOutputs';
type DataStoreDataState = {
  branchOutputs: Output[];
  accountTrees: AccountTree[];
  financialDrawerTablerows: TableRow[]; // we are using this to store the tablerows of the financial drawer and add to dnd context
  currentTableRows: any[];
  branchTopLevelOutputs: Output[]; // these are the baseOutput that have base output id according and are Top level output
};
type DataStoreActionsState = {
  setBranchOutputs: (outputs: Output[]) => void;
  setAccountTrees: (trees: AccountTree[]) => void;
  resetStore: () => void;
  getAllOutputChildren: (outputID: number) => number[];
  setFinancialDrawerTablerows: (rows: TableRow[]) => void;
  setCurrentTableRows: (rows: any[]) => void;
  setBranchTopLevelOutputs: (outputs: Output[]) => void;
};
export type DataStoreState = DataStoreDataState & DataStoreActionsState;
export const defaultState: DataStoreDataState = {
  branchOutputs: [],
  accountTrees: [],
  financialDrawerTablerows: [],
  currentTableRows: [],
  branchTopLevelOutputs: []
};
const DataStore = create(persist<DataStoreState>(set => ({
  ...defaultState,
  setBranchOutputs: (outputs: Output[]) => set({
    branchOutputs: outputs
  }),
  setAccountTrees: (trees: AccountTree[]) => set({
    accountTrees: trees
  }),
  resetStore: () => set({
    ...defaultState
  }),
  getAllOutputChildren: (outputID: number) => {
    // find the accountTree connected with this outputID. Then recursively find all children using accountTree.children. And return a list of outputIDs
    const accountTree = DataStore.getState().accountTrees.find(tree => tree.output.id === outputID);
    if (!accountTree) return [];
    const outputIDs: number[] = [];
    const findChildren = (tree: AccountTree) => {
      if (outputID !== tree.output.id) outputIDs.push(tree.output.id);
      tree.children?.forEach(child => findChildren(child));
    };
    findChildren(accountTree!);
    return outputIDs;
  },
  setBranchTopLevelOutputs: (outputs: Output[]) => set({
    branchTopLevelOutputs: outputs
  }),
  setCurrentTableRows: (rows: any[]) => set({
    currentTableRows: rows
  }),
  setFinancialDrawerTablerows: (rows: TableRow[]) => set({
    financialDrawerTablerows: rows
  })
}), {
  name: 'data-store',
  // key for the local storage
  getStorage: () => sessionStorage // specify storage type, e.g., localStorage
}));
export default DataStore;