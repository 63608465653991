import React, { useEffect, useRef, useState } from 'react';
import styles from '@/styles/scss/formulaCell.module.scss';

/**
 * Interface for CustomModal component props
 * @interface CustomVariableTimeStepModalInterface
 */
interface CustomVariableTimeStepModalInterface {
  close: () => void;
  setSelectedValue: (value: string) => void;
  updateEditorContent: (value: string) => void;
  match: any;
}

/**
 * CustomModal component: A modal for entering custom time step
 * @param {CustomVariableTimeStepModalInterface} props - Props for the CustomVariableTimeStepModal component
 * @returns {JSX.Element} JSX element representing the CustomVariableTimeStepModal component
 */
const CustomVariableTimeStepModal: React.FC<any> = ({
  close,
  setSelectedValue,
  updateEditorContent,
  match
}: CustomVariableTimeStepModalInterface) => {
  const ref = (useRef(null) as any);
  const modalRef = useRef<HTMLDivElement>(null); // Specify HTMLDivElement as the type
  // const { t } = useTranslation(['common', 'formulaCell']);

  /**
   * Function to handle click event
   * @param {React.MouseEvent<HTMLTextAreaElement>} e - The click event
   * @returns {void}
   */
  const onClick = (e: any) => {
    e.preventDefault();
    e.target.focus();
  };
  const [value, setValue] = useState('');

  /**
   * Handles the close event of the CustomVariableTimeStepModal component.
   *
   * This function is responsible for updating the selected value and the editor content based on the user's input in the modal. It checks the value entered by the user and sets the appropriate selected value and updates the editor content accordingly.
   *
   * @param {string} value - The value entered by the user in the modal.
   * @param {any} match - The match object from the regular expression used to replace the input.
   * @param {(value: string) => void} setSelectedValue - A function to set the selected value.
   * @param {(value: string) => void} updateEditorContent - A function to update the editor content.
   * @param {() => void} close - A function to close the modal.
   * @returns {void}
   */
  const onClose = () => {
    const timeStep = value;
    let replacedInput = '';
    if (value === '') {
      // If the value is empty
      setSelectedValue('This Month');
      const newInput = `{{${match[1]},y,x}}`;
      replacedInput = match.input.replace(match[0], newInput);
      updateEditorContent(replacedInput);
      setValue('');
      close();
      return;
    }
    if (+timeStep % 12 !== 0) {
      // If the value is not divisible by 12
      setSelectedValue(`${timeStep} Months Ago`);
      const newInput = `{{${match[1]},y,x-${timeStep}}}`;
      replacedInput = match.input.replace(match[0], newInput);
    }
    if (+timeStep % 12 === 0) {
      // If the value is divisible by 12
      const years = Math.floor(+timeStep / 12);
      setSelectedValue(`${years} ${years === 1 ? 'Year' : 'Years'} Ago`);
      const newInput = `{{${match[1]},y-${years},x}}`;
      replacedInput = match.input.replace(match[0], newInput);
    }
    updateEditorContent(replacedInput);
    setValue('');
    close();
  };

  // Function to handle click outside the modal
  const handleClickOutside = (event: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains((event.target as Node))) {
      onClose(); // Call onClose if the click is outside the modal
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []); // Clean up the event listener when the component unmounts

  /**
   * Loads the value from the match object and sets the state accordingly.
   *
   * This function extracts the year and month from the match object and sets the state value based on the extracted values. If the year is present, it calculates the total number of months and sets the value. If the month is present, it sets the value directly.
   */
  const loadValueFromMatch = () => {
    const year = match[2]?.split('-')[1];
    const month = match[3]?.split('-')[1];
    if (year) {
      setValue((year * 12).toString());
    }
    if (month) {
      setValue(month);
    }
  };
  useEffect(() => {
    if (ref) ref?.current?.focus();
    loadValueFromMatch(); // Load the value from the match object
  }, []);
  return <div className={styles.modal} ref={modalRef} data-sentry-component="CustomVariableTimeStepModal" data-sentry-source-file="customVariableTimeStepModal.tsx">
      <div className={styles.container}>
        <div className={styles.top}>
          <h3>Enter custom time step</h3>
          <button type="button" onClick={onClose}>
            x
          </button>
        </div>
        <div className={styles.largeTextarea}>
          <textarea ref={ref} value={`t-${value}`} // Set the value of the textarea, including the 't-' prefix
        onClick={onClick} onChange={e => {
          if (Number.isNaN(+e.target.value.replace('t-', ''))) return; // If the value is not a number
          const extractedValue = e.target.value.replace('t-', ''); // Extract the value
          if (extractedValue === '') {
            setValue('');
          } else {
            setValue(extractedValue);
          }
        }} onKeyDown={e => {
          if (e.key === 'Enter') {
            e.preventDefault(); // Prevent the default action to avoid form submission or newline in textarea
            onClose(); // Call the onClose function when Enter is pressed
          }
        }} />
        </div>
        <div className={styles.textElements}>
          <div className={styles.shared}>
            <h4>Single Month</h4>
            <p>
              Refer to a specific date e.g. (2023, 1), a helper variable e.g.
              LastActualDate, a relative look back e.g. t-3 to look back 3 time
              steps
            </p>
          </div>
          <div className={styles.shared}>
            <h4>Time Span</h4>
            <p>
              Refer to a specific date e.g. (2023, 1), a helper variable e.g.
              LastActualDate, a relative look back e.g. t-3 to look back 3 time
              steps
            </p>
          </div>
        </div>
      </div>
    </div>;
};
export default CustomVariableTimeStepModal;