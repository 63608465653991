/**
 * OptionMenu Component
 *
 * Description:
 * The `OptionMenu` component provides a selectable list of options to the user. Each option, when clicked,
 * sends a predefined message directly to the chat. This is typically used for giving users quick access to common
 * queries or responses.
 */

import type { FC } from 'react';
import React from 'react';
import { chatQuickOptions } from '@/miscellaneous/constant/quickChatPrompts';
import styles from '@/styles/scss/copilotChat.module.scss';
interface IOptionMenuProps {
  /**
   * Function to handle adding a message to the chat.
   * @param message - The message to add to the chat.
   */
  addMessageToChat: (message: string, chatDisplay?: string) => void;
}

/**
 * OptionMenu component responsible for rendering a menu of predefined options.
 * @param addMessageToChat - Function to handle adding a message to the chat.
 * @returns The OptionMenu component.
 */
const OptionMenu: FC<IOptionMenuProps> = ({
  addMessageToChat
}) => {
  /**
   * An array of options to display in the menu.
   */
  const options = chatQuickOptions;
  return <div className={styles.OptionMenu} data-sentry-component="OptionMenu" data-sentry-source-file="OptionMenu.tsx">
      <div>
        {options.map((option, index) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <ul key={index}>
              <h1>{option.name}</h1>
              {option.options.map((chatOption, i) => {
              return (
                // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                <li onClick={() => {
                  addMessageToChat(chatOption.prompt, chatOption.name);
                }}
                // eslint-disable-next-line react/no-array-index-key
                key={i}>
                    {chatOption.name}
                  </li>
              );
            })}
            </ul>
        );
      })}
      </div>
    </div>;
};
export default OptionMenu;