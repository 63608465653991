/* eslint-disable @typescript-eslint/no-shadow */
import { isEqual } from 'lodash';
import { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import type { TableRow } from '@/components/table/shared/types';
import type { DataStoreState } from '@/miscellaneous/store/DataStore';
import DataStore from '@/miscellaneous/store/DataStore';
import type { ZustandState } from '@/miscellaneous/store/zustand_store';
import useZustandStore from '@/miscellaneous/store/zustand_store';
import type { Branch } from '@/utils/hooks/branches/useBranches';
import type { Formula } from '@/utils/types/formulaTypes';
import DrawerBodySection from '../SubComponents/DrawerBodySection';
import type { EditReportBodyProps } from './EditReportHelper';
import { createSectionNewRow, getDnDItemsWithGrabber, getNewReportItems, getOutputRows, memoizedGenerateBranchFormulaRows } from './EditReportHelper';
import styles from './financialDrawerContentStyles.module.scss';
const EditReportDrawerContent: React.FC<EditReportBodyProps> = ({
  type = 'outputs',
  searchValue
}) => {
  if (searchValue) console.log('🚀 ~ searchValue:', searchValue);
  const {
    t
  } = useTranslation(['financialsDrawer']);
  // The height of the transactions list is calculated baed on the number of transactions.

  const {
    branchOutputs,
    setFinancialDrawerTablerows
  } = DataStore((state: DataStoreState) => state);
  const {
    activeBranch,
    activeReport
  } = useZustandStore((state: ZustandState) => state);
  const branchFormulas: Formula[] = [];

  // creating all the new base report items
  const {
    newFormulaReportItem,
    newOutputReportItem,
    newSectionReportItem
  } = getNewReportItems(activeReport);

  // generating the new avaialble formula rows
  const branchFormulaRows = memoizedGenerateBranchFormulaRows(branchFormulas, newFormulaReportItem);
  const filterRowsBySearchValue = (rows: {
    id: string;
    original: TableRow;
  }[], searchValue: string) => {
    if (!searchValue) return rows;
    return rows.filter(row => row.original.sidebar?.toLowerCase().includes(searchValue.toLowerCase()));
  };

  // gneerating the new available output rows
  const {
    outputs,
    topLevelRows,
    outputRows
  } = useMemo(() => {
    const {
      outputRows,
      topLevelRows
    } = getOutputRows(branchOutputs, newOutputReportItem);
    const filteredTopLevelRows = filterRowsBySearchValue(topLevelRows, searchValue);
    const filteredOutputRows = filterRowsBySearchValue(outputRows, searchValue);
    const outputs = [...filteredTopLevelRows, ...filteredOutputRows];
    return {
      outputs,
      topLevelRows: filteredTopLevelRows,
      outputRows: filteredOutputRows
    };
  }, [branchOutputs, newOutputReportItem, searchValue]);
  const groups = useMemo(() => [createSectionNewRow((activeBranch as Branch), newSectionReportItem)], [activeBranch, newSectionReportItem]);
  const allTableRows = useMemo(() => [...outputs, ...groups, ...branchFormulaRows], [outputs, groups, branchFormulaRows]);

  // saving previous rows for memoization and blocking endless loop with setFinancialDrawerTablerows
  const previousTabRows = useRef<{
    original: TableRow;
    id: string;
  }[]>([]);
  useEffect(() => {
    if (!isEqual(allTableRows, previousTabRows.current)) {
      setFinancialDrawerTablerows(allTableRows.map(row => row.original));
      previousTabRows.current = allTableRows;
    }
  }, [allTableRows]);
  const outputsContent = () => <>
      <DrawerBodySection title={t('top_level_accounts')} data-sentry-element="DrawerBodySection" data-sentry-source-file="EditReportDrawerContent.tsx">
        {getDnDItemsWithGrabber(topLevelRows)}
      </DrawerBodySection>
      <DrawerBodySection title={t('accounts')} data-sentry-element="DrawerBodySection" data-sentry-source-file="EditReportDrawerContent.tsx">
        {getDnDItemsWithGrabber(outputRows)}
      </DrawerBodySection>
    </>;
  const getRowsByType = () => {
    switch (type) {
      case 'outputs':
        return (
          // on this function you need to seperate the top level outputs and the other outputs
          <>
            <DrawerBodySection title={t('top_level_accounts')}>
              {getDnDItemsWithGrabber(topLevelRows)}
            </DrawerBodySection>
            <DrawerBodySection title={t('accounts')}>
              {getDnDItemsWithGrabber(outputRows)}
            </DrawerBodySection>
          </>
        );
      case 'groups':
        return <DrawerBodySection title={t('department_tag')}>
            {getDnDItemsWithGrabber(groups)}
          </DrawerBodySection>;
      case 'calculations':
        // disabled for now
        return <DrawerBodySection title={t('inputs_and_calculations')}>
            {getDnDItemsWithGrabber(branchFormulaRows)}
          </DrawerBodySection>;
      default:
        return <>{outputsContent()}</>;
    }
  };
  return <div className={styles.mainDrawerContentContainer} data-sentry-component="EditReportDrawerContent" data-sentry-source-file="EditReportDrawerContent.tsx">{getRowsByType()}</div>;
};
export default EditReportDrawerContent;