import 'animate.css';
import 'react-modern-drawer/dist/index.css';
import { useAuth0 } from '@auth0/auth0-react';
import { useQueryClient } from '@tanstack/react-query';
import { deleteCookie } from 'cookies-next';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { useShallow } from 'zustand/react/shallow';
import ChatDrawer from '@/components/common/CopilotChat/CopilotChatComponents/ChatDrawer';
import { checkCookiesExist, deleteCookies } from '@/cookies';
import dynamicRoute from '@/miscellaneous/constant';
import { variables } from '@/styles/variables/constant';
import type { Branch } from '@/utils/hooks/branches/useBranches';
import useBranches from '@/utils/hooks/branches/useBranches';
import type { Company } from '@/utils/hooks/company/useCompany';
import useCompany from '@/utils/hooks/company/useCompany';
import { ActionType, useSetComapanyURLForScrape } from '@/utils/hooks/CompanyScraping/useSetComapanyURLForScrape';
import { checkAndSetActiveUrlObject } from '@/utils/hooks/hooksHelper';
import useIntegrations from '@/utils/hooks/useIntegrations/useIntegrations';
import { useIsLoggedIn } from '@/utils/hooks/useIsLoggedIn';
import type { UtilsStoreState } from '../store/utilsStore/utilsStore';
import useUtilsStore from '../store/utilsStore/utilsStore';
import type { ZustandState } from '../store/zustand_store';
import useZustandStore from '../store/zustand_store';
import AppLoader from './AppLoader/AppLoader';
import DeviceLayout from './DeviceLayout/DeviceLayout';
import PageLayout from './PageLayout/PageLayout';
import styles from './PageLayout/pageLayout.module.scss';
const areValidElements = (children: React.ReactNode): boolean => {
  if (Array.isArray(children)) {
    return children.every(child => React.isValidElement(child));
  }
  return React.isValidElement(children);
};
type UnifiedLayoutProps = {
  children: React.ReactNode;
};
/**
 * This component is responsible for rendering the authentication layout of the application. And managing the stat of the company and branch actively according to the url.
 * @param param0
 * @returns
 */
const UnifiedLayout = ({
  children
}: UnifiedLayoutProps) => {
  const router = useRouter();
  const queryClient = useQueryClient();
  const {
    logout
  } = useAuth0();
  const {
    setIsLoggedIn
  } = useIsLoggedIn();
  const {
    setCompanyURLForScrape: handleCompanyUrlScrape
  } = useSetComapanyURLForScrape();
  const {
    isLoggedIn,
    resetStore,
    activeBranch,
    activeCompany,
    setLogoutFunction
  } = useZustandStore(useShallow((state: ZustandState) => ({
    isLoggedIn: Boolean(state.isLoggedIn),
    resetStore: state.resetStore,
    activeCompany: state.activeCompany,
    activeBranch: state.activeBranch,
    setLogoutFunction: state.setLogoutFunction
  })));
  const logoutUserFromThePlatform: any = () => {
    deleteCookies();
    setIsLoggedIn(null);
    resetStore();
    logout({
      logoutParams: {
        returnTo: `${window.location.origin}${dynamicRoute.login}`
      }
    });
  };
  const {
    appLoader
  } = useUtilsStore(useShallow((state: UtilsStoreState) => ({
    appLoader: state.appLoader
  })));
  useEffect(() => {
    setLogoutFunction(logoutUserFromThePlatform);
  }, []);
  const {
    isSuccess: isSuccessCompany,
    userCompanies,
    setActiveCompany
  } = useCompany();
  const {
    branchesByCompanyId,
    isSuccess: isSuccessBranch,
    setActiveBranch
  } = useBranches();
  useIntegrations(); // call useIntegrations to get integrations data

  useEffect(() => {
    // If the user is on the login or signup page and the company URL is present in the query params, scrape the company URL
    if (dynamicRoute.unAuthenticatedRoutes.includes(router.pathname) && router.query?.company_url) {
      // Handle company URL scraping
      handleCompanyUrlScrape(router.pathname === dynamicRoute.login ? ActionType.LOGIN : ActionType.SIGNUP);
    }
  }, [router.query?.company_url, router.pathname]);

  // Handle redirection and session cleanup on logout
  useEffect(() => {
    if (isLoggedIn) {
      if (!checkCookiesExist((activeBranch as Branch), (activeCompany as Company))) {
        // If cookies do not exist but activeBranch and activeCompany exist reset the store. And user does not exist in the cookies, then reset the store
        resetStore();
      } else if (activeCompany && activeCompany?.slug && dynamicRoute.unAuthenticatedRoutes.includes(router.pathname)) {
        const url = `/${activeCompany?.slug}/main/${dynamicRoute.financials}/${dynamicRoute.defaultFinancialReport}`;
        router.push(url, url, {
          locale: router.locale
        });
      }
    } else {
      const {
        unAuthenticatedRoutes
      } = dynamicRoute;
      if (!unAuthenticatedRoutes.includes(router.pathname)) {
        router.push(dynamicRoute.login, dynamicRoute.login, {
          locale: router.locale
        });
      }
      queryClient.clear();
      deleteCookie(variables.lucid_user);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, queryClient, activeCompany]);

  // Unified useMemo to handle both branches and companies
  useEffect(() => {
    if (!isLoggedIn || !isSuccessCompany || !router.query?.company) return;
    checkAndSetActiveUrlObject(isLoggedIn, isSuccessCompany, activeCompany, userCompanies, router.query?.company, setActiveCompany, variables.lucid_user_active_company);
  }, [isLoggedIn, isSuccessCompany, router.query?.company]); // do not change dependencies, this calculation is not dependent on router or activeBranch or activeCompany

  // Unified useMemo to handle both branches and companies
  useEffect(() => {
    if (!isLoggedIn || !isSuccessBranch || !activeCompany?.id || !router.query?.branch) return;
    checkAndSetActiveUrlObject(isLoggedIn, isSuccessBranch, activeBranch, branchesByCompanyId ?? [], router.query?.branch, setActiveBranch, variables.lucid_user_active_branch);
  }, [isLoggedIn, isSuccessBranch, activeCompany?.id, router.query?.branch, router.query?.company]); // do not change dependencies, this calculation is not dependent on router or activeBranch or activeCompany

  return isLoggedIn && !dynamicRoute.unAuthenticatedRoutes.includes(router.pathname) ? <>
      {/* Change padding top as height as header */}
      <DeviceLayout data-sentry-element="DeviceLayout" data-sentry-source-file="UnifiedLayout.tsx">
        <div className={styles.pageBg}>
          {appLoader && <AppLoader />}
          <PageLayout data-sentry-element="PageLayout" data-sentry-source-file="UnifiedLayout.tsx">{children}</PageLayout>
          <ChatDrawer data-sentry-element="ChatDrawer" data-sentry-source-file="UnifiedLayout.tsx" />
        </div>
      </DeviceLayout>
    </> : areValidElements(children) ?
  // eslint-disable-next-line react/jsx-no-useless-fragment
  <>{children}</> : <div />;
};
export default UnifiedLayout;