/* eslint-disable no-param-reassign */
import { ApideckVault } from '@apideck/vault-js';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { useShallow } from 'zustand/react/shallow';
import ApiUtils from '@/api/ApiUtils';
import { QUERYKEYS } from '@/miscellaneous/constant/reactQueryKeyConfig';
import type { ZustandState } from '@/miscellaneous/store/zustand_store';
import useZustandStore from '@/miscellaneous/store/zustand_store';
import { BASE_URL } from '@/utils/AppConfig';
import useIntegrations from '@/utils/hooks/useIntegrations/useIntegrations';
import useRefetchIntegrations from '@/utils/hooks/useIntegrations/useRefetchIntegrations';
import { useIsLoggedIn } from '@/utils/hooks/useIsLoggedIn';
import { errorToaster } from '@/utils/toaster/toasters';

// src/utils/helpers.ts

/**
 * Generates a request body object for creating a new integration in the Apideck Vault.
 *
 * @param connection - The connection object containing details about the integration.
 * @param activeCompanyId - The ID of the active company.
 * @returns An object containing the necessary fields for the integration creation request.
 */
const getBody = (connection: any, activeCompanyId: number) => ({
  company_id: activeCompanyId,
  type: connection?.id.includes('crm') ? 'CRM' : connection?.id.includes('hris') ? 'HR' : 'ACCOUNTING',
  integration_provider: 'API_DECK',
  realm_id: connection.settings?.realm_id,
  created_by: 1,
  consumer_id: activeCompanyId.toString(),
  service_id: connection?.service_id
});

/**
 * Creates a new integration in the Apideck Vault.
 *
 * @param url - The base URL for the Apideck Vault API.
 * @param activeCompanyId - The ID of the active company.
 * @param body - The request body containing the integration details.
 * @param token - The authorization token for the Apideck Vault API.
 * @returns A Promise that resolves to the response from the Apideck Vault API.
 */
const createIntegration = async (url: string, activeCompanyId: number, body: any, token: string) => {
  return axios.post(`${url}/api/v1/${activeCompanyId}/integration/create`, body, {
    headers: {
      Authorization: token
    }
  });
};

/**
 * Provides a hook that opens the Apideck Vault integration and creates a new integration if the connection is enabled and callable.
 *
 * @param url - An optional URL to use for the Apideck Vault integration. If not provided, it will use the `BASE_URL` with the `/api/v1/` part removed.
 * @returns A function that can be called to open the Apideck Vault integration with the specified parameters.
 */
export const useOpenVault = (url: string = '') => {
  url = url || BASE_URL?.replace('/api/v1/', '') || '';
  const {
    isLoggedIn
  } = useIsLoggedIn();
  const activeCompanyId = useZustandStore(useShallow((state: ZustandState) => state.activeCompany?.id ?? null));
  const {
    refetch
  } = useIntegrations();
  const refetchIntegrationsAndRelevantData = useRefetchIntegrations(15);
  const {
    data
  } = useQuery({
    queryKey: [QUERYKEYS.API_DECK_VAULT_TOKEN, activeCompanyId],
    queryFn: () => ApiUtils.getApideckVaultToken(activeCompanyId),
    enabled: !!activeCompanyId
  });

  /**
   * Opens the Apideck Vault integration and creates a new integration if the connection is enabled and callable.
   *
   * @param serviceId - The ID of the service to open the Vault for.
   * @param unifiedApi - The unified API to use for the Vault integration.
   * @param onFinish - An optional callback function to be called when the integration is successfully created.
   * @returns The Apideck Vault integration.
   */
  const openVault = useCallback(async (serviceId?: string, unifiedApi?: string, onFinish?: () => void) => {
    if (!data) return;
    const token = data.session_token;
    ApideckVault.open({
      showAttribution: false,
      token,
      serviceId,
      unifiedApi,
      onConnectionChange: connection => {
        if (connection.enabled === true && connection.state === 'callable') {
          createIntegration(url, activeCompanyId, getBody(connection, activeCompanyId), `${isLoggedIn?.token_type} ${isLoggedIn?.access_token}`).then(res => {
            if (res.status === 200) {
              refetchIntegrationsAndRelevantData(res.data);
              refetch();
              if (onFinish) onFinish();
              toast.success('Integration has been applied successfully, this should take a few minutes', {
                position: 'top-right'
              });
            } else {
              errorToaster('Something went wrong, our team has been notified', {
                position: 'top-right'
              });
            }
          }).catch(() => {
            errorToaster('Something went wrong, our team has been notified', {
              position: 'top-right'
            });
          }).finally(() => {
            ApideckVault.close();
          });
        }
      }
    });
  }, [url, isLoggedIn, activeCompanyId, data, refetch, refetchIntegrationsAndRelevantData]);
  return openVault;
};