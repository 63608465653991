import { acceptCompletion, completionStatus } from '@codemirror/autocomplete';
import { indentWithTab, insertNewlineAndIndent } from '@codemirror/commands';
import { ViewPlugin } from '@codemirror/view';

/**
 * Manages tab behavior for indentation and autocompletion selection.
 * Inspired by: https://sourcegraph.com/github.com/sourcegraph/sourcegraph@f1c08e272abf56e8f107589a49bc2a5c54429d19/-/blob/client/search-ui/src/input/extensions/loading-indicator.ts
 */

/**
 * Represents the state of autocomplete visibility.
 * @type {boolean}
 */
let isAutocompleteOpen = false;

/**
 * Run tab behavior depending on autocomplete visibility.
 *
 * @param {EditorView} view - The EditorView instance
 * @returns {boolean | void} - Returns the result of indentation with tab or autocomplete selection
 */
function tabRun(view: any) {
  return isAutocompleteOpen
    ? acceptCompletion(view)
    : indentWithTab.run && indentWithTab.run(view);
}

/**
 * Keybinding configuration for combined indentation and autocompletion with tab.
 * @type {KeyBinding}
 */
export const indentAndCompletionWithTab = {
  ...indentWithTab,
  run: tabRun,
};

export const preventNewLine = (setHidden: any) => ({
  ...insertNewlineAndIndent,
  mac: 'Enter',
  key: 'Enter',
  run: () => {
    setHidden(true);
    return true;
  },
  preventDefault: true,
});

/**
 * Observes tab events to detect autocomplete visibility changes.
 *
 * @returns {ViewPlugin[]} - Returns a plugin observing tab events
 */
export function tabObservable() {
  return [
    ViewPlugin.fromClass(
      class {
        /**
         * Creates an instance of the tab observer.
         */
        constructor() {
          this.update = this.update.bind(this);
        }

        /**
         * Updates the autocomplete visibility status based on the view update.
         *
         * @param {ViewUpdate} update - The view update
         */
        // eslint-disable-next-line class-methods-use-this
        update(update: any) {
          const status = completionStatus(update.state);
          isAutocompleteOpen = status !== null;
        }
      },
    ),
  ];
}
