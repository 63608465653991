import { useEffect, useState } from 'react';
import LoaderWrapper from './Loaders';
const TableLoader = () => {
  const [tableLoaderSize, setTableLoaderSize] = useState({
    width: 2800,
    height: 800,
    tableWidths: {
      firstColumnWidth: 360,
      standardColumnWidth: 150,
      numberOfStandardColumns: 16,
      lastColumnWidth: 150
    }
  });
  /**
   * Calculates column widths for the hiring plan table based on screen width.
   *
   * Sets the tableLoaderSize state with calculated widths for:
   * - firstColumnWidth: Width of the first column
   * - standardColumnWidth: Width for standard columns
   * - numberOfStandardColumns: Number of columns that fit at standard width
   * - lastColumnWidth: Adjusted width of last column if extra width is available
   */
  const calculateColumnWidths = (screenWidth: any) => {
    // Constants for column widths
    const firstColumnWidth = 360; // Width of the first column in pixels
    const standardColumnWidth = 155; // Standard width for subsequent columns
    // Calculate available width after the first column
    const availableWidth = screenWidth - firstColumnWidth;
    // Calculate how many standard columns can fit in the available width
    const numberOfStandardColumns = Math.floor(availableWidth / standardColumnWidth);
    // Calculate total width used by the first column and the standard columns
    const totalUsedWidth = firstColumnWidth + numberOfStandardColumns * standardColumnWidth;
    // Check if there's enough space for another column by comparing with the screen width
    // Adjust the last column width if necessary
    let lastColumnWidth = standardColumnWidth;
    if (screenWidth > totalUsedWidth) {
      // If there's more space available, adjust the last column's width
      lastColumnWidth += screenWidth - totalUsedWidth;
    }
    setTableLoaderSize(prevState => ({
      ...prevState,
      tableWidths: {
        firstColumnWidth,
        standardColumnWidth,
        numberOfStandardColumns,
        lastColumnWidth
      }
    }));
  };

  /**
   * Handles resizing the window to recalculate column widths
   * and update table dimensions.
   *
   * Attaches a 'resize' event listener on mount that calls
   * handleResize() on window resize. Removes listener on unmount.
   *
   * handleResize() updates tableLoaderSize state with new width
   * and height. Also calls calculateColumnWidths() to recalculate
   * column widths for the new window width.
   */
  const handleResize = () => {
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    setTableLoaderSize(prevState => ({
      ...prevState,
      width: screenWidth,
      height: screenHeight - 500
    }));
    calculateColumnWidths(screenWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return <LoaderWrapper isLoading content="table" width={tableLoaderSize.width} height={tableLoaderSize.height} columnwidths={tableLoaderSize.tableWidths} data-sentry-element="LoaderWrapper" data-sentry-component="TableLoader" data-sentry-source-file="tableLoader.tsx" />;
};
export default TableLoader;