import React from 'react';
import { useTranslation } from 'react-i18next';
import { LuArrowRightToLine } from 'react-icons/lu';
import Select from 'react-select';
import { Tooltip } from 'react-tooltip';
import OptionsMenu from '@/components/common/OptionsMenu/OptionsMenu';
import colors from '@/styles/scss/abstracts/_variables.module.scss';
import { ModelsSelectStyle } from '@/styles/SelectComponentStyle/select';
import styles from '@/styles/table.module.scss';
import useMutations from '@/utils/hooks/mutations/useMutations';
import type { Formula } from '@/utils/types/formulaTypes';
import type { TableRow } from '../../shared/types';
import ModelsOptionsMenuPopup from './ModelsOptionsMenuPopup';

/**
 * React component for rendering a formula cell in the sidebar.
 *
 * @param {object} row - The row data.
 * @param {object} activeModelFormulas - The active model formulas.
 * @param {object} data - Additional data.
 * @param {boolean} hasSubRows - Whether the row has sub rows.
 * @param {function} setSelectedFormula - Callback to set the selected formula.
 */
const SidebarFormulaCell: React.FC<{
  row: any;
  // activeModelFormulas: any;
  data: any;
  calculationsRowsData: any;
  hasSubRows: boolean;
  setSelectedFormula: any;
  formulaRows: {
    subRows: Formula[];
  }[];
  setFormulaRows: (formulaRows: {
    subRows: Formula[];
  }[]) => void;
}> = ({
  row,
  // activeModelFormulas,
  data,
  calculationsRowsData,
  hasSubRows,
  setSelectedFormula,
  formulaRows,
  setFormulaRows
}): JSX.Element => {
  const {
    updateBulkFormula
  } = useMutations();

  /**
   * Finds a formula object by id from the given formulas array
   *
   * @param {number} id - The id of the formula to find
   * @param {any[]} formulas - The array of formula objects to search
   * @returns {any} The formula object with matching id or undefined
   */
  const findFormulaWithId = (id: number, formulas: TableRow) => {
    return formulas.find((formula: TableRow) => +formula.formula_id === +id);
  };

  /**
   * Filters the data array to only include subRows that have an output_id property
   */
  const filteredData = data.filter((subRow: any) => subRow.output_id);
  const activeModelFormulas = calculationsRowsData;

  /**
   * Filters the activeModelFormulas array to only include formulas
   * that do not have an output_id and are not found in the filteredData array.
   */
  const filteredFormula = activeModelFormulas?.filter((formula: any) => formula.group === 'Calculations')?.filter((formula: any) => !formula.output_id && !filteredData.find((data1: any) => data1.id === formula.id));

  /**
   * Updates the output_id of a formula when its id changes.
   *
   * Finds the old and new formulas by id from the formulas list.
   * If the original formula had an output_id, copies it to the new formula
   * and sets the old formula's output_id to null.
   *
   * Bulk updates the formulas via the updateBulkFormula mutation.
   */
  const updateFormulaOutputId = (original: any, id: any, formulas: any) => {
    const oldFormula = findFormulaWithId(original.formula_id, formulas);
    const newFormula = findFormulaWithId(id, formulas);
    const outputId = original?.output_id;
    if (outputId.toString() === 'empty') {
      return;
    }
    updateBulkFormula.mutate([{
      ...oldFormula,
      id: original.formula_id,
      output_id: null
    }, {
      ...newFormula,
      id,
      output_id: outputId
    }]);
  };

  /**
   * Removes a sub-row from an array of data objects based on the provided id.
   *
   * @param data - An array of data objects, each with a `subRows` property that is an array of `Formula` objects.
   * @param idToRemove - The id of the sub-row to remove.
   * @returns A new array of data objects with the specified sub-row removed.
   */
  function removeSubRowById(
  // eslint-disable-next-line @typescript-eslint/no-shadow
  data: {
    subRows: Formula[];
  }[], idToRemove: number | string) {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    return data.map((data: {
      subRows: Formula[];
    }) => {
      if (data.subRows && Array.isArray(data.subRows)) {
        return {
          ...data,
          subRows: data.subRows.filter(subRow => subRow.id !== idToRemove)
        };
      }
      return data;
    });
  }

  /**
   * Deletes a formula output by setting its output_id to null.
   * Only updates the formula if it originally had an output_id.
   *
   * @param original - The original formula object
   */
  const deleteOutput = (original: any) => {
    if (original.output_id === 'empty') {
      setFormulaRows(removeSubRowById(formulaRows, original.id));
      return;
    }
    updateBulkFormula.mutate([{
      group: original?.group,
      input_type: original?.input_type,
      model_id: original?.model_id,
      branch_id: original?.branch_id,
      name: original.name,
      expression_string: original?.expression_string,
      emoji: original?.emoji,
      id: original?.formula_id,
      output_id: null,
      parent_id: original?.parent_id
    }]);
  };
  const buttonsRef = React.useRef(null);
  const {
    t: translate
  } = useTranslation(['models']);

  // Inside the SidebarFormulaCell component, before the return statement:
  const foundActiveCellFormula = findFormulaWithId(row.original.formula_id, activeModelFormulas);
  return activeModelFormulas?.length > 0 ?
  // Check if there are active model formulas
  <div className={styles.outputCellContainer} data-sentry-component="SidebarFormulaCell" data-sentry-source-file="SidebarFormulaCell.tsx">
      <span className={styles.calculationsLabel}>
        {translate('calculations')}
      </span>
      <Tooltip id="output-formula-tooltip" delayShow={1000} noArrow data-sentry-element="Tooltip" data-sentry-source-file="SidebarFormulaCell.tsx" />
      <div data-tooltip-id="output-formula-tooltip" data-tooltip-content={foundActiveCellFormula?.name}>
        <Select menuPlacement="top" options={filteredFormula?.map((formula: any) => ({
        value: formula.formula_id,
        label: formula.name
      }))} components={{
        IndicatorSeparator: () => null
      }} placeholder="Empty Formula" defaultValue={{
        value: foundActiveCellFormula?.id,
        // Set the default value to the formula's id
        label: foundActiveCellFormula?.name // Set the default label to the formula's name
      }} styles={ModelsSelectStyle} onChange={(e: any) => {
        // Update the formula output id and set the selected formula
        updateFormulaOutputId(row.original, e.value, activeModelFormulas);
        setSelectedFormula(findFormulaWithId(e.value, activeModelFormulas) || null);
      }} data-sentry-element="Select" data-sentry-source-file="SidebarFormulaCell.tsx" />
      </div>
      <LuArrowRightToLine size={20} color={colors.manatee} className="ms-2" data-sentry-element="LuArrowRightToLine" data-sentry-source-file="SidebarFormulaCell.tsx" />
      <span ref={buttonsRef} className={`${styles.actions}`} style={{
      paddingTop: hasSubRows && row.getIsExpanded() && row.depth === 0 ? 24 : 0,
      paddingBottom: hasSubRows && row.getIsExpanded() && row.depth === 0 ? 12 : 0
    }}>
        <ModelsOptionsMenuPopup row={row} buttonsRef={buttonsRef} data-sentry-element="ModelsOptionsMenuPopup" data-sentry-source-file="SidebarFormulaCell.tsx">
          <OptionsMenu className={styles.formulaInputOptions} items={[
        // Add options to the options menu
        {
          label: 'Delete Output',
          onClick: () => deleteOutput(row.original),
          id: `table-options-3`,
          icon: <img src="/assets/icons/deleteNewIcon.svg" height={15} width={15} alt="delete" />
        }]} data-sentry-element="OptionsMenu" data-sentry-source-file="SidebarFormulaCell.tsx" />
        </ModelsOptionsMenuPopup>
      </span>
    </div> : <div data-sentry-component="SidebarFormulaCell" data-sentry-source-file="SidebarFormulaCell.tsx" />;
};
export default SidebarFormulaCell;