import variables from '@/styles/scss/abstracts/_variables.module.scss';

const sharedTableCellStyles = {
  shared: {
    minWidth: variables.cellWidth as string,
    width: '100%',
    textAlign: 'right',
    fontSize: '12px',
    fontWeight: '400',
    padding: '0 16px',
    minHeight: variables.cellHeight as string,
    maxHeight: variables.cellHeight as string,
    height: variables.cellHeight as string,
    margin: '0 auto',
    border: '1px solid none',
  },
  individual: [
    {
      accessorKey: 'sidebar',
      style: {
        width: variables.sidebarCellWidth as string,
        minHeight: variables.cellHeight as string,
        maxHeight: variables.cellHeight as string,
        height: variables.cellHeight as string,
        zIndex: 4,
      },
    },
  ],
};

export default sharedTableCellStyles;
