import type { FC } from 'react';
import React from 'react';
interface IProfessionalButtonSvg {
  className?: string;
}
const ProfessionalButtonSvg: FC<IProfessionalButtonSvg> = ({
  className
}) => <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" className={className} data-sentry-element="svg" data-sentry-component="ProfessionalButtonSvg" data-sentry-source-file="ProfessionalButtonSvg.tsx">
    <g clipPath="url(#clip0_1130_5935)" data-sentry-element="g" data-sentry-source-file="ProfessionalButtonSvg.tsx">
      <path d="M1.33331 9.33333C1.33331 6.81918 1.33331 5.5621 2.11436 4.78105C2.89541 4 4.15249 4 6.66665 4H9.33331C11.8475 4 13.1045 4 13.8856 4.78105C14.6666 5.5621 14.6666 6.81918 14.6666 9.33333C14.6666 11.8475 14.6666 13.1046 13.8856 13.8856C13.1045 14.6667 11.8475 14.6667 9.33331 14.6667H6.66665C4.15249 14.6667 2.89541 14.6667 2.11436 13.8856C1.33331 13.1046 1.33331 11.8475 1.33331 9.33333Z" stroke="#131923" data-sentry-element="path" data-sentry-source-file="ProfessionalButtonSvg.tsx" />
      <path d="M10.6666 4.00004C10.6666 2.74296 10.6666 2.11442 10.2761 1.7239C9.8856 1.33337 9.25706 1.33337 7.99998 1.33337C6.7429 1.33337 6.11436 1.33337 5.72384 1.7239C5.33331 2.11442 5.33331 2.74296 5.33331 4.00004" stroke="#131923" data-sentry-element="path" data-sentry-source-file="ProfessionalButtonSvg.tsx" />
      <path d="M11.3333 6.00004C11.3333 6.36823 11.0349 6.66671 10.6667 6.66671C10.2985 6.66671 10 6.36823 10 6.00004C10 5.63185 10.2985 5.33337 10.6667 5.33337C11.0349 5.33337 11.3333 5.63185 11.3333 6.00004Z" fill="#131923" data-sentry-element="path" data-sentry-source-file="ProfessionalButtonSvg.tsx" />
      <path d="M6.00002 6.00004C6.00002 6.36823 5.70154 6.66671 5.33335 6.66671C4.96516 6.66671 4.66669 6.36823 4.66669 6.00004C4.66669 5.63185 4.96516 5.33337 5.33335 5.33337C5.70154 5.33337 6.00002 5.63185 6.00002 6.00004Z" fill="#131923" data-sentry-element="path" data-sentry-source-file="ProfessionalButtonSvg.tsx" />
    </g>
    <defs data-sentry-element="defs" data-sentry-source-file="ProfessionalButtonSvg.tsx">
      <clipPath id="clip0_1130_5935" data-sentry-element="clipPath" data-sentry-source-file="ProfessionalButtonSvg.tsx">
        <rect width="16" height="16" fill="white" data-sentry-element="rect" data-sentry-source-file="ProfessionalButtonSvg.tsx" />
      </clipPath>
    </defs>
  </svg>;
export default ProfessionalButtonSvg;