import { Search } from 'lucide-react';
import { usePathname } from 'next/navigation';
import { useRouter } from 'next/router';
import { type RefObject, useEffect } from 'react';
import { useShallow } from 'zustand/react/shallow';
import { FinancialsSettingPopover } from '@/components/genericToggleMenu/financials/FinancialsSettingsTogglePopup';
import { HiringSettingPopover } from '@/components/genericToggleMenu/HiringPlanSettingTogglePopup';
import type { ZustandState } from '@/miscellaneous/store/zustand_store';
import useZustandStore from '@/miscellaneous/store/zustand_store';
import type { Settings } from '../../types';
import { isFinancials } from '../../utils';
import headerStyles from './styles/tableHeaderStyles.module.scss';
export const TableHeader = ({
  title = 'Table Header',
  hideCogIcon = false,
  navigtionComponent,
  headerRef,
  settings
}: {
  title: string;
  navigtionComponent: any;
  hideCogIcon?: boolean;
  headerRef?: RefObject<HTMLDivElement>;
  settings?: Settings;
}) => {
  const router = useRouter();
  const toggleMenuContainer = () => {
    if (isFinancials(router.pathname)) {
      return FinancialsSettingPopover();
    }
    return HiringSettingPopover();
  };
  const pathname = usePathname();
  const {
    query,
    setQuery
  } = useZustandStore(useShallow((state: ZustandState) => {
    return {
      query: state.query,
      setQuery: state.setQuery,
      setIsSidebarDrawerOpen: state.setIsSidebarDrawerOpen,
      setSidebarDrawerContent: state.setSidebarDrawerContent
    };
  }));
  useEffect(() => {
    setQuery('');
  }, [pathname, setQuery]);
  return <div className={headerStyles.tableHeaderContainer} ref={headerRef} data-sentry-component="TableHeader" data-sentry-source-file="TableHeader.tsx">
      <div className={headerStyles.tableHeaderLeftSide}>
        <h1>{title}</h1>
        {settings?.getHeaderOptions?.()?.map((option, index) => <button
      // eslint-disable-next-line react/no-array-index-key
      key={index} type="button" onClick={() => {
        option.actionHandler();
      }}>
            {option.icon}
          </button>)}
        {!hideCogIcon && toggleMenuContainer()}
      </div>
      <div className={headerStyles.tableHeaderRightSide}>
        <div className={headerStyles.tableHeaderSearch}>
          <Search size={20} data-sentry-element="Search" data-sentry-source-file="TableHeader.tsx" />
          <input type="text" value={query} onChange={e => setQuery(e.target.value)} placeholder="Search" />
        </div>
        {navigtionComponent}
      </div>
    </div>;
};
export default TableHeader;