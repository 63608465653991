import CalculationsStore from '@/miscellaneous/store/CalculationsStore';
import type { ZustandState } from '@/miscellaneous/store/zustand_store';
import useZustandStore from '@/miscellaneous/store/zustand_store';
import styles from '@/styles/scss/formulaCell.module.scss';
import functions from '../Functions';

/**
 * Renders a display of tags for a formula cell in a table.
 *
 * The `TagDisplay` component is responsible for rendering the tags associated with a formula cell in a table. It uses the `useZustandStore` hook to access the `branchFormulas` state from the Zustand store, and then generates the appropriate tag elements based on the formula information.
 *
 * The component uses several helper functions to:
 * - Remove the curly braces from the input text
 * - Generate the appropriate color for the tag based on the formula type
 * - Generate the tag element with the correct class names and content
 *
 * The `TagDisplay` component is rendered within an absolute positioned container that spans the full width of the screen, with the tags displayed in a centered, horizontal layout.
 */
const TagDisplay = ({
  state
}: {
  state: string;
}) => {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const {
    branchFormulas
  } = useZustandStore((state: ZustandState) => state);
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const {
    HPformulas
  } = CalculationsStore((state: any) => state);

  // ?INFO: This function removes the curly braces from the text.
  const clearText = (text: string) => {
    return text?.replace(/[{}]/g, ' ').trim().split(' ');
  };

  // ?INFO: This function generates the color for the tag.
  const colorGenerator = (type: string, text: string) => {
    switch (type) {
      case 'Calculations':
        return styles.variable;
      case 'Inputs':
        return styles.function;
      default:
        return functions.some(el => el.label === text) ? styles.formula : '';
    }
  };

  // ?INFO: This function generates the tag element.
  const generateTag = (text: string) => {
    let className = styles.textFont;
    let content = text;

    // ?INFO: This is a conditional statement that checks if the text includes both 'x' and 'y'.
    if (text.includes('x') && text.includes('y')) {
      const filteredText = text.replace(/[xy,]/g, '').split('-')[0]; // ?INFO: This removes the 'x', 'y', and ',' from the text.

      const formula = [...branchFormulas, ...HPformulas].find((el: any) => el.id === +(filteredText as string)); // ?INFO: This finds the formula with the id of the filtered text.

      if (!formula && Number.isNaN(+(filteredText as string))) {
        return <div className={className}>{content}</div>;
      }

      // ?INFO: This is a conditional statement that checks if the formula is not found and the filtered text is not a number.
      if (!formula && !Number.isNaN(+(filteredText as string))) {
        return <div className={`${styles.tag} ${styles.emptyFormula} mr-0 w-max`}>
            Unknown Variable
          </div>;
      }
      className = `${styles.tag} ${colorGenerator(formula?.group, text)} ${
      // ?INFO: This generates the class name for the tag.
      styles.customTag} w-max`;
      content = formula?.name;
    }
    if (functions.some(el => el.label === text)) {
      className = `${styles.tag} ${colorGenerator('', text)} mr-0 w-max`; // ?INFO: This generates the class name for the tag.
    }
    return <div className={className} data-sentry-component="generateTag" data-sentry-source-file="TagDisplay.tsx">{content}</div>; // ?INFO: This returns the tag element.
  };
  return <div className="absolute left-0 flex h-full  w-screen px-6" data-sentry-component="TagDisplay" data-sentry-source-file="TagDisplay.tsx">
      <span className="flex items-center justify-center">
        {clearText(state)?.map((el, index) =>
      // eslint-disable-next-line react/no-array-index-key
      <div key={index} className="flex items-center">
            {generateTag(el)}
          </div>)}{' '}
      </span>
    </div>;
};
export default TagDisplay;