interface FormulaNode {
  id: number;
}
interface DependencyMap {
  [key: number]: number[];
}
export const topologicalSort = (formulas: FormulaNode[], dependencyMap: DependencyMap): [FormulaNode[], FormulaNode[]] => {
  const stack: FormulaNode[] = [];
  const visited: Record<number, boolean> = {};
  const tempMark: Record<number, boolean> = {};
  const cycleFormulas = new Set<number>();
  const formulaIdtoNode = new Map<number, FormulaNode>();
  formulas.forEach(formula => {
    formulaIdtoNode.set(formula.id, formula);
  });
  const visit = (formula: FormulaNode) => {
    if (tempMark[formula.id]) {
      cycleFormulas.add(formula.id); // Add to cycle when first detected
      return true; // Indicate a cycle was found
    }
    if (!visited[formula.id]) {
      tempMark[formula.id] = true;
      let isCyclic = false;
      const dependencies = dependencyMap[formula.id] || [];
      dependencies.forEach(dependentId => {
        if (dependencyMap[dependentId] && visit((formulaIdtoNode.get(dependentId) as FormulaNode))) {
          isCyclic = true;
          cycleFormulas.add(dependentId); // Add dependent ID if any of its dependencies are part of a cycle
        }
      });
      visited[formula.id] = true;
      if (!isCyclic) {
        stack.push(formula); // Only add to stack if no cycles were detected in any of its dependencies
      }
      tempMark[formula.id] = false;
      return isCyclic;
    }
    return cycleFormulas.has(formula.id); // Return whether this node is part of a cycle
  };
  formulas.forEach(formula => {
    if (!visited[formula.id]) {
      visit(formula);
    }
  });
  const sortedFormulas = stack.filter(f => !cycleFormulas.has(f.id));
  const cyclicFormulas = Array.from(cycleFormulas).map(id => formulaIdtoNode.get(id)) ?? [];
  return [sortedFormulas, (cyclicFormulas as FormulaNode[])];
};