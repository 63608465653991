import React from 'react';
const ModifyButtonSvg = () => <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 25 25" fill="none" data-sentry-element="svg" data-sentry-component="ModifyButtonSvg" data-sentry-source-file="ModifyButtonSvg.tsx">
    <circle cx="12.9375" cy="12.4043" r="2" transform="rotate(-90 12.9375 12.4043)" stroke="#242625" strokeWidth="1.5" data-sentry-element="circle" data-sentry-source-file="ModifyButtonSvg.tsx" />
    <circle cx="10.9375" cy="20.4043" r="2" transform="rotate(-90 10.9375 20.4043)" stroke="#242625" strokeWidth="1.5" data-sentry-element="circle" data-sentry-source-file="ModifyButtonSvg.tsx" />
    <circle cx="2" cy="2" r="2" transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 16.9375 6.4043)" stroke="#242625" strokeWidth="1.5" data-sentry-element="circle" data-sentry-source-file="ModifyButtonSvg.tsx" />
    <path d="M16.9375 12.4043L19.9375 12.4043" stroke="#242625" strokeWidth="1.5" strokeLinecap="round" data-sentry-element="path" data-sentry-source-file="ModifyButtonSvg.tsx" />
    <path d="M14.9375 20.4043L19.9375 20.4043" stroke="#242625" strokeWidth="1.5" strokeLinecap="round" data-sentry-element="path" data-sentry-source-file="ModifyButtonSvg.tsx" />
    <path d="M10.9375 4.4043L5.9375 4.4043" stroke="#242625" strokeWidth="1.5" strokeLinecap="round" data-sentry-element="path" data-sentry-source-file="ModifyButtonSvg.tsx" />
    <path d="M5.9375 12.4043L8.9375 12.4043" stroke="#242625" strokeWidth="1.5" strokeLinecap="round" data-sentry-element="path" data-sentry-source-file="ModifyButtonSvg.tsx" />
    <path d="M5.9375 20.4043L6.9375 20.4043" stroke="#242625" strokeWidth="1.5" strokeLinecap="round" data-sentry-element="path" data-sentry-source-file="ModifyButtonSvg.tsx" />
    <path d="M19.9375 4.4043L18.9375 4.4043" stroke="#242625" strokeWidth="1.5" strokeLinecap="round" data-sentry-element="path" data-sentry-source-file="ModifyButtonSvg.tsx" />
  </svg>;
export default ModifyButtonSvg;