import type { Position, Prompt } from '@/utils/types/AITypes';
import { type Employee } from '@/utils/types/HiringTypes';

/**
 * This function handles displaying the changes made by Hiring Plan prompts to the system. It pulls the current prompts from zustand and displays the changes in the table.
 * Create and Increase are handled in the same way - displaying the list of employees in the table
 * Decrease -
 * @param employees The list of employees from the DB
 * @param prompts List of hiring plan related prompts - generate, decrease, increase, edit
 * @returns
 */
export function ApplyAIPromptsToEmployees(employees: Employee[], prompts: Prompt[]): {
  // return value entails all breakdowns of employees according to prompts
  modifiedEmployeeIds: number[];
  result: Employee[];
} {
  if (!prompts || prompts.length === 0) return {
    result: employees,
    modifiedEmployeeIds: []
  };
  let employeeIDsToRemove = ([] as number[]); // var for removed employees to filter current employees
  let modifiedEmployeeIds = ([] as number[]); //  list of ids of modified employees to return to backend for merge
  let allModifiedEmployeeIds: number[] = []; // list of all modified employee ids to return to backend for merge
  let currentModifiedEmployees: Position[] = []; // var for modified employees to filter current employees
  let result = ([...employees] as Employee[]);
  prompts.forEach((prompt: Prompt) => {
    switch (prompt.promptOperation) {
      case 'generate':
        result = [...result, ...((prompt.response?.positions?.flat() as Employee[]) || [])];
        break;
      case 'expand':
        result = [...result, ...((prompt.response?.positions?.flat() as Employee[]) || [])];
        break;
      case 'modify':
        currentModifiedEmployees = [...(prompt.response?.positions?.flat() || [])]; // list of employees to modify

        // merge any modified employees that have same id taking latest changes according to order of modified employees

        // assign modifiedEmployeeIds all employees ids from modifiedEmployees for employees that is_ai_employee is either null or false
        modifiedEmployeeIds = [...currentModifiedEmployees.map(employee => (employee.id as number))];

        // add unique updates to allModifiedEmployeeIds from modifiedEmployeeIds
        allModifiedEmployeeIds = Array.from(new Set([...allModifiedEmployeeIds, ...modifiedEmployeeIds]));

        // replace old employees in result with new modifeid employees
        result = result.map((employee: any) => {
          const modifiedEmployee = currentModifiedEmployees.find((e: any) => e.id === employee.id // find employee with same id as modified employee
          );
          if (modifiedEmployee && modifiedEmployee.department) {
            // apply new employee fields to old employee
            return ({
              ...employee,
              ...modifiedEmployee,
              is_ai_employee: employee?.is_ai_employee // we need to keep the old employee's is_ai_employee value if it is still an AI employee
            } as Employee);
          }
          return employee; // if not in modified employees return old employee
        });
        break;
      case 'decrease':
        employeeIDsToRemove = prompt.response?.positions?.map(position => Number(position.id)).flat() || []; // list of ids to remove
        result = result.filter((employee: any) => {
          return !employeeIDsToRemove.includes(employee.id);
        });
        break;
      default:
        console.error('Value not found', `value is:*${prompt.promptOperation}*`);
    }
  });
  const endResult = {
    modifiedEmployeeIds: allModifiedEmployeeIds,
    result
  };
  return endResult;
}