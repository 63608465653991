/**
 * Formats a number by adding commas to the thousands place.
 * @param number - The number to format.
 * @returns The formatted number as a string.
 */
const formatNumber = (number: any) => {
  let cleanedNumber = number;
  if (Number.isNaN(+number) && typeof number === 'string') {
    cleanedNumber = number.replace(/,$/g, '');
  }
  return Number.isNaN(+cleanedNumber) ? cleanedNumber : (+cleanedNumber).toLocaleString(global?.navigator?.language, {
    minimumFractionDigits: 0
  });
};

/**
 * Removes commas from a string value.
 * @param value - The string value to remove commas from.
 * @returns The string value with commas removed.
 */
const clearCommas = (value: string) => {
  return value.replace(/,/g, '');
};

/**
 * Truncates a label if it exceeds a specified length and adds an ellipsis.
 * @param {string} label - The label to be truncated.
 * @param {number} maxLength - The maximum length of the label before truncation.
 * @returns {string} - The truncated label with an ellipsis if it exceeds the maxLength.
 */
const truncateLabel = (label: string, maxLength: number): string => {
  if (label?.length > maxLength) {
    return `${label.substring(0, maxLength)}...`;
  }
  return label;
};
export { clearCommas, formatNumber, truncateLabel };