import moment from 'moment';
import type { Dispatch, SetStateAction } from 'react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CurrentDatePicker } from '@/components/common/datePickers/CurrentDatePicker';
import styles from '@/components/common/Header/components/DateRangeSelector/DateRangeSelector.module.scss';
import arrow from '@/public/assets/icons/Alt Arrow Right.svg';
import { getTileClassName, isDateValid } from '@/utils/datePickerUtils';
import { parseUTCDateObject, validateDateF as validateDate } from '@/utils/dateUtils';
import MonthYearPicker from '../../../../datePickers/MonthYearPicker';
const today = parseUTCDateObject();
today.setDate(1);

/**
 * CalendarHeader component displays a header with a label,
 * date, and next/previous buttons to change the date.
 *
 * It takes in the current date, a label string,
 * a date change handler and a validation function to
 * determine if next/previous navigation is allowed.
 */
const CalendarHeader = ({
  date,
  label,
  onChangeDate,
  type,
  toDate,
  fromDate
}: {
  date: Date;
  label: string;
  onChangeDate: Dispatch<SetStateAction<Date>>;
  type: 'from' | 'to';
  toDate: Date;
  fromDate: Date;
}) => {
  /**
   * Handles incrementing or decrementing the date value
   * based on the provided action.
   *
   * Checks if incrementing/decrementing is valid using validateDate,
   * and if so, creates a new date object with year +/- 1
   * and calls onChangeDate with the new date.
   */
  const handleDate = (action: string) => {
    if (date) {
      if (action === 'next') {
        if (validateDate(date, type, 'next', toDate, fromDate)) {
          const newDate = moment(date).add(1, 'year').startOf('month').utc().toDate();
          onChangeDate(newDate);
        }
      }
      if (action === 'prev') {
        if (validateDate(date, type, 'prev', toDate, fromDate)) {
          const newDate = moment(date).subtract(1, 'year').startOf('month').utc().toDate();
          onChangeDate(newDate);
        }
      }
    }
  };
  return <div className={styles.calendarHeader} data-sentry-component="CalendarHeader" data-sentry-source-file="DialogPopover.tsx">
      <div>
        <span>{label}:</span>
        <span>{date && date.getFullYear()}</span>
      </div>

      <div className={styles.arrows}>
        <span onClick={() => handleDate('prev')}>
          <img className={
        /**
         * Conditionally applies the disabledArrow class based on whether decrementing
         * the date is valid according to validateDate.
         *
         * If decrementing is valid, only apply the leftArrow class.
         * If decrementing is invalid, apply both leftArrow and disabledArrow.
         */
        validateDate(date, type, 'prev', toDate, fromDate) ? styles.leftArrow : `${styles.leftArrow} ${styles.disabledArrow}`} src={arrow.src} alt="arrow" />
        </span>
        <span onClick={() => handleDate('next')}>
          <img className={
        /**
         * Conditionally applies the disabledArrow class based on whether incrementing
         * the date is valid according to validateDate.
         *
         * If incrementing is valid, apply empty string.
         * If incrementing is invalid, apply disabledArrow class.
         */
        validateDate(date, type, 'next', toDate, fromDate) ? '' : styles.disabledArrow} src={arrow.src} alt="arrow" />
        </span>
      </div>
    </div>;
};

/**
 * DialogPopover is a component that represents a popover with a dialog,
 * containing a calendar for selecting a date range, along with title and buttons.
 *
 * @component
 * @param {Object} props - The properties of the DialogPopover component.
 * @param {Date} props.fromDate - The starting date of the selected date range.
 * @param {Date} props.toDate - The ending date of the selected date range.
 * @param {Dispatch<SetStateAction<Date>>} props.setFromDate - The state setter for the starting date.
 * @param {Dispatch<SetStateAction<Date>>} props.setToDate - The state setter for the ending date.
 * @returns {JSX.Element} Rendered DialogPopover component.
 */
interface DialogPopoverProps {
  fromDate: Date;
  toDate: Date;
  setFromDate: (e: Date) => void;
  setToDate: (e: Date) => void;
  currentDate?: Date | string;
  setCurrentDate?: (e: Date) => void;
  handleDateChange?: (startDate: Date, endDate: Date) => void;
  startTitle?: string;
  endTitle?: string;
  additionalElement?: React.ComponentType<{
    close: () => void;
  }>;
  close?: () => void;
}
function DialogPopover({
  fromDate,
  toDate,
  setFromDate,
  setToDate,
  currentDate,
  setCurrentDate,
  handleDateChange,
  startTitle,
  endTitle,
  close,
  additionalElement
}: DialogPopoverProps) {
  /**
   * minDate and maxDate store the minimum and maximum selectable dates in the calendar component.
   * They are initialized to the fromDate and toDate props respectively, and updated when those props change.
   */
  // set all 3 dates to day 1
  // fromDate.setDate(1);
  // toDate.setDate(1);
  const [minDate, setMinDate] = React.useState<Date>(fromDate);
  const [maxDate, setMaxDate] = React.useState<Date>(toDate);

  /**
   * Handles updating the month and year when the month/year picker is changed.
   *
   * Updates the selected date and min/max limit date based on the new month/year.
   * Prevents selecting an invalid range by comparing the new date to the current
   * min/max limit date.
   *
   * @param newDate - The newly selected month/year
   * @param setDate - State setter for the selected date
   * @param setLimitDate - State setter for min/max limit date
   * @param currentLimitDate - The current min/max limit date
   * @param isStartDate - Whether this is updating the start or end date
   */
  const handleMonthYearChange = (newDate: Date, setDate: (e: Date) => void, setLimitDate: (e: Date) => void, currentLimitDate: Date, isStartDate: boolean) => {
    const newDateMonth = newDate.getMonth();
    const newDateYear = newDate.getFullYear();
    const newDateValue = moment().year(newDateYear).month(newDateMonth).utc().toDate();
    newDateValue.setDate(1);
    if (!isDateValid(isStartDate, newDateValue, currentLimitDate, minDate, maxDate)) {
      return;
    }
    setDate(newDateValue);
    setLimitDate(newDateValue);
    if (isStartDate) {
      handleDateChange?.(newDateValue, toDate);
    } else {
      handleDateChange?.(fromDate, newDateValue);
    }
  };
  const {
    t
  } = useTranslation('common');
  const [calendarFromDate, setCalendarFromDate] = React.useState<Date>(fromDate);
  const [calendarToDate, setCalendarToDate] = React.useState<Date>(toDate);

  /**
   * Sets the current date to today and updates the `toDate` and `maxDate` state if the current date is the same or later than the existing `toDate`.
   *
   * This function is used to reset the date range selection to the current date.
   *
   * @param setCurrentDate - A function to update the current date state
   * @param toDate - The current end date of the date range
   * @param setToDate - A function to update the end date of the date range
   * @param setMaxDate - A function to update the maximum date that can be selected
   */
  const setCurrentDateToToday = () => {
    const endDate = parseUTCDateObject(toDate);
    if (setCurrentDate) {
      setCurrentDate(today);
      const isSameOrLaterMonth = today.getMonth() >= endDate.getMonth() && today.getFullYear() === endDate.getFullYear();
      const isLaterYear = today.getFullYear() > endDate.getFullYear();
      if (isSameOrLaterMonth || isLaterYear) {
        const nextMonth = moment(today).add(1, 'month').date(1).utc().toDate();
        setToDate(nextMonth);
        setMaxDate(nextMonth);
      }
    }
  };
  useEffect(() => {
    setCalendarFromDate(fromDate);
    setCalendarToDate(toDate);
  }, [fromDate, toDate]);
  return <div className={styles.popover} data-sentry-component="DialogPopover" data-sentry-source-file="DialogPopover.tsx">
      <div className={styles.dialog}>
        <div className={styles.calendarContainer}>
          <div className={styles.subHeaderCalendarContainer}>
            <div className={styles.displayFlex}>
              <div className={styles.flex1}>
                <div className={styles.calendarHeaderContainer}>
                  <CalendarHeader onChangeDate={setCalendarFromDate} date={calendarFromDate} label={startTitle || 'From'} type="from" toDate={toDate} fromDate={fromDate} data-sentry-element="CalendarHeader" data-sentry-source-file="DialogPopover.tsx" />
                </div>
                <MonthYearPicker value={fromDate} activeStartDate={calendarFromDate} onChange={(newDate: Date) => {
                handleMonthYearChange(newDate, setFromDate, setMinDate, calendarToDate, true // for FromDate true, for ToDate false
                );
              }} showNavigation={false}
              // eslint-disable-next-line tailwindcss/no-custom-classname
              className="calendar-design" tileClassName={({
                date,
                view
              }: {
                date: Date;
                view: string;
              }) => {
                return getTileClassName(date, view, minDate, maxDate, true, parseUTCDateObject(currentDate), !!currentDate);
              }} {...currentDate && {
                maxDate: today
              }} data-sentry-element="MonthYearPicker" data-sentry-source-file="DialogPopover.tsx" />
              </div>
              <div className={styles.flex1}>
                <div className={styles.calendarHeaderContainer}>
                  <CalendarHeader onChangeDate={setCalendarToDate} date={calendarToDate} label={endTitle || 'To'} type="to" toDate={toDate} fromDate={fromDate} data-sentry-element="CalendarHeader" data-sentry-source-file="DialogPopover.tsx" />
                </div>
                <MonthYearPicker activeStartDate={calendarToDate} value={toDate} onChange={(newDate: Date) => {
                handleMonthYearChange(newDate, setToDate, setMaxDate, calendarFromDate, false // for FromDate true, for ToDate false
                );
              }} showNavigation={false}
              // eslint-disable-next-line tailwindcss/no-custom-classname
              className="calendar-design" tileClassName={({
                date,
                view
              }: {
                date: Date;
                view: string;
              }) => getTileClassName(date, view, minDate, maxDate, false, parseUTCDateObject(currentDate), !!currentDate)} {...currentDate && {
                minDate: fromDate
              }} data-sentry-element="MonthYearPicker" data-sentry-source-file="DialogPopover.tsx" />
              </div>
            </div>
            {currentDate && setCurrentDate && <div className="flex h-fit items-center  pt-2">
                <div className="flex w-full items-center justify-between">
                  <div className={styles.currentDateHeader}>
                    {t('common:current_date')}
                  </div>
                  <button disabled={parseUTCDateObject(currentDate).getMonth() === today.getMonth() && parseUTCDateObject(currentDate).getFullYear() === today.getFullYear()} type="button" className={styles.todayBtn} onClick={setCurrentDateToToday}>
                    Today
                  </button>
                  <div className=" w-auto">
                    <CurrentDatePicker currentDate={currentDate} setCurrentDate={setCurrentDate} startDate={fromDate} endDate={toDate} className={styles.currentDatePicker} />
                  </div>
                </div>
              </div>}

            {
          // If additionalElement is provided, render it with the close function
          additionalElement && close && React.createElement(additionalElement, {
            close
          }, null)}
          </div>
        </div>
      </div>
    </div>;
}
export default DialogPopover;