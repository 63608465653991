export interface ReportSettings {
  showTotal: boolean;
  showYears: boolean;
  showMonths: boolean;
  showQuarters: boolean;
  showEmptyAccounts: boolean;
}
export interface Report {
  id: number;
  name: string;
  description: string | null;
  branch_id: number;
  slug?: string;
  sort_order?: string;
  settings: ReportSettings | null;
  base_report_id: number | null;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}
export enum CulmutativeEnum {
  Start = 'start',
  End = 'end',
}
export enum ReportItemEnum {
  Section = 'Section',
  Formula = 'Formula',
  AccountTree = 'AccountTree',
}
export interface ReportItem {
  id: number;
  options: any; // JSON translates to 'any' or a more specific type if the structure is known
  name: string | null;
  type: ReportItemEnum;
  report_id: number;
  parent_id: number | null;
  formulaId?: number | null;
  account_id?: number | null;
  sort_order?: string | null;
  sign?: number | null; // 1 or -1
  deleted_at?: string | null;
  cumulative: CulmutativeEnum | null;
  updated_at?: string;
  base_item_id: number | null;
  created_at?: string;
}

export interface ReportQueryResponse {
  reports: Report[];
  report_items: ReportItem[];
}
