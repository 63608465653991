/* eslint-disable */
import { TableRowType } from '@/components/table/shared/types';
import { colors } from '@/styles/variables/color';
import { modelGroupType, variables } from '@/styles/variables/constant';
import { staticStyles } from '@/styles/variables/sizes';
import flattenArray from '@/utils/helpers/flattenArray';
import { FormulaInputType } from '@/utils/types/formulaTypes';
import type { ModelGroupType, TransformedDataTypes } from '@/utils/types/types';
import { filter, map, reduce } from 'lodash';
import { CalendarDays, DollarSign, Hash, ListStart, Percent, Sigma } from 'lucide-react';
import { FaRegFolder, FaRegFolderOpen } from 'react-icons/fa';
import { FaPercent, FaRegFolderClosed } from 'react-icons/fa6';
import { HiHashtag } from 'react-icons/hi';
import { IoCalendarOutline } from 'react-icons/io5';
import { PiFunctionBold } from 'react-icons/pi';
import { TbCurrencyDollar, TbSquareRoundedCheck } from 'react-icons/tb';

/**
 * Creates a row object with the provided information.
 *
 * @param {string} rowType - The type of the row (e.g., 'input', 'calculation', 'output').
 * @param {string} rowName - The name of the row.
 * @param {string} rowFormula - The formula associated with the row.
 * @param {number} rowIndex - The index of the row.
 * @param {number[]} values - An array of values associated with the row (assuming 28 values are needed).
 * @param {number[]} childIndices - An array of indices of child rows if this row is a parent row.
 * @param {number} isParent - Indicates whether this row is a parent row (1 for true, 0 for false).
 * @param {string} outputField - The output field associated with the row.
 * @param {string} inputType - The input field associated with the row.
 * @returns {object} A row object with the provided information.
 */
function createRow(rowType: string, rowName: string, rowFormula: string, rowIndex: number, values: number[], childIndecies: number[], isParent: number, outputField: string, inputType: string) {
  return {
    row_type: rowType,
    row_name: rowName,
    row_formula: rowFormula,
    row_index: rowIndex,
    values,
    // Assuming 28 values are needed
    child_indecies: childIndecies,
    parent: null,
    is_total: 0,
    is_parent: isParent,
    output_field: outputField,
    input_type: inputType
  };
}

/**
 * Transforms and filters data based on the provided parameters.
 *
 * @param {any} initialData - The initial data to be filtered.
 * @param {ModelGroupType} filterDataType - The type of data to filter.
 * @param {number} index - The starting index for row creation.
 * @returns {TransformedDataTypes[]} An array of transformed and filtered data.
 */
export const getFilteredDataForModels = (initialData: any, filterDataType: ModelGroupType | undefined, index: number): TransformedDataTypes[] => {
  // Initialize an empty array to store the transformed data.
  const transformedData = [];

  // Initialize the rowIndex to the provided index.
  let rowIndex = index;

  // Filter the initialData based on the filterDataType (group type).
  const inputTypes = initialData?.filter((item: any) => item.group === filterDataType);
  const child = [];

  // Check if data matching the filter exists.
  //
  if (inputTypes.length > 0) {
    // Create child indices for data matching the filter.
    for (let i = rowIndex + 1; i < rowIndex + 2; i++) {
      child.push(i);
    }
  }
  if (filterDataType) {
    // Create a header row for the specified group type.
    transformedData.push(createRow(filterDataType, filterDataType, '', rowIndex++, Array(28).fill(''), child, 0, '', ''));

    // Iterate over inputTypes and create rows for each item.
    inputTypes.forEach((item: any) => {
      // Create a row with data from the server.
      transformedData.push(createRow(filterDataType === modelGroupType.OUTPUTS ? modelGroupType.OUTPUTS : '', item?.name ? item?.name : 'Base customers', item?.expressions?.[0]?.formula_expression ? item?.expressions?.[0]?.formula_expression : '', rowIndex++, [0, 2350, 2500, 2650, 2800, 2950, 3075, 3200, 3325, 3450, 3550, 3650, 3750, 3850, 3950, 4050, 4150, 4225, 4300, 4375, 4450, 4525, 4600, 4675, 4750, 4800, 4850, 4900, 4950], [], rowIndex, item?.output_field ? item?.output_field : '', item?.input_type ? item?.input_type : ''));
    });

    // Create a row for adding a variable.
    transformedData.push(createRow(variables?.ADD_VARIABLE ? variables?.ADD_VARIABLE : '', 'Add variable', '', rowIndex++, Array(28).fill(''), [], 0, '', ''));
  }

  // Return the array of transformed and filtered data.
  return transformedData;
};
export const getCssFortable = (cell: any) => {
  return `${cell.column.id === 'row_name' ? 'sticky left-0 overflow-visible font-ibmSans font-medium' : cell.column.id === 'row_formula' ? `sticky ${staticStyles.l_17} drop-shadow-[0_90px_40px_rgba(20,32,38,0.15)]` : 'text-right font-ibmMono font-medium'} ${cell.row.original.row_name === '' || cell.row.original?.child_indecies?.length > 0 ? `border-0` : cell.row.getCanExpand() || cell.row.getIsExpanded() ? `border-x border-b-[0.1rem] border-t-0 ${colors.borderTableHorizontalLightBlue} ${colors.borderBottomGrey}` : `border ${colors.borderTableHorizontalLightBlue} ${colors.borderBottomGrey}`}  second:w-64 h-9 py-2 text-xs first:w-64 [&>:not(:first-child)]:w-24 [&>:not(:second-child)]:w-24 ${cell.row.original.row_name === '' || cell.row.original.child_indecies.length > 0 ? `${colors.lightBlue}` : `${colors.white}`} ${cell.row.original?.isChild === true ? 'ml-2.5 pl-4 pr-2' : 'px-2'}`;
};

/**
 * Calculates the initial expanded root rows based on the provided data and settings.
 *
 * @param {any} data - The data array to process.
 * @param {any} settings - The settings object containing expansion configurations.
 * @returns {object} - An object representing the initial expanded root rows.
 */
export const getInitialExpandedRootRows = (data: any, settings: any, additionalRowsIDSToExpand: string[]) => {
  if (settings.expanded) {
    return settings.expanded;
  }
  let expandedRootRows = [];
  if (settings.rowTypesToExpand && settings.rowTypesToExpand.length > 0) {
    expandedRootRows = Array.isArray(data) ? map(filter(flattenArray(data), (originalRow: any) => settings.rowTypesToExpand.includes(originalRow.type)), 'id') : [];
  }
  const result = reduce(expandedRootRows, (acc: any, id: any) => ({
    ...acc,
    [id]: true
  }), {});
  // Add additional rows IDS to expand
  additionalRowsIDSToExpand.forEach(id => {
    result[id] = true;
  });
  return result;
};

/**
 * Generates an icon based on the provided formula input type or table row type.
 *
 * @param {FormulaInputType | TableRowType} type - The type of formula input or table row.
 * @returns {React.ReactElement} - The corresponding icon element.
 */
export const generateTypeIconForTableRows = (type: FormulaInputType | TableRowType, expanded?: boolean) => {
  switch (type) {
    case 'Number':
      return <Hash size={18} className="me-1" />;
    case 'Percentage':
      return <Percent size={18} className="me-1" />;
    case 'Currency':
      return <DollarSign size={18} className="me-1" />;
    case 'Date':
      return <CalendarDays size={18} className="me-1" />;
    case 'Boolean':
      return <TbSquareRoundedCheck size={18} className="me-1" />;
    case 'output':
      return <ListStart size={18} className="me-1 rotate-180" />;
    case 'total':
      return <Sigma size={18} className="me-1" />;
    case 'Group':
      return expanded ? <FaRegFolder size={16} className="me-2" /> : <FaRegFolderOpen size={16} className="me-2" />;
    default:
      return <span />;
  }
};

/**
 * Generates an icon component based on the provided type.
 *
 * @param type - The type of icon to generate. Supported types are 'Percentage', 'Currency', 'Boolean', 'Date', 'Function', and 'Calculations'.
 * @returns A React component representing the icon for the given type, or a default hash icon if the type is not recognized.
 */
export const generateIconsByTypeForAutocomplete = (type: string) => {
  switch (type) {
    case 'Percentage':
      return <FaPercent size={16} />;
    case 'Currency':
      return <TbCurrencyDollar size={20} />;
    case 'Boolean':
      return <TbSquareRoundedCheck size={23} />;
    case 'Date':
      return <IoCalendarOutline size={20} />;
    case 'Function':
      return <Sigma width={16} height={16} />;
    case 'Calculations':
      return <PiFunctionBold />;
    case 'Group':
      return <FaRegFolderClosed size={15} color="black" />;
    default:
      return <HiHashtag size={20} />;
  }
};