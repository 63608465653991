import { Eye } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { IoMdExit } from 'react-icons/io';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import Popup from 'reactjs-popup';
import OptionsMenu from '@/components/common/OptionsMenu/OptionsMenu';
import ShowAllUsageModal from '@/components/common/ShowAllUsageModal/ShowAllUsageModal';
import type { ZustandState } from '@/miscellaneous/store/zustand_store';
import useZustandStore from '@/miscellaneous/store/zustand_store';
import AddChartIcon from '@/public/assets/icons/ChartPlus.svg';
import colors from '@/styles/scss/abstracts/_variables.module.scss';
import styles from '@/styles/table.module.scss';
import useMutations from '@/utils/hooks/mutations/useMutations';

/**
 * Renders a menu item for selecting formula options like moving a formula
 * to a different model or creating a new graph.
 *
 * Uses React hooks to retrieve model data and mutation functions. Renders
 * popups for selecting an option using the OptionsMenu component.
 */
const SubOptionsMenuItem = ({
  row,
  close
}: {
  row: any;
  close: () => void;
}) => {
  const {
    activeBranchModels
  } = useZustandStore((state: ZustandState) => state);
  const {
    updateBulkFormula
  } = useMutations();
  const {
    t
  } = useTranslation('models');

  /**
   * Defines the styles for popup content
   */
  const popupContentStyle = {
    width: 'auto',
    padding: 0,
    borderRadius: 16,
    border: 'none',
    boxShadow: `0px 4px 24px 0px ${colors.formulaOptionShadow}`,
    marginLeft: 3,
    overflow: 'hidden'
  };

  /**
   * Updates the model ID for a formula in bulk.
   *
   * Calls the updateBulkFormula mutation to update the model ID for the
   * formula represented by the row object. Copies all other properties
   * from the original formula.
   *
   * @param modelId - The ID of the new model
   */
  const updateFormulaModelId = (modelId: number) => {
    const {
      original
    } = row;
    updateBulkFormula.mutate([{
      group: original?.group,
      input_type: original?.input_type,
      model_id: modelId,
      branch_id: original?.branch_id,
      name: original.name,
      expression_string: original?.expression_string,
      emoji: original?.emoji,
      id: original?.formula_id,
      output_id: original?.output_id,
      parent_id: original?.parent_id
    }]);
  };

  /**
   * Generates a list of options for selecting a model to update a formula to.
   * Maps over the provided data to generate an option object for each model,
   * setting the label and onclick handler.
   */
  const generateOptions = (data: any) => {
    return data.map((model: any) => {
      return {
        label: model.name,
        onClick: () => {
          updateFormulaModelId(model.id);
        }
      };
    });
  };
  return <div className={styles.subOptionsMenuItemContainer} data-sentry-component="SubOptionsMenuItem" data-sentry-source-file="SubOptionsMenuItem.tsx">
      <ShowAllUsageModal formulaId={row.original.original_row_object?.id} onClose={close} popupTrigger={<div className={styles.formulaInputOptions}>
            <Eye size={15} color="black" />
            <span className={styles.optionLabel}>
              {t('models:show_all_usage')}
            </span>
            <MdOutlineKeyboardArrowRight size={20} />
          </div>} data-sentry-element="ShowAllUsageModal" data-sentry-source-file="SubOptionsMenuItem.tsx" />

      <Popup trigger={<div className={styles.formulaInputOptions}>
            <IoMdExit size={16} />
            <span className={styles.optionLabel}>
              {t('models:move_to_another_model')}
            </span>
            <MdOutlineKeyboardArrowRight size={20} />
          </div>} position={['right top', 'right bottom']} arrow={false} contentStyle={popupContentStyle} repositionOnResize closeOnDocumentClick data-sentry-element="Popup" data-sentry-source-file="SubOptionsMenuItem.tsx">
        <div>
          <OptionsMenu items={generateOptions(activeBranchModels).length > 0 // Check if there are models to display
        ? generateOptions(activeBranchModels) : []} className={styles.formulaInputOptions} data-sentry-element="OptionsMenu" data-sentry-source-file="SubOptionsMenuItem.tsx" />
        </div>
      </Popup>

      <Popup trigger={<div className={`${styles.formulaInputOptions} text-gray-400`}>
            <img src={AddChartIcon.src} alt="chart" width={20} height={20} />
            <span className={styles.optionLabel}>
              {t('models:create_graph')}
            </span>
            <MdOutlineKeyboardArrowRight size={20} />
          </div>} position={['right top', 'right bottom']} arrow={false} contentStyle={popupContentStyle} repositionOnResize closeOnDocumentClick data-sentry-element="Popup" data-sentry-source-file="SubOptionsMenuItem.tsx">
        <div />
      </Popup>
    </div>;
};
export default SubOptionsMenuItem;