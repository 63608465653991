export function getElementOrRowByID(elementId: string) {
  return (
    document.getElementById(elementId) ||
    document.querySelector(`[data-row-id="${elementId}"]`)
  );
}

/**
 * Scrolls the element with the given ID into view smoothly.
 *
 * @param elementId - The ID of the element to scroll into view.
 */
export const scrollElementIntoView = (elementId: string): void => {
  const element = getElementOrRowByID(elementId);

  if (element) {
    const elementRect = element.getBoundingClientRect();
    const offset = window.innerHeight / 2 - elementRect.height / 2;
    window.scrollBy({
      top: elementRect.top - offset,
      behavior: 'smooth',
    });
  }
};
