/* eslint-disable no-return-assign */
import { create } from 'zustand';
import type { Graph, Output } from '@/utils/types/DashboardAndChartsTypes';
export interface GraphState {
  resetStore: () => void;
  showModal: boolean;
  graphData: Graph;
  outputData: Output;
  title: string;
}
const initialState: GraphState = {
  showModal: false,
  graphData: ({} as Graph),
  outputData: ({} as Output),
  title: '',
  resetStore: () => {}
};
const AddGraphPopupStore = create<GraphState>(set => ({
  ...initialState,
  closePopupModal: () => set({
    showModal: false
  }),
  openPopupModal: () => set({
    showModal: true
  }),
  handleGraphData: (graphData: Graph) => set({
    graphData
  }),
  resetGraphData: () => set({
    graphData: ({} as Graph)
  }),
  resetOutputData: () => set({
    outputData: ({} as Output)
  }),
  handleOutputData: (outputData: Output) => set({
    outputData
  }),
  handleModalTitle: (title: string) => set({
    title
  }),
  resetStore: () => set(initialState)
}));
export default AddGraphPopupStore;