import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';
import type { ZustandState } from '@/miscellaneous/store/zustand_store';
import useZustandStore from '@/miscellaneous/store/zustand_store';
import { isValidUrl } from '@/utils/regex';
import type { CompanyWebsite } from '../../onboardingPopupTypes';
import styles from '../CompanyNameOrIntegrationsStage/companyNameOrIntegrationsStage.module.scss';

/**
 * Renders the CompanyWebsiteStage component, which allows the user to input their company's website.
 *
 * @param data - The current value of the company website input.
 * @param onChange - A function to be called when the company website input value changes.
 * @param resetNextStepData - An optional function to be called to reset data for the next step.
 * @param nextStep - An optional function to be called when the user presses the Enter key and the input value is not empty.
 * @returns A React component that renders the company website input field.
 */
const CompanyWebsiteStage = ({
  data,
  onChange,
  nextStep
}: {
  data: string;
  onChange: (data: CompanyWebsite) => void;
  nextStep?: () => void;
}) => {
  const {
    t: translate
  } = useTranslation('company');
  const [value, setValue] = useState(data || '');
  const [errorText, setErrorText] = useState('');
  const {
    userMe
  } = useZustandStore(useShallow((state: ZustandState) => ({
    userMe: state.userMe
  })));

  // Handle value change
  const handleValueChange = (e: any) => {
    const newValue = e.target.value;
    setValue(newValue);
    if (isValidUrl(newValue)) {
      setErrorText('');
    }
    onChange({
      website: newValue
    });
  };
  const username = userMe?.first_name?.trim() || userMe?.full_name?.trim() || userMe?.email?.split('@')[0]?.trim() || userMe?.username || '';
  const usernameString = username ? `, ${username}!` : '!';
  return <div className="h-full w-full  overflow-y-auto" data-testid="company-website" data-sentry-component="CompanyWebsiteStage" data-sentry-source-file="CompanyWebsiteStage.tsx">
      <div className="relative flex h-full w-full flex-col items-center justify-center">
        <div className={styles.welcomeMessage} data-testid="new-or-existing">
          {userMe?.image ? <img src={userMe?.image} alt="avatar" /> : <img src="https://cdn-icons-png.flaticon.com/512/149/149071.png" alt="avatar" />}
          <h1>{translate('welcome') + usernameString}</h1>
        </div>
        <div className={styles.addNameInputContainer}>
          <input type="text" className={`${styles.addNameInput}
            ${isValidUrl(value) ? styles.validInput : ''}
            ${errorText ? styles.invalidInput : ''}
          `} placeholder={translate('company_website')}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus value={value} onKeyDown={e => {
          // Handle Enter key press
          if (e.key === 'Enter' && value.length > 0) {
            if (!isValidUrl(value)) {
              setErrorText(translate('invalid_website'));
            } else {
              nextStep?.();
            }
          }
        }} onChange={e => handleValueChange(e)} />
          {errorText && <div className={styles.invalidInput} data-testid="error-text">
              {errorText}
            </div>}
        </div>
      </div>
    </div>;
};
export default CompanyWebsiteStage;