import { BaseCellType, type Settings } from '../types';
import sharedTableCellStyles from './sharedTableCellStyles/sharedTableCellStyles';
function mergeStyles(sharedStyles?: Settings['styles'], specificStyles?: Settings['styles']) {
  if (!sharedStyles && !specificStyles) return undefined;
  const mergedStyles = {
    shared: {
      ...(sharedStyles?.shared ?? {}),
      ...(specificStyles?.shared ?? {})
    },
    individual: [...(sharedStyles?.individual ?? []), ...(specificStyles?.individual ?? [])]
  };
  return mergedStyles;
}
function mergeGapConfig(sharedGap?: Settings['gapConfig'], specificGap?: Settings['gapConfig']) {
  if (!sharedGap && !specificGap) return undefined;
  const mergedGap = {
    at: [...(sharedGap?.at ?? []), ...(specificGap?.at ?? [])]
  };
  return mergedGap;
}
export function mergeSettings(shared: Settings, specific: Settings): Settings {
  const mergedSettings: Settings = {
    ...shared,
    ...specific,
    ignoreColumns: [...(shared.ignoreColumns ?? []), ...(specific.ignoreColumns ?? [])],
    additionalColumns: [...(shared.additionalColumns ?? []), ...(specific.additionalColumns ?? [])],
    disableEditingOnColumns: [...(shared.disableEditingOnColumns ?? []), ...(specific.disableEditingOnColumns ?? [])],
    styles: mergeStyles(shared.styles, specific.styles),
    gapConfig: mergeGapConfig(shared.gapConfig, specific.gapConfig)
  };
  return mergedSettings;
}

/**
 * Hiring table settings configuration file.
 */
const sharedColumnSettings: Settings = {
  expanded: false,
  ignoreColumns: [],
  rowTypesToExpand: ['section'],
  additionalColumns: [
  // need to add conditional empty column with no max width and no header at the end if columns are too smalle for width
  {
    accessorKey: 'empty',
    header: '',
    enableEditing: true,
    type: BaseCellType.View,
    style: {
      maxWidth: '1000px'
    }
  }],
  disableEditingOnColumns: [],
  styles: sharedTableCellStyles,
  // Array of objects specifying gap configuration at different depths
  gapConfig: {
    at: []
  }
};
export default sharedColumnSettings;