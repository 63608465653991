/**
 * Array containing function data for tags.
 * Each object represents a function with associated properties.
 * @typedef {Object} FunctionData
 * @property {string} label - The label or name of the function.
 * @property {string} type - The type of function.
 * @property {string} rightText - The text representation of the function.
 * @property {boolean} [hasDropdown] - Indicates if the function has a dropdown.
 * @property {string} [insert] - The default insert text for the function.
 * @property {string} [description] - A description of the function.
 */

/**
 * Array containing function data for tags.
 * Each object represents a function with associated properties.
 * @type {FunctionData[]}
 */
const functions = [
  {
    label: 'FLOOR',
    type: 'function',
    hasDropdown: false,
    insert: '{FLOOR}()',
    description: '',
  },
  {
    label: 'CEILING',
    type: 'function',
    hasDropdown: false,
    insert: '{CEILING}()',
    description: '',
  },
  {
    label: 'IF',
    type: 'function',
    insert: '(?:)',
    description: '',
  },
  {
    label: 'NOT',
    type: 'function',
    insert: '{NOT}()',
    description: '',
  },
  {
    label: 'ABS',
    type: 'function',
    insert: '{ABS}()',
    description: 'Function',
  },
  {
    label: 'POWER',
    type: 'function',
    hasDropdown: false,
    insert: '{POWER}(,)',
    description: 'Function',
  },
  {
    label: 'ROUND',
    type: 'function',
    hasDropdown: false,
    insert: '{ROUND}()',
    description: 'Function',
  },
  {
    label: 'SUM',
    type: 'function',
    hasDropdown: false,
    insert: '{SUM}(,)',
    description: 'Function',
  },
  {
    label: 'MAX',
    type: 'function',
    insert: '{MAX}(,)',
    description: 'Function',
  },
  {
    label: 'MIN',
    type: 'function',
    insert: '{MIN}(,)',
    description: 'Function',
  },
];

export default functions;
