import { flexRender } from '@tanstack/react-table';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';
import styles from '@/components/table/shared/table/GeneralTable/styles/tableColumnStyles.module.scss';
import sidebarStyles from '@/components/table/shared/table/GeneralTable/styles/tableSidebarStyles.module.scss';
import type { ZustandState } from '@/miscellaneous/store/zustand_store';
import useZustandStore from '@/miscellaneous/store/zustand_store';
import arrow from '@/public/assets/icons/Alt Arrow Right.svg';
import { ColumnType } from './types';
import { generateUniqueId, getStyle, getStyleForQuarters, getStyleForTotal, getStyleForYears, getSubtitle, getSubtitleForQuarters, getSubtitleForTotal, getSubtitleForYears } from './utils';

/**
 * Generates a subtitle based on the column type and generated date.
 *
 * @param columnType - The type of column (total, year, quarter, month)
 * @param generatedDate - The date string to generate subtitle for
 * @returns The subtitle text ('Actual' or 'Forecast')
 */
export const generateSubtitle = (columnType: ColumnType, generatedDate: string, currentDate: string, endDate: string) => {
  switch (columnType) {
    case ColumnType.Total:
      // Handle total
      return getSubtitleForTotal(currentDate, endDate);
    case ColumnType.Year:
      // Handle year column
      return getSubtitleForYears(generatedDate, currentDate);
    case ColumnType.Quarter:
      return getSubtitleForQuarters(generatedDate, currentDate);
    case ColumnType.Month:
      return getSubtitle(generatedDate, currentDate);
    default:
      return getSubtitle(generatedDate, currentDate);
  }
};

/**
 * Renders the table header cell.
 * @param header - The header props from react-table
 * @param index - The index of the header cell
 */
function TableHeaderCell({
  header,
  index
}: {
  header: any;
  index: number;
}) {
  const {
    currentDate,
    endDate
  } = useZustandStore(useShallow((state: ZustandState) => ({
    currentDate: state.currentDate,
    endDate: state.endDate
  })));

  /**
   * Renders a subtitle div under the header cell if:
   * - The header data has isSubTitle set to true
   * - The current index is not the first cell
   * - Shows 'Actual' if the current date is past the header date
   * - Shows 'Forecast' if the current date is before the header date
   */
  const renderSubtitle = (i: number, headerData: any) => {
    return headerData.isSubTitle && i !== 0 && <div style={headerData.subTitleStyle}>
          {generateSubtitle(headerData.columnType, headerData.id, (currentDate as string), (endDate as string))}
        </div>;
  };

  /**
   * Generates the border top style for a table header cell,
   * based on the column type and generated date.
   *
   * Checks the column type and calls specific helper functions to determine
   * if the border should be a solid blue line or not.
   *
   * @param columnType - The type of column
   * @param generatedDate - The generated date string for comparison
   * @returns A CSS border style value, e.g. '4px solid blue' or 'none'.
   */
  function generateBorderTopStyle(columnType: ColumnType, generatedDate: string) {
    switch (columnType) {
      case ColumnType.Total:
        // Handle Total
        return getStyleForTotal(currentDate, endDate);
      case ColumnType.Year:
        // Handle year column
        return getStyleForYears(generatedDate, currentDate);
      case ColumnType.Quarter:
        return getStyleForQuarters(generatedDate, currentDate);
      case ColumnType.Month:
        return getStyle(generatedDate, currentDate);
      default:
        return getStyle(generatedDate, currentDate);
    }
  }

  /**
   * Applies conditional styling to the header cell based on
   * whether the current date is past the header's date.
   * Sets border, padding and margins.
   */
  const dynamicHeaderStyle = {
    ...header.column.columnDef.headerStyle,
    borderTop: generateBorderTopStyle(header.column.columnDef.columnType, header.column.columnDef.id),
    paddingLeft: '24px',
    paddingRight: '24px'
  };
  return (
    /**
     * Renders the header cell for the table.
     * Applies special styling if it is the first cell.
     * Renders the column header content.
     */
    <div className={`
          ${index === 0 ? styles.departmentHeaderCell : styles.tableHeaderContent}

        ${generateSubtitle(header.column.columnDef.columnType, header.column.columnDef.id, (currentDate as string), (endDate as string)) !== 'Actual' && header.column.columnDef.isSubTitle ? styles.topHeader : ''}
        `} style={index !== 0 && header.column.columnDef.isSubTitle ? dynamicHeaderStyle : {
      ...header.column.columnDef.headerStyle
    }} data-sentry-component="TableHeaderCell" data-sentry-source-file="tableHelper.tsx">
      {renderSubtitle(index, header.column.columnDef)}
      <span>
        {index !== 0 ? flexRender(header.column.columnDef.header, header.getContext()) : ''}
      </span>
    </div>
  );
}

/**
 * Renders a set of navigation buttons that allow the user to scroll the table horizontally.
 *
 * @param tableRef - A ref to the table element that should be scrolled.
 * @param scrollRef - A ref to the element that should be scrolled.
 * @param scrollPXWidth - The number of pixels to scroll when the navigation buttons are clicked. Defaults to 600.
 * @returns A React component that renders the navigation buttons.
 */
export const TableScrollNavigationButtons = ({
  tableRef,
  scrollRef,
  scrollPXWidth = 600
}: {
  tableRef: any;
  scrollRef: any;
  scrollPXWidth?: number;
}) => {
  const [remainingScroll, setRemainingScroll] = useState(0); // Remaining scroll distance

  /**
   * Debounces the scroll event on the table element and updates the remaining scroll
   * distance.
   *
   * @remarks
   * This function is called whenever the table is scrolled. It calculates the
   * remaining scroll distance by subtracting the scroll left position and the
   * client width from the total scroll width of the table. The result is then
   * rounded up and stored in the `remainingScroll` state.
   *
   * @param tableRef - A ref to the table element
   * @param setRemainingScroll - A function to update the `remainingScroll` state
   */
  const handleScroll = debounce(() => {
    if (tableRef) {
      const remaining = tableRef.scrollWidth - tableRef.scrollLeft - tableRef.clientWidth;
      setRemainingScroll(Math.ceil(remaining));
    }
  }, 100);

  /**
   * Adds a scroll event listener to the table reference and calls the `handleScroll` function when the table is scrolled.
   * The event listener is removed when the component is unmounted.
   */
  useEffect(() => {
    if (tableRef) {
      tableRef.addEventListener('scroll', handleScroll);
      handleScroll();
      return () => {
        tableRef.removeEventListener('scroll', handleScroll);
      };
    }
  }, [tableRef]);

  /**
   * Creates a function that scrolls the table horizontally with a smooth animation.
   *
   * @param scrollLength - The distance to scroll in pixels. Defaults to 200.
   * @returns A function that can be called to trigger the scroll animation.
   */
  const createScrollFunc = (scrollLength: number = 200) => {
    return () => {
      if (!tableRef || !scrollRef) {
        return;
      }
      const start = scrollRef.current.scrollLeft; // Start position of the scroll
      const end = start + scrollLength; // End position of the scroll
      const duration = 300; // Duration of the scroll animation in milliseconds
      const startTime = performance.now(); // Start time of the animation

      /**
       * Animates the scrolling of an element by updating its `scrollLeft` property over time.
       *
       * @param currentTime - The current time in milliseconds since the animation started.
       * @returns Void.
       */
      const animateScroll = (currentTime: number) => {
        const elapsed = currentTime - startTime; // Elapsed time since the animation started
        const progress = Math.min(elapsed / duration, 1); // Progress of the animation from 0 to 1
        const ease = progress < 0.5 ? 2 * progress * progress : -1 + (4 - 2 * progress) * progress; // Easing function for smooth animation

        // eslint-disable-next-line no-param-reassign
        scrollRef.current.scrollLeft = start + (end - start) * ease; // Update the scroll position

        if (progress < 1) {
          requestAnimationFrame(animateScroll); // Continue the animation
        }
      };
      requestAnimationFrame(animateScroll); // Start the animation
    };
  };
  return <div className={styles.tableSettingsHeaderNavigationButtons} data-sentry-component="TableScrollNavigationButtons" data-sentry-source-file="tableHelper.tsx">
      <button disabled={tableRef?.scrollLeft <= 1} type="button" onClick={createScrollFunc(-scrollPXWidth)}>
        <img className={styles.leftArrow} src={arrow.src} alt="arrow" />
      </button>
      <button disabled={remainingScroll <= 1} onClick={createScrollFunc(scrollPXWidth)} type="button">
        <img src={arrow.src} alt="arrow" />
      </button>
    </div>;
};
export interface SpecificHeaderProps {
  headerGroup: any;
  activeBranch: any;
}
export const tableHeaderRenderer = (tableHeaderGroups: any, isTableDisabled?: boolean) => {
  const headerGroups = tableHeaderGroups.map((headerGroup: any) => <tr key={headerGroup.id}>
      {headerGroup.headers.map((header: any, index: number) => {
      return <th aria-label="header cell" key={header.id} className={`${header.id === 'sidebar' ? isTableDisabled ? `${styles.topLeftCell} ${sidebarStyles.disabledTable}` : styles.topLeftCell : styles.stickyHeader} ${index === headerGroup.headers.length - 1 ? styles.lastHeader : ''}`}>
            {/*
             * Renders the header cell for the table.
             * Renders the column header content.
             */}
            <TableHeaderCell header={header} index={index} />
          </th>;
    })}
    </tr>);
  return headerGroups;
};

/**
 * Props for the TableRowWithGaps component.
 * @typedef {Object} TableRowWithGapsProps
 * @property {React.ReactNode} children - The child elements.
 * @property {any} row - The row data.
 * @property {any} gapConfig - Configuration for optional gaps.
 */
export interface TableRowWithGapsProps {
  children: React.ReactNode;
  row: any;
  gapConfig: any;
  table: any;
}

/**
 * Represents a table row with optional gaps.
 * @param {TableRowWithGapsProps} props - React component props.
 * @returns {JSX.Element} - The rendered component.
 */
export const TableRowWithGapsDeprecated: React.FC<TableRowWithGapsProps> = ({
  children,
  row,
  gapConfig,
  table
}) => {
  const {
    isTableDisabled
  } = useZustandStore((state: ZustandState) => state);
  // Find gap configuration for the current row depth
  const spec = gapConfig.at.find((setup: any) => setup.depth === row.depth);
  const {
    length
  } = table.getHeaderGroups()[0].headers;
  // Generate an array of empty rows based on the gap configuration
  const gapRows = spec ? new Array(spec.count).fill(null).map((__, i) => {
    // Generate unique keys for gap rows and cells
    const gapRowKey: string = `gapRow-${row.sidebar}-${i}-${generateUniqueId()}`;
    return <tr key={gapRowKey} className={styles.gapRow}>
            {new Array(length).fill(null).map((___, j) => {
        const cellKey = `gapCell-${row.sidebar}-${i}-${j}-${generateUniqueId()}`;
        return <td aria-label="empty cell" key={cellKey} className={`${i === 0 && j === 0 ? isTableDisabled ? `${styles.stickyColumn} ${styles.disabledTable}` : styles.stickyColumn : styles.empty} ${styles.gapRow} `} />;
      })}
          </tr>;
  }) : [];
  return <>
      {gapRows}
      {children}
    </>;
};
interface RenderTableHeaderProps {
  tableHeaderGroups: any;
  isTableDisabled: boolean;
}
export const RenderTableHeader = React.memo<RenderTableHeaderProps>(({
  tableHeaderGroups,
  isTableDisabled
}) => {
  return tableHeaderRenderer(tableHeaderGroups, isTableDisabled);
});
RenderTableHeader.displayName = 'RenderTableHeader';