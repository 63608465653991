/* eslint-disable import/no-cycle */
/* eslint-disable react/no-array-index-key */
import { X } from 'lucide-react';
import React from 'react';
import { QuestionBox } from '../../CompanyDetailsStage/CompanyDetailsStage';
import { type ProductStageColumn, type ProductStageColumnOptions, type ProductStageData } from '../CompanyProductsStage';
import styles from '../companyProductsStage.module.scss';
function isValidProductStageData(key: string, value: any): boolean {
  switch (key) {
    case 'sold_last_month':
    case 'sold_1_year_ago':
    case 'cac':
      return typeof value === 'number' && value >= 0;
    case 'name':
      return !!value;
    // Only name needs to be filled
    case 'subscription_type':
      return !!value;
    // Only subscription type needs to be filled
    case 'price':
      return typeof value === 'number' && value >= 0;
    default:
      return true;
  }
}
export function isProductFinished(Product: ProductStageData) {
  return Object.entries(Product).every(([key, value]) => isValidProductStageData((key as keyof ProductStageData), value));
}

/**
 * Calculates the dynamic border styles for a table cell based on its index in the columns array.
 *
 * @param index - The index of the current column in the columns array.
 * @param addRadius - Whether to add border radius to the first and last columns.
 * @returns An object with the calculated border styles.
 */
const addDynamicBorder = (index: number, addRadius: boolean, columnsOptions: ProductStageColumnOptions, columns: ProductStageColumn[]) => {
  return {
    borderLeft: index === 0 ? `1px solid ${columnsOptions.borderColor}` : 'none',
    borderRadius: addRadius && index === 0 ? '12px 0 0 0' : addRadius && index === columns.length - 1 ? '0 12px 0 0' : 'none'
  };
};

/**
 * Renders the table header for the product stage.
 *
 * @param columns - An array of `ProductStageColumn` objects representing the columns in the table.
 * @param columnsOptions - An object containing options for the table columns, such as the border color.
 * @param commonCellStyle - A CSS properties object representing the common styles for the table cells.
 * @returns A React component that renders the table header.
 */
const ProductStageTableHeader = ({
  columns,
  columnsOptions,
  commonCellStyle
}: {
  columns: ProductStageColumn[];
  columnsOptions: ProductStageColumnOptions;
  commonCellStyle: React.CSSProperties;
}) => {
  return <div className={styles.tableHeader} data-sentry-component="ProductStageTableHeader" data-sentry-source-file="ProducStageSubComponents.tsx">
      <div className={styles.actionColumn} />
      {columns.map((column, index) => <div key={index} style={{
      ...commonCellStyle,
      width: column.width,
      ...addDynamicBorder(index, true, columnsOptions, columns)
    }} className={styles.tableHeaderCell}>
          {column.title}
        </div>)}
    </div>;
};

/**
 * Renders the table body for the product stage.
 *
 * @param columns - An array of `ProductStageColumn` objects representing the columns in the table.
 * @param productData - An array of `ProductStageData` objects representing the product data to be displayed in the table.
 * @param columnsOptions - An object containing options for the table columns, such as the border color.
 * @param commonCellStyle - A CSS properties object representing the common styles for the table cells.
 * @param renderInput - A function that renders the input component for a given column and product.
 * @param onDeleteProduct - A function that is called when a product row is deleted.
 * @returns A React component that renders the table body.
 */
const ProductStageTableBody = ({
  columns,
  productData,
  columnsOptions,
  commonCellStyle,
  renderInput,
  onDeleteProduct
}: {
  columns: ProductStageColumn[];
  productData: ProductStageData[];
  renderInput: (column: ProductStageColumn, product: ProductStageData, productIndex: number, hasError: boolean) => JSX.Element | null;
  columnsOptions: ProductStageColumnOptions;
  commonCellStyle: React.CSSProperties;
  onDeleteProduct: (productIndex: number) => void;
}) => {
  const parseColumnTitle = (title: string) => {
    return title.replace('#', '').replace(/ /g, '_').replace(/\n/g, '_').toLowerCase();
  };
  return <div className={styles.tableBody} data-sentry-component="ProductStageTableBody" data-sentry-source-file="ProducStageSubComponents.tsx">
      <div className={styles.emptyRow} />
      {productData?.map((product, productIndex) => <React.Fragment key={productIndex}>
          <div className={styles.tableRow}>
            <div className={styles.actionColumn}>
              {productIndex !== 0 && <X data-tooltip-id="delete-tooltip" data-tooltip-content="Delete Product Row" size={16} onClick={() => onDeleteProduct(productIndex)} />}
            </div>
            {columns.map((column, index) => <div key={index} style={{
          ...commonCellStyle,
          width: column.width,
          ...addDynamicBorder(index, false, columnsOptions, columns)
        }} className={styles.tableCell}>
                {index === 0 ? <div className={styles.inputContainer}>
                    <QuestionBox isChecked={isProductFinished(product)} iconSize={14} />
                    {renderInput(column, product, productIndex, false)}
                  </div> : renderInput(column, product, productIndex, !isValidProductStageData((parseColumnTitle((column.title as keyof ProductStageColumn)) as string), product[(parseColumnTitle((column.title as keyof ProductStageColumn)) as keyof ProductStageData)]))}
              </div>)}
          </div>
          {productIndex !== productData.length - 1 && <div className={styles.emptyRow} />}
        </React.Fragment>)}
    </div>;
};
export { addDynamicBorder, ProductStageTableBody, ProductStageTableHeader };