import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useShallow } from 'zustand/react/shallow';

import ApiUtils from '@/api/ApiUtils';
import dynamicRoute from '@/miscellaneous/constant';
import { QUERYKEYS } from '@/miscellaneous/constant/reactQueryKeyConfig';
import type { ZustandState } from '@/miscellaneous/store/zustand_store';
import useZustandStore from '@/miscellaneous/store/zustand_store';

import useShallowRoute from '../useShallowRoute';

export type Models = {
  id: number;
  name: string;
  branch_id: number;
  company_id: number;
  start_date: string;
  end_date: string;
  sort_order: string;
};

/**
 * Handles setting the active model state based on the query parameters and available models.
 *
 * Checks the query for a 'model' parameter and tries to find a matching model in allModels.
 * If found, sets that as the active model.
 * If no 'model' query param, sets the first model in allModels as active.
 * If 'model' query param but not found in allModels, sets first model as active
 * and updates the shallow route to the first model name.
 */
const activeModelHandler = (
  allModels: any,
  query: any,
  setActiveModelState: any,
  shallowRoute: any,
) => {
  const firstModel = allModels && allModels.length > 0 ? allModels?.[0] : null;
  const { model } = query;
  const urlModel = allModels?.find((m: any) => m.id === Number(model));
  if (model && urlModel) {
    setActiveModelState(urlModel);
  } else if (!model) {
    setActiveModelState(firstModel);
  } else {
    setActiveModelState(firstModel);
    shallowRoute({
      model: firstModel?.id,
    });
  }
};

const useModels = () => {
  // Zustand store for the active model

  const {
    activeModel,
    setActiveModel,
    activeCompanyId,
    activeBranchId,
    activeBranchModels,
    setActiveBranchModels,
  } = useZustandStore(
    useShallow((state: ZustandState) => ({
      activeModel: state.activeModel,
      setActiveModel: state.setActiveModel,
      activeCompanyId: state.activeCompany?.id ?? null,
      activeBranchId: state.activeBranch?.id ?? null,
      activeBranchModels: state.activeBranchModels,
      setActiveBranchModels: state.setActiveBranchModels,
    })),
  );

  const { data: allModels, ...queryInfo } = useQuery({
    queryKey: [QUERYKEYS.ALLMODELS, activeCompanyId],
    queryFn: () => ApiUtils.getModelByCompany(activeCompanyId),
    enabled: !!activeCompanyId,
  });

  const { query, pathname } = useRouter();
  const shallowRoute = useShallowRoute();

  /**
   * This useEffect hook runs when the activeBranchModels, query,
   * setActiveModel, allModels, queryInfo.isLoading or shallowRoute values change.
   *
   * It calls the activeModelHandler function, passing the activeBranchModels,
   * query, setActiveModel and shallowRoute as arguments.
   *
   * The activeModelHandler handles setting the initial active model if none is set in the query,
   * or updating it if the queried model is not found in allModels.
   */
  useEffect(() => {
    if (!queryInfo.isLoading && allModels?.length > 0) {
      const currentActiveBranchModels = allModels?.filter(
        (model: any) => model.branch_id === activeBranchId,
      );

      setActiveBranchModels(currentActiveBranchModels);
    }
  }, [allModels, queryInfo.isLoading, activeBranchId]);

  useEffect(() => {
    if (activeBranchModels && activeBranchModels.length > 0) {
      if (
        !pathname.includes(`/${dynamicRoute.models}`) &&
        activeBranchModels.find((model: any) => model.id === activeModel?.id)
      ) {
        return;
      }

      activeModelHandler(
        activeBranchModels,
        query,
        setActiveModel,
        shallowRoute,
      );
    }
  }, [query?.model, activeBranchModels]);

  return {
    activeModel,
    allModels,
    allModelsByActiveBranch: activeBranchModels,
    ...queryInfo, // Spreading the rest of the query info for additional control if needed
  };
};

export default useModels;
