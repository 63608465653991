import { useRouter } from 'next/router';
import { useShallow } from 'zustand/react/shallow';

import useUtilsStore from '@/miscellaneous/store/utilsStore/utilsStore';

// src/utils/enums.ts
export enum ActionType {
  LOGIN = 'login',
  SIGNUP = 'signup',
}

/**
 * A React hook that sets the company URL from the URL query parameters for scraping
 *
 * @returns An object with a `handleCompanyUrlScrape` function that can be used to set the company URL object for scraping and mark the login scraping as done.
 */
export const useSetComapanyURLForScrape = () => {
  const router = useRouter();
  const { query } = router;

  const { setCompanyUrlObjectForScrape, setCreateAndScrapeCompany } =
    useUtilsStore(
      useShallow((state) => ({
        setCompanyUrlObjectForScrape: state.setCompanyUrlObjectForScrape,
        setCreateAndScrapeCompany: state.setCreateAndScrapeCompany,
      })),
    );

  // Handle the scraping of the company URL from the URL query parameters.
  const setCompanyURLForScrape = (actionType: ActionType) => {
    if (query?.company_url) {
      setCompanyUrlObjectForScrape({
        url: query.company_url as string,
        direction: actionType,
      });
      if (actionType === ActionType.LOGIN) {
        // If the action type is login, we are creating a new company and scraping the company's website, on signup  we are updating the company's website.
        setCreateAndScrapeCompany(true);
      }
    }
  };

  return { setCompanyURLForScrape };
};
