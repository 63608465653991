import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';

import ApiUtils from '@/api/ApiUtils';
import { QUERYKEYS } from '@/miscellaneous/constant/reactQueryKeyConfig';
import type { ZustandState } from '@/miscellaneous/store/zustand_store';
import useZustandStore from '@/miscellaneous/store/zustand_store';

import useMutations from '../mutations/useMutations';
import type { Dashboard } from './DashboardHookHelper';
import { createActiveDashboard, getDashboardType } from './DashboardHookHelper';

const useDashboards = () => {
  const router = useRouter();
  const [branchDashboards, setBranchDashboards] = useState<Dashboard[]>([]);
  const { createDashboard: createDashboardMutation } = useMutations();

  const {
    activeCompanyId,
    activeBranchId,
    setActiveDashboard,
    setBranchDashboards: setBranchDashboardsStore,
  } = useZustandStore(
    useShallow((state: ZustandState) => ({
      activeCompanyId: state.activeCompany?.id ?? null,
      activeBranchId: state.activeBranch?.id ?? null,
      setActiveDashboard: state.setActiveDashboard,
      setBranchDashboards: state.setBranchDashboards,
    })),
  );

  const createDashboardMutationAndSet =
    createDashboardMutation(setActiveDashboard);

  /**
   * Fetch dashboards data using React Query. Uses the active model's ID if available,
   * otherwise fallback to the model ID from the URL slug, and defaults to 0 if none.
   */
  const {
    data: allDashboards,
    isLoading,
    error,
    isSuccess,
  } = useQuery({
    queryKey: [QUERYKEYS.DASHBOARDSBYCOMPANY, activeCompanyId],
    queryFn: () => ApiUtils.getDashboard(0, activeCompanyId as number),
  });

  /**
   * Process and filter dashboards based on active branch, model, and optional props.
   */
  useEffect(() => {
    if (allDashboards?.length > 0 && !isLoading) {
      const filtered = allDashboards?.filter(
        (dashboard: Dashboard) => dashboard.branch_id === activeBranchId,
      );
      setBranchDashboards(filtered);
      setBranchDashboardsStore(filtered);
    }
  }, [activeBranchId, allDashboards, isLoading]);

  /**
   * Process and filter dashboards based on active branch, model, and optional props.
   */
  useEffect(() => {
    const currentModelID = Number(router?.query?.model); // not using activeModel here because it's not set yet
    const dashboardType = getDashboardType(router.pathname);

    if (
      !dashboardType ||
      !isSuccess ||
      !branchDashboards ||
      branchDashboards.length === 0
    ) {
      return;
    } // this is not a page that requieres dashboard
    // Using find to get the active dashboard or set it to null if not found
    const filteredActiveDashboard = branchDashboards?.find(
      (dashboard: Dashboard) =>
        dashboard.type === dashboardType &&
        (dashboard.type !== 'Model' || dashboard?.model_id === currentModelID),
    );

    if (filteredActiveDashboard === undefined || !filteredActiveDashboard?.id) {
      // create new dashboard
      if (!createDashboardMutationAndSet) return;
      createActiveDashboard(
        dashboardType,
        createDashboardMutationAndSet,
        activeBranchId,
        activeCompanyId,
        currentModelID,
      );
    } else {
      setActiveDashboard(filteredActiveDashboard);
    }
  }, [router.query]);

  return {
    allDashboards,
    branchDashboards,
    isLoading,
    error,
  };
};

export default useDashboards;
