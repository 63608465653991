/**
 * Returns the class name for a Calendar tile based on the date, view,
 * min/max selectable dates, and whether it's for the start or end date.
 *
 * For 'year' view:
 * - Disables tiles outside min/max range
 * - Applies special classes for start, end, and range tiles
 */
const getTileClassName = (
  date: Date,
  view: string,
  minDate: Date,
  maxDate: Date,
  isStartDate: boolean,
  currentDate: Date,
  currenDateEnbaled: boolean,
) => {
  const currentDateMonth = currentDate.getMonth();
  const currentDateYear = currentDate.getFullYear();
  const dateMonth = date.getMonth();
  const dateYear = date.getFullYear();
  // Condition for checking if the current view is 'year'
  const isYearView = view === 'year';
  // Condition to check if the date is within the same year and month as the current date
  const isCurrentDateMonthAndYear =
    currenDateEnbaled &&
    dateYear === currentDateYear &&
    dateMonth === currentDateMonth;

  // Condition to check if the current date is not at the min or max month range within the same year
  const isNotMinOrMaxMonthInSameYear =
    (currentDateMonth !== minDate.getMonth() &&
      currentDateYear === minDate.getFullYear() &&
      currentDateMonth !== maxDate.getMonth() &&
      currentDateYear === maxDate.getFullYear()) ||
    (minDate.getFullYear() === currentDateYear &&
      minDate.getMonth() !== currentDateMonth);

  // Condition to check if the date is the same as both minDate and maxDate
  const isSameAsMinAndMaxDate =
    minDate.toDateString() === date.toDateString() &&
    date.toDateString() === maxDate.toDateString();
  // Condition to check if the date matches minDate
  const isMinDate = date.toDateString() === minDate.toDateString();
  // Condition to check if the date matches maxDate
  const isMaxDate = date.toDateString() === maxDate.toDateString();
  // Condition to check if the date is between minDate and maxDate
  const isWithinRange = date >= minDate && date <= maxDate;

  if (isYearView) {
    // Check if the date is the current month and year, and is not at the min/max range in the same year
    if (isCurrentDateMonthAndYear && isNotMinOrMaxMonthInSameYear) {
      return 'react-calendar__tile--within-range react-calendar__tile--current-date';
    }
    // If the date is the same as both minDate and maxDate
    if (isSameAsMinAndMaxDate) {
      return 'react-calendar__tile--same';
    }
    // // If the date is the same as minDate, return different classes based on whether it's the start date
    if (isMinDate) {
      return isStartDate
        ? 'react-calendar__tile--first-date-from react-calendar__tile--within-range'
        : 'react-calendar__tile--first-date-to react-calendar__tile--within-range';
    }
    // If the date is the same as maxDate, return different classes based on whether it's the start date
    if (isMaxDate) {
      return isStartDate
        ? 'react-calendar__tile--last-date-from react-calendar__tile--within-range'
        : 'react-calendar__tile--last-date-to react-calendar__tile--within-range';
    }
    // If the date is between minDate and maxDate, return within-range class
    if (isWithinRange) {
      return 'react-calendar__tile--within-range';
    }
  }
};

/**
 * Validates if a date is valid for selecting as a start or end date.
 *
 * Checks if the date is within the min/max range and doesn't
 * conflict with the current start/end date limit.
 */
const isDateValid = (
  isStartDate: boolean,
  newDateValue: Date,
  currentLimitDate: Date,
  minDate: Date,
  maxDate: Date,
) => {
  if (!isStartDate && minDate.toDateString() === newDateValue.toDateString()) {
    return false;
  }
  if (isStartDate && maxDate.toDateString() === newDateValue.toDateString()) {
    return false;
  }
  if (isStartDate && newDateValue > currentLimitDate) {
    return false;
  }
  if (!isStartDate && newDateValue < currentLimitDate) {
    return false;
  }

  return true;
};

export { getTileClassName, isDateValid };
