import React, { useState } from 'react';
import { Button } from 'react-aria-components';
import { useTranslation } from 'react-i18next';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { Tooltip } from 'react-tooltip';
import Popup from 'reactjs-popup';
import colors from '@/styles/scss/abstracts/_variables.module.scss';
import styles from '@/styles/scss/formulaCell.module.scss';
import CustomVariableTimeStepModal from '../customVariableTimeStepModal';
const VariableWidget: React.FC = (props: any) => {
  const {
    color,
    content,
    updateEditorContent,
    match
  } = props;
  const [showModal, toggleModal] = useState(false);
  const [selectedValue, setSelectedValue] = useState(props.value || '');
  const formulaId = match && +match[1];
  const {
    t: translate
  } = useTranslation('models');

  /**
   * Handles the toggling of different dropdown options in the DropdownWidget component.
   *
   * When the user selects an option from the dropdown, this function updates the editor content
   * with a new input value based on the selected option. If the user selects the "react-aria-4"
   * option, it toggles the display of the CustomVariableTimeStepModal component.
   *
   * @param value - The value of the selected dropdown option.
   * @returns void
   */
  const handleToggle = (option: any) => {
    let newInput = '';
    if (option === 'This Month') {
      newInput = `{{${match[1]},y,x}}`;
    } else if (option === 'Last Month') {
      newInput = `{{${match[1]},y,x-1}}`;
    } else if (option === '1 Year Ago') {
      newInput = `{{${match[1]},y-1,x}}`;
    } else if (option === 'Custom') {
      toggleModal(true);
      return;
    }
    if (newInput) {
      const {
        input
      } = match;
      const start = match.index;
      const end = start + match[0].length;
      const replacedInput = input.slice(0, start) + newInput + input.slice(end);
      updateEditorContent(replacedInput);
    }
  };

  /**
   * Defines the styles for the popup content in the DropdownWidget component.
   * This object sets the visual properties of the popup, such as the border radius,
   * background color, box shadow, backdrop filter, border, padding, and margin.
   * These styles are applied to the popup content when it is displayed.
   */
  const popupContentStyle = {
    borderRadius: '16px',
    backgroundColor: 'white',
    boxShadow: '0px 8px 20px 0px rgba(19, 25, 35, 0.15)',
    backdropFilter: 'blur(25px)',
    border: 'none',
    padding: '12px',
    paddingBottom: '8px',
    margin: '0px 0px 10px 0px'
  };

  /**
   * Defines the options for the dropdown menu in the DropdownWidget component.
   * Each option has an id, a CSS class name, and an onClick handler that updates the editor content
   * based on the selected option.
   */
  const listBoxOptions = [{
    id: 'This Month',
    className: styles.listBoxItem,
    onClick: () => handleToggle('This Month')
  }, {
    id: 'Last Month',
    className: styles.listBoxItem,
    onClick: () => handleToggle('Last Month')
  }, {
    id: '1 Year Ago',
    className: styles.listBoxItem,
    onClick: () => handleToggle('1 Year Ago')
  }, {
    id: 'Custom',
    className: styles.listBoxItem,
    onClick: () => handleToggle('Custom')
  }];
  const handleClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    if (formulaId) {
      event.preventDefault();
      props.goToFormulaById(formulaId);
    }
  };
  return <>
      {showModal && <CustomVariableTimeStepModal close={() => toggleModal(false)} setSelectedValue={setSelectedValue} updateEditorContent={updateEditorContent} match={match} />}

      <Tooltip id="variable-tooltip" place="top" positionStrategy="fixed" noArrow data-sentry-element="Tooltip" data-sentry-source-file="DropdownWidget.tsx" />

      <div className={styles.dropdownWidget}>
        <span onClick={handleClick} className={`${styles.tag} ${styles[color]}`} data-tooltip-id="variable-tooltip" data-tooltip-content={formulaId ? translate('ctrl_click_message') : ''} data-tooltip-place="top">
          {content}
        </span>
        <Popup trigger={<Button className={styles.select}>
              {selectedValue}
              <MdOutlineKeyboardArrowDown size={20} color={colors.slateGray} />
            </Button>} position="top left" arrow={false} contentStyle={popupContentStyle} data-sentry-element="Popup" data-sentry-source-file="DropdownWidget.tsx">
          {
        // @ts-ignore
        (close: any) => <ul className="border-none shadow-none">
                {listBoxOptions.map(option =>
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
          <li className={option.className} key={option.id} onClick={() => {
            option.onClick();
            close();
          }}>
                    {option.id}
                  </li>)}
              </ul>}
        </Popup>
      </div>
    </>;
};
export default VariableWidget;