import styles from '../genericToggle.module.scss';
import type { Option } from './ToggleOptions';
import ToggleOption from './ToggleOptions';
const OptionList = ({
  options
}: {
  options: Option[];
}) => {
  return <ul className={styles.optionsList} data-sentry-component="OptionList" data-sentry-source-file="OptionList.tsx">
      {options.map((option: any) => {
      return <ToggleOption {...option} id={option.name.toLowerCase().replace(/\s/g, '')} items={options} key={option.name} />;
    })}
    </ul>;
};
export default OptionList;