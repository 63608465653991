import React from 'react';
import { LuPencil } from 'react-icons/lu';
import Popup from 'reactjs-popup';
import colors from '@/styles/scss/abstracts/_variables.module.scss';
import styles from '@/styles/table.module.scss';
import ChangeTypeOptions from './FormulaOptions/ChangeTypeOptions';
import SubOptionsMenuItem from './FormulaOptions/SubOptionsMenuItem';
const ModelsOptionsMenuPopup = ({
  buttonsRef,
  children,
  row
}: {
  buttonsRef: any;
  children: any;
  row?: any;
}) => {
  const popupContentStyle = {
    width: 'auto',
    padding: 0,
    borderRadius: 16,
    border: 'none',
    boxShadow: `0px 4px 24px 0px ${colors.formulaOptionShadow}`,
    marginLeft: 10,
    overflow: 'hidden'
  };
  const isOutputCell = !!row.original.output_id && row.parentId?.split('-')[0] === 'Outputs';
  return <Popup trigger={<span className={styles.hoverable}>
          <LuPencil size={18} color={colors.midBlue} />
        </span>} arrow={false} position={['right top', 'right bottom']} onOpen={() => {
    buttonsRef.current.classList.add(styles.unHoverable);
  }} onClose={() => {
    buttonsRef.current?.classList.remove(styles.unHoverable);
  }} contentStyle={popupContentStyle} nested repositionOnResize data-sentry-element="Popup" data-sentry-component="ModelsOptionsMenuPopup" data-sentry-source-file="ModelsOptionsMenuPopup.tsx">
      {/* 
       Explanation: 
       We need to clone the children component instead of just
       passing the close handler directly to avoid mutating the original
       children passed in.
       The 'close' parameter is a callback function that will close the popup.
       It needs to be passed to the wrapped component that renders the popup so that component can call it to close itself.
             By cloning the element and adding the close prop, we can enhance the 
       child component's behavior without changing the component itself. This
       avoids coupling components and keeps them reusable. 
       */}

      {/* @ts-ignore */
    close => {
      const closePopup = () => {
        close();
      };
      return <>
              {!isOutputCell &&
        // Check if the row's group is 'Inputs'
        <ChangeTypeOptions row={row} closePopup={closePopup} />}
              {React.cloneElement(children, {
          close
        })}
              {!isOutputCell &&
        // Check if the row's group is not 'Outputs'
        <SubOptionsMenuItem row={row} close={close} />}
            </>;
    }}
    </Popup>;
};
export default ModelsOptionsMenuPopup;