// need to update file for fetching graphs from dashboard
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';

// This is often used for shallow comparison
import ApiUtils from '@/api/ApiUtils';
import { QUERYKEYS } from '@/miscellaneous/constant/reactQueryKeyConfig';
import type { ZustandState } from '@/miscellaneous/store/zustand_store';
import useZustandStore from '@/miscellaneous/store/zustand_store';

/**
 * Custom hook for fetching and managing graphs based on the active dashboard and branch.
 *
 * @returns {Object} An object containing information about the graphs.
 * @property {Array} allGraphs - All graphs returned from the API.
 * @property {Array} activeGraphs - The list of filtered graphs according to the active dashboard.
 * @property {Array} branchGraphs - The list of filtered graphs according to the active branch.
 * @property {boolean} isLoading - A boolean indicating whether the data is currently being loaded.
 * @property {Error|null} error - The error object if the data fetching encounters an error, otherwise null.
 */

const useGraphs = () => {
  const [branchGraphs, setBranchGraphs] = useState([]);

  const {
    activeCompanyId,
    activeBranchId,
    activeDashboardId,
    activeGraphs,
    setActiveGraphs,
    setBranchGraphs: setBranchGraphsStore,
  } = useZustandStore(
    useShallow((state: ZustandState) => ({
      activeCompanyId: state.activeCompany?.id ?? null,
      activeBranchId: state.activeBranch?.id ?? null,
      activeDashboardId: state.activeDashboard?.id ?? null,
      activeGraphs: state.activeGraphs,
      setActiveGraphs: state.setActiveGraphs,
      setBranchGraphs: state.setBranchGraphs,
    })),
  );

  /**
   * UseQueryResult for fetching graphs by dashboard ID.
   * @type {UseQueryResult}
   */
  const {
    data: allGraphs,
    isLoading,
    error,
  } = useQuery({
    queryKey: [QUERYKEYS.GRAPHSBYCOMPANYID, activeCompanyId],
    queryFn: () => ApiUtils.getGraphsByCompanyId(0, activeCompanyId as number),
    enabled: !!activeCompanyId,
  });

  useEffect(() => {
    // handle branch graphs
    if (allGraphs?.length > 0 && activeBranchId && !isLoading) {
      const newBranchGraphs = allGraphs?.filter(
        (graph: any) => graph?.graph_data?.branch_id === activeBranchId,
      );
      setBranchGraphs(newBranchGraphs);
      setBranchGraphsStore(newBranchGraphs);
    }
  }, [allGraphs, activeBranchId, isLoading]);

  useEffect(() => {
    // handle active dashboard graphs
    if (activeDashboardId) {
      const filterActiveGraphs = branchGraphs?.filter(
        (graph: any) => graph?.graph_data?.dashboard_id === activeDashboardId,
      );
      setActiveGraphs(filterActiveGraphs);
    }
  }, [activeDashboardId, branchGraphs]);

  return {
    allGraphs, // all graphs returned from API
    activeGraphs, // [the list of filtered graphs according to active dashboard]
    isLoading,
    error,
  };
};

export default useGraphs;
