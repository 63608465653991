import { isValidNumber } from '@/components/modelCalculations/modelUtils/modelUtils';
import variables from '@/styles/scss/abstracts/_variables.module.scss';

export const getSidebarCellContentStyle = (
  columnSpec: any,
  hasSubRows: boolean,
  isExpanded: boolean,
  depth: number,
  getLeftPadding: (depth: number) => number,
) => {
  return (
    columnSpec.style.paddingLeft && {
      paddingTop: hasSubRows && isExpanded && depth === 0 ? 14.5 : 0,
      paddingBottom: hasSubRows && isExpanded && depth === 0 ? 12 : 0,
      paddingLeft: getLeftPadding(depth),
      paddingRight: 0,
    }
  );
};

export const getSidebarCellContainerStyle = (
  columnSpec: any,
  curRowDepth: number,
  getLeftPadding: (depth: number) => number,
  backgroundColor: string,
) => {
  return {
    ...columnSpec.style,
    margin: 'auto 0',
    paddingLeft: columnSpec.style.paddingLeft
      ? columnSpec.style.paddingLeft
      : getLeftPadding(curRowDepth),
    height: variables.cellHeight,
    maxHeight: variables.cellHeight,
    minHeight: variables.cellHeight,
    alignItems: 'center',
    backgroundColor,
  };
};

export const getDepth = (depth: number) => {
  const precentage = 0.0833;
  const leftPadding = depth * precentage * 280 - 10;
  return isValidNumber(leftPadding) ? leftPadding : 0;
};

export const getSidebarDropdownStyle = (row: any, styles: any) => {
  return row.getIsExpanded() ? styles.expanded : '';
};

export const getSideBarCellDivStyle = (curRowDepth: number, styles: any) => {
  return curRowDepth > 0 ? styles.sideBarCellChild : styles.sideBarCell;
};
