/* eslint-disable no-continue */
import { formatDistanceToNow, getQuarter, getYear } from 'date-fns';
import moment from 'moment';
import type { unitOfTime } from 'moment-timezone';
import momentTimeZone from 'moment-timezone';

import { GlobalRegex } from './regex';

function getUTCTimeStamp(localDate: Date): number {
  if (
    localDate.getDate() === 1 &&
    localDate.getHours() === 0 &&
    localDate.getMinutes() === 0 &&
    localDate.getSeconds() === 0
  ) {
    return localDate.getTime();
  }

  // Get the timezone offset in minutes (negative if the local timezone is behind UTC)
  const timezoneOffset = localDate.getTimezoneOffset();

  // Adjust the date by subtracting the timezone offset (convert minutes to milliseconds)
  return localDate.getTime() + timezoneOffset * 60 * 1000;
}

/**
 * This function parses a date object to a UTC date object. if given null or undefined, it will return the first of the month UTC date.
 * @param input MomentInput type ususall
 * @returns Date in UTC
 */
export function parseUTCDateObject(input?: any): Date {
  if (!input) {
    // return first of the month UTC date with momentjs
    return moment().startOf('month').utc().toDate();
  }
  if (input instanceof Date) {
    return new Date(getUTCTimeStamp(input));
  }
  return moment(input).utc().toDate();
}

export const globalDateStringFormat = 'YYYY-MM-DD';

/**
 * This function returns the format of the date in the format of 'YYYY-MM-DD' for entire system using momentjs.
 * @param date
 * @returns
 */
export function getDateStringFormat(date: Date, formatStr?: string): string {
  return moment(date).format(formatStr || globalDateStringFormat);
}

type ConvertDateToISOType = (dateValue: Date) => string | undefined;

// Convert date to ISO format
const convertDateToISO: ConvertDateToISOType = (dateValue: Date) => {
  if (!dateValue) return;
  const firstDayOfMonth = moment(dateValue).date(1).utc().toDate();

  return firstDayOfMonth.toISOString().split('T')[0];
};
export const isValidDate = (d: any) =>
  d instanceof Date && !Number.isNaN(d?.getTime());

/**
 * Converts a Date object to a string in YYYY-MM-DD format.
 *
 * @param date - The Date object to convert
 * @returns The date string in YYYY-MM-DD format
 */
const changeDateToString = (date: Date) => {
  const d = parseUTCDateObject(date);
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return [year, month, day].join('-');
};

// Format date to month and year result: 'Jan 2021'
const dateFormatter = (date: Date, isShortyYear = false) => {
  if (!date) return;
  return date.toLocaleDateString('en-US', {
    month: 'short',
    year: isShortyYear ? '2-digit' : 'numeric',
  });
};

// create column date formatter for Jan '23
export const columnDateFormatter = (date: Date) => {
  if (!date) return '';
  const month = date.toLocaleDateString('en-US', { month: 'short' }); // Extracts the month in the short format e.g., Jan
  const year = date.toLocaleDateString('en-US', { year: '2-digit' }); // Extracts the last two digits of the year e.g., 23
  return `${month} '${year}`; // Combines them in the desired format
};

/**
 * Validates a date for navigation buttons.
 * Checks if navigating to prev/next year is valid based on fromDate and toDate.
 * @param date - The date to validate
 * @param type - Either 'from' or 'to'
 * @param action - Either 'prev' or 'next'
 * @returns Whether navigation to prev/next year is valid
 */
const validateDateF = (
  date: Date,
  type: 'from' | 'to',
  action: 'prev' | 'next',
  toDate: Date,
  fromDate: Date,
) => {
  const dateYear = date.getFullYear();

  if (type === 'from') {
    if (!toDate) return true;
    const toYear = (toDate as Date).getFullYear();
    if (action === 'next') {
      if (dateYear >= toYear) {
        return false;
      }
    }
    return true;
  }

  if (type === 'to') {
    if (!fromDate) return true;
    const fromYear = (fromDate as Date).getFullYear();
    if (action === 'prev') {
      if (dateYear <= fromYear) {
        return false;
      }
    }
    return true;
  }

  return false;
};

export function getDatesBetween(startDate: Date, endDate: Date) {
  const dates = [];
  const currentDate = parseUTCDateObject(startDate);
  // convert current date day to 1st day of the month
  currentDate.setDate(1);

  while (currentDate <= endDate) {
    dates.push(parseUTCDateObject(currentDate));
    currentDate.setMonth(currentDate.getMonth() + 1);
  }

  return dates;
}

/**
 * Formats a given date to a string representing the month (short form) and year (numeric).
 *
 * @param {Date} dateString - The input date to be formatted.
 *
 * @returns {string} The formatted date string (e.g., "Jan 2022").
 */
export function formatDateToMonthAndYearShort(dateString: Date) {
  const date = parseUTCDateObject(dateString);
  const options = { month: 'short', year: '2-digit' }; // Using '2-digit' for abbreviated year
  const formattedDate = new Intl.DateTimeFormat('en-US', options as any).format(
    date,
  );
  return formattedDate;
}

/**
 * Generates an array of dates based on the provided date.
 *
 * @param date - The date to generate the dates array from.
 * @param step - The number of months to step between each date in the array.
 * @param isFullDate - Whether to generate a full date range or just the first day of each month.
 * @returns An array of dates based on the provided date.
 */
export const generateDatesArray = (
  date: Date,
  startDate: string,
  endDate: string,
  step: number = 0,
  isFullDate: boolean = false,
) => {
  const datesArray = [];

  if (isFullDate) {
    const sDate = parseUTCDateObject(startDate); // convert start Date string to Date object
    const eDate = parseUTCDateObject(endDate); // convert end Date string to Date object

    for (let i = sDate; i <= eDate; i.setDate(i.getDate() + 1)) {
      datesArray.push(
        changeDateToString(moment(i).startOf('day').utc().toDate()),
      );
    }

    return datesArray;
  }

  for (let i = step; i <= parseUTCDateObject(date).getMonth(); i += 1) {
    datesArray.push(
      `${parseUTCDateObject(date).getFullYear()}-${(i + 1)
        .toString()
        .padStart(2, '0')}-01`,
    );
  }

  return datesArray;
};

/**
 * Generates an array of dates based on the provided date string.
 *
 * @param date - The date string to generate the dates array from.
 * @returns An array of dates based on the provided date string.
 */
export const getRevertedDatesFromColumns = (
  date: string,
  startDate: string,
  endDate: string,
) => {
  if (date?.startsWith('total')) {
    // Return ColumnType.Year for 'total-yyyy' format
    const parseYear = +(date.split('-')[1] as string);

    return generateDatesArray(
      moment().year(parseYear).month(11).date(31).utc().toDate(),
      startDate,
      endDate,
    );
  }
  if (date?.startsWith('q')) {
    // Return ColumnType.Quarter for 'qN-yyyy' format
    const parseQuarter = +(date.split('-')[0]?.split('q')[1] as string);
    const parseYear = +(date.split('-')[1] as string);
    return generateDatesArray(
      moment()
        .year(+parseYear)
        .month(parseQuarter * 3 - 1)
        .date(1)
        .utc()
        .toDate(),
      startDate,
      endDate,
      // 1,2,3,4 => 0,3,6,9
      (parseQuarter - 1) * 3,
    );
  }
  if (date?.includes('-')) {
    // Return ColumnType.Month for 'yyyy-mm-dd' format
    return [date];
  }
  // Return ColumnType.Total for other cases
  return generateDatesArray(
    parseUTCDateObject(date),
    startDate,
    endDate,
    0,
    true,
  );
};

export { changeDateToString, convertDateToISO, dateFormatter, validateDateF };

export function getIsoString(currentDate: Date) {
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // +1 because getMonth() returns 0-11
  const isoDate = `${year}-${month}-01`;
  return isoDate;
}

/**
 *   takes string of date yyyy-mm-dd and returns same date string of one month back.
 */
export function lastMonthKey(date: string) {
  const { dateRegex } = GlobalRegex;
  if (date.match(dateRegex) === null) return date; // if wrong format return date of this regex
  const newDate = parseUTCDateObject(date);
  newDate.setMonth(newDate.getMonth() - 1);
  return getIsoString(newDate);
}

/**
 * Generate an array of ISO-formatted dates between a start and end date.
 *
 * @param {Date} startDate - The start date.
 * @param {Date} endDate - The end date.
 * @returns {string[]} An array of ISO-formatted dates between the start and end date.
 */
export function generateMonthlyDates(
  startDate: Date | string,
  endDate: Date | string,
) {
  const dates = [];
  const currentDate = parseUTCDateObject(startDate);

  // Ensure we start at the first of the month
  currentDate.setDate(1);
  while (currentDate <= endDate) {
    // Manually construct the ISO date string from local date components
    const isoDate = getIsoString(currentDate);
    dates.push(isoDate);
    // Increment the month
    currentDate.setMonth(currentDate.getMonth() + 1);
  }

  return dates;
}

export function generateUniqueMonthlyDatesByModels(
  modelIDToDateMap: Record<number, { startDate: Date; endDate: Date }>,
): string[] {
  const uniqueDates = new Set<string>();

  Object.values(modelIDToDateMap).forEach(({ startDate, endDate }) => {
    const currentDate = parseUTCDateObject(startDate);

    // Ensure we start at the first of the month
    currentDate.setDate(1);
    while (currentDate <= endDate) {
      // Manually construct the ISO date string from local date components
      const isoDate = getIsoString(currentDate);
      uniqueDates.add(isoDate);
      // Increment the month
      currentDate.setMonth(currentDate.getMonth() + 1);
    }
  });
  const sortedDates = Array.from(uniqueDates).sort();
  return sortedDates;
}

export interface DateSettings {
  showYears: boolean;
  showMonths: boolean;
  showQuarters: boolean;
  showTotal: boolean;
}
/**
 * Generates an array of date string columns covering the given date range at
 * the specified granularity.
 *
 * @param startDate - The start date of the range
 * @param endDate - The end date of the range
 * @param settings - Configuration options for which date parts to include
 * @returns An array of date string columns in YYYY-MM-DD or quarter/year format
 */
export function generateDateColumnsFromList(
  dateList: string[],
  settings: DateSettings,
) {
  const generatedDates: string[] = [];
  for (const date of dateList) {
    const [year, month] = date.split('-').map(Number);
    if (!year || Number.isNaN(year) || !month || Number.isNaN(month)) {
      console.error(`Invalid date format: ${date}`);
      continue;
    }

    const quarter = Math.floor((month - 1) / 3) + 1;

    // Add month
    if (settings.showMonths) {
      generatedDates.push(`${year}-${month.toString().padStart(2, '0')}-01`);
    }

    // Check and add quarter
    if (settings.showQuarters && month % 3 === 0) {
      generatedDates.push(`q${quarter}-${year}`);
    }

    // Add year-end total
    if (month === 12 && settings.showYears) {
      generatedDates.push(`total-${year}`);
    }
  }

  // Conditionally add the 'total' marker based on showTotal setting
  if (settings.showTotal) {
    generatedDates.push('Total');
  }

  return generatedDates;
}

export function getMonthDateFromIsoString(isoString: string): {
  month: number;
  year: number;
} {
  // without converting to date and with error handeling
  const [year, month] = isoString.split('-').map(Number);
  if (Number.isNaN(year) || !year || Number.isNaN(month) || !month) {
    return { month: 0, year: 0 };
  }
  return { month, year };
}

const shortMonthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export function getShortMonthName(month: number): string {
  if (month < 1 || month > 12) {
    console.error('Invalid month value:', month);
    return '';
  }
  return shortMonthNames[month - 1] || '';
}

// get Aug 2024 from string yyyy-mm-dd using getShortMonthName
export function getMonthYearFromIsoString(isoString: string) {
  const [year, month] = isoString.split('-').map(Number);
  if (Number.isNaN(year) || !year || Number.isNaN(month) || !month) {
    return '';
  }
  const shortMonthName = getShortMonthName(month);
  return `${shortMonthName} ${year}`;
}

export function generateDateColumns(
  startDate: any,
  endDate: any,
  settings: DateSettings,
) {
  const start = parseUTCDateObject(startDate);
  const end = parseUTCDateObject(endDate);
  const dateList = [];
  const current = parseUTCDateObject(start);

  while (current <= end) {
    const year = current.getFullYear();
    const month = current.getMonth() + 1; // JavaScript months are 0-based.
    const quarter = Math.floor((month - 1) / 3) + 1;

    // Add month
    if (settings.showMonths) {
      dateList.push(`${year}-${month.toString().padStart(2, '0')}-01`);
    }

    // Check and add quarter
    if (settings.showQuarters && month % 3 === 0) {
      dateList.push(`q${quarter}-${year}`);
    }

    // Move to the next month
    current.setMonth(current.getMonth() + 1);

    // If it's December, add the total for the year after the loop
    // We only add the year's total if we actually reach or pass December in our range
    if (month === 12 && settings.showYears) {
      dateList.push(`total-${year}`);
    }
  }

  // Conditionally add the 'total' marker based on showTotal setting
  if (settings.showTotal) {
    dateList.push('Total');
  }

  return dateList;
}

export const dateToString = (timestamp: string) => {
  const date = parseUTCDateObject(timestamp);
  return getDateStringFormat(date);
}; /**
 * Formats a given date to display the initial three letters of the month
 * followed by the last two characters of the year.
 *
 * @param {Date} dateString - The input date string or Date object.
 * @returns {string} The formatted date with the initial three letters of the month
 *                  and the last two characters of the year.
 */

export function formatDateToMonthAndYearAbbreviation(dateString: Date) {
  const date = parseUTCDateObject(dateString);
  const options = { month: 'short', year: '2-digit' };
  // check date is valid with Number.isNaN
  if (Number.isNaN(date.getTime())) {
    console.error('Invalid date:', dateString);
    return '';
  }
  const formattedDate = new Intl.DateTimeFormat(
    'en-US',
    options as any,
  )?.format(date);
  return formattedDate;
} /**
 * Format a date as "MM/YY".
 *
 * @param {Date} date - The input date to format.
 * @returns {string} The formatted date in "MM/YY" format.
 */

export const formatDate = (date: Date) => {
  /**
   * This function formats a given `date` as a string in the "MM/YY" format (e.g., "10/23").
   *
   * @param {Date} date - The input date to format.
   * @returns {string} The formatted date in "MM/YY" format.
   */
  const formattedDate = date
    .toLocaleDateString('en-US', { month: '2-digit', year: '2-digit' })
    .replace(/\//g, '/');
  return formattedDate;
};
/**
 * Formats a given date to display the initial three letters of the month.
 *
 * @param {Date} dateString - The input date string or Date object.
 * @returns {string} The formatted date with the initial three letters of the month.
 */

export function formatDateToMonthAbbreviation(dateString: Date) {
  const date = parseUTCDateObject(dateString);
  const options = { month: 'short' }; // Specify 'short' for abbreviated month name
  const monthAbbreviation = date.toLocaleString('en-US', options as any);
  return monthAbbreviation;
}

// recieves a dates string in the format yyyy-mm-dd and return the keys for that date of total, quarter and date.
export function getColumnKeysForDate(date: string) {
  const utcDate = parseUTCDateObject(date);
  const dateISOString = getDateStringFormat(utcDate);
  const year = getYear(utcDate);
  const quarter = getQuarter(utcDate);
  const isoDateKey = dateISOString;
  const quarterKey = `q${quarter}-${year}`;
  const totalYearKey = `total-${year}`;
  return { isoDateKey, quarterKey, totalYearKey };
}

export function getPreviousQuarterKey(date: string) {
  const dateISOString = getIsoString(parseUTCDateObject(date));
  const year = getYear(dateISOString);
  const quarter = getQuarter(dateISOString);
  const previousQuarter = quarter - 1;
  const previousYear = year;
  const previousQuarterKey = `q${previousQuarter}-${previousYear}`;
  return previousQuarterKey;
}

export function getPreviousYearKey(date: string) {
  const dateISOString = getIsoString(parseUTCDateObject(date));
  const year = getYear(dateISOString);
  const previousYear = year - 1;
  const previousYearKey = `total-${previousYear}`;
  return previousYearKey;
}

export function getNextMonthKey(date: string) {
  const curDate = parseUTCDateObject(date);
  curDate.setMonth(curDate.getMonth() + 1);
  return getIsoString(curDate);
}

/**
 * Calculates the time ago from a given UTC date and returns it as a string.
 *
 * @param {Date} date - The input UTC date.
 * @returns {string} The time ago from the given UTC date.
 */
export const getTimeAgoFromUTCDate = (date: Date): string => {
  const utcMoment = moment.utc(moment(date).format());

  const localTimezone = momentTimeZone.tz.guess();

  const localTime = utcMoment.tz(localTimezone);

  // @ts-ignore
  // eslint-disable-next-line no-underscore-dangle
  return formatDistanceToNow(moment(localTime._d).format(), {
    addSuffix: true,
  });
};

export function getUTCDate(dateString: string): Date {
  const now = parseUTCDateObject(dateString);
  const nowUTC = parseUTCDateObject(now.toUTCString().slice(0, -4));
  return nowUTC;
}

interface DateAdjustment {
  value: number;
  unit: unitOfTime.DurationConstructor; // 'years', 'months', 'days', etc.
}

interface GenerateUTCDateOptions {
  year?: number; // Optional year to set
  month?: number; // Optional month to set (0-indexed)
  day?: number; // Optional day to set
  adjustment?: DateAdjustment; // Optional adjustment to add/subtract time
  operations?: {
    method: 'startOf' | 'endOf'; // Operation to apply (e.g., startOf, endOf)
    unit: unitOfTime.StartOf; // Unit for the operation (e.g., 'year', 'month', 'day')
  }[]; // Optional list of operations to apply in sequence
}

/**
 * Generate a UTC date with default start of month and day,
 * with configurable year, month, day, adjustments, and operations.
 *
 * @param {GenerateUTCDateOptions} options - Options for configuring the date.
 * @returns {Date} - The resulting UTC date.
 */
export const createAdjustedUTCDate = (
  options: GenerateUTCDateOptions = {},
): Date => {
  let date = moment.utc();

  // Apply specific year and month first
  if (options.year !== undefined) date = date.year(options.year);
  if (options.month !== undefined) date = date.month(options.month);

  // Apply specific day or default to start of month
  if (options.day !== undefined) {
    date = date.date(options.day).startOf('day');
  } else {
    date = date.startOf('month');
  }

  // Apply operations in sequence
  if (options.operations) {
    for (const operation of options.operations) {
      const { method, unit } = operation;
      date = method === 'startOf' ? date.startOf(unit) : date.endOf(unit);
    }
  }

  // Apply any adjustments
  if (options.adjustment) {
    const { value, unit } = options.adjustment;
    date = date.add(value, unit);
  }

  return parseUTCDateObject(date.toDate());
};

/** Formula date handeling */
/**
 * Cuts out the Date string {DATE}{mm,yyyy} to extract (mm,yyyy) and returns the ISO string
 * @param date Converts the date string to ISO string
 * @returns date iso string, if invalid returns todays iso string
 */
export const getISOStringFromFormulaDateValue = (date: string) => {
  const splitStr = date.match(/\(([^)]+)\)/)?.[1]; // Cuts out the Date string {DATE}{mm,yyyy}
  // currently string is in format "mm,yyyy" convert it to yyyy/mm/dd
  const monthYear = splitStr?.split(',') ?? [];
  if (!monthYear || monthYear.length !== 2) {
    return getDateStringFormat(parseUTCDateObject());
  }
  const dateObj = parseUTCDateObject(`${monthYear[1]}/${monthYear[0]}/01`);
  if (dateObj.toString() === 'Invalid Date') {
    return getDateStringFormat(parseUTCDateObject());
  }
  return getDateStringFormat(dateObj);
};

// takes the formula string {DATE}{mm,yyyy}  and returns the date in MMM yy'format Apr 21'
export const getFormattedDateFromformulaString = (date: string) => {
  try {
    const isoStringDate = getISOStringFromFormulaDateValue(date);
    const dateObj = parseUTCDateObject(isoStringDate);
    return dateObj.toLocaleString('default', {
      month: 'short',
      year: '2-digit',
    });
  } catch (e) {
    return '';
  }
};
