export const fundingStagesData = [
  '$0',
  '$100K',
  '$200K',
  '$300K',
  '$400K',
  '$500K',
  '$600K',
  '$700K',
  '$800K',
  '$900K',
  '$1M',
  '$1.5M',
  '$2M',
  '$2.5M',
  '$3M',
  '$3.5M',
  '$4M',
  '$4.5M',
  '$5M',
  '$6M',
  '$7M',
  '$8M',
  '$9M',
  '$10M',
  '$11M',
  '$12M',
  '$13M',
  '$14M',
  '$15M',
  '$16M',
  '$17M',
  '$18M',
  '$19M',
  '$20M',
  '$21M',
  '$22M',
  '$23M',
  '$24M',
  '$25M',
  '$26M',
  '$27M',
  '$28M',
  '$29M',
  '$30M',
];
