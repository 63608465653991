import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { GoGrabber } from 'react-icons/go';
import Switch from '@/components/common/Switch';
import styles from '../genericToggle.module.scss';

// Represents a single toggle option with a label.
export type Option = {
  name: string;
  enabled: boolean;
  onSelectionChange: (isSelected: boolean) => void;
  isDraggable?: boolean;
};
interface ToggleOptionProps extends Option {
  id: any;
  items: any;
}

// Single toggle option component with a switch and a label.
const ToggleOption: React.FC<ToggleOptionProps> = ({
  name,
  enabled,
  onSelectionChange,
  id,
  items,
  isDraggable = false
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({
    id
  });
  const enabledItems = items.filter((item: any) => item.enabled);
  const isDisabled = enabledItems.length === 1 &&
  // if its the last item do not enable toggeling it off
  enabledItems[0].name === name && items.length > 1; // dont disable if its a single item

  /**
   * Defines inline styles for the sortable item element.
   * Applies transform and transition for drag animations.
   * Sets box shadow and position when dragging.
   */
  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition || 'transform 500ms ease',
    boxShadow: isDragging ? '0 0 0 2px #e5e5e5' : '',
    positon: isDragging ? 'absolute' : ''
  };
  const dynamicGrabber = (isDraggable: boolean, enabled: boolean) => {
    return enabled && isDraggable && <span {...attributes} {...listeners} ref={setActivatorNodeRef}>
          <GoGrabber className={styles.grabIcon} size={26} fontWeight="bold" />
        </span>;
  };
  return <li className={styles.toggleOption} ref={setNodeRef} style={style} data-sentry-component="ToggleOption" data-sentry-source-file="ToggleOptions.tsx">
      <div>
        <Switch id={`toggleMenuSwitch-${name}`} defaultSelected={enabled} onSelectionChange={onSelectionChange} isDisabled={isDisabled} data-sentry-element="Switch" data-sentry-source-file="ToggleOptions.tsx" />
        <span className={styles.toggleLabel}>{name}</span>
      </div>

      {/*
       * Conditionally renders a drag handle icon if: 
       * - The option name is not 'Inactive Employees' 
       * - The option is enabled * - Renders the icon
       wrapped in a span with sortable handlers and refs 
       */}
      {dynamicGrabber(isDraggable, enabled)}
    </li>;
};
export default ToggleOption;