import { convertToKAndM } from '@/utils/ChartUtils';
import type { ChartOptionsType } from '@/utils/types/DashboardAndChartsTypes';

import styles from '../../../components/Graphs/graphs.module.scss';

export const areaChartOptions = (
  { minValue, maxValue }: ChartOptionsType,
  signForGraph: string,
) => {
  return {
    stroke: {
      show: true,
      curve: 'straight',
      colors: ['#6877FF'],
      dashArray: 0,
      width: 3,
    },
    fill: {
      type: 'solid',
      colors: ['#B8C7FF'],
    },
    yaxis: {
      min: minValue,
      max: maxValue,
      tickAmount: 5,
      labels: {
        maxWidth: 160,
        align: 'left',
        formatter(value: number) {
          return convertToKAndM(value, signForGraph);
        },
        style: {
          padding: '10px',
          fontFamily: styles.fontFamily,
          fontSize: '14px',
          fontWeight: '400',
        },
      },
    },
  };
};
