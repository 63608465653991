import { Copy, Ellipsis } from 'lucide-react';
import React from 'react';
import { FaPlus } from 'react-icons/fa6';
import { MdArrowDropDown } from 'react-icons/md';
import { Tooltip } from 'react-tooltip';
import Popup from 'reactjs-popup';
import OptionsMenu from '@/components/common/OptionsMenu/OptionsMenu';
import styles from '@/components/table/shared/table/GeneralTable/styles/tableSidebarStyles.module.scss';
import type { ZustandState } from '@/miscellaneous/store/zustand_store';
import useZustandStore from '@/miscellaneous/store/zustand_store';
import type { FinancialsSidebarCellProps } from '../../financials/financialsSideBarCells';
import FinancialsSidebarCell from '../../financials/financialsSideBarCells';
import type { ModelsSidebarCellProps } from '../../models/modelsSideBarCells';
// eslint-disable-next-line import/no-cycle
import ModelsSidebarCell from '../../models/modelsSideBarCells';
import { SidebarCellType } from '../types';

/**
 * Renders a Popup component with options menu content.
 *
 * The Popup is triggered by a button and takes buttonsRef and children props.
 *
 * On open, it adds an unHoverable class to buttonsRef to prevent hovering.
 * On close, it removes the unHoverable class.
 *
 * The content is a cloned version of the children prop with the close handler passed in.
 */
export const OptionsMenuPopup = ({
  buttonsRef,
  children
}: {
  buttonsRef: any;
  children: any;
}) => {
  return <Popup trigger={<button type="button" className={styles.hoverable} aria-label="Ellipsis">
          <Ellipsis size={18} />
        </button>} arrow={false} position={['bottom left', 'top left']} onOpen={() => {
    buttonsRef.current.classList.add(styles.unHoverable);
  }} onClose={() => {
    buttonsRef.current.classList.remove(styles.unHoverable);
  }} repositionOnResize data-sentry-element="Popup" data-sentry-component="OptionsMenuPopup" data-sentry-source-file="sidebarCells.tsx">
      {/* 
       Explanation: 
       We need to clone the children component instead of just
       passing the close handler directly to avoid mutating the original
       children passed in.
       The 'close' parameter is a callback function that will close the popup.
       It needs to be passed to the wrapped component that renders the popup so that component can call it to close itself.
             By cloning the element and adding the close prop, we can enhance the 
       child component's behavior without changing the component itself. This
       avoids coupling components and keeps them reusable. 
       */}
      {/* @ts-ignore */
    close => React.cloneElement(children, {
      close
    })}
    </Popup>;
};
interface TableSidebarCellProps {
  row: any;
  columnSpec: any;
  cell: any;
}

/**
 * Renders a sidebar cell.
 *
 * Conditionally applies styling and renders subrow controls based on:
 * - Row depth
 * - Whether row has subrows
 * - Column spec paddingLeft style
 *
 * Renders copy button or options menu for rows without subrows.
 * Renders toggle and add buttons for rows with subrows.
 */
const TableSidebarCell: React.FC<TableSidebarCellProps> = ({
  row,
  columnSpec,
  cell
}: TableSidebarCellProps) => {
  const buttonsRef = React.useRef(null);
  /**
   * Calculates padding left based on row depth,
   * and conditionally sets sidebar cell div style
   * based on calculated padding.
   *
   * If row depth > 0, sets div style to sideBarCellChild.
   * Otherwise sets div style to sideBarCell.
   */
  const paddingLeft = 24 * row.depth + (columnSpec.style.paddingLeft ? 0 : 24);
  /**
   * Conditionally sets the sidebar cell div style
   * based on the row depth.
   * If the row depth is greater than 0, it sets the style
   * to sideBarCellChild for indented styling.
   * Otherwise it sets the style to sideBarCell.
   */
  const sideBarCellDivStyle = row.depth > 0 ? styles.sideBarCellChild : styles.sideBarCell;

  /**
   * Checks if the row has subrows.
   */
  const hasSubRows = row.subRows && row.subRows.length > 0;
  /**
   * Conditionally sets the font style for the sidebar cell
   * based on the row depth and whether it has subrows.
   *
   * For depth 0, use black font.
   * For depth > 0 and hasSubRows, use bold font.
   * Otherwise no custom font style.
   */
  const sidebarCellFontStyle = row.depth === 0 ? styles.black : hasSubRows || row.depth === 1 ? styles.bold : '';

  /**
   * Conditionally applies sidebar cell hover styling based on row depth.
   * Rows with depth >= 1 get the hover styling.
   */
  const sidebarCellHoverStyle = row.depth >= 1 ? styles.actions : '';
  /**
   * Conditionally applies the 'expanded' style if the row is expanded.
   * This allows the dropdown icon to rotate when expanded.
   */
  const sidebarDropdownStyle = row.getIsExpanded() ? styles.expanded : '';
  /**
   * Styles object for the sidebar cell content based on padding left from column spec,
   * whether the row has subrows, and the row depth. Adjusts padding and indentation.
   */
  const sidebarCellContentStyle = columnSpec.style.paddingLeft && {
    paddingTop: hasSubRows && row.depth > 0 ? 24 : 0,
    paddingBottom: hasSubRows && row.depth > 0 ? 12 : 0,
    paddingLeft: row.depth > 1 && hasSubRows ? paddingLeft : row.depth >= 1 && !hasSubRows ? paddingLeft + 10 : 0
  };

  /**
   * Styles object for the sidebar cell container. Sets padding based on whether
   * column has custom paddingLeft. Defaults to no padding if custom paddingLeft.
   */
  const sidebarCellContainerStyle = {
    ...columnSpec.style,
    padding: columnSpec.style.paddingLeft ? 'none' : '0 24px 0 36px',
    paddingLeft: columnSpec.style.paddingLeft ? columnSpec.style.paddingLeft : paddingLeft,
    backgroundColor: row.original.rowStyle?.backgroundColor
  };
  const {
    isTableDisabled
  } = useZustandStore((state: ZustandState) => state);

  // Conditionally renders the given context based on whether the table is disabled
  const conditionallyRender = (context: any) => {
    return isTableDisabled || columnSpec?.getOptions(row)?.length === 0 ? null : context;
  };

  /**
   * Conditionally renders a button to toggle the expanded state of the row if it has subrows.
   * The button contains a dropdown icon that rotates when expanded. Includes custom styling based on row depth.
   * Only rendered for rows with depth > 0 that have subrows.
   */
  const collapseButtonRenderer = () => {
    return (hasSubRows || row.depth === 1) && row.depth > 0 && <button type="button" aria-label="Toggle Sub Rows" className={`${styles.toggleSubrowsButton} ${sidebarDropdownStyle}`} onClick={() => row.toggleExpanded(!row.getIsExpanded())}>
          <MdArrowDropDown size={22} />
        </button>;
  };

  /**
   * Renders the actions button for the sidebar cell.
   * Conditionally renders a '+' expand button if the row has subrows,
   * otherwise renders the copy and more options buttons.
   */
  const actionsButtonRenderer = () => {
    return hasSubRows || row.depth === 1 ? conditionallyRender(<span className={sidebarCellHoverStyle}>
            <button aria-label="Add Employee" type="button" onClick={() => columnSpec.handlerFunctions.onClick(row)} className={styles.hoverable}>
              <FaPlus size={16} />
            </button>
          </span>) : conditionallyRender(<span ref={buttonsRef} className={`${styles.actions}`}>
            <button type="button" onClick={e => columnSpec.handlerFunctions.onCopyClick(e, row)} className={styles.hoverable} aria-label="Copy">
              <Copy size={18} />
            </button>

            <OptionsMenuPopup buttonsRef={buttonsRef}>
              <OptionsMenu items={columnSpec?.getOptions(row)} />
            </OptionsMenuPopup>
          </span>);
  };

  /**
   * Trims the `full_name` and `role` properties of the current row's original data.
   * The trimmed values are stored in `trimmedName` and `trimmedRole` respectively.
   *
   * @param row - The current row object, which contains the original row data.
   * @returns The trimmed name and role values.
   */
  const trimmedName = (row.original.full_name as string)?.trim();
  const trimmedRole = (row.original.role as string)?.trim();

  /**
   * Generates a sidebar text string based on the trimmed name, trimmed role, and row depth.
   * If the trimmed name and trimmed role exist and the row depth is greater than 0, the sidebar text will be in the format `${trimmedName} | ${row.original.role}`.
   * Otherwise, the sidebar text will be `${trimmedName || trimmedRole || ''}`.
   *
   * @param trimmedName - The trimmed name value.
   * @param trimmedRole - The trimmed role value.
   * @param row - The row object, which contains the original row data.
   * @returns The generated sidebar text string.
   */
  const sidebarText = trimmedName && trimmedRole && row.depth > 0 ? `${trimmedName} | ${row.original.role}` : `${trimmedName || trimmedRole || ''}`;
  return <div className={`${sideBarCellDivStyle}`} style={sidebarCellContainerStyle} data-sentry-component="TableSidebarCell" data-sentry-source-file="sidebarCells.tsx">
      <Tooltip delayShow={1000} id="hiringplan-sidebar-cell-tooltip" data-sentry-element="Tooltip" data-sentry-source-file="sidebarCells.tsx" />
      <p data-tooltip-id="hiringplan-sidebar-cell-tooltip" data-tooltip-content={sidebarText || cell.getValue()} style={sidebarCellContentStyle} className={styles.subheader}>
        {collapseButtonRenderer()}
        <span className={`${sidebarCellFontStyle} ${styles.departmentName}`}>
          {sidebarText || cell.getValue()}
        </span>
      </p>
      {actionsButtonRenderer()}
    </div>;
};

/**
 * Default sidebar cell component that renders the initial value in a div.
 * Accepts initialValue and style props.
 */
const DefaultSidebarCell: React.FC<any> = ({
  initialValue,
  style
}: any) => <div style={{
  ...style
}} data-sentry-component="DefaultSidebarCell" data-sentry-source-file="sidebarCells.tsx">{initialValue}</div>;
interface CellComponentMap {
  [SidebarCellType.HiringPlan]: React.FC<TableSidebarCellProps>;
  [SidebarCellType.FinancialTable]: React.FC<FinancialsSidebarCellProps>;
  [SidebarCellType.ModelsTable]: React.FC<ModelsSidebarCellProps>;
  [SidebarCellType.GenericFlatTable]: React.FC<TableSidebarCellProps>;
}
const cellComponentMap: CellComponentMap = {
  [SidebarCellType.HiringPlan]: TableSidebarCell,
  [SidebarCellType.FinancialTable]: FinancialsSidebarCell,
  [SidebarCellType.ModelsTable]: ModelsSidebarCell,
  [SidebarCellType.GenericFlatTable]: TableSidebarCell
};
export const getSidebarCellByTableType = (type: SidebarCellType): React.FC<any> => {
  return cellComponentMap[type] || DefaultSidebarCell;
};