import useGetUserMe from '@/utils/hooks/user_manager/useGetUserMe';
const DataProvider = () => {
  useGetUserMe();
  // const date = moment('2023-08-01').utc().toDate();
  // const utcDate = moment.utc(date).toDate();
  // const d = new Date('2023-08-01');
  // const parsedDate = parseUTCDateObject(d);
  // const today = parseUTCDateObject();
  // const byValue = parseUTCDateObject(parsedDate.getTime());
  // const parsedDateToString = getDateStringFormat(date);

  // console.log('$$$$$$$$$$$$$$$$$#DataProvider###$$$$$$$$$$$$$$$');
  // console.log('🚀 ~ DataProvider ~ date:', date);
  // console.log('🚀 ~ DataProvider ~ utcDate:', utcDate);
  // console.log('🚀 ~ DataProvider ~ parsedDate:', parsedDate);
  // console.log('🚀 ~ DataProvider ~ byValue:', byValue);
  // console.log('🚀 ~ DataProvider ~ d:', d);
  // console.log('🚀 ~ DataProvider ~ today:', today);
  // console.log('🚀 ~ DataProvider ~ parsedDateToString:', parsedDateToString);

  // console.log('$$$$$$$$$$$$$$$$$#DataProvider###$$$$$$$$$$$$$$$');

  // const d = new Date('2023-08-01');
  // const utcD = new Date(d.toUTCString());
  // const utcDate = new Date(new Date('2023-08-01').toUTCString());
  // const isoDate = new Date(new Date('2023-08-01').toISOString());

  // console.log('🚀 ~ DataProvider ~ d:', d);
  // console.log('🚀 ~ DataProvider ~ utcD:', utcD);
  // console.log('🚀 ~ DataProvider ~ utcDate:', utcDate);
  // console.log('🚀 ~ DataProvider ~ isoDate:', isoDate);

  return null;
};
export { DataProvider };