export const riskScorePrompt = `MAKE SURE YOU ARE CORRECT! THIS IS VERY IMPORTANT TO ME. 
Think about and create a risk score based on my business and hiring plan. 
Consider the identification or risks, their categorization, the assessment of their likelihood and impact, determining vulnerability and mitigation strategies.`;
export const riskRegiserPrompt = `MAKE SURE YOU ARE CORRECT! THIS IS VERY IMPORTANT TO ME. 
Think about and create a risk register based on my business and hiring plan. 
Consider the identification or risks, risk evaluation, risk prioritization, mitigation strategies and response plans, as well as monitoring and review.`;
export const SWOTPrompt = `MAKE SURE YOU ARE CORRECT! THIS IS VERY IMPORTANT TO ME. 
Think about and create a SWOT analysis based on my business and hiring plan. 
Consider the strengths, weaknesses, opportunities and threats, as well as a comparative analysis and future outlook.`;
export const industryTrendsPrompt = `MAKE SURE YOU ARE CORRECT! THIS IS VERY IMPORTANT TO ME. 
Think about and suggest industry trends based on my business and hiring plan. 
Consider the market dynamics, competitive landscape, technological advancements, consumer behavior and preferences and economic factors.`;
export const customerProfilesPrompt = `MAKE SURE YOU ARE CORRECT! THIS IS VERY IMPORTANT TO ME. 
Think about and offer customer profiles based on my business and hiring plan. 
Consider the demographics, psychographic information, buying habits, needs and preferences, channels of engagement and eventually segmentation.`;
export const businessPlansPrompt = `MAKE SURE YOU ARE CORRECT! THIS IS VERY IMPORTANT TO ME. 
Think about and offer a business plan based on my business and hiring plan. 
Consider the scope of the business, the target market and customer segments, the competitive landscape, financial projections and the legal requirements.`;
export const strategicInitiativePrompt = `MAKE SURE YOU ARE CORRECT! THIS IS VERY IMPORTANT TO ME. 
Think about and offer strategic initiatives based on my business and hiring plan. 
Consider the market and industry, the challenges, objectives and available resources.`;
export const goalSettingsPrompt = `MAKE SURE YOU ARE CORRECT! THIS IS VERY IMPORTANT TO ME. 
Think about and offer goals to set based on my business and hiring plan. 
Consider the core vision, current position in the market, and KPIs that are relevant.`;
const runwayIncrease = `Make sure you are correct- THIS IS VERY IMPORTANT TO ME!
You will receive a balance and a hiring plan of a company.
1. Memorize the company's balance from the system prompt.
2. Review and understand the company's hiring plan from the system prompt.
3. Calculate how much runway will be reduced based a 50% addition to the workforce- THINK ABOUT YOUR CALCULATION STEP BY STEP!
4. Your ONLY output will be the runway reduction for the request, the reduction for different scenarios, and your own suggestion regarding it, WHICH SHOULD BE DIVERSE- YOUR LIFE DEPENDS ON IT!
_Input_:
Balance = $1,782,000
Employees = {"positions": [
{"id": 1, "role": "CEO", "yearly_salary": 200000, "bonus": 20000, "department": "G&A", "start_date": "2023-10-01"},
{"id": 2, "role": "CTO", "yearly_salary": 150000, "bonus": 15000, "department": "R&D", "start_date": "2023-10-01"},
{"id": 3, "role": "CFO", "yearly_salary": 120000, "bonus": 12000, "department": "G&A", "start_date": "2023-10-05"},
{"id": 8, "role": "Software Engineer", "yearly_salary": 100000, "bonus": 10000, "department": "R&D", "start_date": "2023-11-10"},
{"id": 11, "role": "Software Engineer", "yearly_salary": 100000, "bonus": 10000, "department": "R&D", "start_date": "2023-11-12"},
{"id": 14, "role": "DevOps Engineer", "yearly_salary": 110000, "bonus": 11000, "department": "R&D", "start_date": "2023-12-02"},
{"id": 19, "role": "DevOps Engineer", "yearly_salary": 110000, "bonus": 11000, "department": "R&D", "start_date": "2023-12-15"},
{"id": 22, "role": "UX/UI Designer", "yearly_salary": 90000, "bonus": 9000, "department": "R&D", "start_date": "2024-01-03"},
{"id": 23, "role": "UX/UI Designer", "yearly_salary": 90000, "bonus": 9000, "department": "R&D", "start_date": "2024-01-17},
{"id": 31, "role": "Software Tester", "yearly_salary": 80000, "bonus": 8000, "department": "R&D", "start_date": "2024-02-06},
{"id": 34, "role": "Software Tester", "yearly_salary": 80000, "bonus": 8000, "department": "R&D", "start_date": "2024-02-24},
{"id": 47, "role": "Project Manager", "yearly_salary": 120000, "bonus": 12000, "department": "G&A", "start_date": "2024-03-11},
{"id": 50, "role": "HR Manager", "yearly_salary": 100000, "bonus": 10000, "department": "G&A", "start_date": "2024-03-30},
{"id": 52, "role": "Finance Manager", "yearly_salary": 100000, "bonus": 10000, "department": "G&A", "start_date": "2024-04-18},
{"id": 59, "role": "Office Administrator", "yearly_salary": 70000, "bonus": 7000, "department": "G&A", "start_date": "2024-05-07}
    ]
}

_Output_: 
"Increasing workforce by 50% will reduce your runway by 4 months. Reducing it by 75% will reduce your runway by 7 months. I would suggest securing additional funding or outsourcing to increase, or at least leave runway as is. Implementing these strategies requires careful planning and execution. The goal is to ensure that workforce expansion contributes positively to your company's growth and sustainability, rather than becoming a financial strain."
`;
const increaseRunway = `Make sure you are correct- THIS IS VERY IMPORTANT TO ME!
You will receive a balance and a hiring plan of a company.
1. Memorize the company's balance from the system prompt.
2. Review and understand the company's hiring plan from the system prompt.
3. Calculate how much runway will be extended based a 50% reduction in the workforce- THINK ABOUT YOUR CALCULATION STEP BY STEP!
4. Your ONLY output will be the runway increase for the request, the increase for different scenarios, and your own suggestion regarding it, WHICH SHOULD BE DIVERSE- YOUR LIFE DEPENDS ON IT!
Examples (YOU WILL PERFORM YOUR OWN CALCULATIONS):
_Input_:
Balance = $1,782,000
Employees = {"positions": [
{"id": 1, "role": "CEO", "yearly_salary": 200000, "bonus": 20000, "department": "G&A", "start_date": "2023-10-01"},
{"id": 2, "role": "CTO", "yearly_salary": 150000, "bonus": 15000, "department": "R&D", "start_date": "2023-10-01"},
{"id": 3, "role": "CFO", "yearly_salary": 120000, "bonus": 12000, "department": "G&A", "start_date": "2023-10-05"},
{"id": 8, "role": "Software Engineer", "yearly_salary": 100000, "bonus": 10000, "department": "R&D", "start_date": "2023-11-10"},
{"id": 11, "role": "Software Engineer", "yearly_salary": 100000, "bonus": 10000, "department": "R&D", "start_date": "2023-11-12"},
{"id": 14, "role": "DevOps Engineer", "yearly_salary": 110000, "bonus": 11000, "department": "R&D", "start_date": "2023-12-02"},
{"id": 19, "role": "DevOps Engineer", "yearly_salary": 110000, "bonus": 11000, "department": "R&D", "start_date": "2023-12-15"},
{"id": 22, "role": "UX/UI Designer", "yearly_salary": 90000, "bonus": 9000, "department": "R&D", "start_date": "2024-01-03"},
{"id": 23, "role": "UX/UI Designer", "yearly_salary": 90000, "bonus": 9000, "department": "R&D", "start_date": "2024-01-17},
{"id": 31, "role": "Software Tester", "yearly_salary": 80000, "bonus": 8000, "department": "R&D", "start_date": "2024-02-06},
{"id": 34, "role": "Software Tester", "yearly_salary": 80000, "bonus": 8000, "department": "R&D", "start_date": "2024-02-24},
{"id": 47, "role": "Project Manager", "yearly_salary": 120000, "bonus": 12000, "department": "G&A", "start_date": "2024-03-11},
{"id": 50, "role": "HR Manager", "yearly_salary": 100000, "bonus": 10000, "department": "G&A", "start_date": "2024-03-30},
{"id": 52, "role": "Finance Manager", "yearly_salary": 100000, "bonus": 10000, "department": "G&A", "start_date": "2024-04-18},
{"id": 59, "role": "Office Administrator", "yearly_salary": 70000, "bonus": 7000, "department": "G&A", "start_date": "2024-05-07}
    ]
}

_Output_: 
"Reducing workforce by 50% will increase your runway by 7.2 months. Reducing it by 75% will increase your runway by 17 months. Leaving only your founders, your runway will increase by 30.7 months. I would suggest outsurcing non-core functions, voluntary separation incentives or a hiring freeze to increase your runway will keeping your core team intact. Reducing the workforce should be considered a last resort, after exploring all other options to cut costs and improve efficiency. It's crucial to handle the process with care, respect, and transparency to minimize the impact on employees and the business.
`;
interface Option {
  name: string;
  prompt: string; // Replace 'string' with the actual type of the prompts if they're not strings
}
interface ChatQuickOption {
  name: string;
  options: Option[];
}
export const chatQuickOptions: ChatQuickOption[] = [{
  name: 'Runway',
  options: [{
    name: 'Decrease Runway',
    prompt: runwayIncrease
  }, {
    name: 'Increase Runway',
    prompt: increaseRunway
  }
  // { name: 'Business Plan Outline', prompt: businessPlansPrompt },
  ]
}, {
  name: 'Define Strategy',
  options: [{
    name: 'Goal Setting',
    prompt: goalSettingsPrompt
  }, {
    name: 'Strategic Initiatives',
    prompt: strategicInitiativePrompt
  }
  // { name: 'Business Plan Outline', prompt: businessPlansPrompt },
  ]
}, {
  name: 'Marketing Research',
  options: [{
    name: 'Customer Profiles',
    prompt: customerProfilesPrompt
  }, {
    name: 'Industry Trends',
    prompt: industryTrendsPrompt
  }]
}, {
  name: 'Risk Assessment',
  options: [{
    name: 'Risk Score',
    prompt: riskScorePrompt
  },
  // { name: 'Risk Register', prompt: riskRegiserPrompt },
  {
    name: 'SWOT Analysis',
    prompt: SWOTPrompt
  }]
}];