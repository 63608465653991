import validator from 'validator';

const nameRegex = /^[A-Za-z0-9_-]*$/;
const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

export function capitalizeFirstLetters(str: string) {
  return str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

const dateRegex = /^\d{4}-\d{2}-\d{2}$/; // YYYY-MM-DD
const totalYearDateRegex = /total-\d{4}/; // total-YYYY
const quarterDateRegex = /q\d{1}-\d{4}/; // q1-YYYY
const totalRegex = /^Total$/; // total

// regex to catch any one of the 3 above.
const anyDateColumnRegex = new RegExp(
  `${dateRegex.source}|${totalYearDateRegex.source}|${quarterDateRegex.source}|${totalRegex.source}`,
); // create a regex to catch {DATE}(mm,yyyy) where mm can be any numbe in leng 1-2 and yyyy is 4 digits
const formulaDateRegex = /{DATE}\(\d{1,2},\d{4}\)/g;

/**
 * Checks if a given string is a valid URL, allowing partial domains.
 *
 * @param url - The URL to validate.
 * @returns `true` if the URL is valid, `false` otherwise.
 */
export const isValidUrl = (url: string): boolean => {
  return validator.isURL(url, {
    require_protocol: false,
    require_valid_protocol: false,
    allow_underscores: true,
  });
};

export const GlobalRegex = {
  dateRegex,
  nameRegex,
  passwordRegex,
  quarterDateRegex,
  totalDateRegex: totalYearDateRegex,
  formulaDateRegex,
  anyDateColumnRegex,
};
