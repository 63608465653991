import { useEffect, useRef, useState } from 'react';
import styles from './dynamicTableScrollBar.module.scss';

/**
 * A dynamic table scroll bar component that is displayed when the table content exceeds the viewport width.
 * It provides a horizontal scrollbar that synchronizes the scrolling of the table content.
 *
 * @param ref - A ref to the table content element.
 * @param scrollRef - A ref to the scroll bar element.
 * @param isScrollVisible - A boolean indicating whether the scroll bar should be visible.
 * @returns A React component that renders the dynamic table scroll bar.
 */
const DynamicTableScrollBar = ({
  tableRef,
  scrollRef
}: {
  tableRef: any;
  scrollRef: any;
}) => {
  const [isScrollVisible, setIsScrollVisible] = useState(true);
  const isScrolling = useRef(false); // A ref to keep track of the scrolling state

  /**
   * Observes the table element and sets the `isScrollVisible` state based on whether the table is intersecting with the viewport.
   * This is used to determine when to show or hide the dynamic table scroll bar.
   */
  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsScrollVisible((entry?.isIntersecting as boolean)); // Set the visibility of the scroll bar based on the intersection state
    }, {
      root: null,
      rootMargin: '0px',
      threshold: 0.05
    });
    if (tableRef.current) {
      observer.observe(tableRef.current); // Observe the table element
    }
    return () => {
      if (tableRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(tableRef.current); // Stop observing the table element
      }
    };
  }, []);

  /**
   * Handles the scrolling behavior of the dynamic table.
   * This function is called whenever the scroll position of the table changes.
   * It ensures that the scroll position of the table is synchronized with the scroll position of the scroll bar.
   */
  const handleScroll = () => {
    if (!isScrolling.current) {
      isScrolling.current = true;
      requestAnimationFrame(() => {
        if (scrollRef.current && tableRef.current) {
          // eslint-disable-next-line no-param-reassign
          tableRef.current.scrollLeft = scrollRef.current.scrollLeft;
        }
        isScrolling.current = false;
      });
    }
  };
  return <div style={{
    display: isScrollVisible ? 'flex' : 'none'
  }} className={styles.fixedScrollbar} data-sentry-component="DynamicTableScrollBar" data-sentry-source-file="DynamicTableScrollBar.tsx">
      <div className={styles.horizontalSpacer} />

      <div className={`${styles.horizontalViewPort} `} ref={scrollRef} onScroll={handleScroll}>
        <div style={{
        width: tableRef?.current?.scrollWidth ? tableRef.current.scrollWidth - 375 // 375 is the width of the sidebar
        : 'auto'
      }} className={`${styles.horizontalViewPortInner}`} />
      </div>
    </div>;
};
export default DynamicTableScrollBar;