import classNames from 'classnames';
type TColorProp = string | string[];
interface ShineBorderProps {
  borderRadius?: number;
  borderWidth?: number;
  duration?: number;
  color?: TColorProp;
  className?: string;
  children: React.ReactNode;
  style?: React.CSSProperties;
}

/**
 * @name Shine Border
 * @description It is an animated background border effect component with easy to use and configurable props.
 * @param borderRadius defines the radius of the border.
 * @param borderWidth defines the width of the border.
 * @param duration defines the animation duration to be applied on the shining border
 * @param color a string or string array to define border color.
 * @param className defines the class name to be applied to the component
 * @param children contains react node elements.
 */
const ShineBorder = ({
  borderRadius = 8,
  borderWidth = 1,
  duration = 14,
  color = '#000000',
  className,
  children,
  style
}: ShineBorderProps) => {
  return <div style={({
    '--border-radius': `${borderRadius}px`,
    ...style
  } as React.CSSProperties)} className={classNames('relative h-fit w-fit overflow-hidden', className)} data-sentry-component="ShineBorder" data-sentry-source-file="ShineBorder.tsx">
      <div style={({
      '--border-width': `${borderWidth}px`,
      '--border-radius': `${borderRadius}px`,
      '--duration': `${duration}s`,
      '--mask-linear-gradient': `linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)`,
      '--background-radial-gradient': `radial-gradient(transparent,transparent, ${color instanceof Array ? color.join(',') : color},transparent,transparent)`
    } as React.CSSProperties)}
    // eslint-disable-next-line tailwindcss/no-custom-classname, tailwindcss/no-contradicting-classname
    className={`before:bg-shine-size before:size-full before:pointer-events-none before:absolute before:inset-0 before:aspect-square before:rounded-[--border-radius] before:p-[--border-width] before:will-change-[background-position] before:content-[""] before:![-webkit-mask-composite:xor] before:![mask-composite:exclude] before:[background-image:--background-radial-gradient] before:[background-size:300%_300%] before:[mask:--mask-linear-gradient] motion-safe:before:animate-shine`} />
      {children}
    </div>;
};
export default ShineBorder;