/* eslint-disable no-param-reassign */
import { memoize } from 'lodash';

import type { AccountTree, Output } from './useOutputs';

function isAncestor(ancestor: AccountTree, descendant: AccountTree): boolean {
  if (ancestor.output.id === descendant.output.id) {
    return true;
  }
  if (descendant.children) {
    return descendant.children.some((child) => isAncestor(ancestor, child));
  }
  return false;
}

export const mapBaseOutputIds = {
  banks: 1,
  creditCard: 2,
  income: 3,
  cogs: 6,
  expense: 10,
  assets: 40,
  equity: 49,
  liabilities: 46,
};

export const baseOutputIDs = Object.values(mapBaseOutputIds);

export const baseOutputIdToSign = {
  1: 1, // banks
  2: -1, // credit card
  3: 1, // income
  6: -1, // cogs
  10: -1, // expense
  40: -1, // assets
  49: 1, // equity
  46: 1, // liabilities
};

// we need to create a list of all base output ids and their names.
// Then we need to update banks output to get the values of all his children and display when shown on sidebar drawer.

export function createAccountTrees(outputs: Output[]): AccountTree[] {
  const accountTreeMap: { [key: number]: AccountTree } = {};
  outputs.forEach((output) => {
    accountTreeMap[output.id] = {
      output,
      children: [],
    };
  });

  // loop over the account tree map and add children to their parent children list
  Object.values(accountTreeMap).forEach((accountTree) => {
    const parentOutputId = accountTree.output.parent_output;
    if (parentOutputId) {
      const parentAccountTree = accountTreeMap[parentOutputId];
      if (parentAccountTree && isAncestor(parentAccountTree, accountTree)) {
        console.error(
          `circular dependency found between${parentAccountTree.output.name} and ${accountTree.output.name}`,
          {
            parent: parentAccountTree,
            child: accountTree,
          },
        );
        // splive the parent account from the children of this account
        accountTree.children = accountTree.children?.filter(
          (child) => child.output.id !== parentAccountTree.output.id,
        );
      } else {
        parentAccountTree?.children?.push(accountTree);
      }
    }
  });

  const allAccountTrees = outputs.map(
    // keeping the sort of the account tree map
    (output) => accountTreeMap[output.id],
  ) as AccountTree[];
  return allAccountTrees;
}

export const memoizedCreateAccountTrees = memoize(
  createAccountTrees,
  (outputs) => JSON.stringify(outputs),
) as (outputs: Output[]) => AccountTree[];

/**
 * Generates a mapping of output IDs to their base output ancestor IDs using an iterative approach.
 * An ancestor is an output with a non-null `base_output_id`.
 *
 * @param {AccountTree[]} accountTrees - List of account trees to process.
 * @returns {Record<number, number>} - Mapping of output ID to base output ancestor ID.
 */
export function generateOutputIDToBaseOutputAncestorID(
  accountTrees: AccountTree[],
): { [key: number]: number } {
  const result: { [key: number]: number } = {};
  const stack: AccountTree[] = [];
  for (const tree of accountTrees) {
    const rootOutput = tree.output;
    if (
      rootOutput.parent_output === null &&
      rootOutput.base_output_id !== null
    ) {
      const baseAncestorID = rootOutput.base_output_id;
      stack.push(tree);
      while (stack.length) {
        const node = stack.pop()!;
        result[node.output.id] = baseAncestorID;
        if (node.children) {
          for (const child of node.children) {
            stack.push(child);
          }
        }
      }
    }
  }
  return result;
}

/**
 * This function takes a tree and returns an array of all the children outputs.
 * @param tree - the tree to get the children outputs from
 * @returns an array of all the children outputs
 */
export const getChildrenOutputs = (tree: AccountTree | undefined): Output[] => {
  if (!tree) return [];
  const childrenOutputs: any[] = [];
  tree?.children?.forEach((child: any) => {
    childrenOutputs.push(child.output);
    childrenOutputs.push(...getChildrenOutputs(child));
  });
  return childrenOutputs;
};
