import { type Settings } from '../shared/types';
export const fieldToType = (field: string) => {
  switch (field) {
    case 'full_name':
      return 'text';
    case 'role':
      return 'text';
    case 'yearly_salary':
      return 'currency';
    // this should be currency
    case 'bonus':
      return 'currency';
    // this should be currency
    case 'start_date':
      return 'date';
    case 'end_date':
      return 'date';
    case 'monthly_salary':
      return 'currency';
    // this should be currency
    case 'monthly_hours':
      return 'number';
    default:
      return 'text';
  }
};

/**
 * Hiring table settings configuration file.
 */
const settings: Settings = {
  expanded: true,
  ignoreColumns: ['company_id', 'id', 'integration_id', 'number_of_employees', 'employee_integration_id', 'created_at', 'updated_at', 'branch_id', 'custom_fields', 'role_id', 'geo_location', 'is_contractor', 'parent_employee', 'department'],
  additionalColumns: [],
  disableEditingOnColumns: [],
  // Array of objects specifying gap configuration at different depths
  gapConfig: {
    at: [{
      depth: 0,
      count: 1
    }]
  }
};
export default settings;