/* eslint-disable @typescript-eslint/no-shadow */
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { Tooltip } from 'react-tooltip';

// eslint-disable-next-line import/no-cycle
import { ModelsTableContext } from '@/components/table/models';
import type { DataStoreState } from '@/miscellaneous/store/DataStore';
import DataStore from '@/miscellaneous/store/DataStore';
import variables from '@/styles/scss/abstracts/_variables.module.scss';
import { ModelsSelectStyle } from '@/styles/SelectComponentStyle/select';
import useMutations from '@/utils/hooks/mutations/useMutations';
import styles from './OutputCellComponent.module.scss';

/**
 * Renders the output cell for a formula row.
 *
 * Finds the output object with the given ID and allows updating the formula's output ID.
 */
const OutputCellComponent = ({
  row
}: any) => {
  const {
    t: translate
  } = useTranslation(['models']);
  const {
    branchOutputs: outputData
  } = DataStore((state: DataStoreState) => state);
  const {
    selectedFormula
  }: any = useContext(ModelsTableContext);

  /**
   * Finds the output object with the given id from the outputData array
   * @param id - The id of the output to find
   * @returns The output object or undefined if not found
   */
  const findOutputWithId = (id: number) => {
    return outputData?.find((output: any) => output.id === id);
  };
  const {
    updateFormula
  } = useMutations();

  /**
   * Updates the output ID for a formula mutation
   *
   * @param original - The original formula object
   * @param id - The new output ID
   */
  const updateFormulaOutputId = (original: any, id: any) => {
    const formula = selectedFormula ?? original;
    const formulaId = formula?.formula_id ?? original.formula_id;
    updateFormula.mutate({
      group: formula?.group,
      input_type: formula?.input_type,
      model_id: formula?.model_id,
      branch_id: formula?.branch_id,
      name: formula?.name,
      expression_string: formula.expression_string,
      emoji: formula?.emoji,
      id: +formulaId,
      output_id: id,
      parent_id: formula?.parent_id
    });
  };
  const foundActiveCellOutput = findOutputWithId(row.original.output_id);
  return <div className={styles.outputCellContainer} data-sentry-component="OutputCellComponent" data-sentry-source-file="OutputCellComponent.tsx">
      <span className={styles.financialsLabel}>{translate('financial')}</span>
      <Tooltip id="output-cell-tooltip" delayShow={1000} noArrow data-sentry-element="Tooltip" data-sentry-source-file="OutputCellComponent.tsx" />
      <div data-tooltip-id="output-cell-tooltip" data-tooltip-content={foundActiveCellOutput?.name}>
        <Select menuPlacement="top" options={outputData?.map((output: any) => ({
        value: output.id,
        label: output.name
      }))} components={{
        IndicatorSeparator: () => null
      }} defaultValue={foundActiveCellOutput && {
        value: row.original.output_id,
        label: foundActiveCellOutput?.name
      }} placeholder="Choose Output"
      // @ts-ignore
      styles={{
        ...ModelsSelectStyle,
        container: (styles, state) => ({
          // @ts-ignore
          ...ModelsSelectStyle?.container?.(styles, state),
          width: variables?.outputCellSelectWidth
        }),
        control: (styles, state) => ({
          // @ts-ignore
          ...ModelsSelectStyle?.control?.(styles, state),
          width: variables?.outputCellSelectWidth
        })
      }} onChange={(e: any) => {
        updateFormulaOutputId(row.original, e.value);
      }} data-sentry-element="Select" data-sentry-source-file="OutputCellComponent.tsx" />
      </div>
    </div>;
};
export default OutputCellComponent;