import { useContext, useState } from 'react';
import { FiPlus } from 'react-icons/fi';

// eslint-disable-next-line import/no-cycle
import { ModelsTableContext } from '@/components/table/models';
import sidebarStyles from '@/components/table/shared/table/GeneralTable/styles/tableSidebarStyles.module.scss';
import type { ZustandState } from '@/miscellaneous/store/zustand_store';
import useZustandStore from '@/miscellaneous/store/zustand_store';
import variables from '@/styles/scss/abstracts/_variables.module.scss';
import styles from '@/styles/table.module.scss';
import { getDepth } from '../../shared/TableHelpers/sidebarCellsStyles/sidebarCellsStylesHelper';

/**
 * React component for rendering cells in the sidebar.
 *
 * @param {object} props - Properties passed to component
 * @param {object} props.row - Row data
 * @param {boolean} props.isRenaming - Whether row is being renamed
 * @param {boolean} props.isAddBtn - Whether add button is shown
 * @param {string} props.newName - New name when renaming
 * @param {function} props.setIsRenaming - Callback to set isRenaming state
 * @param {function} props.setNewName - Callback to set newName state
 * @param {function} props.renameFormula - Callback to rename row
 * @param {function} props.addNewRow - Callback to add new row
 * @param {object} props.columnSpec - Column spec
 */
const SidebarCellContent = ({
  row,
  isRenaming,
  isAddBtn,
  newName,
  setIsRenaming,
  setNewName,
  renameFormula,
  addNewRow,
  columnSpec
}: {
  row: any;
  isRenaming: boolean;
  isAddBtn: boolean;
  newName: string;
  setIsRenaming: any;
  setNewName: any;
  renameFormula: any;
  addNewRow: any;
  columnSpec: any;
}) => {
  const {
    activeModel
  } = useZustandStore((state: ZustandState) => state);
  const {
    newRowIndex,
    setNewRowIndex
  } = useContext<any>(ModelsTableContext);
  const [isNewRow, setIsNewRow] = useState(!!newRowIndex);
  // Not an add button, but only if you set editing directly or when adding a new field
  if (isRenaming || newRowIndex && row.original?.formula_id === newRowIndex) {
    return <input className={styles.modelNameInput} type="text" onBlur={() => {
      setIsRenaming(false);
      setNewRowIndex?.('');
      setIsNewRow(false);
      renameFormula(row.original, newName);
    }} onKeyDown={e => {
      if (e.key === 'Enter') {
        setIsRenaming(false);
        setNewRowIndex?.('');
        setIsNewRow(false);
        renameFormula(row.original, newName);
      }
    }} defaultValue={isNewRow ? '' : newName} onChange={e => setNewName(e.target.value)}
    // eslint-disable-next-line jsx-a11y/no-autofocus
    autoFocus />;
  }
  if (isAddBtn) {
    return <button type="button" onClick={() => {
      if (row.original.group === 'Outputs') {
        addNewRow(row);
      } else {
        columnSpec?.getOptions(row, activeModel.id, setIsRenaming)[0].onClick();
      }
    }} className={styles.addBtn}>
        <FiPlus size={18} /> {row.original.name}
      </button>;
  }
  const curRowDepth = row?.original?.depth;
  const hasSubRows = row?.original?.subRows?.length > 0;
  const excatMaxWidth = +(variables.sidebarCellWidth?.replace('px', '') as string) - +(variables.sidebarAdditionalWidthModels?.replace('px', '') as string) - getDepth(curRowDepth);
  const badgeWidth = 45;
  const calculatedMaxWidthForElipsis = hasSubRows ? excatMaxWidth - badgeWidth : excatMaxWidth;
  return <span id={row.original.original_row_object?.id} data-tooltip-id="models-sidebar-cell-tooltip" data-tooltip-content={newName} style={{
    maxWidth: calculatedMaxWidthForElipsis
  }} className={sidebarStyles.modelsSidebarCellContent} data-sentry-component="SidebarCellContent" data-sentry-source-file="SidebarCellContent.tsx">
      {newName}
    </span>;
};
export default SidebarCellContent;