/* eslint-disable simple-import-sort/imports */
import useUtilsStore from '@/miscellaneous/store/utilsStore/utilsStore';
import variables from '@/styles/scss/abstracts/_variables.module.scss';
import { X } from 'lucide-react';
import { useEffect, useState } from 'react';
import { Oval } from 'react-loader-spinner';
import Popup from 'reactjs-popup';
import { useShallow } from 'zustand/react/shallow';

/**
 * Renders a modal component that displays a tutorial using the Elfsight platform.
 * The modal is controlled by the `isTutorialsModalOpen` state in the `utilsStore`.
 * The modal is displayed when `isTutorialsModalOpen` is true, and closed when it is false.
 * The modal is styled with a fixed size and centered layout.
 *
 * While the Elfsight content is loading, a loading spinner is displayed.
 */
const TutorialsModal = () => {
  const [isElfsightLoaded, setIsElfsightLoaded] = useState(false);
  const popupStyles = {
    borderRadius: '20px',
    padding: '18px',
    width: '1100px',
    maxWidth: '1100px',
    maxHeight: '750px',
    height: '750px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden'
  };
  const {
    isTutorialsModalOpen,
    setIsTutorialsModalOpen
  } = useUtilsStore(useShallow(state => ({
    isTutorialsModalOpen: state.isTutorialsModalOpen,
    setIsTutorialsModalOpen: state.setIsTutorialsModalOpen
  })));

  // Check if the Elfsight content has loaded and set the state accordingly when the modal is opened.
  useEffect(() => {
    if (!isTutorialsModalOpen) {
      setIsElfsightLoaded(false);
      return;
    }
    const waitForElem = (selector: string) => new Promise<Element>(resolve => {
      if (document.querySelector(selector)) {
        resolve(document.querySelector(selector)!);
      }
      const observer = new MutationObserver(() => {
        if (document.querySelector(selector)) {
          observer.disconnect();
          resolve(document.querySelector(selector)!);
        }
      });
      observer.observe(document.body, {
        childList: true,
        subtree: true
      });
    });
    let hasListener = false;
    const initializeSearchInput = async () => {
      const search = await waitForElem('.yottie-widget-feed-section-search-form-input');
      const blurInput = (e: MouseEvent) => {
        const target = (e.target as Element);
        if (target.closest('.yottie-widget-feed-section-search-form-input')) {
          return;
        }
        (search as HTMLElement).blur();
        document.removeEventListener('click', blurInput, true);
        hasListener = false;
      };
      search.addEventListener('click', () => {
        (search as HTMLElement).focus();
        if (!hasListener) {
          document.addEventListener('click', blurInput, true);
          hasListener = true;
        }
      });
    };

    // Initialize search functionality after Elfsight is loaded
    const checkElfsightLoaded = setInterval(() => {
      const elfsightDiv = document.querySelector('.elfsight-app-e0a87d8f-e01f-484d-98b0-1e4007fea850');
      if (elfsightDiv && elfsightDiv.children.length > 0) {
        setIsElfsightLoaded(true);
        clearInterval(checkElfsightLoaded);
        initializeSearchInput();
      }
    }, 100);
    return () => {
      clearInterval(checkElfsightLoaded);
      // Cleanup listeners if any
      if (hasListener) {
        document.removeEventListener('click', () => {}, true);
      }
    };
  }, [isTutorialsModalOpen]);
  return <Popup open={isTutorialsModalOpen} onClose={() => setIsTutorialsModalOpen(false)} modal arrow={false} contentStyle={popupStyles} nested data-sentry-element="Popup" data-sentry-component="TutorialsModal" data-sentry-source-file="TutorialsModal.tsx">
      <div className="absolute right-4 top-4 z-10 cursor-pointer">
        <X size={22} onClick={() => setIsTutorialsModalOpen(false)} color={variables.slateGray} data-sentry-element="X" data-sentry-source-file="TutorialsModal.tsx" />
      </div>
      {!isElfsightLoaded && <div className="absolute inset-0 flex items-center justify-center">
          <Oval visible height="50" width="50" color={variables.midBlue} secondaryColor={variables.lavendeGray} strokeWidth={4} />
        </div>}
      <div className="elfsight-app-e0a87d8f-e01f-484d-98b0-1e4007fea850 relative h-full w-full" data-elfsight-app-lazy />
    </Popup>;
};
export default TutorialsModal;