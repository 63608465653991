/**
 * @file applyStylesToColumns.ts
 * @description A utility function to apply styles to react-table columns based on settings.
 */

/**
 * Applies styles to a react-table column based on the provided settings.
 *
 * @function
 * @param {Object} originalColumn - The original react-table column configuration.
 * @param {Object} settings - The settings object containing style configurations.
 * @returns {Object} - The modified column with applied styles.
 */
const applyStylesToColumns = (originalColumn: any, settings: any) => {
  // Create a copy of the original column to avoid modifying the original configuration
  const column = {
    ...originalColumn
  };

  // If no styles are provided, return the original column
  if (!settings.styles) return column;

  // Apply shared styling if available
  if (settings.styles.shared) {
    column.style = {
      ...column.style,
      ...settings.styles.shared
    };
  }

  // Apply individual styling if available
  const individualStyle = settings.styles.individual.find((style: any) => style.accessorKey === column.accessorKey);
  if (individualStyle) {
    column.style = {
      ...column.style,
      ...individualStyle.style
    };
  }

  // Return the modified column with applied styles
  return column;
};

// Export the applyStylesToColumns function as the default export
export default applyStylesToColumns;