/* eslint-disable no-plusplus */
/* eslint-disable no-bitwise */

import moment from 'moment';
import { BsFileEarmarkPlusFill } from 'react-icons/bs';
import { FaDollarSign } from 'react-icons/fa6';
import { FiLink2 } from 'react-icons/fi';
import { LiaHashtagSolid } from 'react-icons/lia';
import { MdDateRange, MdOutlineListAlt, MdOutlinePercent } from 'react-icons/md';
import { resizableChartHW } from '@/utils/ChartUtils';
import { changeDateToString, parseUTCDateObject } from '@/utils/dateUtils';
import { months } from '../../styles/variables/constant';
export const chartDateHelper = (startDateTimestamp: any, endDateTimestamp: any) => {
  const dates = [];
  const currentDate = parseUTCDateObject(startDateTimestamp);
  while (currentDate <= parseUTCDateObject(endDateTimestamp)) {
    const month = currentDate.getMonth() + 1;
    const day = currentDate.getDate();
    const year = currentDate.getFullYear();
    const formattedDate = `${month}/${day}/${year}`;
    dates.push(formattedDate);

    // Move to the next day
    currentDate.setMonth(currentDate.getMonth() + 1);
  }
  return dates;
};
/**
 * Get an icon component based on the icon type.
 *
 * @param {string} iconType - The type of icon to retrieve.
 * @returns {JSX.Element|null} The icon component or null if the type is not recognized.
 */
export const getIconForFormula = (iconType: string) => {
  /**
   * This function returns an icon component based on the provided `iconType`.
   * It is used to map specific icon types to corresponding React icon components.
   *
   * @param {string} iconType - The type of icon to retrieve.
   * @returns {JSX.Element|null} The icon component or null if the type is not recognized.
   */
  let icon;
  switch (iconType) {
    case 'number':
      icon = <LiaHashtagSolid />;
      break;
    case 'currency':
      icon = <FaDollarSign />;
      break;
    default:
      break;
  }
  return icon;
};

/**
 * Get an array of input data.
 *
 * @param {any} t - Translation or text localization function.
 * @returns {Object[]} An array of input data objects with labels and icons.
 */
export function getInputData(t: any) {
  /**
   * This function returns an array of input data objects, where each object contains an 'id', a 'label', and an 'icons' property.
   * These objects are typically used for input options in the user interface.
   *
   * @param {any} t - Translation or text localization function.
   * @returns {Object[]} An array of input data objects.
   */
  return [{
    id: 1,
    label: t('models:number'),
    icons: LiaHashtagSolid
  }, {
    id: 2,
    label: t('models:percentage'),
    icons: MdOutlinePercent
  }, {
    id: 3,
    label: t('models:currency'),
    icons: FaDollarSign
  }, {
    id: 4,
    label: t('models:month'),
    icons: MdDateRange
  }, {
    id: 5,
    label: t('models:list'),
    icons: MdOutlineListAlt
  }, {
    id: 6,
    label: t('models:import'),
    icons: BsFileEarmarkPlusFill
  }, {
    id: 7,
    label: t('models:link'),
    icons: FiLink2
  }];
}

/**
 * Calculate the strength of a password based on certain criteria.
 *
 * @param {string} password - The password to evaluate.
 * @returns {number} A strength value representing the password's strength.
 */
export const calculatePasswordStrength = (password: string) => {
  /**
   * This function calculates the strength of a given password based on specific criteria, including length, character types, etc.
   *
   * @param {string} password - The password to evaluate.
   * @returns {number} A strength value representing the password's strength.
   */

  let strength = -1;
  if (password.length >= 8) {
    strength++;
  }
  if (/[a-z]/.test(password)) {
    strength++;
  }
  if (/[A-Z]/.test(password)) {
    strength++;
  }
  if (/\d/.test(password)) {
    strength++;
  }
  if (/[!@#$%^&*()_+{}[\]:;<>,.?~\\/-]/.test(password)) {
    strength++;
  }
  return strength;
};

/**
 * Get the month and year from a given date and return them as a formatted string.
 * @param {Date} date - The input date from which to extract the month and year.
 * @returns {string} A string in the format "Month Year" (e.g., "October 2023").
 */
export const getYearsMonth = (date: any) => {
  /**
   * This function extracts the month and year from a given `date` and returns them as a formatted string.
   *
   * @param {Date} date - The input date from which to extract the month and year.
   * @returns {string} A string in the format "Month Year" (e.g., "October 2023").
   */

  const month = parseUTCDateObject(date).getMonth();
  const year = parseUTCDateObject(date).getFullYear();
  const monthNames = months;
  const monthName = monthNames[month];
  return `${monthName} ${year}`;
};

/**
 * Calculate and adjust the height and width of a chart within specified limits.
 *
 * @param {number} height - The original height of the chart.
 * @param {number} width - The original width of the chart.
 * @returns {[number, number]} An array containing the adjusted width and height.
 */
export function calculateHeightAndWidth(height: number, width: number): [number, number] {
  /**
   * This function calculates and adjusts the height and width of a chart within specified limits.
   *
   * @param {number} height - The original height of the chart.
   * @param {number} width - The original width of the chart.
   * @returns {[number, number]} An array containing the adjusted width and height.
   */

  let newHeight = height;
  let newWidth = width;
  if (newHeight < resizableChartHW.min) {
    newHeight = resizableChartHW.min;
  } else if (newHeight > resizableChartHW.max) {
    newHeight = resizableChartHW.max;
  } else {
    newHeight = Math.round(newHeight / resizableChartHW.fixHeight) * resizableChartHW.fixHeight;
  }
  if (newWidth < resizableChartHW.min) {
    newWidth = resizableChartHW.min;
  } else if (newWidth > resizableChartHW.max) {
    newWidth = resizableChartHW.max;
  } else {
    newWidth = Math.round(newWidth / resizableChartHW.fixWidth) * resizableChartHW.fixWidth;
  }
  return [newWidth, newHeight];
}

/**
 * Capitalize the first character of a string.
 *
 * @param {any} txt - The input text to capitalize.
 * @returns {string} The input text with the first character capitalized.
 */

export const capitalize = (txt: any) => {
  /**
   * This function capitalizes the first character of a given text.
   *
   * @param {any} txt - The input text to capitalize.
   * @returns {string} The input text with the first character capitalized.
   */

  if (!txt) return txt;
  return txt.charAt(0).toUpperCase() + txt.slice(1);
};
export function capitalizeWords(str: string) {
  return str.replace(/\b\w/g, char => char.toUpperCase());
}
/**
 * Get the name of a month from a given date object.
 *
 * @param {any} date - The input date object.
 * @returns {string} The name of the month and year (e.g., "October 2023").
 */
export const getMonthName = (date: any) => {
  /**
   * This function gets the name of the month from a given date object and returns it as a formatted string.
   *
   * @param {any} date - The input date object.
   * @returns {string} The name of the month and year (e.g., "October 2023").
   */

  const monthNames = months;
  const monthCount = date.month - 1;
  const monthName = monthNames[monthCount];
  return `${monthName} ${date?.year}`;
};

/**
 * Extract the first two characters from a given string.
 *
 * @param {string} inputString - The input string.
 * @returns {string} The first two characters of the input string.
 */
export const extractTwoChar = (inputString: string) => {
  /**
   * This function extracts the first two characters from a given string and returns them as a new string.
   *
   * @param {string} inputString - The input string.
   * @returns {string} The first two characters of the input string.
   */

  return inputString.substring(0, 2);
};

/**
 * Generate a slug from a given string.
 *
 * @param {string} str - The input string to generate a slug from.
 * @returns {string} The generated slug.
 */
export const getSlug = (str: string) => {
  /**
   * This function generates a slug from a given string by converting it to lowercase and replacing spaces with hyphens.
   *
   * @param {string} str - The input string to generate a slug from.
   * @returns {string} The generated slug.
   */

  const slug = str?.toLowerCase().replace(/ /g, '-');
  return slug;
};

// Utility function to convert hex to RGB
export const hexToRGB = (hex: string) => {
  // Remove the hash if it's included
  // eslint-disable-next-line no-param-reassign
  hex = hex?.replace(/^#/, '');

  // Parse the hex values
  const bigint = parseInt(hex, 16);

  // Extract the RGB components
  const r = bigint >> 16 & 255;
  const g = bigint >> 8 & 255;
  const b = bigint & 255;
  return {
    r,
    g,
    b
  };
};

/**
 * Generates an array of dates based on the provided date.
 *
 * @param date - The date to generate the dates array from.
 * @param step - The number of months to step between each date in the array.
 * @param isFullDate - Whether to generate a full date range or just the first day of each month.
 * @returns An array of dates based on the provided date.
 */
export const generateDatesArray = (date: Date, startDate: string, endDate: string, step: number = 0, isFullDate: boolean = false) => {
  const datesArray = [];
  if (isFullDate) {
    const sDate = parseUTCDateObject(startDate); // convert start Date string to Date object
    const eDate = parseUTCDateObject(endDate); // convert end Date string to Date object

    for (let i = sDate; i <= eDate; i.setDate(i.getDate() + 1)) {
      datesArray.push(changeDateToString(parseUTCDateObject(i)));
    }
    return datesArray;
  }
  for (let i = step; i <= parseUTCDateObject(date).getMonth(); i += 1) {
    datesArray.push(`${parseUTCDateObject(date).getFullYear()}-${(i + 1).toString().padStart(2, '0')}-01`);
  }
  return datesArray;
};

/**
 * Generates an array of dates based on the provided date string.
 *
 * @param date - The date string to generate the dates array from.
 * @returns An array of dates based on the provided date string.
 */
export const getRevertedDatesFromColumns = (date: string, startDate: string, endDate: string) => {
  if (date?.startsWith('total')) {
    // Return ColumnType.Year for 'total-yyyy' format
    const parseYear = +(date.split('-')[1] as string);
    return generateDatesArray(moment().year(parseYear).month(11).date(31).utc().toDate(), startDate, endDate);
  }
  if (date?.startsWith('q')) {
    // Return ColumnType.Quarter for 'qN-yyyy' format
    const parseQuarter = +(date.split('-')[0]?.split('q')[1] as string);
    const parseYear = +(date.split('-')[1] as string);
    return generateDatesArray(moment().year(+parseYear).month(parseQuarter * 3 - 1).date(1).utc().toDate(), startDate, endDate,
    // 1,2,3,4 => 0,3,6,9
    (parseQuarter - 1) * 3);
  }
  if (date?.includes('-')) {
    // Return ColumnType.Month for 'yyyy-mm-dd' format
    return [date];
  }
  // Return ColumnType.Total for other cases
  return generateDatesArray(parseUTCDateObject(date), startDate, endDate, 0, true);
};