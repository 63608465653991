/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-param-reassign */

import CalculationsStore from '@/miscellaneous/store/CalculationsStore';
import { getOutputValues, type OutputValues } from '@/miscellaneous/store/calulcationsStoreHelper';
import useZustandStore from '@/miscellaneous/store/zustand_store';
import colors from '@/styles/scss/abstracts/_variables.module.scss';
import type { AccountValues } from '@/utils/hooks/Calculations/useEntries';
import type { AccountTree, Output } from '@/utils/hooks/Outputs/useOutputs';
import type { CulmutativeEnum, Report, ReportItem } from '@/utils/hooks/reports/reportTypes';
import { ReportItemEnum } from '@/utils/hooks/reports/reportTypes';
import type { Formula } from '@/utils/types/formulaTypes';

// eslint-disable-next-line import/no-cycle
import { addDateValuesFromRowToRow, addEntriesToRows, createAccountTreeRow, getformulaTableRow, getSectionRow, multiplyDateValues, rowHasDates, sortItemsByFieldName, sortItemsByID } from '../shared/TableHelpers/sharedTableRowRenderUtils';
import type { TableRow } from '../shared/types';
export const isDebug = false;
function renderFormulaRow(item: ReportItem, tableDepth: number): any {
  const {
    formulaEntries
  } = CalculationsStore.getState();
  const {
    branchFormulas
  } = useZustandStore.getState();
  const curFormula = (branchFormulas as Formula[]).find((f: Formula) => f.id === item.formulaId);
  const formulaRow: TableRow = getformulaTableRow(item, curFormula, tableDepth);
  const entries = formulaEntries[item.formulaId ?? 0];
  if (!entries || Object.keys(entries).length === 0) {
    if (isDebug) console.log(`🚀 ~ renderFormulaRow ~ entries missing: ${item.formulaId}`);
    return formulaRow;
  }

  // addEntriesToRows([formulaRow], formulaEntries ?? []);
  addEntriesToRows([formulaRow], entries);
  return formulaRow;
}

/**
 * Renders rows for an account tree, including entries and a total row for each account.
 *
 * @param {AccountTree} accountTree The account tree to render.
 * @param {{ [key: string]: Entry[] }} entriesGroupedByAccount Grouped entries by account ID.
 * @param {number} accountTreeLevel The current level in the account tree, used for indentation (default is 0).
 * @returns {TableRow} The row representation of the account tree.
 */
function renderAccountTreeRows(report: Report, accountTree: AccountTree, getAccountValuesMapByID: (outputID: number) => OutputValues | undefined, sign: number, tableDepth: number, accountTreeLevel: number = 0, item?: ReportItem, rowCulmutativeCalculationType: CulmutativeEnum | null = null): TableRow {
  if (!accountTree) {
    console.log('🚀 ~ rowCulmutativeCalculationType:', rowCulmutativeCalculationType);
    return {
      id: ''
    };
  }
  const currentRowObject = accountTree?.output;
  const accountTreeTotalValues: TableRow = {
    id: `accountTree-${accountTree.output.id}-Total-report-item-${item?.id}`,
    sidebar: `${accountTree.output.name} Total`,
    subRows: [],
    isTotalRow: true,
    type: 'total',
    original_row_object: currentRowObject,
    rowStyle: {
      fontWeight: 'bold'
    },
    baseRowItemParent: item,
    depth: tableDepth + 1
  };

  // Create the parent row for the current account tree node.
  const accoutTreeParentRow: TableRow = createAccountTreeRow({
    item,
    accountTreeLevel,
    currentRowObject,
    tableDepth
  });

  // Start building rows from the account tree and add them to the initial row's subRows
  accoutTreeParentRow.subRows = accountTree?.children?.map(child => renderAccountTreeRows(report, child, getAccountValuesMapByID, sign, tableDepth + 1, accountTreeLevel + 1, item, rowCulmutativeCalculationType)).flat() || [];
  accoutTreeParentRow.subRows = (sortItemsByFieldName(
  // sorting by the id of the output, the children outputs.
  accoutTreeParentRow.subRows, accoutTreeParentRow.original_row_object?.sort_order || '', 'original_row_object') as TableRow[]);

  // add the actual output related with this row to the list of subrows
  const outputRow: TableRow = {
    ...accountTree.output,
    sidebar: accountTree.output.name,
    output_id: accountTree.output.id,
    id: `output-${accountTree.output.id.toString()}-report-item-${item?.id}-depth-${tableDepth}`,
    subRows: [],
    type: 'output',
    original_row_object: currentRowObject,
    baseRowItemParent: item,
    depth: tableDepth + 1,
    level: accountTreeLevel
  };
  const curValues = getAccountValuesMapByID(accountTree.output.id); // here we can pass filter scheam to filter by deparment for example R&D or something else

  if (!curValues) {
    if (isDebug) {
      console.log(`🚀 ~ renderAccountTreeRows ~ curValues missing: ${accountTree.output.id}`);
    }
  }
  const currentEntries = getOutputValues(curValues);

  // Add entries to the current node's row.
  addEntriesToRows([outputRow], currentEntries, report, sign, rowCulmutativeCalculationType);
  if (accoutTreeParentRow.subRows.length === 0 && accountTreeLevel > 0) {
    // if it is a single row return
    outputRow.depth = tableDepth;
    return outputRow;
  }
  accoutTreeParentRow.subRows.unshift(outputRow); // else we add it to the rows

  // summing up all the children rows
  // accoutTreeParentRow.subRows.forEach((row) => {
  //   if (row.type === 'accountTree') {
  //     addLastChildRowValuesToRow(row, accountTreeTotalValues);
  //   }
  //   if (row.type === 'output') {
  //     addDateValuesFromRowToRow(row, accountTreeTotalValues);
  //   }
  // });

  // // addEntriesToRows([accoutTreeParentRow], accountTreeTotalValues, report, sign); // sum the values of all children total rows and add them to the total row and parent row
  // addDateValuesFromRowToRow(accountTreeTotalValues, accoutTreeParentRow); // sum the values of all children total rows and add them to the total row and parent row

  addEntriesToRows(
  // adding the total output value to total and to parent row
  [accountTreeTotalValues, accoutTreeParentRow], (curValues?.outputTotalValues as AccountValues), report, sign, rowCulmutativeCalculationType);

  // add totals row to the end of the subRows
  accoutTreeParentRow.subRows.push(accountTreeTotalValues);
  const {
    newOutputs,
    setNewOutputs
  } = useZustandStore.getState();
  const isRowNewOutput = (row: TableRow) => {
    return newOutputs?.some((output: Output) => output.id === row?.original_row_object?.id);
  };
  const {
    showEmptyAccounts
  } = report.settings ?? {};

  /**
   * Filters the subRows of the accoutTreeParentRow based on certain conditions, and returns a new TableRow object with the filtered subRows.
   */
  if (!showEmptyAccounts) {
    const filteredRows = accoutTreeParentRow.subRows.filter(row => row.original_row_object === accoutTreeParentRow.original_row_object ||
    // check if row is the first row
    rowHasDates(row) ||
    // check if row has date related keys
    row.isTotalRow ||
    // check if row is total row
    isRowNewOutput(row) // check if new outputs exist
    );
    return ({
      ...accoutTreeParentRow,
      subRows: filteredRows
    } as TableRow);
  }

  /**
   * Clears the `newOutputs` state if `showEmptyAccounts` is true and `setNewOutputs` is defined.
   */
  if (showEmptyAccounts && setNewOutputs) {
    setNewOutputs([]);
  }
  return accoutTreeParentRow;
}
export interface RenderRowsProps {
  report: Report;
  items: Record<number, ReportItem[]>;
  parentSection?: ReportItem;
  accountTrees: AccountTree[];
  getAccountValuesMapByID: (outputID: number) => OutputValues | undefined;
  depth: number;
  rowCulmutativeCalculationType?: CulmutativeEnum | null;
}

// This function handles creating a section Recursivly. It takes the current section and renders all the children of the section
// and then adds a total row to the end of the section
function CreateSection(rowBaseValues: TableRow, currentSection: ReportItem, renderRowsProps: RenderRowsProps, depth: number, sign: number = 1) {
  rowBaseValues.sidebar = (currentSection?.name as string);
  rowBaseValues.id = `section-${currentSection.id}`;
  // const logRow = currentSection.name === 'Change in Cashflow';

  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const childRows = renderRows({
    ...renderRowsProps,
    parentSection: currentSection,
    depth: depth + 1,
    rowCulmutativeCalculationType: currentSection.cumulative
  }); // set the parent id to be the current section
  let sectionParentRow: TableRow = getSectionRow({
    currentSection,
    branch_id: renderRowsProps.report.branch_id,
    sign,
    depth
  });

  // iterate over all the children and if account tree take last subrow (total row) values and add values to total row values
  childRows.forEach((row: TableRow) => {
    if (!row?.isTotalRow) {
      if (row.type === 'section') {
        addDateValuesFromRowToRow(row, sectionParentRow);
      } else if (row.type === 'accountTree' || row.type === 'total') {
        addDateValuesFromRowToRow(row, sectionParentRow);
      } else if (row.type === 'output') {
        addDateValuesFromRowToRow(row, sectionParentRow);
      } else if (row.type === 'formula') {
        addDateValuesFromRowToRow(row, sectionParentRow);
      } else if (!row.type) {
        // account tree with no children
        addDateValuesFromRowToRow(row, sectionParentRow);
      }
    }
  });
  sectionParentRow = sign === -1 ? multiplyDateValues(sectionParentRow, (sign as number)) : sectionParentRow;
  const sectionTotalRow: TableRow = {
    ...sectionParentRow,
    sidebar: `${currentSection.name} Total`,
    id: `section-total-${currentSection.id}-${sectionParentRow.id}`,
    rowStyle: {
      color: colors.black,
      fontWeight: 'bold'
    },
    isTotalRow: true,
    type: 'total',
    subRows: []
  };
  const gapRow: TableRow = {
    id: `gap-${currentSection.id}-${sectionParentRow.id}`,
    type: 'gap'
  };
  sectionParentRow.subRows = [...childRows, sectionTotalRow];
  if (depth === 0) sectionParentRow.subRows.push(gapRow);
  return sectionParentRow;

  // return [...(childRows as TableRow[]), sectionSummaryRow, gapRow];
}
const baseRowValues: TableRow = {
  sidebar: '',
  id: '',
  emptyCellsRow: false,
  subRows: [],
  rowStyle: {},
  depth: 0
};
export const renderRows = (props: RenderRowsProps): TableRow[] => {
  const {
    report,
    items,
    parentSection,
    accountTrees,
    getAccountValuesMapByID,
    depth,
    rowCulmutativeCalculationType
  } = props;
  if (!accountTrees) return [];
  // we are getting the parent to set the sorting on the report items here before working forward
  const currentParent = parentSection?.id ? parentSection : report;
  const currentParentId = parentSection?.id ?? 0;
  const currentSortOrder = currentParent.sort_order || '';
  const currentItems = items[currentParentId] ?? [];
  const sortedItems = (sortItemsByID(currentItems, currentSortOrder) as ReportItem[]);
  let curAccountTree: AccountTree | undefined;
  const result = sortedItems.map(item => {
    const currentRow: TableRow = {
      ...baseRowValues,
      baseRowItemParent: item
    };
    switch (item.type) {
      case ReportItemEnum.Section:
        return CreateSection(currentRow, item, props, depth, item?.sign === -1 ? -1 : 1);
      case ReportItemEnum.AccountTree:
        curAccountTree = undefined;
        curAccountTree = accountTrees?.find(tree => tree.output.base_output_id === item.account_id || tree.output.id === item.account_id);
        if (!curAccountTree) {
          if (isDebug) console.log(`🚀 ~ renderRows curAccountTree missing: report item ${item}`, curAccountTree, item);
          return null;
        }
        return renderAccountTreeRows(report, curAccountTree, getAccountValuesMapByID, item?.sign === -1 ? -1 : 1,
        // add report item sign to the account tree
        depth, 0, item, rowCulmutativeCalculationType);
      case ReportItemEnum.Formula:
        return renderFormulaRow(item, depth);
      // passing in the list here the entries of formulas to calculate formula ususally
      default:
        return null;
    }
  });
  // flattened result

  // remove null values and flatten
  const flatResult = result.filter(row => row !== null).flat();
  return flatResult;
};