import React from 'react';
import { getFormulasParsed } from '@/components/modelCalculations/modelUtils/modelUtils';
import styles from '@/styles/scss/formulaCell.module.scss';
import { FormulaCountCell } from '../formulaCell';
// eslint-disable-next-line import/no-cycle
import InputCellsComponent from './InputCellsComponent';

/**
 * Renders an input cell component that displays a formula count button or an input cell component based on the number of formulas in the original expression.
 *
 * @param {object} row - The row data object.
 * @param {object} cell - The cell data object.
 * @param {object} columnSpec - The column specification object.
 * @returns {React.ReactElement} - The rendered input formula cell component.
 */
const InputFormulaCell = ({
  row,
  cell,
  columnSpec
}: {
  row: any;
  cell: any;
  columnSpec: any;
}) => {
  const {
    original
  } = row;
  const currentFormulas = getFormulasParsed(original?.expression_string);
  const [isShowInput, setIsShowInput] = React.useState(false);
  return <span data-sentry-component="InputFormulaCell" data-sentry-source-file="InputFormulaCell.tsx">
      {currentFormulas?.length > 1 && !isShowInput ?
    // eslint-disable-next-line jsx-a11y/control-has-associated-label
    <button type="button" className={styles.formulaCountCell} onClick={() => setIsShowInput(true)}>
          <FormulaCountCell count={currentFormulas.length} />
        </button> : <InputCellsComponent row={row} cell={cell}
    // info: columnSpec.handlerFunctions is use to handle the onBlur event of the input cell component
    columnSpec={{
      ...columnSpec,
      handlerFunctions: {
        onBlur: () => (e: any) => {
          console.log('onBlur', cell, row);
          columnSpec.handlerFunctions.onBlur(cell, row)(e);
          setIsShowInput(false);
        }
      }
    }} focus={isShowInput} />}
    </span>;
};
export default InputFormulaCell;