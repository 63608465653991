import React from 'react';
import { getISOStringFromFormulaDateValue } from '@/utils/dateUtils';
import type { BaseCellType } from '../../types';
// eslint-disable-next-line import/no-cycle
import { cellComponentMap } from '../baseCells';
import styles from './InputCellsStyles.module.scss';

/**
 * Renders an input cell component based on the input type specified in the row data.
 *
 * The component retrieves the default value for the input cell by parsing the cell's value as JSON and extracting the first element's `formula` property. It then generates a valid default value based on the input type, handling date inputs and other input types differently.
 *
 * @param row - The row data object.
 * @param cell - The cell object.
 * @param columnSpec - The column specification object.
 * @returns A React element representing the input cell component.
 */
const InputCellsComponent = ({
  row,
  cell,
  columnSpec,
  defaultValue: baseValue,
  focus = false
}: {
  row: any;
  cell: any;
  columnSpec: any;
  defaultValue?: any;
  focus?: boolean;
}) => {
  /**
   * Retrieves the default value for an input cell by parsing the cell's value as JSON and extracting the first element's `formula` property.
   *
   * @param cell - The input cell object.
   * @returns The default value for the input cell, or `undefined` if the cell's value is not a valid JSON string or does not contain a `formula` property.
   */
  const defaultValue = baseValue || cell?.getValue() && JSON.parse(cell.getValue())[0]?.formula;

  /**
   * Checks if the provided value is a valid number.
   *
   * @param value - The value to check.
   * @returns `true` if the value is not a valid number, `false` otherwise.
   */
  const isCorrectValue = (value: any) => {
    return Number.isNaN(+value);
  };

  /**
   * Generates the default value for an input cell based on the input type.
   *
   * If the input type is 'date', it generates a valid ISO 8601 date string from the provided default value.
   * For other input types, it returns the default value if it is a valid number, or an empty string if it is not.
   *
   * @param inputType - The type of the input cell.
   * @returns The default value for the input cell.
   */
  const generateDefaultValue = (inputType: string) => {
    switch (inputType) {
      case 'date':
        return getISOStringFromFormulaDateValue(defaultValue);
      case 'percentage':
        return isCorrectValue(defaultValue) ? '' : +defaultValue;
      default:
        return isCorrectValue(defaultValue) ? '' : defaultValue;
    }
  };
  return <div className={styles.cellContainer} data-sentry-component="InputCellsComponent" data-sentry-source-file="InputCellsComponent.tsx">
      {React.createElement(cellComponentMap[(row.original.input_type?.toLowerCase() as BaseCellType)] || cellComponentMap.view, {
      cell,
      row,
      columnSpec,
      defaultValue: generateDefaultValue(row.original.input_type?.toLowerCase()),
      focus
    })}
    </div>;
};
export default InputCellsComponent;