import { motion } from 'framer-motion';
import type { FC } from 'react';
import styles from './threeDotsAnimation.module.scss';
interface ThreeDotsAnimationProps {
  className?: string;
  text: string;
}
const ThreeDotsAnimation: FC<ThreeDotsAnimationProps> = ({
  className,
  text
}) => {
  const dot = {
    initial: {
      opacity: 0
    },
    animate: {
      opacity: [0, 1, 0]
    },
    transition: {
      repeat: Infinity,
      duration: 1.2,
      ease: 'easeInOut'
    }
  };
  return <div data-testid="three-dots-animation" className={className ? `${styles.threeDotsAnimationWrapper} ${className}` : styles.threeDotsAnimationWrapper} data-sentry-component="ThreeDotsAnimation" data-sentry-source-file="ThreeDotsAnimation.tsx">
      <span>{text}</span>
      {[...Array(3)].map((_, i) => <motion.span
    // eslint-disable-next-line react/no-array-index-key
    key={i} className={styles.dot} {...dot} transition={{
      ...dot.transition,
      delay: i * 0.4
    }} />)}
    </div>;
};
export default ThreeDotsAnimation;