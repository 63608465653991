import React from 'react';
import styles from './OptionsMenu.module.scss';
// This will be our CSS module
interface IMenuItem {
  label: string;
  id: number | string;
  icon: JSX.Element; // We will use a React component for the icon
  isNotDeletable?: boolean;
  isNotEditable?: boolean;
  onClick: () => void;
}
interface IDynamicRowProps {
  items: IMenuItem[];
  close?: () => void;
  className?: string;
}
const OptionsMenu: React.FC<IDynamicRowProps> = ({
  items,
  close,
  className
}) => {
  return <div className={styles.row} data-sentry-component="OptionsMenu" data-sentry-source-file="OptionsMenu.tsx">
      {items.map(item => <div key={item.id} className={`${className || styles.menuItem} ${(item.isNotDeletable || item.isNotEditable) && styles.disabled}`} onClick={e => {
      e.stopPropagation();
      item.onClick();
      if (close) {
        close();
      }
    }} onMouseEnter={() => {
      /* Handle hover state here */
    }} onMouseLeave={() => {
      /* Handle hover off state here */
    }}>
          <span className={styles.icon}>{item.icon} </span>
          <span className={styles.label}>{item.label}</span>
        </div>)}
    </div>;
};
export default OptionsMenu;