import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useShallow } from 'zustand/react/shallow';

import ApiUtils from '@/api/ApiUtils';
import { QUERYKEYS } from '@/miscellaneous/constant/reactQueryKeyConfig';
import type { ZustandState } from '@/miscellaneous/store/zustand_store';
import useZustandStore from '@/miscellaneous/store/zustand_store';

import useRefetchIntegrations from './useRefetchIntegrations';

export interface Integration {
  api_key: string;
  company_id: number;
  type: string;
  created_by: number;
  update_interval: number;
  updated_at: string;
  service_id: string;
  client_secret: string;
  realm_id: number;
  id: number;
  integration_provider: string;
  latest_fetch_date: string;
  created_at: string;
  consumer_id: number;
  client_id: number;
  is_deleted: boolean;
}

const useIntegrations = () => {
  const { activeCompany } = useZustandStore(
    useShallow((state: ZustandState) => ({
      activeCompany: state.activeCompany,
    })),
  );
  const refetchIntegrationsAndRelevantData = useRefetchIntegrations(15);
  const { data, isLoading, error, refetch } = useQuery<Integration[], Error>({
    queryKey: [QUERYKEYS.INTEGRATIONS, activeCompany?.id],
    queryFn: () => ApiUtils.getIntegrations(activeCompany?.id),
    enabled: !!activeCompany?.id,
  });

  useEffect(() => {
    let cleanup: (() => void) | undefined;

    if (data) {
      const integrationWithNullFetchDate = data.find(
        (integration) => integration.latest_fetch_date === null,
      );

      if (integrationWithNullFetchDate) {
        cleanup = refetchIntegrationsAndRelevantData(
          integrationWithNullFetchDate,
        );
      }
    }

    return () => {
      if (cleanup) {
        cleanup();
      }
    };
  }, [data]);

  return {
    data,
    isLoading,
    error,
    refetch,
  };
};

export default useIntegrations;
