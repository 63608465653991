import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import addEditChartPoupTranslations from '@/public/locales/en/addEditChartPopup.json';
import addguestTranslations from '@/public/locales/en/addguest.json';
import billingorganizationTranslations from '@/public/locales/en/billingorganization.json';
import branchTranslations from '@/public/locales/en/branch.json';
import commonTranslations from '@/public/locales/en/common.json';
import companyTranslations from '@/public/locales/en/company.json';
import copilotTranslations from '@/public/locales/en/copilot.json';
import financialreportTranslations from '@/public/locales/en/financialreport.json';
import financialsDrawerTranslations from '@/public/locales/en/financialsDrawer.json';
import formTranslations from '@/public/locales/en/form.json';
import formulaCellTranslations from '@/public/locales/en/formulaCell.json';
import hiringplanTranslations from '@/public/locales/en/hiringplan.json';
import hiringsettingsTranslations from '@/public/locales/en/hiringsettings.json';
import integrationsTranslations from '@/public/locales/en/integrations.json';
import modelsTranslations from '@/public/locales/en/models.json';
import organizationsettingTranslations from '@/public/locales/en/organizationsetting.json';
import ownersettingTranslations from '@/public/locales/en/ownersetting.json';
import pagesTranslations from '@/public/locales/en/pages.json';
import personalinfoTranslations from '@/public/locales/en/personalinfo.json';
import projectsettingTranslations from '@/public/locales/en/projectsetting.json';
import sidebarTranslations from '@/public/locales/en/sidebar.json';
import tableTranslations from '@/public/locales/en/table.json';
import templateTranslations from '@/public/locales/en/template.json';

/**
 * Defines the translations resources for the 'en' locale.
 *
 * The keys match the translation keys used throughout the codebase. The values
 * provide the actual translation data by importing JSON files for each section.
 */
const resources = {
  en: {
    common: commonTranslations,
    models: modelsTranslations,
    branch: branchTranslations,
    addEditChartPopup: addEditChartPoupTranslations,
    addguest: addguestTranslations,
    billingorganization: billingorganizationTranslations,
    copilot: copilotTranslations,
    financialreport: financialreportTranslations,
    form: formTranslations,
    formulaCell: formulaCellTranslations,
    hiringplan: hiringplanTranslations,
    hiringsettings: hiringsettingsTranslations,
    organizationsetting: organizationsettingTranslations,
    ownersetting: ownersettingTranslations,
    personalinfo: personalinfoTranslations,
    projectsetting: projectsettingTranslations,
    sidebar: sidebarTranslations,
    table: tableTranslations,
    template: templateTranslations,
    company: companyTranslations,
    financialsDrawer: financialsDrawerTranslations,
    integrations: integrationsTranslations,
    pages: pagesTranslations,
  },
};

/**
 * Initializes i18next for React by calling initReactI18next and passing configuration options.
 *
 * Initializes translations with the provided resources and configuration. Sets English as the fallback language if the current language is not available. Disables escaping of values during interpolation to allow HTML.
 */
i18n.use(initReactI18next).init({
  resources,
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
