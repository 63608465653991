import { Search, X } from 'lucide-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Popup from 'reactjs-popup';
import { useOpenVault } from '@/components/authComponents/useOpenVault';
import colors from '@/styles/scss/abstracts/_variables.module.scss';
import type { ApiDeckConnector } from '@/utils/hooks/useGetConnectors/useGetConnectors';
import useGetConnectors from '@/utils/hooks/useGetConnectors/useGetConnectors';

// eslint-disable-next-line import/no-cycle
import { IntegrationPageBody } from '../Integrations';
import styles from '../integrations.module.scss';
interface AddIntegrationsPopupProps {
  isIntegrationPopUpOpen: boolean;
  setIsIntegrationPopUpOpen: (isOpen: boolean) => void;
}

// AddIntegrationsPopup is a component that displays a popup for adding integrations
const AddIntegrationsPopup: React.FC<AddIntegrationsPopupProps> = ({
  isIntegrationPopUpOpen,
  setIsIntegrationPopUpOpen
}) => {
  const {
    t: translate
  } = useTranslation(['integrations']);
  const [searchValue, setSearchValue] = useState('');
  const {
    connectorsData
  } = useGetConnectors();
  const openVault = useOpenVault();

  // handleSearch is a function that filters the connectorsData based on the searchValue
  const handleSearch = () => {
    if (connectorsData) {
      return connectorsData.filter((item: ApiDeckConnector) => item?.service_id?.toLowerCase().includes(searchValue.toLowerCase()));
    }
    return [];
  };

  // onConnectIntegration is a function that is called when the user clicks on the connect button for an integration
  const onConnectIntegration = (item: ApiDeckConnector) => {
    openVault(item.service_id, item.unified_api, () => {
      setIsIntegrationPopUpOpen(false);
    });
  };

  // popupContentStyle is a style object that defines the dimensions and appearance of the popup content
  const popupContentStyle = {
    maxWidth: '1100px',
    width: '1100px',
    height: '608px',
    padding: '24px',
    borderRadius: '20px',
    backgroundColor: colors.grayBg2
  };

  // popupOverlayStyles is a style object that defines the appearance of the popup overlay
  const popupOverlayStyles = {
    backgroundColor: colors.modalOcerlayColor
  };
  return <Popup contentStyle={popupContentStyle} overlayStyle={popupOverlayStyles} modal open={isIntegrationPopUpOpen} onClose={() => setIsIntegrationPopUpOpen(false)} data-sentry-element="Popup" data-sentry-component="AddIntegrationsPopup" data-sentry-source-file="AddIntegrationsPopup.tsx">
      <div className={styles.addIntegrationsPoupContainer} data-testid="add-integrations-popup">
        <div className={styles.addIntegrationsPoupHeader}>
          <h1 data-testid="popup-title">{translate('new_integration')}</h1>
          <div className={styles.addIntegrationsPoupInputContainer}>
            <Search className={styles.searchIcon} size={16} data-sentry-element="Search" data-sentry-source-file="AddIntegrationsPopup.tsx" />
            <input type="text" value={searchValue} placeholder={translate('searchPlaceholder')} onChange={e => setSearchValue(e.target.value)} data-testid="search-input"
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus />
            {searchValue && <X className={styles.clearIcon} size={16} onClick={() => setSearchValue('')} data-testid="clear-icon" />}
          </div>
          <X size={20} onClick={() => setIsIntegrationPopUpOpen(false)} data-testid="close-button" data-sentry-element="X" data-sentry-source-file="AddIntegrationsPopup.tsx" />
        </div>
        <div className={styles.addIntegrationsPoupBody}>
          <IntegrationPageBody apiDeckData={handleSearch()} isIntegrationPopUpOpen={isIntegrationPopUpOpen} onConnectIntegration={onConnectIntegration} data-sentry-element="IntegrationPageBody" data-sentry-source-file="AddIntegrationsPopup.tsx" />
        </div>
      </div>
    </Popup>;
};
export default AddIntegrationsPopup;