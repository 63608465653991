import { getCookie } from 'cookies-next';
import { parseCookieString } from '@/cookies';

/**
 * Sets the active object in the URL, cookie, and state.
 *
 * @param data An array of objects with a slug property. These objects
 *            should represent the different possible URLs that can be active.
 * @param activeObject The currently active object, which should have a
 *                     slug property. If this object is not in data, it will be
 *                     ignored.
 * @param setActiveObject A function which updates the active object in the state.
 *                        This function should take an object with a slug property.
 * @param cookieKey The key of the cookie to store the active object in.
 * @param queryKeyValue The current URL slug (from next/router or next/link)
 */
// const logFunction = false;
// function logURLObjChange(
//   urlSameAsActiveObject: any,
//   activeObjectIsInData: any,
//   cookieExists: any,
//   cookieSameAsActiveObject: any,
//   queryKeyValue: any,
//   urlIsOK: any,
// ) {
//   console.log('urlSameAsActiveObject', urlSameAsActiveObject);
//   console.log('activeObjectIsInData', activeObjectIsInData);
//   console.log('cookieExists', cookieExists);
//   console.log('cookieSameAsActiveObject', cookieSameAsActiveObject);
//   console.log('queryKeyValue', queryKeyValue);
//   console.log('urlIsOK', urlIsOK);
// }
// if (logFunction)
//   logURLObjChange(
//     urlSameAsActiveObject,
//     activeObjectIsInData,
//     cookieExists,
//     cookieSameAsActiveObject,
//     queryKeyValue,
//     urlIsOK,
//   );

export function setActiveUrlObject(data: {
  slug: string;
  id: any;
}[], activeObject: {
  slug: string;
  id: any;
}, setActiveObject: (obj: any) => void, cookieKey: string, queryKeyValue: string) {
  const activeObjectCookie = (getCookie(cookieKey) as string | undefined);
  if (data) {
    const urlSameAsActiveObject = activeObject?.slug === queryKeyValue;

    // First: We need to check if the queryKeyValue (slug) is exist in the data
    const activeObjectIsInData = data.some(item => item.id === activeObject?.id);
    const cookieExists = !!activeObjectCookie;

    // Check if the the cookieKey is exist in the storage and return parsed cookie object
    const cookieObject = activeObjectCookie ? parseCookieString(activeObjectCookie) : undefined;
    const cookieSameAsActiveObject = cookieObject?.id === activeObject?.id;
    const urlIsOK = urlSameAsActiveObject && activeObjectIsInData;
    const urlObj = data.find(item => item.slug === queryKeyValue);
    const cookieObjectInData = data.find(item => item?.id === cookieObject?.id);
    if (urlIsOK && !cookieSameAsActiveObject) {
      // update cookie to the active object
      setActiveObject(activeObject);
    } else if (urlObj) {
      setActiveObject(urlObj);
    } else if (cookieExists && !!cookieObjectInData) {
      // we need to setActiveObject because it is wrong, meaning we have to set it.
      // resetting slug and url and zustand to the cookie object
      setActiveObject(cookieObjectInData);
    } else {
      // resetting slug and url and zustand to the first data object, for example main
      setActiveObject(data[0]);
    }
  }
}
// Generic function to handle URL synchronization
export function checkAndSetActiveUrlObject(isLoggedIn: boolean, isSuccess: boolean, currentEntity: any, entities: any[], entityQuery: string | string[] | undefined, setEntity: (entity: any) => void, localStorageKey: string): void {
  if (isLoggedIn && isSuccess) {
    const entityCondition = !currentEntity?.slug || currentEntity?.slug !== entityQuery || !entities?.some((entity: any) => entity.id === currentEntity?.id) || !currentEntity?.id;
    if (entityCondition) {
      // && entityQuery
      setActiveUrlObject(entities, currentEntity, setEntity, localStorageKey, (entityQuery as string));
    }
  }
}