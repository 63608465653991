import 'reactjs-popup/dist/index.css';
import { ChevronRight } from 'lucide-react';
import moment from 'moment';
import type { FC } from 'react';
import { useState } from 'react';
import Popup from 'reactjs-popup';
import styles from '@/styles/scss/cellDatePicker.module.scss';
import { changeDateToString, dateFormatter, parseUTCDateObject } from '@/utils/dateUtils';
import MonthYearPicker from '../../../common/datePickers/MonthYearPicker';
interface HandlerFunctions {
  onBlur: (cell: any, row: any) => (event: {
    target: {
      value: string;
    };
  }) => void;
}
interface ColumnSpec {
  handlerFunctions?: HandlerFunctions;
  // ... include other properties of columnSpec as needed
}
interface CellDatePickerProps {
  defaultValue: string;
  cell: any;
  row: any;
  columnSpec: ColumnSpec;
  style: any;
  isShortyYear?: boolean;
  allowReset?: boolean;
}
const CellDatePicker: FC<CellDatePickerProps> = ({
  defaultValue,
  cell,
  row,
  columnSpec,
  style,
  isShortyYear = false,
  allowReset = false
}) => {
  const [date, setDate] = useState(defaultValue ? parseUTCDateObject(defaultValue) : null);

  /**
   * Updates the date based on the input and triggers an onBlur event.
   * It combines the functionality of adjusting the date by a specified number of months or setting it directly.
   *
   * @param {number | Date} num - The value to adjust the date. If a number, it adjusts the month by this value.
   *                              If a Date, it sets the date directly to this value. A positive number increases the month,
   *                              while a negative number decreases it. The function prevents setting a future date if the
   *                              adjustment results in a date beyond the current date.
   *                              If a Date object, it directly sets the date to the provided value.
   *
   * Preconditions: 'date' should be a valid Date object. The 'columnSpec.handlerFunctions' must exist and contain an 'onBlur' method.
   *
   * Postconditions: If conditions are met and no future date is attempted to be set, the date state is updated, and
   *                 the onBlur handler is triggered with the new date value. If any checks fail, appropriate actions
   *                 are taken (e.g., logging an error, not setting a future date).
   */
  const handleDateChange = (value: number | Date | null) => {
    // Type safety: Ensure onBlur is provided
    if (!columnSpec.handlerFunctions || typeof columnSpec.handlerFunctions.onBlur !== 'function') {
      throw new Error('Component rendered without required onBlur handler on handlerFunctions.');
    }
    let newDate: Date | null = null;
    if (typeof value === 'number') {
      if (!date) return;
      newDate = moment(date).add(value, 'months').utc().toDate();
    } else {
      newDate = value;
    }
    setDate(newDate);
    columnSpec.handlerFunctions.onBlur(cell, row)({
      target: {
        // @ts-ignore
        value: newDate ? (changeDateToString(newDate) as string) : newDate
      }
    });
  };

  /**
   * Function to set the class name for calendar tiles.
   * Checks if the view is 'year' and the newDate matches the component's
   * date state, to set the 'active-month' class.
   */
  const tileClassName = ({
    date: newDate,
    view
  }: {
    date: Date;
    view: string;
  }) => {
    if (view === 'year') {
      if (newDate.getTime() === date?.getTime()) {
        return 'active-month';
      }
    }
  };
  return <div className={styles.dataCellPickerContainer} style={{
    ...style,
    padding: 0
  }} data-sentry-component="CellDatePicker" data-sentry-source-file="cellDatePicker.tsx">
      <div className={styles.dataCellPicker}>
        <span onClick={() => {
        handleDateChange(-1);
      }} className={!date ? `${styles.disabled} ${styles.rightArrow}` : `${styles.rightArrow}`}>
          <ChevronRight size={18} data-sentry-element="ChevronRight" data-sentry-source-file="cellDatePicker.tsx" />
        </span>
        <Popup trigger={<span className={styles.dateText}>
              {date ? dateFormatter(date, isShortyYear) : '-'}
            </span>} position="bottom center" data-sentry-element="Popup" data-sentry-source-file="cellDatePicker.tsx">
          {
        // @ts-ignore
        (close: () => void) => <>
                <MonthYearPicker className="calendar-design" value={(date as Date)} onChange={handleDateChange} tileClassName={tileClassName} />

                {allowReset && <div className={styles.resetButtonContainer}>
                    <button type="button" className={styles.resetButton} disabled={!date} onClick={() => {
              handleDateChange(null);
              close();
            }}>
                      Reset date
                    </button>
                  </div>}
              </>}
        </Popup>
        <span onClick={() => {
        handleDateChange(1);
      }} className={!date ? styles.disabled : ''}>
          <ChevronRight size={18} data-sentry-element="ChevronRight" data-sentry-source-file="cellDatePicker.tsx" />
        </span>
      </div>
    </div>;
};
export default CellDatePicker;