import type { UseMutationResult } from '@tanstack/react-query';
import { useShallow } from 'zustand/react/shallow';
import { updateEmployeeSortOrder } from '@/components/table/shared/utils';
import type { UtilsStoreState } from '@/miscellaneous/store/utilsStore/utilsStore';
import useUtilsStore from '@/miscellaneous/store/utilsStore/utilsStore';
import type { ZustandState } from '@/miscellaneous/store/zustand_store';
import useZustandStore from '@/miscellaneous/store/zustand_store';
import type { Prompt } from '@/utils/types/AITypes';
import type { Employee, HiringPlan } from '@/utils/types/HiringTypes';
import { getDateStringFormat, parseUTCDateObject } from '../dateUtils';
import type { Branch } from '../hooks/branches/useBranches';
import useBranches from '../hooks/branches/useBranches';
import { useEmployeesQuery } from '../hooks/employees/useEmployees';
import { useProcessedBranchEmployees } from '../hooks/employees/useProcessedBranchEmployees';
import useMutations from '../hooks/mutations/useMutations';

/**
 * applies Hiring prompts to employees
 */
function applyHiringPrompts(branch_id: number, employees: Employee[], processedEmployees: Employee[], modifiedEmployeeIdsList: number[], createBulkEmployee: UseMutationResult<any, any, any, unknown>, deleteBulkEmployee: UseMutationResult<any, unknown, {
  ids: number[];
}, unknown>, updateBulkEmployee: UseMutationResult<any, unknown, any, unknown>, updateHiringPlan: UseMutationResult<any, unknown, any, unknown>, activeHiringPlan: HiringPlan) {
  return new Promise<void>(resolve => {
    const currentEmployeeSortOrder = activeHiringPlan.employee_sort_order;
    const mutations = [];

    // Define employees to delete
    const employeesToDelete = employees.filter(employee => employee.id !== undefined && processedEmployees.findIndex(processedEmployee => processedEmployee.id === employee.id) === -1).map(employee => (employee.id as number));

    // Define employees to create
    const employeesToCreate = processedEmployees.filter(employee => employee?.is_ai_employee).map(employee => {
      const newEmployee = ({
        ...employee,
        branch_id,
        start_date: employee.start_date ? getDateStringFormat(parseUTCDateObject(employee.start_date)) : ''
      } as Employee);
      delete newEmployee.id;
      delete newEmployee.is_ai_employee;
      return newEmployee;
    });

    // Define employees to update
    const employeesToUpdate = processedEmployees.filter(employee => !employee.is_ai_employee && modifiedEmployeeIdsList.includes((employee.id as number))).map(employee => {
      const currentEmployee = employee;
      if (!currentEmployee.role) {
        delete currentEmployee.role;
      }
      return ({
        ...employee,
        branch_id
      } as Employee);
    });
    if (employeesToDelete?.length > 0) {
      mutations.push(deleteBulkEmployee.mutateAsync({
        ids: employeesToDelete
      }).then(() => updateHiringPlan.mutateAsync({
        ...activeHiringPlan,
        employee_sort_order: updateEmployeeSortOrder(employeesToDelete, currentEmployeeSortOrder, true)
      })));
    }
    if (employeesToCreate?.length > 0) {
      mutations.push(createBulkEmployee.mutateAsync(employeesToCreate)
      // eslint-disable-next-line @typescript-eslint/no-shadow
      .then((employees: Employee[]) => updateHiringPlan.mutateAsync({
        ...activeHiringPlan,
        employee_sort_order: updateEmployeeSortOrder(employees.map(employee => (employee.id as number)), currentEmployeeSortOrder)
      })));
    }
    if (employeesToUpdate?.length > 0) {
      mutations.push(updateBulkEmployee.mutateAsync(employeesToUpdate));
    }
    Promise.all(mutations).then(() => resolve());
  });
}
async function mergePromptsToNewBranch(activeBranch: any, cloneBranch: (brannchToCloneId: number, body?: any) => Promise<Branch>, processedEmployees: Employee[], createBulkEmployee: UseMutationResult<any, any, any, unknown>) {
  const {
    id,
    ...branchBody
  } = activeBranch;
  const newBranchBody = {
    model: {
      ...branchBody,
      is_main: false,
      name: `Copy of ${activeBranch.name}`,
      status: 'active',
      slug: `copy-of-${activeBranch.slug}`
    },
    //  optional parametr to disable cloning eployees
    ignore_children: ['employees']
  };
  const employeesToCreate = processedEmployees.map(employee => {
    const newEmployee = ({
      ...employee,
      start_date: employee.start_date ? getDateStringFormat(parseUTCDateObject(employee.start_date)) : null // converting start date to ISO string
    } as Employee);
    delete newEmployee.id; // remove temporary id from employee
    delete newEmployee.is_ai_employee; // remove is_ai_employee from employee
    return newEmployee;
  });

  // clone new branch and set active branch to new branch
  const clonedBranch = await cloneBranch(id, newBranchBody);

  // add branch id to all employees to create
  employeesToCreate.forEach((employee: Employee) => {
    // eslint-disable-next-line no-param-reassign
    employee.branch_id = clonedBranch.id;
  });
  createBulkEmployee.mutate(employeesToCreate);
  return clonedBranch.id;
}
export function useMergePrompts() {
  const {
    employees: processedEmployees,
    modifiedEmployeeIdsList
  } = useProcessedBranchEmployees();
  const {
    employeesByBranch
  } = useEmployeesQuery();
  const {
    activeBranch,
    cloneBranch
  } = useBranches();
  const {
    prompts,
    resetPrompts,
    activeHiringPlan
  } = useZustandStore(useShallow((state: ZustandState) => ({
    prompts: state.prompts,
    resetPrompts: state.resetPrompts,
    activeHiringPlan: state.activeHiringPlan
  })));
  const {
    setAppLoader
  } = useUtilsStore(useShallow((state: UtilsStoreState) => ({
    setAppLoader: state.setAppLoader
  })));
  const {
    createBulkEmployee,
    deleteBulkEmployee,
    updateBulkEmployee,
    updateHiringPlan
  } = useMutations();
  async function mergePrompts(mergeToNewBranch: boolean = false) {
    let currentBranchID = activeBranch.id;
    // Extract prompts to local variable before any operations
    const localPrompts = [...prompts];
    const hiringPrompts = localPrompts.filter((prompt: Prompt) => prompt.type === 'hiring-plan');
    if (!localPrompts?.length) {
      console.error('No prompts provided');
      return;
    }

    // Reset prompts in store immediately
    await resetPrompts();
    setAppLoader(true);
    try {
      if (mergeToNewBranch && hiringPrompts?.length > 0) {
        currentBranchID = await mergePromptsToNewBranch(activeBranch, cloneBranch, processedEmployees, createBulkEmployee);
      } else if (hiringPrompts?.length > 0) {
        await Promise.all([new Promise<void>(resolve => {
          applyHiringPrompts(currentBranchID, employeesByBranch, processedEmployees ?? [], modifiedEmployeeIdsList, createBulkEmployee, deleteBulkEmployee, updateBulkEmployee, updateHiringPlan, activeHiringPlan);
          resolve();
        })]);
      }
    } catch (error) {
      console.error('Error applying prompts:', error);
    } finally {
      setTimeout(() => {
        setAppLoader(false);
      }, 1500);
    }
  }
  return mergePrompts;
}