import { MdKeyboardArrowDown } from 'react-icons/md';
import styles from '@/styles/scss/header.module.scss';
const DropdownToggle = ({
  header,
  toggleDropdown,
  color,
  style
}: {
  header: string;
  toggleDropdown: () => void;
  color?: string;
  style?: any;
}) => {
  return <div className="flex items-center" data-sentry-component="DropdownToggle" data-sentry-source-file="DropdownToggle.tsx">
      <button type="button"
    // eslint-disable-next-line tailwindcss/no-custom-classname
    className={`${styles.dropdownToggleBtn} ${color ? `text-${color}` : ` text-white`}`} onClick={toggleDropdown} style={style || {}}>
        {header}
      </button>
      <MdKeyboardArrowDown size={20} data-sentry-element="MdKeyboardArrowDown" data-sentry-source-file="DropdownToggle.tsx" />
    </div>;
};
export default DropdownToggle;