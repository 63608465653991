import React from 'react';
import Drawer from 'react-modern-drawer';
import type { ZustandState } from '@/miscellaneous/store/zustand_store';
import useZustandStore from '@/miscellaneous/store/zustand_store';

// eslint-disable-next-line import/no-cycle

/**
 * Renders a sidebar drawer component with custom styles and content.
 * The drawer is controlled by the `isDrawerOpen` state from the `SidebarDrawerContext`.
 * The content of the drawer is passed in as the `drawerContent` prop.
 */
const SidebarDrawer = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const drawerStyles = {
    height: 'calc(100% - 75px)',
    // full height minus the height of the navbar.
    width: '370px',
    bottom: '0',
    top: 'none',
    borderRadius: '24px 24px 0px 0px'
  };
  const {
    isSidebarDrawerOpen,
    setIsSidebarDrawerOpen
  } = useZustandStore((state: ZustandState) => state);
  // when pathname changes close the drawer
  React.useEffect(() => {
    setIsSidebarDrawerOpen(false);
  }, []);
  return <Drawer enableOverlay={false} // Disables the overlay click to close the drawer.
  open={isSidebarDrawerOpen} // Controls if the drawer is open based on the chat state.
  direction="right" // Sets the drawer to slide in from the right side.
  style={drawerStyles} // Applies custom styles to the drawer.
  data-sentry-element="Drawer" data-sentry-component="SidebarDrawer" data-sentry-source-file="SidebarDrawer.tsx">
      {children}
    </Drawer>;
};

// Exporting SidebarDrawer to be used elsewhere in the application.
export default SidebarDrawer;