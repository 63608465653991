import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useShallow } from 'zustand/react/shallow';
import ApiUtils from '@/api/ApiUtils';
import type { ZustandState } from '@/miscellaneous/store/zustand_store';
import useZustandStore from '@/miscellaneous/store/zustand_store';
import { QUERYKEYS } from '../../../miscellaneous/constant/reactQueryKeyConfig';
interface Dates {
  end_date?: string;
  start_date?: string;
  current_date?: string;
}
export interface UserJson {
  id: number;
  username: string;
  email: string;
  first_name: string;
  last_name: string;
  full_name: string;
  dates?: Dates;
  image?: string;
}

// function getDatesForAPI(data: UserJson) {
//   let currentDate = new Date(data?.dates?.current_date ?? 0);
//   currentDate = isValidDate(currentDate) ? currentDate : new Date();
//   currentDate.setHours(0, 1, 0, 0);
//   currentDate.setDate(1);

//   let startDate = new Date(data?.dates?.start_date ?? 0);
//   startDate = isValidDate(startDate)
//     ? startDate
//     : new Date(currentDate.setMonth(currentDate.getMonth() - 1));
//   startDate.setDate(1);
//   startDate.setHours(0, 1, 0, 0);
//   let endDate = new Date(data?.dates?.end_date ?? 0);
//   endDate = isValidDate(endDate)
//     ? endDate
//     : new Date(currentDate.setFullYear(currentDate.getFullYear() + 1));
//   endDate.setDate(1);
//   endDate.setHours(0, 1, 0, 0);
//   return { startDate, currentDate, endDate };
// }

const useGetUserMe = () => {
  const {
    userMe,
    setUserMe,
    isLoggedIn
  } = useZustandStore(useShallow((state: ZustandState) => ({
    isLoggedIn: state.isLoggedIn,
    userMe: state.userMe,
    setUserMe: state.setUserMe,
    setCurrentDate: state.setCurrentDate,
    setEndDate: state.setEndDate
  })));
  const {
    data,
    isLoading,
    error
  } = useQuery<UserJson>({
    queryKey: [QUERYKEYS.USER_ME, isLoggedIn?.username],
    queryFn: () => ApiUtils.getUserMe(),
    enabled: Boolean(isLoggedIn)
  });
  useEffect(() => {
    if (data) {
      setUserMe(data);
    }
  }, [data, isLoading, error]);
  return {
    userMe,
    isLoading,
    error
  };
};
export default useGetUserMe;