import { useShallow } from 'zustand/react/shallow';
import { getOptionsFromReports } from '@/components/genericToggleMenu/financials/subComponents/toggleFinancialUtils';
import type { Option } from '@/components/genericToggleMenu/subComponents/ToggleOptions';
import type { ZustandState } from '@/miscellaneous/store/zustand_store';
import useZustandStore from '@/miscellaneous/store/zustand_store';
import useMutations from '@/utils/hooks/mutations/useMutations';
import TableOptionsSection from './TableOptionsSection';

/**
 * Renders the Financials Table Options Section component.
 * This component is responsible for rendering the options section of the financials table, which allows the user to customize the table's appearance and behavior.
 * It retrieves the active report from the Zustand store and uses the `getOptionsFromReports` function to generate the available options.
 * The options are then passed to the `TableOptionsSection` component for rendering.
 * If there is no active report or the report's ID is undefined, a placeholder `<p>` element is returned instead.
 */
const FinancialsTableOptionsSection = () => {
  const {
    updateReport
  } = useMutations();
  const {
    activeReport
  } = useZustandStore(useShallow((state: ZustandState) => {
    return {
      activeReport: state.activeReport
    };
  }));
  if (!activeReport || activeReport?.id === undefined) {
    // check that there ar hiring plans otherwise return p tag
    return <p id="popover" data-testid="popover" />;
  }
  const reportOptions = getOptionsFromReports(activeReport, updateReport);
  const isLastOption = reportOptions.filter((option: Option) => option.enabled).length === 1;
  return <TableOptionsSection options={reportOptions} isLastOption={isLastOption} data-sentry-element="TableOptionsSection" data-sentry-component="FinancialsTableOptionsSection" data-sentry-source-file="FinancialsTableOptionsSection.tsx" />;
};
export default FinancialsTableOptionsSection;