import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import Popup from 'reactjs-popup';
import { useShallow } from 'zustand/react/shallow';
import { generateIconsByType, goToFormulaById } from '@/components/table/shared/cells/formulaCell/formulaCellUtils';
import CalculationsStore from '@/miscellaneous/store/CalculationsStore';
import type { ZustandState } from '@/miscellaneous/store/zustand_store';
import useZustandStore from '@/miscellaneous/store/zustand_store';
import type { Formula } from '@/utils/types/formulaTypes';
import styles from './showAllUsageModal.module.scss';

/**
 * A modal component that displays all the formulas related to a specific formula.
 *
 * @param {object} props - The component props.
 * @param {boolean} props.open - Determines whether the modal should be open or not.
 * @param {() => void} props.onClose - A callback function to close the modal.
 * @returns {JSX.Element} - The ShowAllUsageModal component.
 */
const ShowAllUsageModal = ({
  popupTrigger,
  formulaId,
  onClose
}: {
  popupTrigger: JSX.Element;
  formulaId: number;
  onClose: () => void;
}) => {
  const [relatedFormulas, setRelatedFormulas] = useState<Formula[] | undefined>([]);

  // Styles for the popup content.
  const popupContentStyle = {
    backgroundColors: 'transparent',
    border: 'none',
    boxShadow: 'rgba(19, 25, 35, 0.15) 0px 8px 20px 0px',
    width: 'auto',
    padding: '0',
    outline: 'none',
    borderRadius: '16px',
    overflow: 'hidden',
    minWidth: '400px',
    minHeight: '270px',
    maxHeight: '270px',
    maxWidth: '400px',
    marginLeft: '10px'
  };
  const router = useRouter();

  // Gets the branch formulas, active branch models, active branch slug, and active company slug from the store.
  const {
    branchFormulas,
    activeBranchModels
  } = useZustandStore(useShallow((state: ZustandState) => ({
    branchFormulas: state.branchFormulas,
    activeBranchModels: state.activeBranchModels
  })));

  // Gets the formula dependency map from the store.
  const {
    formulaDependencyMap
  } = CalculationsStore(useShallow(state => ({
    formulaDependencyMap: state.formulaDependencyMap
  })));

  // Finds the model by ID.
  const findModelById = (modelId: number) => {
    return activeBranchModels.find(model => model.id === modelId);
  };

  // Finds the model name by ID.
  useEffect(() => {
    if (formulaId) {
      const formulaKeys: number[] = [];
      for (const [formulaKey, formulas] of Object.entries(formulaDependencyMap)) {
        if (formulas.includes(formulaId)) {
          formulaKeys.push(+formulaKey);
        }
      }
      const findFormulaById = branchFormulas.filter(formula => formulaKeys.includes(formula.id));
      setRelatedFormulas(findFormulaById);
    }
  }, [formulaId]);

  // Redirects the user to the models page when the transaction item is clicked.
  const redirectToCalculation = (formula_id: number, close: any) => {
    close();
    goToFormulaById(formula_id, router);
  };
  return <Popup trigger={popupTrigger} arrow={false} contentStyle={popupContentStyle} onClose={() => onClose()} position={['right top', 'right bottom']} data-sentry-element="Popup" data-sentry-component="ShowAllUsageModal" data-sentry-source-file="ShowAllUsageModal.tsx">
      {
    // @ts-ignore
    (close: any) => <div className={styles.showAllUsageModalContent}>
            <Tooltip id="tooltip-formula" positionStrategy="fixed" style={{
        zIndex: 9999
      }} />
            {relatedFormulas?.map(formula => {
        return <div key={formula.id} className={styles.showAllUsageModalItem} onClick={() => redirectToCalculation(formula.id, close)}>
                  <span className={styles.showAllUsageModalItemIcon}>
                    {generateIconsByType(formula.group)}
                  </span>
                  <div className={styles.showAllUsageModalItemContent}>
                    <span data-tooltip-id="tooltip-formula" data-tooltip-content={formula.name}>
                      {formula.name}
                    </span>
                    <span className={styles.showAllUsageModalItemGroup}>
                      {formula.group?.toLocaleLowerCase()}
                    </span>
                  </div>
                  <span className={styles.showAllUsageModalItemModel} data-tooltip-id="tooltip-formula" data-tooltip-content={findModelById(formula.model_id)?.name}>
                    {findModelById(formula.model_id)?.name}
                  </span>
                </div>;
      })}
            {relatedFormulas?.length === 0 && <div className={styles.showAllUsageModalNoItem}>
                No active usage found
              </div>}
          </div>}
    </Popup>;
};
export default ShowAllUsageModal;