'use client';

import { ChevronDown } from 'lucide-react';
import { formatDateToMonthAndYearAbbreviation } from '@/utils/dateUtils';
import styles from '../DateRangeSelector.module.scss';
interface DialogButtonProps {
  fromDate: Date | undefined;
  toDate: Date | undefined;
  buttonClassName?: string;
}

/**
 * DialogButton is a component that represents a button triggering a dialog.
 *
 * @component
 * @param {Object} props - The properties of the DialogButton component.
 * @param {Date} props.fromDate - The starting date of the selected date range.
 * @param {Date} props.toDate - The ending date of the selected date range.
 * @returns {JSX.Element} Rendered DialogButton component.
 */
function DialogButton({
  fromDate,
  toDate,
  buttonClassName
}: DialogButtonProps) {
  const className = buttonClassName ? styles[buttonClassName] : styles.dialogButton;
  return <button type="button" data-testid="color-button" className={className} data-sentry-component="DialogButton" data-sentry-source-file="DialogButton.tsx">
      {!fromDate && !toDate && 'Select Dates'}
      {fromDate && `${formatDateToMonthAndYearAbbreviation(fromDate)} - `}
      {toDate && formatDateToMonthAndYearAbbreviation(toDate)}{' '}
      <ChevronDown size={17} data-sentry-element="ChevronDown" data-sentry-source-file="DialogButton.tsx" />
    </button>;
}
export default DialogButton;