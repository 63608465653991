import { useQuery } from '@tanstack/react-query';
import { isEqual } from 'lodash';
import { useEffect } from 'react';

import ApiUtils from '@/api/ApiUtils';
import { QUERYKEYS } from '@/miscellaneous/constant/reactQueryKeyConfig';
import type { ZustandState } from '@/miscellaneous/store/zustand_store';
import useZustandStore from '@/miscellaneous/store/zustand_store';

export interface EmployeeColumn {
  model_type: string;
  id: number;
  type: string;
  company_id: number;
  name: string;
}

/**
 * Custom hook to manage employee columns state and api calls.
 *
 * Fetches employee columns data from API and stores in Zustand.
 * Provides methods to delete, create, and update employee columns.
 * Handles loading and error state.
 */

const useEmloyeeColumns = () => {
  const { fields, setFields, activeCompany } = useZustandStore(
    (state: ZustandState) => state,
  );

  const { data, isLoading, error, refetch } = useQuery<EmployeeColumn[]>({
    queryKey: [QUERYKEYS.EMPLOYEE_COLUMNS, activeCompany?.id],
    queryFn: () => ApiUtils.getEmployeeColumns(activeCompany?.id as number),
  });

  useEffect(() => {
    if (data && data instanceof Array && activeCompany && setFields) {
      if (!isEqual(data, fields)) {
        setFields(data);
      }
    }
  }, [data, activeCompany]);

  return {
    fields,
    isLoading,
    error,
    refetch,
  };
};

export default useEmloyeeColumns;
