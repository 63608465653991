/* eslint-disable no-param-reassign */

import type {
  FormulaEntries,
  FormulaEntry,
} from '@/components/modelCalculations/modelUtils/modelUtils';
import { isValidNumber } from '@/components/modelCalculations/modelUtils/modelUtils';
import { addEntriesToRows } from '@/components/table/shared/TableHelpers/sharedTableRowRenderUtils';
import CalculationsStore from '@/miscellaneous/store/CalculationsStore';
import {
  type CombinedEntry,
  getOutputValues,
  type OutputValues,
  type OutputValuesMap,
  updateOutputValuesWithCumulativeSum,
  type ValueByDate,
} from '@/miscellaneous/store/calulcationsStoreHelper';
import DataStore from '@/miscellaneous/store/DataStore';
import useZustandStore from '@/miscellaneous/store/zustand_store';
import {
  getDateStringFormat,
  getIsoString,
  parseUTCDateObject,
} from '@/utils/dateUtils';
import type { Formula } from '@/utils/types/formulaTypes';
import type { ItemType } from '@/utils/types/types';

import type { Company } from '../company/useCompany';
import type { AccountTree, Output } from '../Outputs/useOutputs';
import type { LiveEntry } from './useLiveEntries';

export function getOutputBalanceEntries(
  activeCompany: Company,
  accountTrees: AccountTree[],
  activeBranchId: number,
): LiveEntry[] {
  const timelineStartDate = activeCompany?.timeline_start_date;
  // one year back
  const placeholder = parseUTCDateObject();
  placeholder.setFullYear(placeholder.getFullYear() - 1);

  let startDateMinusOne = placeholder.toString();

  if (timelineStartDate) {
    const startDate = parseUTCDateObject(timelineStartDate);
    startDate.setMonth(startDate.getMonth() - 1);
    startDateMinusOne = getDateStringFormat(startDate);
  }

  const outputBalanceEntries = accountTrees
    .map((accountTree, idx) => {
      if (accountTree.output.balance) {
        // create new live entry for first balance with timeline start date and output id as output.
        const entry: LiveEntry = {
          output_id: accountTree.output.id,
          company_id: activeCompany.id,
          deleted_at: null,
          created_at: timelineStartDate || '',
          date: startDateMinusOne,
          name: `${accountTree.output.name} Initial Balance`,
          department_id: null,
          id: idx,
          updated_at: timelineStartDate || '',
          amount: accountTree.output.balance,
          branch_id: activeBranchId,
          formula_id: 0,
          reference_id: 0,
          reference_type: 'ACCOUNTING',
        };
        return entry;
      }
      return null;
    })
    .filter((entry) => entry !== null);
  return (
    (outputBalanceEntries.filter(
      (entry): entry is LiveEntry => entry !== null,
    ) as LiveEntry[]) || []
  );
}

export function createNewTotalRowsByAccountMap(
  outputData: Output[] | undefined,
  startDate: Date,
  endDate: Date,
) {
  const totalRowsByAccount: OutputValuesMap = {};

  // iterate over all outputs, from start to end, and populate accountValuesMap with 0
  outputData?.forEach((output) => {
    const accountID = output.id;
    if (!accountID) return; // skipping the current row if accountID is not available
    totalRowsByAccount[accountID] = {
      output,
      dateValues: {},
      culmutativeDateValues: {},
    };

    const d = parseUTCDateObject(startDate);
    for (d; d <= endDate; d.setMonth(d.getMonth() + 1)) {
      const timestampKey = getIsoString(d);
      const curRow = totalRowsByAccount[accountID]!;
      curRow.dateValues[timestampKey] = {
        TotalValueAtDate: 0,
        entries: [],
      };
    }
  });
  return totalRowsByAccount;
}
// just create a map with outputs without dates
export function createNewTotalRowsByAccountMapWithoutDates(
  outputData: Output[] | undefined,
) {
  const totalRowsByAccount: OutputValuesMap = {};
  // iterate over all outputs, from start to end, and populate accountValuesMap with 0
  outputData?.forEach((output) => {
    const accountID = output.id;
    if (!accountID) return; // skipping the current row if accountID is not available
    totalRowsByAccount[accountID] = {
      output,
      dateValues: {},
      culmutativeDateValues: {},
    };
  });
  return totalRowsByAccount;
}

// this function processes the formula entries and sums them up according to outputs
export function ProcessFormulaEntries( // add all entries here into banksz`
  items: FormulaEntries,
  branchOutputs: Output[],
): OutputValuesMap {
  const totalRowsByAccount: OutputValuesMap =
    createNewTotalRowsByAccountMapWithoutDates(branchOutputs);
  const { branchFormulas } = useZustandStore.getState();
  const { HPformulas } = CalculationsStore.getState();
  const formulas = [...branchFormulas, ...HPformulas];

  // iterate over FormulaEntries formula by formula
  Object.keys(items).forEach((formulaId: string) => {
    const formulaEntry = items[Number(formulaId)] as FormulaEntry;
    const formula = formulas.find(
      (f: Formula | ItemType) => f.id === Number(formulaId),
    );
    const modelID = formula?.model_id ?? 0;
    const accountID = formulaEntry?.to_account ?? 0;

    if (!accountID) return; // skipping the current row if accountID is not available

    Object.keys(formulaEntry).forEach((date: string) => {
      // if is not in date format, skip
      if (!date.match(/^\d{4}-\d{2}-\d{2}$/)) return;
      // date = "YYYY-  MM-DD"
      const valueAtDate = formulaEntry[date]; // value at date

      if (!isValidNumber(valueAtDate)) return; // not infinity or NaN

      // Total rows by account
      if (accountID && valueAtDate !== undefined && valueAtDate !== null) {
        if (!totalRowsByAccount[accountID]) {
          totalRowsByAccount[accountID] = {
            dateValues: {},
            culmutativeDateValues: {},
          };
        }
        const accountValues = totalRowsByAccount[accountID]!;
        const timestampKey = getIsoString(parseUTCDateObject(date));

        accountValues.dateValues[timestampKey] = accountValues.dateValues[
          timestampKey
        ] || {
          TotalValueAtDate: 0,
          entries: [],
        }; // setting default value
        const currentAccountDateValue = accountValues.dateValues[
          timestampKey
        ] as ValueByDate;

        const newTotalValue =
          (accountValues.dateValues[timestampKey]?.TotalValueAtDate as number) +
          (valueAtDate ?? 0);

        currentAccountDateValue.TotalValueAtDate = newTotalValue;
        const newEntry = {
          date,
          amount: valueAtDate,
          output_id: accountID,
          formula_id: Number(formulaId),
          branch_id: (formula as Formula)?.branch_id ?? 0,
          created_at: (formula as Formula)?.created_at ?? '',
          updated_at: (formula as Formula)?.updated_at ?? '',
          name: formula?.name ?? '',
          model_id: modelID,
        } as CombinedEntry;
        currentAccountDateValue.entries.push(newEntry);

        // const curRow = accountValues.defaultOutputValues;
        // curRow[timestampKey] = curRow[timestampKey] || 0; // setting default value
        // curRow[timestampKey] = Math.round(
        //   (curRow[timestampKey] as number) + (valueAtDate ?? 0),
        // );
      }
    });
  });
  return totalRowsByAccount;
}

// Main function to process LiveEntry items to create a map of live account values
export function processLiveEntries(liveEntries: LiveEntry[]): OutputValuesMap {
  const { branchOutputs } = DataStore.getState();
  const totalRowsByAccount: OutputValuesMap =
    createNewTotalRowsByAccountMapWithoutDates(branchOutputs);

  liveEntries.forEach((entry) => {
    if (!entry.output_id) return; // Skip if output_id is missing
    const entryDate = parseUTCDateObject(entry.date);

    // if (entryDate < startDate || entryDate > endDate) return; // Skip entries outside the date range
    const dateKey = getIsoString(entryDate);

    // Initialize account in map if it doesn't exist
    if (!totalRowsByAccount[entry.output_id]) {
      totalRowsByAccount[entry.output_id] = {
        dateValues: {},
        culmutativeDateValues: {},
      };
    }

    const accountValues = totalRowsByAccount[entry.output_id] as OutputValues;
    accountValues.dateValues[dateKey] = accountValues.dateValues[dateKey] || {
      TotalValueAtDate: 0,
      entries: [],
    };
    const dateValues = accountValues.dateValues[dateKey] as ValueByDate;
    // Initialize the date in the account if it doesn't exist
    // Sum up the amounts and add entry details

    if (isValidNumber(entry.amount)) {
      dateValues.TotalValueAtDate += entry.amount;
      dateValues?.entries.push(entry as CombinedEntry);
    }
  });

  return totalRowsByAccount;
}

function getHeight(node: AccountTree): number {
  if (!node.children || node.children.length === 0) {
    return 0;
  }
  return 1 + Math.max(...node.children.map(getHeight));
}

export function sortAccountTrees(trees: AccountTree[]): AccountTree[] {
  return trees.sort((a, b) => getHeight(a) - getHeight(b));
}

// adding the output total balues to map, summing up children values to a total values for account trees
function setOutputtotalValuesToMap(
  accountTrees: AccountTree[],
  newMap: OutputValuesMap,
) {
  const sortedTrees = sortAccountTrees(accountTrees); // sorting by height to make sure we are calaculating children values before parents.

  sortedTrees.forEach((accountTree) => {
    const accountID = accountTree.output.id;
    let accountMap = newMap[accountID];
    const totalOutputValues = accountMap?.outputTotalValues ?? {};

    if (!accountMap) {
      accountMap = {
        output: accountTree.output,
        dateValues: {},
        culmutativeDateValues: {},
        outputTotalValues: totalOutputValues,
      };
      newMap[accountID] = accountMap;
    }
    // add current output dates to outputTotalValues
    const curAccountDefaultOutputs = getOutputValues(accountMap) ?? {};
    // Add entries to the current node's row.
    addEntriesToRows(
      [totalOutputValues],
      curAccountDefaultOutputs,
      undefined,
      1,
    );

    // add the account map values to outputTotalValues
    accountTree.children?.forEach((child) => {
      // go over each child and add its totalOutputValues to the map account values object at total
      const childID = child.output.id;
      const childMap = newMap[childID];
      if (childMap) {
        // add the child map values to outputTotalValues
        const entriesToAdd =
          Object.keys(childMap?.outputTotalValues ?? {}).length > 0
            ? childMap.outputTotalValues
            : Object.keys(childMap?.dateValues ?? {}).length > 0
              ? getOutputValues(childMap)
              : {};
        addEntriesToRows([totalOutputValues], entriesToAdd ?? {}, undefined, 1);
      }
    });
    accountMap.outputTotalValues = totalOutputValues;
    newMap[accountID] = accountMap;

    updateOutputValuesWithCumulativeSum(accountMap);
  });
}

export const memoizedSetOutputtotalValuesToMap = setOutputtotalValuesToMap;

// Memoized function
// export const memoizedSetOutputtotalValuesToMap = _.memoize(
//   setOutputtotalValuesToMap,
//   (accountTrees, newMap) => {
//     // Custom resolver function to determine cache key
//     return (
//       JSON.stringify(accountTrees.map((tree) => tree.output)) +
//       JSON.stringify(Object.keys(newMap).sort())
//     );
//   },
// );
