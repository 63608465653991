/* eslint-disable import/no-cycle */
import type { ISymbol } from '@/utils/types/formulaTypes';
import { enableLogging } from '../calculateFormula';
function isNumber(str: any) {
  // eslint-disable-next-line no-restricted-globals
  return !isNaN(str);
}
export const formulaToSymbols = (formulaStr: string): ISymbol[] => {
  if (typeof formulaStr !== 'string') {
    if (enableLogging) console.log(`formulaToSymbols: formulaStr is not a string: ${formulaStr}`);
    return [];
  }
  const symbols: ISymbol[] = [];
  let index = 0;
  if (!formulaStr) {
    return symbols;
  }
  const {
    length
  } = formulaStr;
  while (index < length) {
    const char = (formulaStr[index] as string);
    if (char === ' ') {
      index += 1;
      // eslint-disable-next-line no-continue
      continue;
    }
    if (['+', '-', '*', '/', '>', '<', '?', '=', '%'].includes(char)) {
      symbols.push({
        symbolName: char,
        symbolType: 'Operator'
      });
      index += 1;
    } else if (char === '{') {
      // Function/Formula case
      if (formulaStr[index + 1] === '{') {
        // Formula case
        index += 2;
        const startIndex = index;
        while (index < length && formulaStr[index] !== '}') {
          index += 1;
        }
        const formulaName = formulaStr.substring(startIndex, index);
        index += 2;
        symbols.push({
          symbolName: formulaName,
          symbolType: 'Formula'
        });
      } else {
        const startIndex = index + 1;
        while (index < length && formulaStr[index] !== '}') {
          index += 1;
        }
        const functionName = formulaStr.substring(startIndex, index);
        index += 1;
        symbols.push({
          symbolName: functionName,
          symbolType: 'Function'
        });
      }
    } else if (isNumber(char)) {
      const startIndex = index;
      let curChar = (formulaStr[index] as string);
      while (index < length && curChar && (isNumber(curChar) || curChar === '.')) {
        index += 1;
        curChar = (formulaStr[index] as string);
      }
      symbols.push({
        symbolName: formulaStr.substring(startIndex, index),
        symbolType: 'Number'
      });
    } else if (['?', ':', '(', ')', ',', '[', ']'].includes(char)) {
      symbols.push({
        symbolName: char,
        symbolType: 'LogicOperand'
      });
      index += 1;
    } else {
      if (enableLogging) console.log(`formula has error char error: ${char}, formulaster:${formulaStr}`, formulaStr);
      return [];
    }
  }
  return symbols;
};
export default formulaToSymbols;

// write a component that renders the list of values returned by formulaToSymbols given a string