const EditableInput = ({
  value,
  id,
  handleEdit,
  setEditValues,
  editValues
}: {
  value: string;
  id: number;
  handleEdit: (id: number, value: string) => void;
  setEditValues: React.Dispatch<React.SetStateAction<{
    [key: number]: string;
  }>>;
  editValues: {
    [key: number]: string;
  };
}) => {
  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      handleEdit(id, value);
    }
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    if (!newValue.includes("'")) {
      setEditValues({
        ...editValues,
        [id]: newValue
      });
    }
  };
  return <input type="text" className="p-2 text-black-100" defaultValue={value && ''} value={value}
  // eslint-disable-next-line jsx-a11y/no-autofocus
  autoFocus onChange={handleChange} onBlur={() => handleEdit(id, value)}
  // autoFocus
  onKeyDown={handleKeyDown} data-sentry-component="EditableInput" data-sentry-source-file="EditableInput.tsx" />;
};
export default EditableInput;