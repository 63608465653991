/**
 * @file columnFactory.tsx
 * @description Factory functions and rendering logic for creating react-table columns.
 */

import React from 'react';

// Importing the getCellComponentByType function for dynamic cell rendering
// eslint-disable-next-line import/no-cycle
import { getCellComponentByType } from '../cells/baseCells';
// eslint-disable-next-line import/no-cycle
import { getSidebarCellByTableType } from '../cells/sidebarCells';

/**
 * Render the appropriate cell content based on the column type.
 *
 * @param {Object} row - React-table row object.
 * @param {Object} cell - React-table cell object.
 * @param {Object} columnSpec - React-table columnSpec object.
 * @returns {React.ReactNode} - The rendered cell content.
 */
const renderCell = (row: any, cell: any, columnSpec: any): React.ReactNode => {
  // Prepare cellProps for dynamic cell rendering
  const cellProps: any = {
    cell,
    row,
    columnSpec
  };
  switch (columnSpec.accessorKey) {
    case 'sidebar':
      // Render the sidebar cell
      return React.createElement(getSidebarCellByTableType(columnSpec.type), cellProps);
    default:
      // Dynamically create and render the appropriate cell component based on the column type
      return React.createElement(getCellComponentByType(columnSpec.type), cellProps);
  }
};

/**
 * Factory function that creates a column object with a cell renderer for react-table.
 *
 * @function
 * @param {Object} columnSpec - Specification for the column.
 * @returns {Object} - Column object suitable for react-table.
 */
const columnFactory = (columnSpec: any) => ({
  ...columnSpec,
  Header: columnSpec.header,
  accessor: columnSpec.accessorKey,
  cell: ({
    cell,
    row
  }: any) => renderCell(row, cell, columnSpec)
});
export default columnFactory;