import { useContext } from 'react';
import { MutationsContext } from '@/miscellaneous/layouts/HooksLayout';

// fetches mutation from mutation context
const useMutations = () => {
  const context = useContext(MutationsContext);
  if (context === null) {
    console.error('useMutationContext must be used within a MutationsProvider');
  }
  return context;
};
export default useMutations;