/* eslint-disable */
import { WidgetType } from '@codemirror/view';
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import styles from '@/styles/scss/formulaCell.module.scss'; // Import your SCSS file here

/**
 * WidgetWrapper class for wrapping and rendering React components as widgets.
 */
export class WidgetWrapper extends WidgetType {
  /**
   * Creates an instance of WidgetWrapper.
   *
   * @param {any} component - The React component to be wrapped.
   * @param {any} props - The props to be passed to the wrapped component.
   */
  constructor(
    readonly component: any,
    readonly props: any,
  ) {
    super();
  }

  /**
   * Converts the wrapped React component to a DOM node.
   *
   * @returns {HTMLElement} The container element wrapping the React component.
   */
  toDOM() {
    const container = document.createElement('div');
    if (styles.widgetWrapper) container.className = styles.widgetWrapper;

    // Use createRoot to manage the root of the React tree.
    const root = createRoot(container); // Create a root.
    root.render(React.createElement(this.component, this.props)); // Use the root to render.

    return container;
  }

  /**
   * Determines whether the widget should ignore events.
   *
   * @returns {boolean} False to allow handling events, true to ignore events.
   */
  ignoreEvent() {
    return false;
  }
}
