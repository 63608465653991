/* eslint-disable no-param-reassign */
// accountValuesHelper.ts

import _, { memoize } from 'lodash';

import {
  generateMonthlyDates,
  getIsoString,
  parseUTCDateObject,
} from '@/utils/dateUtils';
import type { Output } from '@/utils/hooks/Outputs/useOutputs';

// both entries in 1 interface integratees live entries and formula entries
export interface CombinedEntry {
  id: number;
  created_at: string;
  updated_at: string;
  amount: number;
  memo?: string;
  split_entry_id?: number;

  // Properties from NewFormulaEntry
  output_id: number;
  branch_id: number;
  formula_id: number;
  model_id?: number;

  // Properties specific to LiveEntry
  company_id?: number;
  deleted_at?: null | string;
  date?: string;
  name?: string;
  department_id?: null | number;
  reference_type?: string;
  reference_id?: number;
}

export interface ValueByDate {
  TotalValueAtDate: number;
  entries: CombinedEntry[];
}
interface DateToValue {
  [date: string]: number;
}

export interface OutputValues {
  // mapping data from the formula entries to the outputs by date
  output?: Output | undefined;
  dateValues: { [date: string]: ValueByDate }; // output was added to prevent the typescript error
  culmutativeDateValues: { [date: string]: number };
  outputTotalValues?: {
    [date: string]: number; // the total values including all his children at certain
  };
}

export interface OutputValuesMap {
  [accountID: number]: OutputValues;
}

type EntryKeys = keyof CombinedEntry;
export type FilterSchema = {
  [K in EntryKeys]?: (value: any) => boolean;
};

const emptyValueByDate: ValueByDate = {
  TotalValueAtDate: 0,
  entries: [],
};

/**
 * This is a custom function to handle merging the output values from srcValue into objValue.
 * @param objValue The object to be merged into.
 * @param srcValue The object to merge from.
 * @param key The key being merged.
 * @returns the merged object.
 */
function outputValuesCustomMerge(
  objValue: any,
  srcValue: any,
  key: string,
  sign: number = 1,
): any {
  if (key === 'entries') {
    // we just want to take the srcValue and append it to the objValue, we want to just append the entries caught here
    return objValue ? [...objValue, ...srcValue] : srcValue;
  }
  if (key === 'output') {
    return objValue; // Prevent modifying the output field
  }
  if (_.isArray(objValue) || _.isArray(srcValue)) {
    // handle the case where obj value
    // if srcValue has keys that are called amount, multiply them by -1. Multiply each object in the list srcValue, if it has key amount with the sign
    const modifIedSrcValue = _.map(srcValue, (value) => {
      if (value.amount) {
        value.amount *= sign;
      }
      return value;
    });
    return objValue ? [...objValue, ...modifIedSrcValue] : modifIedSrcValue;
  }

  if (_.isNumber(objValue) || _.isNumber(srcValue)) {
    return (objValue ?? 0) + (srcValue ?? 0) * sign;
  }

  // For objects like ValueByDate, merge their properties as needed
  if (_.isObject(objValue) && _.isObject(srcValue)) {
    return _.mergeWith(objValue, srcValue, (o, s, k) =>
      outputValuesCustomMerge(o, s, k, sign),
    );
  }
  return undefined; // Use default merge behavior
}

// Function to add fromOutputValues to toOutputValues
export function addOutputValues(
  fromOutputValues: OutputValues,
  toOutputValues: OutputValues,
  sign: number = 1,
): OutputValues {
  return _.mergeWith(toOutputValues, fromOutputValues, (o, s, k) =>
    outputValuesCustomMerge(o, s, k, sign),
  );
}

const getDefaultOutputValues = (outputValues: OutputValues | undefined) => {
  if (!outputValues) {
    return {};
  }
  const defaultOutputValues: { [date: string]: number } = {};

  Object.keys(outputValues.dateValues).forEach((date) => {
    defaultOutputValues[date] =
      outputValues?.dateValues?.[date]?.TotalValueAtDate ?? 0;
  });

  return defaultOutputValues;
};

export const getOutputValues: (outputValues: OutputValues | undefined) => {
  [date: string]: number;
} = memoize(getDefaultOutputValues);

export function generateCumulativeDateValuesFunc(
  dateValues: DateToValue,
): DateToValue {
  const cumulativeDateValues: DateToValue = {};
  let cumulativeSum = 0;

  // Sort the dates to ensure the cumulative sum is calculated in order yyyy-mm-dd
  const sortedDates = Object.keys(dateValues).sort();
  const firstDate = sortedDates?.[0] ?? '';
  const lastDate = sortedDates?.[sortedDates.length - 1] ?? '';
  const fullDateRange =
    firstDate && lastDate
      ? generateMonthlyDates(
          parseUTCDateObject(firstDate),
          parseUTCDateObject(lastDate),
        )
      : [];

  fullDateRange.forEach((date) => {
    cumulativeSum += dateValues[date] ?? 0;
    cumulativeDateValues[date] = cumulativeSum;
  });

  return cumulativeDateValues;
}

export const generateCumulativeDateValues = memoize(
  generateCumulativeDateValuesFunc,
);

export function updateOutputValuesWithCumulativeSum(
  outputValues: OutputValues,
): void {
  const { dateValues } = outputValues;
  const dateValuesForCalculation: DateToValue = {};

  // Transform dateValues to DateToValue format
  Object.keys(dateValues).forEach((date) => {
    dateValuesForCalculation[date] = dateValues[date]?.TotalValueAtDate ?? 0;
  });
  // Use the calculateCumulativeDateValues function to get cumulative values
  outputValues.culmutativeDateValues = generateCumulativeDateValues(
    dateValuesForCalculation,
  );
}

/**
 * Filters entries based on a dynamic filter schema.
 * @param entries The list of entries to filter.
 * @param filter The dynamic filter schema.
 * @returns The filtered list of entries.
 */
const filterEntries = (
  entries: CombinedEntry[],
  filter: FilterSchema,
): CombinedEntry[] => {
  return entries.filter((entry: CombinedEntry) =>
    Object.keys(filter).every((key: string) => {
      const filterKey = key as keyof CombinedEntry;
      const filterFunction = filter[filterKey];
      if (!filterFunction) return false;
      const entryValue = entry[filterKey];
      return filterFunction(entryValue);
    }),
  );
};

/**
 * Calculates the total value at a given date based on account values and an optional filter.
 * @param accountValues The account values containing entries for each date.
 * @param date The date for which to calculate the total value.
 * @param filter The optional filter schema.
 * @returns The total value at the given date.
 */
const getAccountValuesAtDate = (
  accountValues: OutputValues | undefined,
  date: string,
  filter: any,
): ValueByDate => {
  if (!accountValues) return { TotalValueAtDate: 0, entries: [] };
  const valueByDate = accountValues.dateValues[date];
  if (!valueByDate) return { TotalValueAtDate: 0, entries: [] };

  if (!filter) {
    return valueByDate;
  }
  const filteredEntries = filterEntries(valueByDate.entries, filter);
  const totalValue = filteredEntries.reduce(
    (sum, entry) => sum + entry.amount,
    0,
  );
  return { TotalValueAtDate: totalValue, entries: filteredEntries };
};

// this function extracts output values from 1 object to another
function assignValuesToSourceOutputData(
  sourceOutputValues: OutputValues,
  targetOutputValues: OutputValues,
  dateCondition: (date: string) => boolean,
) {
  const keysSet = new Set([
    ...Object.keys(sourceOutputValues?.dateValues || {}),
    ...Object.keys(sourceOutputValues?.outputTotalValues || {}),
  ]);
  keysSet.forEach((date) => {
    if (dateCondition(date)) {
      targetOutputValues.dateValues[date] =
        sourceOutputValues.dateValues[date] ?? emptyValueByDate;
      targetOutputValues.culmutativeDateValues[date] =
        sourceOutputValues?.culmutativeDateValues?.[date] ?? 0;
      if (targetOutputValues.outputTotalValues) {
        targetOutputValues.outputTotalValues[date] =
          sourceOutputValues?.outputTotalValues?.[date] ?? 0;
      }
    }
  });
}
/**
 * This function takes 2 output values and merges them together into 1 object. If you set current date to
 * @param startObj the first object to merge before current date
 * @param endObj the second object to merge after current date
 * @param currentDate the current date - split the dates and determines up to where the new object will be start
 * @returns the merged object
 */
function mergeOutputValues(
  startObj: OutputValues,
  endObj: OutputValues,
  currentDate: string,
): OutputValues {
  const mergedValues: OutputValues = {
    output: startObj?.output || endObj?.output,
    dateValues: {},
    culmutativeDateValues: {},
    outputTotalValues: {},
  };

  assignValuesToSourceOutputData(
    startObj,
    mergedValues,
    (date) => date <= currentDate,
  );

  assignValuesToSourceOutputData(
    endObj,
    mergedValues,
    (date) => date > currentDate,
  );

  return mergedValues;
}

export function createBothDataMap(
  liveDataMap: OutputValuesMap,
  forecastDataMap: OutputValuesMap,
  zustandCurrentDate: any,
) {
  const OutputIDKEysFromNewMapAndFromForecastDataMap = Object.keys(
    liveDataMap,
  ).concat(Object.keys(forecastDataMap));
  const zustandCurrentDateObject = parseUTCDateObject(zustandCurrentDate);

  const newBothDataMap: OutputValuesMap = {};
  OutputIDKEysFromNewMapAndFromForecastDataMap.forEach((key) => {
    const keyNumber = Number(key);
    newBothDataMap[keyNumber] = mergeOutputValues(
      liveDataMap[keyNumber] as OutputValues,
      forecastDataMap[keyNumber] as OutputValues,
      getIsoString(zustandCurrentDateObject),
    );
  });
  return newBothDataMap;
}

interface GetAccountDataFunctionDataProps {
  liveDataMap: OutputValuesMap;
  forecastDataMap: OutputValuesMap;
  bothDataMap: OutputValuesMap; // this is the both default data map by current date in s
}

export interface GetAccountValuesByDateRangeProps {
  outputID: number;
  sortedDateRange?: string[]; // dateKeys
  currentDate?: Date | null;
  filterValuesFromEntries?: FilterSchema;
  dataType?: 'live' | 'forecast' | 'both';
}

interface GetAccountValuesByDateRangeHelperProps
  extends GetAccountDataFunctionDataProps,
    GetAccountValuesByDateRangeProps {
  currentDate: Date;
  sortedDateRange: string[]; // dateKeys
  isCurrentZustandDate: boolean;
}

/**
 * Retrieves account values for a specified date range.
 * @param accountID The account ID to get the values for.
 * @param dataType The type of data to retrieve (live, forecast, or both).
 * @param sortedDateRange The sorted date range for which to retrieve values.
 * @param currentDate The current date to distinguish between live and forecast data.
 * @param filterValuesFromEntries The dynamic filter schema (if null, return TotalValueAtDate).
 * @param liveDataMap The live data map.
 * @param forecastDataMap The forecast data map.
 * @param bothDataMap The combined live and forecast data map.
 * @param isCurrentZustandDate A boolean indicating whether the current date is set, meaning we can return precalculated both map
 * @returns The account values for the specified date range.
 */
export const getAccountValuesByDateRangeHelper = ({
  liveDataMap,
  forecastDataMap,
  bothDataMap,
  outputID,
  sortedDateRange,
  currentDate,
  filterValuesFromEntries,
  dataType = 'both',
  isCurrentZustandDate = true,
}: GetAccountValuesByDateRangeHelperProps): OutputValues | undefined => {
  if (dataType === 'live') {
    // this is an optimization to avoid unnecessary calculations
    if (!filterValuesFromEntries) return liveDataMap[outputID];
  }
  if (dataType === 'forecast') {
    // this is an optimization to avoid unnecessary calculations
    if (!filterValuesFromEntries) return forecastDataMap[outputID];
  }
  if (dataType === 'both') {
    // this is an optimization to avoid unnecessary calculations
    if (!filterValuesFromEntries && isCurrentZustandDate) {
      return bothDataMap[outputID];
    }
  }
  const result: OutputValues = {
    dateValues: {},
    culmutativeDateValues: {},
  };

  const getMap = (date: Date) => {
    if (dataType === 'live' || (dataType === 'both' && date <= currentDate)) {
      return liveDataMap;
    }
    if (
      dataType === 'forecast' ||
      (dataType === 'both' && date > currentDate)
    ) {
      return forecastDataMap;
    }
  };

  // get map according to date and current date, return the correct map according to dataType
  sortedDateRange.forEach((dateString) => {
    if (!dateString) return;
    // convert dateString yyyy-mm-dd to Date
    const date = parseUTCDateObject(dateString);
    // set the time to 00:00:00
    date.setHours(0, 0, 0, 0);
    const map = getMap(date);
    if (!map) return;
    const ValueAtDate = getAccountValuesAtDate(
      map[outputID],
      dateString,
      filterValuesFromEntries,
    );

    result.dateValues[dateString] = ValueAtDate;
    result.culmutativeDateValues[dateString] = ValueAtDate.TotalValueAtDate;
  });

  return result;
};
