import styles from '@/components/table/shared/table/GeneralTable/styles/tableSidebarStyles.module.scss';
import type { TableRow } from '../../shared/types';

/**
 * React component for rendering cells in the sidebar.
 *
 * @param {object} props - Properties passed to component
 * @param {object} props.row - Row data
 * @param {boolean} props.isRenaming - Whether row is being renamed
 * @param {string} props.newName - New name when renaming
 * @param {function} props.setIsRenaming - Callback to set isRenaming state
 * @param {function} props.setNewName - Callback to set newName state
 * @param {function} props.renameOutput - Callback to rename row value
 */
const SidebarCellContent = ({
  row,
  isRenaming,
  newName,
  setIsRenaming,
  setNewName,
  renameOutput
}: {
  row: TableRow;
  isRenaming: boolean;
  newName: string;
  setIsRenaming: any;
  setNewName: any;
  renameOutput: any;
}) => {
  if (isRenaming) {
    return <input className={styles.modelNameInput} type="text" onBlur={() => {
      renameOutput(row.original, newName);
      setIsRenaming(false);
    }} onKeyDown={e => {
      if (e.key === 'Enter') {
        renameOutput(row.original, newName);
        setIsRenaming(false);
      }
    }} defaultValue={newName} onChange={e => setNewName(e.target.value)}
    // eslint-disable-next-line jsx-a11y/no-autofocus
    autoFocus />;
  }
  return <span data-tooltip-id="financials-sidebar-cell-tooltip" data-tooltip-content={newName} data-sentry-component="SidebarCellContent" data-sentry-source-file="SidebarCellContent.tsx">
      {newName}
    </span>;
};
export default SidebarCellContent;