import { useRouter } from 'next/router';

import dynamicRoute from '@/miscellaneous/constant';

const useShallowRoute = () => {
  const router = useRouter();

  const shallowRoute = <T extends Record<string, string>>(queryChanges: T) => {
    if (router.push) {
      const pathname = router.pathname || ''; // Ensure pathname is not null
      const currentQuery = router.query || {}; // Ensure query is not null
      const forbiddenPaths = [dynamicRoute.login, dynamicRoute.signup];
      // ensue pathname is not in forbidden paths
      if (forbiddenPaths.includes(pathname)) return;
      // Merge the current query with the changes
      const query = {
        ...currentQuery,
        ...queryChanges,
      };

      // Construct the URL
      const url = {
        pathname,
        query,
      };

      router.push(url, undefined, { shallow: true });
    }
  };

  return shallowRoute;
};

export default useShallowRoute;
