import type { FormulaEntries } from '@/components/modelCalculations/modelUtils/modelUtils';

import type { HPCalculationConfig } from '../hooks/formulas/SystemFormulas';

export enum FormulaGroupType {
  Inputs = 'Inputs',
  Calculations = 'Calculations',
  Outputs = 'Outputs', // this is not from API this is for rendering the model table rows only
}

export enum FormulaInputType { // for input type only
  Number = 'Number',
  Function = 'Function',
  Formula = 'Formula',
  Percentage = 'Percentage',
  Currency = 'Currency',
  Boolean = 'Boolean',
  Date = 'Date',
  Link = 'Link',
  Group = 'Group',
}

export type Formula = {
  updated_at: string;
  branch_id: number;
  name: string;
  group: FormulaGroupType;
  expression_string: string; // This is a list of expressions each for a date range. {formula: string,end:string, start:string}
  created_at: string;
  model_id: number;
  emoji: string | null;
  input_type: FormulaInputType;
  output_id: number | null;
  id: number;
  symbolMap?: { [key: string]: ISymbol[] }; // maps formula parsed string to symbols
  parsedExpressions?: ExpressionType[];
  hiringCalculationConfig?: HPCalculationConfig;
  evalFunction?: (
    date: string,
    formulaId?: number,
    formulaEntries?: FormulaEntries,
    currentRowFormula?: Formula,
  ) => number; // accepts a date in format 'yyyy-mm-dd' and returns a number
  formulas?: any;
  sort_order?: string;
  parent_id?: number | null;
};

export type ExpressionType = {
  formula: string;
  end: string;
  start: string;
  symbols?: ISymbol[];
};

type SymbolType =
  | 'Operator'
  | 'Formula'
  | 'Function'
  | 'Number'
  | 'LogicOperand'
  | 'Date';

export interface ISymbol {
  symbolName: string;
  symbolType: SymbolType;
}
