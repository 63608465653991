import Popup from 'reactjs-popup';
type PopupPosition = 'top left' | 'top center' | 'top right' | 'right top' | 'right center' | 'right bottom' | 'bottom left' | 'bottom center' | 'bottom right' | 'left top' | 'left center' | 'left bottom' | 'center center';
interface TooltipProps {
  element: any;
  content: any;
  position?: PopupPosition;
}
const Tooltip = ({
  element,
  content,
  position = 'bottom left'
}: TooltipProps) => {
  const popupStyle = {
    boxShadow: '0px 4px 24px 0px rgba(0, 0, 0, 0.15)',
    borderRadius: '4px',
    backgroundColor: '#ffffff',
    padding: '4px 10px',
    color: '#131923',
    fontFamily: 'Figtree',
    fontSize: '11px',
    fontWeight: '400',
    width: 'auto',
    border: 'none',
    marginTop: '5px',
    marginLeft: '-5px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    maxWidth: '150px'
  };
  return <Popup trigger={<span>{element}</span>} position={position} on="hover" arrow={false} contentStyle={popupStyle} closeOnDocumentClick data-sentry-element="Popup" data-sentry-component="Tooltip" data-sentry-source-file="Tooltip.tsx">
      <span>{content}</span>
    </Popup>;
};
export default Tooltip;