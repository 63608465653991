import type { FC } from 'react';
import React from 'react';
interface ISimplerButtonSvg {
  className?: string;
}
const SimplerButtonSvg: FC<ISimplerButtonSvg> = ({
  className
}) => <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" className={className} data-sentry-element="svg" data-sentry-component="SimplerButtonSvg" data-sentry-source-file="SimplerButtonSvg.tsx">
    <path d="M13.3333 6.33337L2.66665 6.33337" stroke="#131923" strokeLinecap="round" data-sentry-element="path" data-sentry-source-file="SimplerButtonSvg.tsx" />
    <path d="M9.05292 9.66663H2.66669" stroke="#131923" strokeLinecap="round" data-sentry-element="path" data-sentry-source-file="SimplerButtonSvg.tsx" />
  </svg>;
export default SimplerButtonSvg;