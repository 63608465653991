/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import { isNumber } from 'lodash';
import CalculationsStore from '@/miscellaneous/store/CalculationsStore';
import type { Formula, ISymbol } from '@/utils/types/formulaTypes';
import type { FormulaEntry } from './modelUtils';

/**
 * Updates the dependency map with a formula's new expression.
 * @param formula formula to update the dependency map with
 * @param dependentMap current dependency map
 * @returns updated dependency map
 */
export const updateDependencyMap = (formula: Formula, dependentMap: Record<number, number[]>): void => {
  const symbols = Object.values(formula?.symbolMap || {}).flat(); // get all symbols of the formula
  const dependentFormulas: number[] = [];
  symbols.forEach((s: ISymbol) => {
    if (s.symbolType !== 'Formula') return;
    const [formulaStr, year, month] = s.symbolName!.split(','); // {{1,y,x}},{{32,y-1,x-12}},{{N,y-a,x-b}},

    const curFormulaSymbolID: number = parseInt(formulaStr || '', 10);
    if (Number.isNaN(curFormulaSymbolID) || !isNumber(curFormulaSymbolID)) {
      console.error('Invalid formula ID:', s.symbolName);
    } else if (!year?.includes('-') && !month?.includes('-')) {
      // since we are calculating per month, we only need to check if the formula is dependent on the current month, previous months are already calculated.
      dependentFormulas.push(curFormulaSymbolID);
    }
  });
  // add the dependent formulas to the dependency map, if there is value already update it otherwise set it
  dependentMap[formula.id] = [...(dependentMap[formula.id] || []), ...dependentFormulas];
}; /**
   * Creates a map of formula IDs to formulas that are dependent on them. This is used to calculate the formulas in the correct order.
   * @param formulas list of formulas to create a dependent map for
   * @returns object with formula IDs as keys and an array of formula IDs that are dependent on them
   */

export const createDependentMap = (formulas: Formula[], formulaDependencyMap: Record<number, number[]> = {}): Record<number, number[]> => {
  formulas.forEach((f: Formula) => {
    updateDependencyMap(f, formulaDependencyMap);
  });
  return formulaDependencyMap;
}; // this function handles the case where the formula is an input type and adds entries accordingly
export function calculateInputFormula(curFormula: Formula, datesList: string[], entries: FormulaEntry) {
  const {
    addFormulaEntry
  } = CalculationsStore.getState();
  if (curFormula.symbolMap) {
    // get the first item in the symbol map
    const firstSymbol = Object.keys(curFormula.symbolMap)[0];
    let value = parseFloat(firstSymbol ?? '');
    if (!value || Number.isNaN(value)) {
      value = NaN;
    }
    datesList.forEach(date => {
      // eslint-disable-next-line no-param-reassign
      entries[date] = value;
    });
    addFormulaEntry(curFormula.id, entries);
  }
}