import moment from 'moment';
import { useRouter } from 'next/router';
import { useMemo, useRef, useState } from 'react';
import { MdOutlineArrowRightAlt } from 'react-icons/md';
import { PiFunctionBold } from 'react-icons/pi';
import { formateDateToMatchISOString } from '@/components/Graphs/graphHelper';
import { FormulaExpressionByDate, getFormulaExpressionForDate, getFormulasParsed } from '@/components/modelCalculations/modelUtils/modelUtils';
import type { CalculationsStoreState } from '@/miscellaneous/store/CalculationsStore';
import CalculationsStore from '@/miscellaneous/store/CalculationsStore';
import type { ZustandState } from '@/miscellaneous/store/zustand_store';
import useZustandStore from '@/miscellaneous/store/zustand_store';
import colors from '@/styles/scss/abstracts/_variables.module.scss';
import styles from '@/styles/scss/formulaCell.module.scss';
import { getDateStringFormat, getIsoString, parseUTCDateObject } from '@/utils/dateUtils';
import { clearCommas } from '@/utils/helpers/formattingHelper';
import type { Branch } from '@/utils/hooks/branches/useBranches';
import type { Models } from '@/utils/hooks/models/useModels';
import useMutations from '@/utils/hooks/mutations/useMutations';
import type { Formula } from '@/utils/types/formulaTypes';

// eslint-disable-next-line import/no-cycle
import { ViewCell } from '../baseCells';
import { FormulaCellPopup, formulaCellPopupStyle, goToFormulaById, onLoadEditor, toggleEditorVisibility, updateFormulasList, updateFormulaWrapper } from '../formulaCell/formulaCellUtils';
import InputCellsComponent from '../inputCells/InputCellsComponent';

/**
 * Renders an input cell component that can be edited in a table. The input cell component is a button that toggles between an input field and a view cell.
 *
 * @param row - The row data for the current cell.
 * @param cell - The cell data for the current cell.
 * @param columnSpec - The specification for the current column, including any handler functions.
 * @param state - The current state of the cell.
 * @param setState - A function to update the state of the cell.
 * @param onBlur - A function to call when the cell loses focus.
 * @param formulaFunctionColumnUIWrapper - A function to wrap the cell's UI with additional functionality.
 * @returns A React component that renders the input cell.
 */
const InputCellsWrapper = ({
  row,
  cell,
  columnSpec,
  state,
  setState,
  onBlur,
  formulaFunctionColumnUIWrapper
}: any) => {
  const [editMode, setEditMode] = useState(false);
  const fillRigthStyle = {
    paddingRight: '0px' // this style is needed, default configuration has right padding we need to ignore this style for these inputs.
  };
  const groupRow = row?.original?.input_type === 'Group';
  return <button type="button" onClick={() => {
    if (!groupRow) setEditMode(true);
  }} data-sentry-component="InputCellsWrapper" data-sentry-source-file="index.tsx">
      {editMode ?
    // eslint-disable-next-line jsx-a11y/control-has-associated-label
    <InputCellsComponent row={row} cell={cell} columnSpec={{
      ...columnSpec,
      handlerFunctions: {
        ...columnSpec.handlerFunctions,
        onBlur: () => (e: any) => {
          setState(clearCommas(e.target.value.toString()));
          onBlur(clearCommas(e.target.value.toString()));
          setEditMode(false);
        }
      }
    }} defaultValue={state} focus={editMode} /> : <span>
          {formulaFunctionColumnUIWrapper(<ViewCell cell={cell} columnSpec={columnSpec} />, fillRigthStyle)}
        </span>}
    </button>;
};
const FormulaViewCellComponent = ({
  row,
  columnSpec,
  cell
}: any) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const {
    original
  } = row;
  const currentFormulaObject = (original.original_row_object as Formula);
  const currentDate = columnSpec.accessorKey; // The date for the current column.
  const {
    startDate
  } = useZustandStore((state: ZustandState) => state);
  // Check if the cell is an input cell
  const isInputCell = row.original.original_row_object.group === 'Inputs';

  /**
   * Memoizes the result of parsing the `expression_string` property of the `original` object into an array of `FormulaExpressionByDate` objects.
   *
   * @param original - An object with an `expression_string` property that contains a formula expression.
   * @returns An array of `FormulaExpressionByDate` objects representing the parsed formula expression.
   */
  const currentFormulas = (getFormulasParsed(original?.expression_string) as FormulaExpressionByDate[]);

  /**
   * Determines if the current cell is the first formula cell in the column.
   * This is calculated by comparing the start date of the column to the column ID.
   *
   * @param startDate - The start date of the column.
   * @param cell - The current cell being rendered.
   * @returns `true` if the current cell is the first formula cell in the column, `false` otherwise.
   */
  const isFirstFormulaCell = getIsoString(parseUTCDateObject(startDate)) === cell.column.id;

  /**
   * Determines whether the current formula cell is the first formula cell or if there are multiple formulas starting on the current date.
   *
   * @param isFirstFormulaCell - A boolean indicating whether the current formula cell is the first formula cell.
   * @param currentFormulas - An array of formulas for the current date range.
   * @param currentDate - The current date.
   * @returns A boolean indicating whether the current formula cell is an edit cell.
   */
  const isFormulaEditCell = isFirstFormulaCell && currentFormulas.length > 1 || currentFormulas.some((formula: FormulaExpressionByDate) => formula.start === currentDate);

  /**
   * Memoizes the formula expression for the current date based on the current formulas.
   *
   * @param currentFormulas - The current formulas to use for generating the expression.
   * @param currentDate - The current date to generate the expression for.
   * @returns The memoized formula expression for the current date.
   */
  const currentDateFormulaExpression = getFormulaExpressionForDate(currentFormulas, currentDate);
  const wrapperRef = useRef(null);
  const [hidden, setHidden] = useState<any>(true);
  const editorRef = useRef<HTMLDivElement>(null);
  const {
    activeBranch,
    branchFormulas: data,
    activeBranchModels
  } = useZustandStore((state: ZustandState) => state);
  const {
    HPformulas
  } = CalculationsStore((state: CalculationsStoreState) => state);

  /**
   * Manages the state of the formula editor, initialized with the current date formula expression or an empty string.
   */
  const [editorState, setEditorState] = useState<any>(currentDateFormulaExpression || '');
  const {
    updateFormula
  } = useMutations();

  /**
   * Updates the formula value in the table.
   *
   * If the current editor state is the same as the current date formula expression or is an empty string, this function does nothing.
   *
   * Otherwise, it creates a new `FormulaExpressionByDate` object with the current date, an empty end date, and the new formula expression from the editor state.
   * It then calls `updateFormulasList` to update the list of formulas, and calls `updateFunction` to update the formula in the database.
   */
  const updateFormulaValue = (value?: any) => {
    const currentCellValue = value || editorState;
    if (currentCellValue === currentDateFormulaExpression || currentCellValue === '') {
      return;
    }

    /**
     * Formats the current date to the next month's date in ISO string format.
     * @param currentDate - The current date to use as the base for calculating the next month's date.
     * @returns The next month's date in ISO string format.
     */
    const nextMonthDate = formateDateToMatchISOString(getDateStringFormat(moment(currentDate).add(1, 'month').startOf('month').utc().toDate()));
    const newFormula = new FormulaExpressionByDate(currentDate, nextMonthDate, value || editorState);
    const newFormulas = updateFormulasList(currentFormulas, newFormula);
    updateFormulaWrapper(updateFormula, original, JSON.stringify(newFormulas));
  };

  /**
   * Calculates the width of a popup element based on the position of the wrapper element.
   * The popup will be the full width of the screen minus the left position of the wrapper element.
   */
  const popupStyle = {
    ...formulaCellPopupStyle,
    width: `calc(100% - ${
    // @ts-ignore
    wrapperRef.current?.getBoundingClientRect().left || 0}px)`,
    maxWidth: `calc(100% - ${
    // @ts-ignore
    wrapperRef.current?.getBoundingClientRect().left || 0}px)`
  };

  /**
   * Closes the formula editor popup and updates the formula value in the table.
   *
   * If the current editor state is the same as the current date formula expression or is an empty string, this function does nothing.
   *
   * Otherwise, it creates a new `FormulaExpressionByDate` object with the current date, an empty end date, and the new formula expression from the editor state.
   * It then calls `updateFormulasList` to update the list of formulas, and calls `updateFunction` to update the formula in the database.
   */
  const onClosePopup = () => {
    setHidden(true);
    updateFormulaValue();
  };

  /**
   * Fills the formula to the right for the current month.
   *
   * @param e - The event object.
   */
  const fillFormulaRight = (e: any) => {
    e.stopPropagation();
    const nextMonthDate = formateDateToMatchISOString(getDateStringFormat(moment(currentDate).add(1, 'month').date(1).utc().toDate()));
    const currentFormulaIndex = currentFormulas.findIndex(formula => formula.end === nextMonthDate);
    const newFormulas = currentFormulas.slice(0, currentFormulaIndex + 1);
    const newCurrentFormulas = newFormulas.map(formula => {
      if (formula.end === nextMonthDate) {
        // eslint-disable-next-line no-param-reassign
        formula.end = '';
      }
      return formula;
    });
    updateFormulaWrapper(updateFormula, original, JSON.stringify(newCurrentFormulas));
  };
  const generateFillRightContent = useMemo(() => {
    const nextMonthDate = formateDateToMatchISOString(getDateStringFormat(moment(currentDate).add(1, 'month').startOf('month').utc().toDate()));
    const nextFormula = currentFormulas.find((formula: FormulaExpressionByDate) => formula.start > currentDate);
    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {nextFormula && nextMonthDate === nextFormula?.start && currentFormulas.length > 1 ? <div className={styles.leftArrow}>
            <MdOutlineArrowRightAlt onClick={fillFormulaRight} data-tooltip-id="my-tooltip" data-tooltip-content="Fill Right" size={24} />
          </div> : <span />}
      </>
    );
  }, [currentDate, currentFormulas]);

  /**
   * Wraps a formula function column UI element with additional styles and layout.
   * This Wrapper aplies fillRigth Arrow ui and functinality.
   *
   * @param children - The content to be rendered inside the formula function column.
   * @param style - Optional additional styles to apply to the wrapper.
   * @returns A React component that renders the formula function column UI.
   */
  const formulaFunctionColumnUIWrapper = (children: any, style = {}) => {
    return <div className={`${styles.formulaViewCell} relative cursor-pointer`} style={style} data-sentry-component="formulaFunctionColumnUIWrapper" data-sentry-source-file="index.tsx">
        <div className="w-full">
          <div className={`relative flex h-full w-full items-center  ${isFormulaEditCell ? 'justify-between' : 'justify-end'}`}>
            {isFormulaEditCell && <PiFunctionBold color={colors.midBlue} size={16} />}
            <div className=" justify-self-end">{children}</div>
          </div>
          {generateFillRightContent}
        </div>
      </div>;
  };

  /**
   * Renders a formula view cell in the table.
   *
   * The cell displays the formatted value of the cell, and if `isFormulaEditCell` is true, it also displays a PiFunctionBold icon.
   *
   * When the cell is clicked, it triggers the formula editor popup.
   *
   * @returns A React element representing the formula view cell.
   */
  const popupTrigger = () => {
    return <>
        {formulaFunctionColumnUIWrapper(<ViewCell cell={cell} columnSpec={columnSpec} />)}
      </>;
  };

  /**
   * Memoizes the combined formulas list.
   */
  const combinedFormulas = useMemo(() => {
    return [...(data as Formula[]), ...(HPformulas as Formula[])];
  }, [data, HPformulas]);
  const router = useRouter();

  // Define the function outside the render method
  const handleLoadEditor = () => {
    onLoadEditor((currentDateFormulaExpression as string), editorRef, setEditorState, combinedFormulas, (activeBranch as Branch), (activeBranchModels as Models[]), () => toggleEditorVisibility(setHidden, editorRef), setHidden, (currentFormulaObject as Formula), (id: number) => goToFormulaById(id, router));
  };

  /**
   * Renders a formula editor popup component that is triggered by the `popupTrigger` function.
   * The popup component is responsible for loading the formula editor, managing the editor's state, and handling the closing of the popup.
   * The popup's position and size are determined by the `popupStyle` object, which calculates the width based on the position of the wrapper element.
   * When the popup is closed, the `onClosePopup` function is called to update the formula value in the table.
   */
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {isInputCell ? <InputCellsWrapper row={row} cell={cell} columnSpec={columnSpec} state={currentDateFormulaExpression} setState={setEditorState} onBlur={updateFormulaValue} formulaFunctionColumnUIWrapper={formulaFunctionColumnUIWrapper} /> : <FormulaCellPopup row={row} trigger={popupTrigger} onLoadEditor={handleLoadEditor} hidden={hidden} wrapperRef={wrapperRef} editorRef={editorRef} onClosePopup={onClosePopup} popupStyle={popupStyle} />}
    </>
  );
};
export default FormulaViewCellComponent;