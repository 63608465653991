// eslint-disable-next-line simple-import-sort/imports
import { convertNumberToCurrencyString } from '@/components/table/shared/utils';
// eslint-disable-next-line import/no-cycle
import { convertToKAndM, disableGraphClick, disableGraphHover, type GraphConfig } from '@/utils/ChartUtils';
import { isDepartment, type CalculatedEmployeeData } from '@/utils/HiringPlanUtils';
import type { CommonConfigOptions } from '@/utils/types/DashboardAndChartsTypes';
import colors from '@/styles/scss/abstracts/_variables.module.scss';
import { parseUTCDateObject } from '@/utils/dateUtils';
import styles from '../../components/Graphs/graphs.module.scss';
interface ConfigPropsData {
  name: string;
  data: number[];
}
interface ChartConfigProps {
  currentDate?: any;
  configPropsData: ConfigPropsData[];
  hasLegend?: boolean;
  graphType?: string;
  yaxisLabels?: any;
  xaxisLabels?: any;
  graphColors?: string[];
  plotOptions?: any;
}
const generateGraphConfig = (chartConfigProps: ChartConfigProps) => {
  const {
    configPropsData,
    graphColors,
    xaxisLabels,
    graphType,
    plotOptions,
    yaxisLabels
  } = chartConfigProps;
  // calculate max value from all series in configPropsData
  let maxValue = configPropsData.reduce((acc: any, curr: any) => {
    return Math.max(acc, Math.max(...curr.data));
  }, 0);

  /**
   * Adding 100 to graphs so bar won't reach the top
   * Adjusts the max value for the y-axis.
   * If maxValue is greater than 1000, add 100 to maxValue.
   * Otherwise, just use maxValue.
   * If maxValue is undefined, set it to 10.
   */
  maxValue = maxValue ? maxValue > 1000 ? maxValue + 100 : maxValue : maxValue + 10;

  // calculate max series length from all series in configPropsData
  const maxSeriesLength = configPropsData.reduce((acc: any, curr: any) => {
    return Math.max(acc, curr.data.length);
  }, 0);
  return {
    option: {
      ...disableGraphHover,
      chart: {
        toolbar: {
          show: false
        }
      },
      fill: {
        colors: graphColors
      },
      colors: graphColors,
      yaxisLabels,
      dataLabels: {
        enabled: false
      },
      legend: {
        fontFamily: 'Figtree',
        fontSize: '14px',
        fontWeight: '400',
        itemMargin: {
          horizontal: 24,
          vertical: 10
        }
      },
      plotOptions: {
        ...plotOptions
      },
      xaxis: {
        type: graphType,
        categories: xaxisLabels,
        tickAmount: maxSeriesLength,
        labels: {
          style: {
            fontFamily: 'Figtree',
            fontSize: '12px',
            fontWeight: '400',
            color: colors.black
          },
          colors: graphColors
        },
        axisTicks: {
          show: false
        },
        axisBorder: {
          show: false
        }
      },
      tooltip: {
        x: {
          show: false
        },
        y: {
          formatter(value: number) {
            return convertToKAndM(value);
          }
        }
      },
      yaxis: {
        min: 0,
        /**
         * Sets the maximum y-axis value to be slightly greater than the max data value,
         * for better visual spacing. Rounds up to the nearest 100 if max is greater
         * than 1000.
         */
        max: maxValue,
        tickAmount: yaxisLabels.length - 1,
        // Set tick amount based on yaxisLabels length
        yaxisLabels,
        labels: {
          formatter(val: number, index: number) {
            return yaxisLabels[index] || val.toFixed(0); // Use custom labels if available
          },
          offsetX: yaxisLabels[yaxisLabels.length - 1].length > 3 ? -10 : 0,
          // Offset labels if they are long
          style: {
            padding: '10px',
            fontFamily: 'Figtree',
            fontSize: '12px',
            fontWeight: '400',
            color: colors.black
          }
        }
      }
    },
    series: configPropsData
  };
};
export const hiringPlanColorConfig = [colors.midBlueDark, colors.midBlueLight, colors.orangeLight, colors.lightCyan];
export function getEmployeeBarChart(graphConfig: GraphConfig) {
  if (!graphConfig) return {
    option: {},
    series: []
  };
  /**
   * Converts a currency string (e.g. '$1,000' or '1k') to a numeric value.
   *
   * Removes any currency symbols ($) and commas, then checks if it ends in 'k'
   * and multiplies by 1000 if so. Otherwise parses the string as a float.
   *
   * This is used to convert formatted currency axis labels to numbers for charting.
   */
  const convertCurrencyStringToNumber = (currencyString: string) => {
    const numericPart = currencyString.replace(/\$|,/g, '');
    if (/[Kk]$/.test(numericPart)) {
      return parseFloat(numericPart) * 1000;
    }
    return parseFloat(numericPart);
  };

  /**
   * Calculates the maximum y-axis value from the graph config data.
   *
   * Loops through each data series in the graph config, finds the max
   * value in each series data array, and returns the overall max of those.
   * This is used to set the y-axis max value.
   */

  const maxYaxisValue = Math.max(...graphConfig.configPropsData.map(data => Math.max(...data.data)));

  /**
   * Filters the y-axis labels from the graph config to only include
   * those less than or equal to the max y-axis value.
   *
   * This ensures the y-axis labels do not exceed the max value displayed.
   */
  const yAxisLabelArr = graphConfig.yaxisLabels.filter(label => convertCurrencyStringToNumber(label) <= +maxYaxisValue);

  /**
   * Determines the y-axis labels to use based on xaxisLabels length.
   * if yaxisLabels is empty, use yaxisLabels . Check that condition with xaxisLabels length
   * If xaxisLabels is empty, uses yaxisLabels directly.
   * Otherwise filters yaxisLabels to only those <= max y value.
   */
  const yaxisLabels = graphConfig.xaxisLabels.length === 0 ? graphConfig.yaxisLabels : yAxisLabelArr;
  return {
    ...generateGraphConfig({
      configPropsData: graphConfig.configPropsData,
      graphColors: (hiringPlanColorConfig as string[]),
      yaxisLabels,
      xaxisLabels: graphConfig.xaxisLabels,
      graphType: 'bar',
      plotOptions: {
        bar: {
          horizontal: false,
          // Set to true if you want horizontal bars
          columnWidth: '80%',
          // Adjust this value to control the width of bars
          borderRadius: 5,
          borderRadiusApplication: 'end'
        }
      }
    })
  };
}
export const lineModelConfig = {
  option: {
    chart: {
      toolbar: {
        show: false
      }
    },
    stroke: {
      width: 2,
      color: '#F44336'
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.7,
        opacityTo: 0.5
        // stops: [0, 100],
      }
    },
    xaxis: {
      show: false,
      labels: {
        show: false
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      }
    },
    yaxis: {
      show: false,
      labels: {
        show: false
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      }
    },
    grid: {
      show: false
    },
    tooltip: {
      enabled: false
    },
    markers: {
      size: 0
    }
  },
  series: [{
    data: [0, 2.5, 5]
  }]
};
export const getdonutHiringConfig = (currentCostByDepartment: number[], type: 'headcount' | 'payroll') => {
  if (currentCostByDepartment?.length === 0 || !currentCostByDepartment) return {
    series: [],
    options: {}
  };
  /**
   * Calculates the total by summing all series totals.
   *
   * @param w - Object containing seriesTotals array
   * @returns Total sum of all seriesTotals
   */
  const calculatedData = (w: any) => {
    return w.globals.seriesTotals.reduce((a: any, b: any) => {
      return a + b;
    }, 0);
  };

  /**
   * Formatter function that converts the calculated total to a string based on the type.
   * If type is 'payroll', convert the number to a currency string.
   * Otherwise, just return the calculated total.
   *
   * @param w - The calculated total number
   * @returns The formatted total as a string
   */
  const formatterFunction = (w: number) => {
    return type === 'payroll' ? convertNumberToCurrencyString(calculatedData(w)) : calculatedData(w);
  };
  return {
    series: currentCostByDepartment,
    shouldComponentUpdate: true,
    options: {
      ...disableGraphClick,
      chart: {
        type: 'donut'
      },
      tooltip: {
        enabled: true,
        custom(chartOptions: {
          series: number[];
          w: any;
          config: {
            colors: string[];
          };
          seriesIndex: number;
        }) {
          const color = chartOptions.w.config.colors[chartOptions.seriesIndex];
          const label = chartOptions.w.globals.labels[chartOptions.seriesIndex];
          const value = chartOptions.series[chartOptions.seriesIndex];
          return `
      <div class="apex-chart-tooltip-box">
        <div class="apex-chart-color-box" style="background-color: ${color};"></div>
        <span>${label}: <strong>${value}</strong></span>
      </div>
    `;
        },
        x: {
          show: true,
          formate: 'MM YY',
          formatter(value: number, data: any) {
            return data?.w?.globals?.categoryLabels?.[value - 1];
          },
          style: {
            fontFamily: styles.fontFamily,
            fontSize: '14px',
            fontWeight: '400'
          }
        },
        theme: 'light',
        style: {
          fontFamily: styles.fontFamily,
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: 'white'
        },
        y: {
          formatter(w: number) {
            return type === 'payroll' ? convertNumberToCurrencyString(+w) : w;
          }
        }
      },
      fill: {
        colors: hiringPlanColorConfig
      },
      labels: ['G&A', 'COGS', 'S&M', 'R&D'],
      colors: hiringPlanColorConfig,
      legend: {
        show: false
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: 0
      },
      plotOptions: {
        pie: {
          expandOnClick: false,
          donut: {
            labels: {
              show: true,
              name: {
                show: true
              },
              value: {
                show: true,
                fontFamily: 'Figtree',
                fontSize: '20px',
                fontWeight: '600',
                value: '35,000$'
              },
              total: {
                show: true,
                showAlways: true,
                fontFamily: 'Figtree',
                fontSize: '18px',
                fontWeight: '600',
                formatter(w: any) {
                  return formatterFunction(w);
                }
              }
            }
          }
        }
      }
    }
  };
};
export const donutHiringConfig = {
  series: [9167, 5417, 26667, 33333],
  options: {
    ...disableGraphClick,
    chart: {
      type: 'donut'
    },
    fill: {
      colors: hiringPlanColorConfig
    },
    labels: ['G&A', 'COGS', 'S&M', 'R&D'],
    colors: hiringPlanColorConfig,
    legend: {
      show: false
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      width: 0
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
        donut: {
          labels: {
            show: true,
            name: {
              show: true
            },
            value: {
              show: true,
              fontFamily: styles.fontFamily,
              fontSize: '20px',
              fontWeight: '600',
              value: '35,000$'
            },
            total: {
              show: true,
              showAlways: true,
              fontFamily: styles.fontFamily,
              fontSize: '18px',
              fontWeight: '600',
              formatter(w: any) {
                return w.globals.seriesTotals.reduce((a: any, b: any) => {
                  return convertNumberToCurrencyString(a + b);
                }, 0);
              }
            }
          }
        }
      }
    }
  }
};
/**
 * Generates configuration for a donut chart.
 *
 * @function
 *
 * @param {CommonConfigOptions} options - Options for configuring the donut chart.
 * @returns {{ series: any[], options: object }} - Configuration for the donut chart.
 */
export const getDonutConfig = (options: CommonConfigOptions) => {
  const {
    dynamicSeries,
    graphColors,
    labels
  } = options;
  return {
    series: dynamicSeries,
    options: {
      chart: {
        type: 'donut'
      },
      fill: {
        colors: graphColors
      },
      colors: graphColors,
      labels,
      legend: {
        show: false
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: 0
      },
      plotOptions: {
        pie: {
          expandOnClick: false,
          donut: {
            labels: {
              show: true,
              name: {
                show: true
              },
              value: {
                show: true,
                fontFamily: styles.fontFamily,
                fontSize: '20px',
                fontWeight: '600',
                value: '35,000$'
              },
              total: {
                show: true,
                showAlways: true,
                fontFamily: styles.fontFamily,
                fontSize: '16px',
                fontWeight: '600',
                formatter(w: any) {
                  return w.globals.seriesTotals.reduce((a: any, b: any) => {
                    return a + b;
                  }, 0);
                }
              }
            }
          }
        }
      }
    }
  };
};
export function getCostPerMonth(calculatedData: CalculatedEmployeeData, currentDate: Date, type: 'headcount' | 'payroll'): number[] {
  // check that current date is a Date otherwise convert it to one.
  let date = currentDate;
  if (!(currentDate instanceof Date)) {
    date = parseUTCDateObject(currentDate);
  }

  /**
   * Initialize series variable to null.
   * Based on passed in type, assign series to either
   * headCountByDepartment or costByDepartment from calculatedData.
   */
  let series: any = null;
  if (type === 'headcount') {
    series = calculatedData.headCountByDepartment;
  }
  if (type === 'payroll') {
    series = calculatedData.costByDepartment;
  }
  const currentMonthYear = `${date.getMonth() + 1 < 10 ? '0' : ''}${date.getMonth() + 1}-${date.getFullYear()}`;
  const currentCostByDepartment = series ? Object.keys(series).map(department => {
    if (!isDepartment(department)) return 0;
    return series[department][currentMonthYear] || 0;
  }) : [];
  return currentCostByDepartment;
}
export const hexColorList = [{
  id: 1,
  code: colors.midBlue
}, {
  id: 2,
  code: colors.vividOrange
}, {
  id: 3,
  code: colors.brightPink
}, {
  id: 4,
  code: colors.vividOrangePeel2
}, {
  id: 5,
  code: colors.brightRed
}, {
  id: 6,
  code: colors.brightBlueSky2
}, {
  id: 7,
  code: colors.brightPurple2
}, {
  id: 8,
  code: colors.darkCornflowerBlue
}, {
  id: 9,
  code: colors.strongMintGreen
}, {
  id: 10,
  code: colors.vermilion
}, {
  id: 11,
  code: colors.desaturatedBlue
}, {
  id: 12,
  code: colors.bluish
}, {
  id: 13,
  code: colors.softGreen
}, {
  id: 14,
  code: colors.deepRose
}, {
  id: 15,
  code: colors.midBlue3
}, {
  id: 16,
  code: colors.peach
}, {
  id: 17,
  code: colors.midBlueOpac
}, {
  id: 18,
  code: colors.manatee
}, {
  id: 19,
  code: colors.lightOrange
}, {
  id: 20,
  code: colors.vividOrangePeel
}, {
  id: 21,
  code: colors.desaturatedBlue
}];

/**
 * Configures the options for an area chart component.
 *
 * The options object configures various aspects of the chart, such as:
 * - Hiding the chart toolbar
 * - Disabling chart animations
 * - Hiding the X and Y axis labels, borders, and ticks
 * - Hiding the grid lines
 * - Setting the stroke width and color
 * - Disabling data labels and tooltips
 *
 * This configuration is used to render a minimalist area chart in the `GraphCellComponent`.
 */
export const graphCellComponentOptions = {
  chart: {
    toolbar: {
      show: false
    },
    animations: {
      enabled: false
    }
  },
  plotOptions: {
    area: {
      opacity: 1
    }
  },
  fill: {
    colors: [styles.lightMidBlue],
    opacity: 1
  },
  xaxis: {
    labels: {
      show: false
    },
    axisBorder: {
      show: false
    },
    axisTicks: {
      show: false
    }
  },
  yaxis: {
    labels: {
      show: false
    },
    axisBorder: {
      show: false
    },
    axisTicks: {
      show: false
    }
  },
  grid: {
    show: false
  },
  stroke: {
    width: 1,
    colors: [styles.midBlue]
  },
  dataLabels: {
    enabled: false
  },
  tooltip: {
    enabled: false
  }
};