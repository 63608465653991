import Fuse from 'fuse.js';
import numeral from 'numeral';
import dynamicRoute from '@/miscellaneous/constant';
import colors from '@/styles/scss/abstracts/_variables.module.scss';
import { getDateStringFormat, parseUTCDateObject } from '@/utils/dateUtils';
import type { Employee, HiringPlan } from '@/utils/types/HiringTypes';
import { fieldToType } from '../models/settings';
import { removeIdFromSortOrder, updateSortOrder } from './TableHelpers/TableDnDHelpers/TableDNDUtils';
import type { TableRow } from './types';

/**
 * Filters rows based on a search key, matching against the sidebar field if it exists,
 * or the name field if the sidebar field is falsy. The function recursively filters subRows as well.
 *
 * @param data - The array of TableRow objects to filter.
 * @param searchKey - The search key to match against the sidebar or name field.
 * @returns The filtered array of TableRow objects with subRows filtered accordingly.
 */

const fuseOptions = {
  keys: ['sidebar', 'name'],
  // fields to search against,
  threshold: 0.3 // Adjust this threshold to set the sensitivity of the search
};
export const fuzzyFilter = (data: TableRow[], searchKey: string): TableRow[] => {
  const shouldIncludeRow = (row: TableRow, filteredSubRows: TableRow[], matches: any): boolean => {
    return matches.length > 0 || filteredSubRows.length > 0 || row.type === 'button';
  };
  const filterSubRows: any = (subRows: TableRow[]) => {
    return subRows.map((row: TableRow) => {
      const filteredSubRows = row.subRows ? filterSubRows(row.subRows) : [];
      const fuseSub = new Fuse([row], fuseOptions);
      const matches = fuseSub.search(searchKey);
      if (shouldIncludeRow(row, filteredSubRows, matches)) {
        return {
          ...row,
          subRows: filteredSubRows
        };
      }
      return null;
    }).filter(row => row !== null);
  };
  return filterSubRows(data);
};

// Format currency
export const formatCurrency = (_value: number | bigint, language: string | string[] | undefined) => {
  // Create an Intl.NumberFormat instance for the currency format
  return new Intl.NumberFormat(language, {
    style: 'currency',
    currency: 'USD',
    // You can replace 'USD' with your desired currency code
    maximumFractionDigits: 5
  }).format(_value);
};

// Utility function to check if an input is a valid currency
export function isCurrency(input: string): boolean {
  let bool = false;
  // Define a regular expression to match currency values with optional commas
  const currencyPattern = /^(\$[1-9]\d{0,2}(,\d{3})*(\.\d{2})?|\$0(\.00)?)$/;
  bool = currencyPattern.test(input);
  if (!bool) {
    // Remove the first character ('$') from the input string
    const numericValue = parseFloat(input.slice(1));
    // Check if the parsed numeric value is a valid number and not NaN
    bool = !Number.isNaN(Number(numericValue));
  }
  return bool;
}

// Function to map column types to desired types (number or text)
export const mapColumnTypeToType = (value: any) => {
  if (value === null || value === undefined) {
    return 'text'; // Default to text if the value is null or undefined
  }
  if (typeof value === 'number') {
    if (Number.isInteger(value)) {
      return 'number';
    }
    return 'number';
  }
  if (typeof value === 'boolean') {
    return 'checkbox';
  }
  if (value instanceof Date) {
    return 'date';
  }
  if (typeof value === 'string') {
    if (/^\d{4}-\d{2}-\d{2}$/.test(value)) {
      return 'date'; // Check if it's a string in the format 'YYYY-MM-DD'
    }
    if (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/.test(value)) {
      return 'date'; // Check if it's a string in the format 'YYYY-MM-DDTHH:mm:ss'
    }
    if (!Number.isNaN(Date.parse(value))) {
      return 'date'; // Check if it's a string that can be parsed as a date
    }
    if (/^\d+(\.\d+)?$/.test(value)) {
      return 'number'; // Check if it's a numeric string
    }
    if (/^true|false$/i.test(value)) {
      return 'checkbox'; // Check if it's a boolean string
    }
    if (/^\d+$/.test(value)) {
      return 'number'; // Check if it's an integer string
    }
    if (/^(0x[a-fA-F0-9]+)?$/i.test(value)) {
      return 'hex'; // Check if it's a hexadecimal string
    }
    if (/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(value)) {
      return 'email'; // Check if it's an email address
    }
    if (/^\+\d{1,3}-\d{4,14}$/.test(value)) {
      return 'phone'; // Check if it's a phone number with country code
    }
    // Add more specific checks for string types, e.g., additional regex patterns
    // e.g., if (/customRegexPattern/.test(value)) return 'customType';
    return 'text';
  }
  if (Array.isArray(value)) {
    return 'array';
  }
  if (typeof value === 'object') {
    return 'object';
  }
  return 'text'; // Default to text if the type is not recognized
};

/**
 * Capitalizes the first letter of each word in a string.
 * @param inputString - The input string to be transformed.
 */
export const titleCase = (inputString: string) => {
  const words = inputString.split('_');
  const titleCaseWords = words.map((word: string) => word.charAt(0).toUpperCase() + word.slice(1));
  return titleCaseWords.join(' ');
};

/**
 * Format the input value based on the type.
 * @param {string} inputValue - The input value to be formatted.
 * @param {string} type - The type of the value.
 * @returns {string} - The formatted input value.
 */
export const formatInputValue = (inputValue: string, type: string): string => {
  if (type !== 'currency') return inputValue;
  // Assuming formatCurrency and isCurrency are defined elsewhere
  const numericValue = parseFloat(inputValue.replace(/[^0-9.]/g, ''));
  return formatCurrency(numericValue, 'en');
};

/**
 * Get the initial value based on the type.
 * @param {string} initialValue - The initial value.
 * @param {string} type - The type of the value.
 * @returns {string} - The formatted initial value.
 */
export const getInitialValue = (initialValue: string, type: string): any => {
  if (!initialValue) return '';
  switch (type) {
    case 'number':
    case 'currency':
      return parseFloat(initialValue);
    case 'date':
      return parseUTCDateObject(initialValue);
    default:
      return initialValue;
  }
};

/**
 * Utility function to generate a unique ID.
 * @returns {string} - The generated unique ID.
 */
export const generateUniqueId = (): string => {
  return Math.random().toString(36).substring(2, 15);
};
export function convertNumberToCurrencyString(value: number, currency: string = '$') {
  let res = '';
  // handle modifying to B M K according to the number
  if (value >= 1000000000) {
    res = `${Math.round(value / 1000000000)}B`;
  } else if (value >= 1000000) {
    res = `${Math.round(value / 1000000)}M`;
  } else if (value >= 1000) {
    res = `${Math.round(value / 1000)}K`;
  } else {
    res = value.toString();
  }
  return currency + res;
}
export const getEnabledDepartments = (hiringPlan: any) => {
  const departments = hiringPlan ? Object.keys(hiringPlan).filter(key => {
    return key.includes('department_is_enabled') && hiringPlan[key];
  }) : [];
  return departments.map(department => {
    return department.replace('department_is_enabled_', '');
  });
};

// Update the shared updateEmployeeSortOrder function to handle copy case
export const updateEmployeeSortOrder = (employeeIds: number[], currentSortOrder: string | null, isDelete: boolean = false, recordId?: number): string => {
  const sortOrder = currentSortOrder || '';
  // Handle deletion
  if (isDelete) {
    return employeeIds.reduce((updatedSortOrder, id) => removeIdFromSortOrder(updatedSortOrder, id.toString()), sortOrder);
  }
  // Handle adding/updating sort order
  const employeeIdStrings = employeeIds.map(String);
  if (recordId) {
    // Insert new IDs around a specific `recordId`
    return employeeIdStrings.reduce((updatedSortOrder, id) => updateSortOrder(updatedSortOrder, 'top', recordId, id, true), sortOrder);
  }
  // Prepend the employee IDs if `recordId` is not specified
  return `${employeeIdStrings.join('|')}${sortOrder ? `|${sortOrder}` : ''}`;
};
export const getUpdateFunctionFromMutation = (mutation: any) => (cell: any, row: any, e: any, customFields?: any, isHiringPlan: boolean = false) => {
  const {
    value
  } = e.target;
  const columnId = cell.column.id;
  const originalValue = row.original[columnId] || null;

  /**
   * Finds a custom field object by name from the provided customFields array.
   *
   * @param name - The name of the custom field to find.
   * @returns The custom field object with the matching name.
   * ! It is only for hiring plan
   */
  const findFieldByName = (name: string) => {
    return customFields.find((field: any) => field.name.toLowerCase().split(' ').join('_') === name);
  };

  // eslint-disable-next-line no-underscore-dangle
  const newData = {
    ...row.original
  }; // extracting the table display of the values in the employee
  delete newData.rowStyle;
  delete newData.sidebar;

  // handle value is null
  if (value === null || value === '' || value === undefined) {
    if (originalValue === null) return; // check if original value exists
    if (originalValue) {
      // if it is deleting a column data
      newData[columnId] = null;
      // eslint-disable-next-line no-param-reassign
      row.original[columnId] = null; // updating original row
      mutation.mutate(newData);
      return; // stop after removing column
    }
  }

  // value is not null but has not changed
  if (originalValue !== null) {
    if (row.original[columnId]?.toString() === value.toString()) return; // check that the value has changed
  }
  if (columnId.includes('date') || isHiringPlan && findFieldByName(columnId)?.type.toLowerCase() === 'date' // it is only for hiring plan
  ) {
    if (value === null || value === '') {
      // this is not working. When resetting date to "" it is failing throwing an error
      delete newData[columnId];
      // eslint-disable-next-line no-param-reassign
      delete row.original[columnId]; // updating original row
    } else {
      const newDateString = getDateStringFormat(parseUTCDateObject(value));
      newData[columnId] = newDateString;
      // eslint-disable-next-line no-param-reassign
      row.original[columnId] = newDateString; // updating original row
    }
  } else {
    const newValue = fieldToType(columnId) === 'number' ? numeral(value).value() : value;
    // update newData with new value
    newData[columnId] = newValue;

    // eslint-disable-next-line no-param-reassign
    row.original[columnId] = newValue; // updating original row
  }
  if (isHiringPlan) {
    /**
     * Loop through customFields array, extract each field's id and name,
     * normalize the name, and add to newCustomFields object with id as key
     * and normalized name as value. Also remove normalized name from
     * newData object.
     *
     * Note: This code is specific to the hiring plan.
     *
     * Example:
     * If customFields = [{ id: 1, name: "Custom Field 1" }, { id: 2, name: "Custom Field 2" }]
     * and newData = { custom_field_1: "Value 1", custom_field_2: "Value 2", other_field: "Other Value" }
     *
     * Then:
     * newCustomFields = { 1: "Value 1", 2: "Value 2" }
     * newData = { other_field: "Other Value" }
     */
    let newCustomFields: any = {};
    customFields.forEach((field: any) => {
      newCustomFields = {
        ...newCustomFields,
        [field.id]: newData[field.name.toLowerCase().split(' ').join('_')]
      };
      delete newData[field.name.toLowerCase().split(' ').join('_')];
    });
    mutation.mutate({
      ...newData,
      custom_fields: newCustomFields
    });
  } else {
    mutation.mutate({
      ...newData
    });
  }
};
export const hiringPlanGetCreateFunctionFromMutation = (mutation: any, payload: any) => (row: any, activeHiringPlan: HiringPlan, updateHiringPlan: any) => {
  const department = row.original.department_name;
  mutation.mutate({
    ...payload,
    department,
    start_date: getDateStringFormat(parseUTCDateObject())
  }, {
    onSuccess: (employee: Employee) => {
      updateHiringPlan.mutate({
        ...activeHiringPlan,
        employee_sort_order: updateEmployeeSortOrder([(employee.id as number)], activeHiringPlan.employee_sort_order)
      });
    }
  });
};
export const genericGetCreateFunctionFromMutation = (mutation: any, payload: any) => () =>
/**
 * Handles updating data based on row, and event.
 * @param {any} row - The row containing the cell.
 */
{
  mutation.mutate({
    ...payload
  });
};

// Use in getCopyFunctionFromMutation
export const getCopyFunctionFromMutation = (mutation: any) => (record: any, activeHiringPlan: HiringPlan, updateHiringPlan: any) => {
  if (record) {
    const recordId = record.id;
    const newData = {
      ...record
    };
    delete newData.id;
    Object.keys(newData).forEach(key => {
      if (newData[key] === null) {
        delete newData[key];
      }
    });
    mutation.mutate(newData, {
      onSuccess: (employee: Employee) => {
        updateHiringPlan.mutate({
          ...activeHiringPlan,
          employee_sort_order: updateEmployeeSortOrder([(employee.id as number)], activeHiringPlan.employee_sort_order, false, recordId)
        });
      }
    });
  }
};
export const getDeleteFunctionFromMutation = (mutation: any, activeHiringPlan: HiringPlan, updateHiringPlan: any) => (row: any) => {
  const employeeId = row.original.id;
  mutation.mutate(row.original, {
    onSuccess: () => {
      updateHiringPlan.mutate({
        ...activeHiringPlan,
        employee_sort_order: updateEmployeeSortOrder(employeeId, activeHiringPlan.employee_sort_order, true)
      });
    }
  });
};

/**
 * Determines whether the current page is the financial report page.
 *
 * @param pathname - The current pathname from the URL.
 * @returns {boolean} - Returns true if it's the financial report page, otherwise false.
 */
export const isFinancials = (pathname: string): boolean | '' => {
  if (pathname) {
    const pathParts = pathname?.split('/').filter(part => part !== '');
    // remove any empty strings from the array
    if (pathParts.length > 2) {
      const res = pathParts[2] === dynamicRoute.financials;
      return res;
    }
  }
  return false;
};

/**
 * Compares the reset generated date to the current date
 * to determine if the subtitle should be 'Actual' or 'Forecast'.
 *
 * @param generatedDate - The generated date to compare
 * @returns 'Actual' if generated date is in the past, 'Forecast' otherwise
 */

function isActualDate(generatedDate: string, currentDate: any) {
  return currentDate >= generatedDate;
}
export function getSubtitle(generatedDate: any, currentDate: any) {
  return isActualDate(generatedDate, currentDate) ? 'Actual' : 'Forecast';
}

/**
 * Generates a subtitle string indicating if the date is actual or forecast, for quarterly dates.
 * @param generatedDate - The generated quarterly date string to check
 * @returns A subtitle string indicating if the date is actual or forecast
 */
export function getSubtitleForQuarters(generatedDate: any, currentDate: any) {
  const year = generatedDate.split('-')[1]; // get year
  const quarter = generatedDate.charAt(1); // get quarter number

  const quarterToMonth: any = {
    '1': '03',
    '2': '06',
    '3': '09',
    '4': '12'
  };
  const month = quarterToMonth[quarter] || '01';
  const newGeneratedDate = month === '01' ? `${year}-01-0` : `${year}-${month}-01`;
  return getSubtitle(newGeneratedDate, currentDate);
}

/**
 * Gets the subtitle text ("Actual" or "Forecast") for a generated date
 * that represents a year. Checks if the year in the generated date is the
 * current year, and if so checks the month to determine subtitle.
 * @param generatedDate - The generated date to get the subtitle for
 * @returns The subtitle text
 */
export function getSubtitleForYears(generatedDate: any, currentDate: any) {
  const generatedYear = parseUTCDateObject(generatedDate).getFullYear();
  const currYear = parseUTCDateObject(generatedDate).getFullYear();
  if (currYear === generatedYear) {
    return parseUTCDateObject(currentDate).getMonth() + 1 === 12 ? 'Actual' : 'Forecast';
  }
  if (currYear > generatedYear) {
    return 'Actual';
  }
  return 'Forecast';
}

/**
 * Returns 'Actual' if the current date (with hours reset) equals the end date (with hours reset),
 * otherwise returns 'Forecast'. Used to generate subtitles indicating if data is actual or forecasted.
 */
export function getSubtitleForTotal(currentDate: any, endDate: any) {
  return parseUTCDateObject(currentDate).setHours(0, 0, 0, 0) === parseUTCDateObject(endDate).setHours(0, 0, 0, 0) ? 'Actual' : 'Forecast';
}

/**
 * Compares the generated date (with hours reset) against the current date,
 * and returns a style string for the cell border based on whether the date is past or future.
 * Returns a blue border style if the date is in the past, otherwise no border.
 */
export function getStyle(generatedDate: any, currentDate: any) {
  return isActualDate(generatedDate, currentDate) ? `4px solid ${colors.midBlue}` : 'none';
}

/**
 * Determines the border style for quarterly columns based on comparing
 * the current date to the start of the quarter for the generated date.
 *
 * @param generatedDate - The generated date string in YYYY-Q format
 * @returns The CSS border style property value
 */
export function getStyleForQuarters(generatedDate: any, currentDate: any) {
  const year = generatedDate.split('-')[1]; // get year
  const quarter = generatedDate.charAt(1); // get quarter number

  const quarterToMonth: any = {
    '1': '03',
    '2': '06',
    '3': '09',
    '4': '12'
  };
  const month = quarterToMonth[quarter] || '01';
  const newGeneratedDate = month === '01' ? `${year}-01-01` : `${year}-${month}-01`;
  return getStyle(newGeneratedDate, currentDate);
}

/**
 * Determines the border style for table header cells based on the year.
 *
 * Compares the year from the generated date string to the current year.
 * - If years match, checks if current month is December.
 * - If current year is greater, returns solid border.
 * - Otherwise returns no border.
 */
export function getStyleForYears(generatedDate: any, currentDate: any): any {
  const generatedYear = parseUTCDateObject(generatedDate).getFullYear();
  const currYear = parseUTCDateObject(currentDate).getFullYear();
  if (currYear === generatedYear) {
    return parseUTCDateObject(generatedDate).getMonth() + 1 === 12 ? `4px solid ${colors.midBlue}` : 'none';
  }
  if (currYear > generatedYear) {
    return `4px solid ${colors.midBlue}`;
  }
}

/**
 * Compares the endDate date (with hours/minutes/seconds set to 0)
 * to the currentDate (with hours/minutes/seconds set to 0).
 *
 * If the dates match, returns a solid blue border style.
 * Otherwise returns an empty string.
 *
 * Used to determine border style for total column in table.
 */
export function getStyleForTotal(currentDate: any, endDate: any) {
  return parseUTCDateObject(currentDate).setHours(0, 0, 0, 0) === parseUTCDateObject(endDate).setHours(0, 0, 0, 0) ? `4px solid ${colors.midBlue}` : '';
}