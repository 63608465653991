// eslint-disable-next-line simple-import-sort/imports
import type { Active, DropAnimation } from '@dnd-kit/core';
import { DndContext, DragOverlay, PointerSensor, closestCenter, defaultDropAnimationSideEffects, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { useEffect, useState } from 'react';
import styles from '../genericToggle.module.scss';
import type { Option } from './ToggleOptions';
import ToggleOption from './ToggleOptions';

// Represents the properties of a drag and drop component.
const DragAndDropComponent = ({
  options,
  updateSortOrder
}: {
  options: any;
  updateSortOrder?: (order: string) => void;
}) => {
  const [active, setActive] = useState<Active | null>(null);
  const [items, setItems] = useState(options);

  /**
   * Moves disabled items in the given array to the end.
   *
   * Filters the array into two arrays - enabled and disabled items.
   * Returns a new array with enabled items first, disabled items last.
   *
   * This allows enabled items to be sorted first separately from disabled.
   */
  const moveDisabledToEnd = (array: any) => {
    const disabledItems = array.filter((item: any) => !item.enabled);
    const enabledItems = array.filter((item: any) => item.enabled);
    return [...enabledItems, ...disabledItems];
  };

  // Updates the items state when the options prop changes.
  useEffect(() => {
    setItems(moveDisabledToEnd(options));
  }, [options]);

  /**
   * Generates a sorted order string from the enabled toggle options.
   * Filters out disabled options, maps option names to standardized keys,
   * joins the keys into a pipe-delimited string, and calls the callback.
   */
  const sortedOrderGenerator = (arr: any) => {
    const sortedArray = arr.filter((option: any) => option.enabled);
    const mappedArray = sortedArray.map((option: any) => {
      if (option.name === 'Bonus Yearly') return 'bonus';
      if (option.name === 'Name') return 'full_name';
      if (option.name === 'Salary Yearly') return 'yearly_salary';
      return option.name.toLocaleLowerCase().replace(/\s/g, '_');
    });
    setItems(arr);
    if (updateSortOrder) updateSortOrder(mappedArray.join('|'));
  };
  /**
   * Moves an element in an array from one index to another.
   *
   * @param arr - The array to move the element in.
   * @param from - The index of the element to move.
   * @param to - The index to move the element to.
   * @returns The new array with the element moved.
   */
  const arrayMove = (arr: any, from: any, to: any) => {
    const clone = [...arr];
    const startIndex = to < 0 ? clone.length + to : to;
    const item = clone.splice(from, 1)[0];
    clone.splice(startIndex, 0, item);
    return clone;
  };

  /**
   * Defines the sensors used for drag and drop interactions.
   *
   * Uses the PointerSensor for mouse/touch events.
   * Uses the KeyboardSensor to allow sorting via keyboard.
   */
  const sensors = useSensors(useSensor(PointerSensor));

  /**
   * Handles the drag end event for drag and drop sorting.
   * Moves the dragged item in the items array if it was dropped over another item.
   * Calls the sortedOrderGenerator callback with the updated items array.
   */
  const handleDragEnd = (event: any) => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const {
      active,
      over
    } = event;
    if (over && active.id !== over.id) {
      const oldIndex = items.findIndex((item: any) => item.name.toLowerCase().replace(/\s/g, '') === active.id);
      const newIndex = items.findIndex((item: any) => item.name.toLowerCase().replace(/\s/g, '') === over.id);
      sortedOrderGenerator(arrayMove(items, oldIndex, newIndex));
    }
    setActive(active);
  };

  /**
   * Configures the drop animation when dragging and dropping sortable items.
   * Uses the default drop animation side effects from DND with custom styling.
   */
  const dropAnimationConfig: DropAnimation = {
    sideEffects: defaultDropAnimationSideEffects({
      styles: {
        active: {
          opacity: '0.5'
        }
      }
    })
  };
  return <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}
  // eslint-disable-next-line @typescript-eslint/no-shadow
  onDragStart={({
    active
  }) => {
    setActive(active);
  }} onDragCancel={() => setActive(null)} data-sentry-element="DndContext" data-sentry-component="DragAndDropComponent" data-sentry-source-file="DragAndDropComponent.tsx">
      <SortableContext items={items} strategy={verticalListSortingStrategy} data-sentry-element="SortableContext" data-sentry-source-file="DragAndDropComponent.tsx">
        <ul className={styles.optionsList}>
          {items.map((option: any) => {
          return <ToggleOption {...option} id={option.name.toLowerCase().replace(/\s/g, '')} items={items} key={option.name} isDraggable={option.isDraggable} withGrabber />;
        })}
        </ul>
      </SortableContext>
      <DragOverlay dropAnimation={dropAnimationConfig} data-sentry-element="DragOverlay" data-sentry-source-file="DragAndDropComponent.tsx">
        {active ? <ToggleOption {...(items.find((item: any) =>
      // Checks if the ID of the currently active item matches the ID
      // generated from the name of the current item in the loop
      item.name.toLowerCase().replace(/\s/g, '') === active.id) as Option)} id={active.id} items={items} isDraggable /> : null}
      </DragOverlay>
    </DndContext>;
};
export default DragAndDropComponent;