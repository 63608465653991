// eslint-disable-next-line simple-import-sort/imports
import { useState } from 'react';
import colors from '@/styles/scss/abstracts/_variables.module.scss';
import styles from '@/styles/template.module.scss';
import type { Models } from '@/utils/hooks/models/useModels';
import type { Graph } from '@/utils/types/DashboardAndChartsTypes';
import type { Formula } from '@/utils/types/formulaTypes';
import { Tooltip } from 'react-tooltip';
import type { ITemplate } from '.';
import TemplateHoverCardSvg from '../TemplateHoverCard';
import { templateIconGenerator } from '../templateUtils';

/**
 * Generates a cube UI element with the specified color and icon.
 *
 * @param {string} color - The background color of the cube.
 * @param {string} icon - The icon to display inside the cube.
 * @returns {JSX.Element} - A React component representing the cube.
 */
const cubeGenerator = (color: string, icon: string) => {
  return <div className={styles.cube} style={{
    backgroundColor: color
  }} data-sentry-component="cubeGenerator" data-sentry-source-file="MostUsedTemplates.tsx">
      {templateIconGenerator(icon, colors.white)}
    </div>;
};
export const hoverCardColors: string[] = [(colors.periwinkle2 as string), (colors.peach as string), (colors.shockingPink as string), (colors.lightCoral as string), (colors.lavenderPurple as string), (colors.mayaBlue as string), (colors.vividSkyBlue as string), (colors.shamrockGreen as string)];

/**
 * Generates a color based on the provided index, cycling through the `colorsPallete` array.
 *
 * @param index - The index to use for generating the color.
 * @returns The color from the `colorsPallete` array at the given index, or the first color if the index is out of bounds.
 */
// eslint-disable-next-line @typescript-eslint/no-shadow
export const colorGeneratorByOrder = (index: number, colors: string[]) => {
  return colors[index % colors.length] || (
  // Explained: if index is 8, 8 % 8 = 0, so it will return the first color
  colors[0] as string);
};

/**
 * Renders a template card component that displays information about a template, including its name, icon, and a "Start Here" button to select the template.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.template - The template object to display.
 * @param {function} props.handleSelectTemplate - A function to call when the "Start Here" button is clicked, passing the selected template.
 * @param {number} props.index - The index of the template in the list of templates.
 * @param {function} props.setIsHovered - A function to call when the user hovers over the template card, passing the template and its color.
 */
const TemplateCard = ({
  template,
  handleSelectTemplate,
  index,
  setHoveredTemplate,
  t,
  buttonText,
  disabledSelect,
  disableText
}: {
  template: ITemplate;
  handleSelectTemplate: (template: ITemplate) => void;
  index: number;
  setHoveredTemplate: (value: {
    color: string;
    template: ITemplate;
  } | null) => void;
  t: (key: string) => string;
  buttonText?: string;
  disabledSelect: boolean;
  disableText?: string;
}) => {
  // These are keys, are not colors. We don't need to import colors from _variables.scss

  const colorsPallete: string[] = [(colors.desaturatedBlue as string), (colors.paleOrange as string), (colors.livelyPink as string), (colors.softCoralPink as string), (colors.bluish as string), (colors.cheerfulBlue as string)];
  const iconTexts = ['SaaS', 'Formulas', 'Dashboards', 'Fundraising', 'Marketing'];

  /**
   * Generates an icon text based on the provided index.
   *
   * @param index - The index to use for generating the icon text.
   * @returns The icon text corresponding to the provided index, or the first icon text if the index is out of bounds.
   */
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const iconTextGeneratorByOrder = (index: number) => {
    return iconTexts[index % iconTexts.length] || (iconTexts[0] as string); // Explained: if index is 5, 5 % 5 = 0, so it will return the first icon text
  };
  const isExsistCustomColor = !!(template?.color && template?.color !== '#FFFFFF');
  return <div className={styles.mosdtUsedTemplateCard} onMouseEnter={() => setHoveredTemplate({
    color: isExsistCustomColor ? (template.color as string) : colorGeneratorByOrder(index, hoverCardColors),
    template
  })} onClick={() => {
    handleSelectTemplate(template);
    setHoveredTemplate(null);
  }} onMouseLeave={() => setHoveredTemplate(null)} data-sentry-component="TemplateCard" data-sentry-source-file="MostUsedTemplates.tsx">
      {disabledSelect && <Tooltip positionStrategy="fixed" id={`template-button-tooltip}-${index}`} style={{
      zIndex: 9999
    }} />}
      <div className={styles.templateCardIcon}>
        {cubeGenerator(isExsistCustomColor ? (template?.color as string) : colorGeneratorByOrder(index, colorsPallete), iconTextGeneratorByOrder(index))}
      </div>
      <div className={styles.templateCardHeader}>
        <h3>{template.name?.replace(/\(.*?\)/g, '')}</h3>
      </div>
      <div className={styles.hoverEffect}>
        <button type="button" disabled={disabledSelect} data-tooltip-id={`template-button-tooltip}-${index}`} data-tooltip-content={disableText}>
          {buttonText || t('template:start_here')}
        </button>
      </div>
    </div>;
};
interface TemplateDetailProps {
  data: Models[] | Formula[] | Graph[];
  detailType: string;
  color: string;
  t: (key: string) => string;
}

/**
 * Renders a list of template details, displaying the number of items and the detail type for each template.
 *
 * @param templates - An array of template detail objects, each containing information about a specific template.
 * @param t - A translation function used to translate the detail type strings.
 * @returns A React component that displays the template details.
 */
const TemplateDetails = ({
  templates,
  t
}: {
  templates: TemplateDetailProps[];
  t: (key: string) => string;
}) => {
  if (!templates || templates.length === 0) return null;
  /**
   * Renders a row of models for a template.
   *
   * @param models - An array of model objects.
   * @param template - An object containing the detail type and color of the template.
   * @returns A React component that displays the models for a template.
   */
  const generateModelsRow = (models: Models[], template: {
    detailType: string;
    color: string;
  }) => {
    return <div data-sentry-component="generateModelsRow" data-sentry-source-file="MostUsedTemplates.tsx">
        {models.map(model => <div className="mt-2 flex items-center" key={model.id}>
            {templateIconGenerator(template.detailType, template.color)}
            <span className="no-wrap ms-2 overflow-hidden text-ellipsis">
              {model.name}{' '}
              {model.name.includes('Model') ? '' : t('template:model')}
            </span>
          </div>)}
      </div>;
  };
  return <ul className="overflow-hidden" data-sentry-component="TemplateDetails" data-sentry-source-file="MostUsedTemplates.tsx">
      {templates.map((template, index: number) =>
    // eslint-disable-next-line react/no-array-index-key
    <li key={index} style={{
      color: colors[template.color]
    }}>
          <div className="flex items-center gap-2">
            {
        // eslint-disable-next-line no-nested-ternary
        template.detailType === 'Models' && template.data.length > 0 ? generateModelsRow((template.data as Models[]), template) : template.data.length > 0 && <>
                      {templateIconGenerator(template.detailType, template.color)}
                      <span>
                        {t(`template:${template?.detailType?.toLowerCase()}`)}
                      </span>
                    </>}
          </div>
        </li>)}
    </ul>;
};
export const TemplateHoverCard = ({
  hoveredTemplate,
  t
}: {
  hoveredTemplate: {
    color: string;
    template: ITemplate;
  };
  t: (key: string) => string;
}) => {
  return <div className={styles.templateHoverCard} data-sentry-component="TemplateHoverCard" data-sentry-source-file="MostUsedTemplates.tsx">
      <div className={styles.templateHoverCardDetail}>
        <h3>{hoveredTemplate.template.name}</h3>
        <TemplateDetails templates={[{
        data: hoveredTemplate.template.models,
        detailType: 'Models',
        color: hoveredTemplate.color,
        t
      }, {
        data: hoveredTemplate.template.formulas,
        detailType: 'Formulas',
        color: hoveredTemplate.color,
        t
      }, {
        data: hoveredTemplate.template.graphs,
        detailType: 'Graphs',
        color: hoveredTemplate.color,
        t
      }]} t={t} data-sentry-element="TemplateDetails" data-sentry-source-file="MostUsedTemplates.tsx" />
      </div>
      <TemplateHoverCardSvg data={hoveredTemplate} data-sentry-element="TemplateHoverCardSvg" data-sentry-source-file="MostUsedTemplates.tsx" />
    </div>;
};

/**
 * Renders a component that displays the most used templates.
 *
 * @param templates - An array of template objects.
 * @param translate - A function to translate strings.
 * @param handleSelectTemplate - A function to handle selecting a template.
 * @returns A React component that displays the most used templates.
 */
const MostUsedTemplates = ({
  templates,
  t,
  handleSelectTemplate,
  title,
  buttonText,
  disabledSelect = false,
  disableText
}: {
  templates: ITemplate[] | undefined;
  t: (key: string) => string;
  handleSelectTemplate: (template: ITemplate) => void;
  title?: string | null;
  buttonText?: string;
  disabledSelect?: boolean;
  disableText?: string;
}) => {
  const [hoveredTemplate, setHoveredTemplate] = useState<{
    color: string;
    template: ITemplate;
  } | null>(null);
  const showNoTemplates = () => <div className={styles.noTemplates} data-sentry-component="showNoTemplates" data-sentry-source-file="MostUsedTemplates.tsx">
      <p>{t('template:no_templates')}</p>
    </div>;
  return <div className="mb-8" data-sentry-component="MostUsedTemplates" data-sentry-source-file="MostUsedTemplates.tsx">
      {title && <h1 className={styles.sectionHeader}>
          {title || t('template:most_used_templates')}
        </h1>}

      <div className="flex flex-wrap items-center gap-5">
        {templates && templates.map((template: ITemplate, index: number) => <TemplateCard handleSelectTemplate={handleSelectTemplate} key={template.id} template={template} index={index} setHoveredTemplate={setHoveredTemplate} t={t} buttonText={buttonText} disabledSelect={disabledSelect} disableText={disableText} />)}

        {!templates || templates.length === 0 ? showNoTemplates() : null}
      </div>

      {hoveredTemplate && <TemplateHoverCard hoveredTemplate={hoveredTemplate} t={t} />}
    </div>;
};
export default MostUsedTemplates;