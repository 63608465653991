/* eslint-disable import/no-cycle */
import { useQuery } from '@tanstack/react-query';
import { isArray } from 'lodash';
import { useEffect } from 'react';
import { useShallow } from 'zustand/react/shallow';

import ApiUtils from '@/api/ApiUtils';
import { enableLogging } from '@/components/modelCalculations/calculateFormula';
import { formulaToSymbols } from '@/components/modelCalculations/modelUtils/formulaToSymbols';
import { QUERYKEYS } from '@/miscellaneous/constant/reactQueryKeyConfig';
import type { ZustandState } from '@/miscellaneous/store/zustand_store';
import useZustandStore from '@/miscellaneous/store/zustand_store';
import type {
  ExpressionType,
  Formula,
  ISymbol,
} from '@/utils/types/formulaTypes';

export function getParsedFormulas(formula: Formula) {
  let parsedFormulas: ExpressionType[] = [];
  let cleanFormulaStr: string = '';
  try {
    cleanFormulaStr = formula.expression_string.replace(/`/g, '').trim(); // replace backticks with double quotes
    parsedFormulas = JSON.parse(cleanFormulaStr || '[]'); // {formula: string,end:string, start:string}
  } catch (e) {
    if (enableLogging) {
      console.log('🚀 ~ addSymbolMapToFormula ~ formula:', formula);
      console.log(
        `Error parsing formula : ${formula.expression_string}, cleanFormulaStr is ${cleanFormulaStr}`,
        e,
      );
    }
    return [];
  }
  return parsedFormulas;
}
// this function is used to process the formulas and add symbol map to each formula
export function processFormula(formula: Formula) {
  const newMAp: { [key: string]: ISymbol[] } = {};

  if (!formula?.expression_string) return;
  let parsedFormulas: ExpressionType[] = [];
  parsedFormulas = getParsedFormulas(formula);
  // if parsed formulas is not an ExpressionType skip it
  if (!parsedFormulas || !isArray(parsedFormulas)) {
    console.error(`Error parsing all formulas ,  formula is:`, formula);
    console.error(
      `Error parsing all formulas , all formulas is:`,
      parsedFormulas,
    );
    return;
  }
  parsedFormulas?.forEach((parsedFormula: ExpressionType) => {
    if (!parsedFormula?.formula && parsedFormula.formula !== '') {
      if (enableLogging) {
        console.error(
          `Error parsing expression string ,  formula is:`,
          formula,
        );
        console.error(
          `Error parsing expression string ,  expression falied is:`,
          formula,
        );
      }
      return;
    }
    const symbols = formulaToSymbols(parsedFormula.formula);
    // eslint-disable-next-line no-param-reassign
    parsedFormula.symbols = symbols;
    newMAp[parsedFormula.formula] = symbols;
  });
  // eslint-disable-next-line no-param-reassign
  formula.symbolMap = newMAp;
  // eslint-disable-next-line no-param-reassign
  formula.parsedExpressions = parsedFormulas;
}

export const useFormulas = () => {
  const {
    activeCompanyId,
    activeBranchId,
    setBranchFormulas,
    branchFormulas,
    setActiveModelFormulas,
    activeModelId,
  } = useZustandStore(
    useShallow((state: ZustandState) => ({
      activeCompanyId: state.activeCompany?.id ?? null,
      activeBranchId: state.activeBranch?.id ?? null,
      setBranchFormulas: state.setBranchFormulas,
      branchFormulas: state.branchFormulas,
      setActiveModelFormulas: state.setActiveModelFormulas,
      activeModelId: state.activeModel?.id ?? null,
    })),
  );

  const { data, isLoading, error, refetch, isSuccess } = useQuery<Formula[]>({
    queryKey: [QUERYKEYS.ALLFORMULAS, activeCompanyId],
    queryFn: () => ApiUtils.getAllFormulas(activeCompanyId as number),
    enabled: !!activeCompanyId,
  });

  useEffect(() => {
    // add symbol map to each formula and parse it.
    if (isSuccess && data && data?.length > 0) {
      data.forEach((formula: Formula) => {
        processFormula(formula);
      });
    }
  }, [isSuccess, data, data?.length]);

  useEffect(() => {
    // this useEffect to calculate active branch formulas
    if (isSuccess && data && data?.length > 0 && activeBranchId) {
      const filteredBranchFormulas = data?.filter(
        (formula: any) => formula.branch_id === activeBranchId,
      );
      const filtered = filteredBranchFormulas?.filter(
        (formula: any) => formula.model_id === activeModelId,
      );
      setActiveModelFormulas(filtered);
      setBranchFormulas(filteredBranchFormulas);
    } else {
      setBranchFormulas([]);
    }
  }, [data, isSuccess, activeBranchId, activeModelId]);

  useEffect(() => {
    // this useEffect to calculate active models formulas
    if (branchFormulas) {
      const filtered = (branchFormulas as Formula[])?.filter(
        (formula: Formula) => formula.model_id === activeModelId,
      );
      setActiveModelFormulas(filtered);
    } else {
      setActiveModelFormulas([]);
    }
  }, [activeModelId]);

  return {
    data,
    isLoading,
    error,
    refetch,
    isSuccess,
  };
};
