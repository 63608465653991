import _ from 'lodash';
import { createAccountTreeRow, getformulaTableRow, getSectionRow } from '@/components/table/shared/TableHelpers/sharedTableRowRenderUtils';
import { DnDItemWithGrabber, DndParentRow } from '@/components/table/shared/TableHelpers/TableRowsComponents';
import type { TableRow } from '@/components/table/shared/types';
import type { Branch } from '@/utils/hooks/branches/useBranches';
import type { Output } from '@/utils/hooks/Outputs/useOutputs';
import type { ReportItem } from '@/utils/hooks/reports/reportTypes';
import { ReportItemEnum } from '@/utils/hooks/reports/reportTypes';
import type { Formula } from '@/utils/types/formulaTypes';

// generates the new report items to add to the rows in edit report drawer
export function getNewReportItems(activeReport: any) {
  const newSectionReportItem: ReportItem = {
    id: 0,
    options: {},
    name: 'Section',
    type: ReportItemEnum.Section,
    report_id: activeReport.id,
    base_item_id: null,
    parent_id: null,
    cumulative: null
  };
  const newFormulaReportItem: ReportItem = {
    ...newSectionReportItem,
    name: 'Formula',
    type: ReportItemEnum.Formula
  };
  const newOutputReportItem: ReportItem = {
    ...newSectionReportItem,
    name: 'Output',
    type: ReportItemEnum.AccountTree
  };
  return {
    newFormulaReportItem,
    newOutputReportItem,
    newSectionReportItem
  };
}

// converts table row to a structure that can be used in the table so DND parent row will use it correctly
function getTableOriginalStructure(row: TableRow) {
  return {
    original: row,
    id: String(row.id),
    type: row.type?.toLocaleLowerCase()
  };
}
// generates the new section row to add to the rows in edit report drawer
export interface EditReportBodyProps {
  type: 'groups' | 'outputs' | 'calculations';
  searchValue: string;
}

// generates the new section row to add to the rows in edit report drawer
export function createSectionNewRow(activeBranch: Branch, newSectionReportItem: ReportItem) {
  const sectionRow = getTableOriginalStructure(getSectionRow({
    currentSection: newSectionReportItem,
    sign: 1,
    depth: 0,
    branch_id: activeBranch.id
  }));
  return sectionRow;
}
// generates the new section row to add to the rows in edit report drawer
const generateBranchFormulaRows = (branchFormulas: Formula[], newFormulaReportItem: any) => {
  return branchFormulas.reduce((acc: ReturnType<typeof getTableOriginalStructure>[], formula: Formula) => {
    if (formula.group === 'Calculations') {
      const tableRow = getTableOriginalStructure(getformulaTableRow({
        ...newFormulaReportItem,
        formulaId: formula.id,
        type: 'formula'
      }, formula, 0));
      acc.push(tableRow);
    }
    return acc;
  }, []);
};
// memoized version of generateBranchFormulaRows
export const memoizedGenerateBranchFormulaRows = _.memoize(generateBranchFormulaRows, (branchFormulas, newFormulaReportItem) => {
  // Custom resolver function to determine cache key
  return JSON.stringify(branchFormulas) + newFormulaReportItem.id;
});
// generates the new avaialble formula rows
export function getDnDRows(rows: {
  id: string;
  original: TableRow;
}[]) {
  return rows?.map((row: any) => <DndParentRow key={row.original.id} row={row} rowStyle={{}} isOverEnabled={false}>
      <div>{row.original.sidebar}</div>
    </DndParentRow>);
}
export function getDnDItemsWithGrabber(rows: {
  id: string;
  original: TableRow;
}[]) {
  return rows?.map((row: any) => <DnDItemWithGrabber key={row.original.id} row={row} isOverEnabled={false}>
      <div>{row.original.sidebar}</div>
    </DnDItemWithGrabber>);
}

// generates the new avaialble output rows from branch Outputs
export function getOutputRows(branchOutputs: Output[], newOutputReportItem: ReportItem) {
  let outputRows: {
    id: string;
    original: TableRow;
  }[] = [];
  let topLevelRows: {
    id: string;
    original: TableRow;
  }[] = [];

  // function to generate the output row
  const getOutputTableRow = (output: any) => {
    return getTableOriginalStructure(createAccountTreeRow({
      item: {
        ...newOutputReportItem,
        account_id: output.id
      },
      tableDepth: 0,
      accountTreeLevel: 0,
      currentRowObject: output
    }));
  };
  [outputRows, topLevelRows] = branchOutputs.reduce((acc: any, output: Output) => {
    if (output.parent_output) {
      acc[0].push(getOutputTableRow(output));
    } else {
      acc[1].push(getOutputTableRow(output));
    }
    return acc;
  }, [[], []]);
  topLevelRows.reverse();
  return {
    outputRows,
    topLevelRows
  };
}