// eslint-disable-next-line import/no-cycle
import { getKeyLabel } from './subComponents/toggleHiringPlanUtils';

/**
 * Sorts the given options array based on the provided sort order list and hiring plan.
 *
 * The options are sorted by finding the index of their key label in the sort order list.
 * Options not in the sort order list are placed at the end.
 */
const sortToggleMenuOptions = (options: any, sortOrderList: any, hiringPlan: any) => {
  return options.sort((a: any, b: any) => {
    const aIndex = sortOrderList(hiringPlan).indexOf(getKeyLabel(a.name).toLocaleLowerCase());
    const bIndex = sortOrderList(hiringPlan).indexOf(getKeyLabel(b.name).toLocaleLowerCase());
    if (aIndex === -1) {
      return 1;
    }
    if (bIndex === -1) {
      return -1;
    }
    return aIndex - bIndex;
  });
};
export { sortToggleMenuOptions };