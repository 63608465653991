import { flexRender, getCoreRowModel, getExpandedRowModel, useReactTable } from '@tanstack/react-table';
import { useVirtualizer } from '@tanstack/react-virtual';
import { isEqual } from 'lodash';
import { useRouter } from 'next/router';
import React, { memo, useEffect, useMemo } from 'react';
import { useShallow } from 'zustand/react/shallow';
import SidebarDrawer from '@/components/common/SidebarDrawer/SidebarDrawer';
import FinancialDrawerContent from '@/components/financialReports/FinancialDrawer/FinancialDrawerContent/FinancialDrawerContent';
import styles from '@/components/table/shared/table/GeneralTable/styles/baseTableStyles.module.scss';
import { getInitialExpandedRootRows } from '@/miscellaneous/helper/tableHelper';
import type { UtilsStoreState } from '@/miscellaneous/store/utilsStore/utilsStore';
import useUtilsStore from '@/miscellaneous/store/utilsStore/utilsStore';
import type { ZustandState } from '@/miscellaneous/store/zustand_store';
import useZustandStore from '@/miscellaneous/store/zustand_store';
import variables from '@/styles/scss/abstracts/_variables.module.scss';
import { getElementOrRowByID, scrollElementIntoView } from '@/utils/hooks/useScrollIntoView/useScrollIntoView';
import { RenderTableHeader } from '../tableHelper';
import { TableDNDProvider } from '../TableHelpers/TableDnDHelpers/TableDNDContext';
import { DnDOverlay, TableRow } from '../TableHelpers/TableRowsComponents';
import TableWrapper, { useTableScrollingRef } from '../TableHelpers/TableWrapper';
import { fuzzyFilter } from '../utils';
/**
 * Props for the Table component.
 * @typedef {Object} TableProps
 * @property {any} data - The data to be displayed in the table.
 * @property {any} columns - The column configuration for the table.
 * @property {any} settings - The settings configuration for the table.
 */
interface TableProps {
  data: any;
  columns: any;
  settings: any;
  hideCogIcon?: boolean;
  enableDnd?: boolean;
  headerTitle?: string;
  isVirtualized?: boolean;
}

/**
 * Table component with advanced features and styling.
 * @param {TableProps} props - React component props.
 * @returns {JSX.Element} - The rendered component.
 */

const Table: React.FC<TableProps> = ({
  data,
  columns,
  settings,
  hideCogIcon = false,
  enableDnd = false,
  headerTitle = 'Table Header',
  isVirtualized = false
}) => {
  const {
    isTableDisabled,
    query: searchQuery,
    activeCompany
  } = useZustandStore(useShallow((state: ZustandState) => {
    return {
      isTableDisabled: state.isTableDisabled,
      query: state.query,
      activeCompany: state.activeCompany
    };
  }));
  const {
    parentRowIDsToScrollTo,
    rowIDToScrollTo,
    setRowIDToScrollTo
  } = useUtilsStore(useShallow((state: UtilsStoreState) => {
    return {
      parentRowIDsToScrollTo: state.parentRowIDsToScrollTo,
      rowIDToScrollTo: state.rowIDToScrollTo,
      setRowIDToScrollTo: state.setRowIDToScrollTo
    };
  }));
  const tableHeadWrapper = React.useRef<HTMLTableSectionElement>(null);
  const router = useRouter();
  const previousQueryRef = React.useRef(router.query);
  const previousCompanyRef = React.useRef(activeCompany);
  const tableFirstTimeRendered = React.useRef(false);

  /**
   * Filters the table data based on the search query.
   * @returns {Array} - The filtered data. If the search query is empty or consists only of whitespace,
   *                    the original data is returned. Otherwise, it filters the `subRows` of each item
   *                    in the data using the `fuzzyFilter` function.
   */
  const filteredData = useMemo(() => {
    if (!searchQuery?.trim()) {
      return data;
    }
    return fuzzyFilter(data || [], searchQuery);
  }, [searchQuery, data]);

  // Create a React table instance
  const table = useReactTable({
    data: filteredData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    enableColumnPinning: true,
    enableExpanding: true,
    enableHiding: true,
    enablePinning: true,
    enableRowPinning: true,
    enableColumnFilters: true,
    enableSorting: true,
    autoResetExpanded: false,
    initialState: {
      columnPinning: {
        left: ['sidebar']
      },
      expanded: getInitialExpandedRootRows(data, settings, parentRowIDsToScrollTo),
      columnVisibility: {
        'mrt-row-expand': false
      }
    },
    getSubRows: (originalRow: any) => originalRow.subRows,
    getRowId: (originalRow: any) => originalRow.id
  });
  useEffect(() => {
    tableFirstTimeRendered.current = true;
  }, []);
  useEffect(() => {
    if (searchQuery) {
      table.setExpanded(true);
    } else {
      table.setExpanded(getInitialExpandedRootRows(data, settings, parentRowIDsToScrollTo));
    }
  }, [searchQuery, parentRowIDsToScrollTo]);
  useEffect(() => {
    if (!isEqual(previousQueryRef.current, router.query) || previousCompanyRef.current.name !== activeCompany.name || tableFirstTimeRendered.current) {
      table.setExpanded(getInitialExpandedRootRows(data, settings, parentRowIDsToScrollTo));
      tableFirstTimeRendered.current = false;
      setTimeout(() => {
        previousQueryRef.current = router.query;
        previousCompanyRef.current = activeCompany;
      }, 1);
    }
  }, [settings.expanded, settings.rowTypesToExpand, router.query, tableFirstTimeRendered, data, activeCompany]);

  // Retrieve header groups from the table model
  const tableHeaderGroups = table.getHeaderGroups();
  // Retrieve rows from the table model
  const tableRows = table.getRowModel().rows;
  useEffect(() => {
    if (!rowIDToScrollTo) return;
    // find row in table rows that is equal to rowIDToScrollTo
    let elementFoundTime: number | null = null;
    const interval = setInterval(() => {
      const element = getElementOrRowByID(rowIDToScrollTo);
      if (element) {
        if (!elementFoundTime) {
          elementFoundTime = performance.now();
        } else if (performance.now() - elementFoundTime >= 1000) {
          scrollElementIntoView(rowIDToScrollTo);
          setRowIDToScrollTo(null, parentRowIDsToScrollTo);
          clearInterval(interval);
        }
      } else {
        elementFoundTime = null;
      }
    }, 100);
    return () => clearInterval(interval);
  }, [rowIDToScrollTo, setRowIDToScrollTo]);

  /**
   * Conditionally enables rendering based on the depth of the row and the value of the cell.
   * @param {any} row - The row object.
   * @param {any} cell - The cell object.
   * @param {any} toRender - The content to be rendered.
   * @returns {any} - The rendered content if the conditions are met; otherwise, returns undefined.
   */

  const selectivelyEnable = (row: any, cell: any, toRender: any) => {
    /**
     * Checks if the row has a flag indicating empty cells.
     * This is used to determine if the row should be rendered when empty.
     */
    const isEmptyCellsRow = row.original?.emptyCellsRow;
    const displayCellDataWhenExpanded = row.original?.displayCellDataWhenExpanded;
    const checkIsNotEmptRow = () => isEmptyCellsRow === undefined ? true : !row.original.emptyCellsRow;
    const rowHasSubRows = row.original.subRows && row.original.subRows.length > 0;
    const rowIsExpanded = row.getIsExpanded() && rowHasSubRows;

    /**
     * Conditionally renders the cell content if:
     * - The row is a leaf node (no subrows) and is not marked as an empty row and not expanded
     * - Or the cell is in the 'sidebar' column
     * -  or displayCellDataWhenExpanded is true and the row is expanded
     * This allows leaf rows and the sidebar column to render even when empty,
     * while other empty cells do not render anything.
     */
    const rowIsNotEmptyAndNotExpanded = checkIsNotEmptRow() && !rowIsExpanded;
    const isSidebarCell = cell.column.id === 'sidebar';
    if (rowIsNotEmptyAndNotExpanded || isSidebarCell || displayCellDataWhenExpanded && rowIsExpanded) {
      // Render if it's a leaf node or if it's the 'sidebar' column
      return toRender;
    }
  };

  /**
   * Renders content in a cell based on conditions.
   * @param {any} row - The row data.
   * @param {any} cell - The cell data.
   * @returns {React.ReactNode} - The rendered cell content.
   */
  const renderCellContent = (row: any, cell: any): React.ReactNode => {
    return selectivelyEnable(row, cell, flexRender(cell.column.columnDef.cell, cell.getContext()));
  };
  const {
    tableRef,
    scrollRef,
    handleScroll,
    tableElementRef
  } = useTableScrollingRef();

  /**
   * Initializes a virtual scroller for the table rows.
   * The virtual scroller is used to efficiently render only the rows that are visible in the table viewport,
   * improving performance for large datasets.
   *
   * @param {number} count - The total number of rows in the table.
   * @param {() => HTMLElement | null} getScrollElement - A function that returns the HTML element that should be used for scrolling.
   * @param {() => number} estimateSize - A function that estimates the height of each row.
   * @param {number} overscan - The number of extra rows to render above and below the visible viewport.
   * @returns {any} - The virtual scroller instance.
   */
  const rowVirtualizer = useVirtualizer({
    count: tableRows.length,
    getScrollElement: () => tableRef.current,
    estimateSize: () => +(variables.cellHeight as string).replace('px', ''),
    overscan: 100
  });

  /**
   * Renders the table body by either using virtualization or rendering all rows directly.
   * @param {any[]} rows - The array of row data to be rendered.
   * @returns {React.ReactNode} - The rendered table body.
   */
  const renderTableBody = (rows: any) => {
    const renderTableRow = (row: any) => <TableRow key={row.id} rowStyle={row.original.rowStyle ?? {}} row={row} isTableDisabled={isTableDisabled} isEmptyCellsRow={row.original.emptyCellsRow ?? false} renderCellContent={renderCellContent} enableDnd data-sentry-element="TableRow" data-sentry-component="renderTableRow" data-sentry-source-file="table.tsx" />;

    // if isVirtualized is true, use the virtualizer to render only the visible rows
    if (isVirtualized) {
      return rowVirtualizer.getVirtualItems().map(virtualRow => {
        const row = rows[virtualRow.index] || {};
        return renderTableRow(row);
      });
    }
    return rows.map((row: any) => renderTableRow(row));
  };
  return <TableDNDProvider data-sentry-element="TableDNDProvider" data-sentry-component="Table" data-sentry-source-file="table.tsx">
      <TableWrapper data={data} renderDndContext={enableDnd} hideCogIcon={hideCogIcon} headerTitle={headerTitle} tableHeadWrapper={tableHeadWrapper} settings={settings} tableRef={tableRef} scrollRef={scrollRef} handleScroll={handleScroll} isVirtualized={isVirtualized} data-sentry-element="TableWrapper" data-sentry-source-file="table.tsx">
        <table ref={tableElementRef} id={styles.customTable} className="table-head-container">
          <thead className={`${styles.tableHead}`} ref={tableHeadWrapper}>
            <RenderTableHeader {...{
            tableHeaderGroups,
            isTableDisabled
          }} data-sentry-element="RenderTableHeader" data-sentry-source-file="table.tsx" />
          </thead>
          <tbody>{renderTableBody(tableRows)}</tbody>
        </table>
        <SidebarDrawer data-sentry-element="SidebarDrawer" data-sentry-source-file="table.tsx">
          <FinancialDrawerContent data-sentry-element="FinancialDrawerContent" data-sentry-source-file="table.tsx" />
        </SidebarDrawer>
        <DnDOverlay data-sentry-element="DnDOverlay" data-sentry-source-file="table.tsx" />
      </TableWrapper>
    </TableDNDProvider>;
};
export default memo(Table);