import chroma from 'chroma-js';

// Generate related colors based on the base color
export const generateRelatedColors = (hex: string) => {
  const baseColor = chroma(hex);
  // Define factors based on observed patterns
  const factors = [1.1, 1.3, 0.9, 1.2, 1];
  // Generate related colors
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const colors: string[] = factors.map((factor) => {
    // Adjust the color's luminance
    const adjustedColor = baseColor.luminance(
      baseColor.luminance() * factor,
      'lab',
    );
    return adjustedColor.hex();
  });
  return colors;
};
