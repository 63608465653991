/**
 * ActionMenu Component
 *
 * Description:
 * The `ActionMenu` component represents a menu with options to modify the tone or style
 * of the ChatGPT response. It provides a list of buttons, each corresponding to a different
 * type of modification (e.g., Shorter, Longer, Simpler, Casual, Professional). Clicking on
 * an option invokes the `addOptionToChat` callback and closes the menu.
 */

import type { FC } from 'react';
import React from 'react';
import CasualButtonSvg from '@/miscellaneous/Images/CopilotIcons/CasualButtonSvg';
import LongerButtonSvg from '@/miscellaneous/Images/CopilotIcons/LongerButtonSvg';
import ProfessionalButtonSvg from '@/miscellaneous/Images/CopilotIcons/ProfessionalButtonSvg';
import ShorterButtonSvg from '@/miscellaneous/Images/CopilotIcons/ShorterButtonSvg';
import SimplerButtonSvg from '@/miscellaneous/Images/CopilotIcons/SimplerButtonSvg';
import styles from '@/styles/scss/copilotChat.module.scss';
interface IActionMenu {
  /**
   * Function to close the action menu.
   */
  close: () => void;

  /**
   * Function to handle adding an option to the chat.
   * @param option - The selected option.
   * @param id - The ID of the message to replace with the option.
   */
  addOptionToChat?: (option: string, id: any) => void;

  /**
   * The unique identifier of the message.
   */
  id: string;
}

/**
 * ActionMenu component responsible for rendering a menu of modification options.
 * @param close - Function to close the action menu.
 * @param addOptionToChat - Function to handle adding an option to the chat.
 * @param id - The unique identifier of the message.
 * @returns The ActionMenu component.
 */
const ActionMenu: FC<IActionMenu> = ({
  close,
  addOptionToChat,
  id
}) => {
  /**
   * Handles the click event of an action menu option.
   * @param type - The type of modification to apply to the response.
   * @returns void
   */
  const onClickActionMenu = (type: string) => {
    if (addOptionToChat) {
      addOptionToChat(type, id);
    }
    close();
  };

  /**
   * An array of options to display in the action menu.
   */
  const actionMenuOptions = [{
    name: 'Shorter',
    icon: <ShorterButtonSvg />,
    onClick: () => onClickActionMenu('shorter')
  }, {
    name: 'Longer',
    icon: <LongerButtonSvg />,
    onClick: () => onClickActionMenu('longer')
  }, {
    name: 'Simpler',
    icon: <SimplerButtonSvg />,
    onClick: () => onClickActionMenu('simpler')
  }, {
    name: 'Casual',
    icon: <CasualButtonSvg />,
    onClick: () => onClickActionMenu('casual')
  }, {
    name: 'Professional',
    icon: <ProfessionalButtonSvg />,
    onClick: () => onClickActionMenu('professional')
  }];
  return <div className={styles.ActionMenu} data-sentry-component="ActionMenu" data-sentry-source-file="ActionMenu.tsx">
      <span>Modify:</span>
      <ul>
        {actionMenuOptions.map((option, index) => {
        return (
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, react/no-array-index-key
          <li key={index} onClick={option.onClick}>
              {option.icon} {option.name}
            </li>
        );
      })}
      </ul>
    </div>;
};
export default ActionMenu;