import { Compartment } from '@codemirror/state';
import type { NextRouter } from 'next/router';
import { Tooltip } from 'react-tooltip';
import Popup from 'reactjs-popup';
import { type FormulaExpressionByDate, getFormulaAnscestorsRowIds } from '@/components/modelCalculations/modelUtils/modelUtils';
import dynamicRoute from '@/miscellaneous/constant';
import { generateIconsByTypeForAutocomplete } from '@/miscellaneous/helper/tableHelper';
import { getModelNameById } from '@/miscellaneous/store/CalculationsStore';
import useUtilsStore from '@/miscellaneous/store/utilsStore/utilsStore';
import useZustandStore from '@/miscellaneous/store/zustand_store';
import styles from '@/styles/scss/formulaCell.module.scss';
import { createAdjustedUTCDate, parseUTCDateObject } from '@/utils/dateUtils';
import type { Branch } from '@/utils/hooks/branches/useBranches';
import { SystemFormulaIdsRange } from '@/utils/hooks/formulas/SystemFormulas';
import type { Models } from '@/utils/hooks/models/useModels';
import type { Formula } from '@/utils/types/formulaTypes';
import { formulaIDToRowID } from '../../types';
// eslint-disable-next-line import/no-cycle
import { setAutocompletionIdiom } from './autocompletion';
import { generateEditor } from './utils';
const languageCompart = new Compartment();
const autocompleteCompart = new Compartment();

/**
 * Defines the styles for the popup component used in the formula cell.
 * The popup is positioned below the formula cell and has no border, box shadow, or top margin.
 */
const formulaCellPopupStyle = {
  height: 'auto',
  padding: 0,
  border: 'none',
  boxShadow: 'none',
  marginTop: '49px'
};

/**
 * Generates an array of variables from the provided formula data, filtered by the active branch and mapped to include relevant information.
 *
 * @param formulas - An array of Formula objects.
 * @param activeBranch - The currently active Branch object.
 * @param allModelsByActiveBranch - An object containing all models associated with the active branch.
 * @returns An array of variable objects, each with an id, label, type, rightText, hasDropdown, and description.
 */
const generateVariablesFromData = (formulas: Formula[], activeBranch: Branch, allModelsByActiveBranch: Models[]) => {
  if (!activeBranch?.id) console.log('🚀 ~ activeBranch:', activeBranch);
  const variables = formulas?.map((formula: Formula) => {
    // ! We need to move this variable to the zusatnd store. Because this is a costly operation.
    let modelName = '';
    if (formula.id <= SystemFormulaIdsRange) {
      modelName = getModelNameById(formula.id);
    } else {
      const model = allModelsByActiveBranch?.find((m: any) => m.id === formula.model_id);
      modelName = model?.name.trim() || '';
    }
    return {
      id: formula?.id,
      label: formula?.name,
      type: formula?.group?.toLowerCase(),
      rightText: formula.name,
      hasDropdown: true,
      description: formula?.group === 'Calculations' ? 'Calculations' : formula?.input_type,
      modelName
    };
  });
  return variables;
};

/**
 * Generates a new editor instance with the provided configuration.
 * This editor is specifically designed for editing formula expressions within a cell.
 *
 * @param ref - A reference to the editor element.
 * @param editorValue - The initial value of the editor.
 * @param setEditorState - A function to update the editor state.
 * @param data - An array of Formula objects.
 * @param activeBranch - The currently active Branch object.
 * @param allModelsByActiveBranch - An object containing all models associated with the active branch.
 * @returns A new editor instance.
 */
const generateNewEditor = (ref: {
  current: HTMLDivElement | null;
}, editorValue: string, setEditorState: (state: any) => void, data: Formula[], activeBranch: Branch, allModelsByActiveBranch: Models[], setHidden: (hidden: boolean) => void, goToFormulaById: (formulaId: number) => void) => {
  const newEditor = generateEditor(languageCompart, autocompleteCompart, ref, setEditorState, generateVariablesFromData((data as Formula[]), activeBranch, allModelsByActiveBranch), setHidden, editorValue, goToFormulaById);
  return newEditor;
};

/**
 * Loads and initializes a new formula cell editor instance with the provided configuration.
 * This editor is specifically designed for editing formula expressions within a cell.
 *
 * @param currentFormulaExpression - The initial formula expression value of the editor.
 * @param editorRef - A reference to the DOM element where the formula cell editor will be mounted.
 * @param setEditorState - A function to update the state of the formula cell editor.
 * @param data - An array of Formula objects to be used within the editor for autocomplete and validation.
 * @param activeBranch - The currently active Branch object, used to contextualize formula editing.
 * @param allModelsByActiveBranch - An object containing all models associated with the active branch, providing additional context for formula editing.
 * @param toggleEditorVisibility - A function to toggle the visibility of the formula cell editor, allowing for a dynamic UI experience.
 * @param setHidden - A function to control the visibility state of the editor, particularly useful for conditional rendering.
 * @returns Void
 */
const onLoadEditor = (currentFormulaExpression: string, editorRef: {
  current: HTMLDivElement | null;
}, setEditorState: (state: any) => void, data: Formula[], activeBranch: Branch, allModelsByActiveBranch: Models[], toggleEditorVisibility: () => void, setHidden: (hidden: boolean) => void, currentFormula: Formula, goToFormulaById: (formulaId: number) => void) => {
  const newEditor = generateNewEditor(editorRef, currentFormulaExpression, setEditorState, data, (activeBranch as Branch), allModelsByActiveBranch, setHidden, goToFormulaById);
  setAutocompletionIdiom(newEditor, autocompleteCompart, generateVariablesFromData((data as Formula[]), (activeBranch as Branch), allModelsByActiveBranch), currentFormula?.id);
  toggleEditorVisibility();
};

/**
 * Updates a formula with the provided parameters.
 *
 * @param updateFormula - A mutation function to update the formula.
 * @param original - The original formula object.
 * @param expression_string - The new expression string for the formula.
 * @returns Void
 */
const updateFormulaWrapper = (updateFormula: {
  mutate: (arg0: any) => void;
}, original: Formula & {
  formula_id: number;
}, expression_string: string) => {
  updateFormula.mutate({
    group: original?.group,
    input_type: original?.input_type,
    model_id: original?.model_id,
    branch_id: original?.branch_id,
    name: original?.name,
    expression_string,
    emoji: original?.emoji,
    id: original?.formula_id,
    output_id: original?.output_id,
    parent_id: original?.parent_id
  });
};

/**
 * Renders a shared popup component that displays a formula editor.
 *
 * @param trigger - A function that returns the trigger element for the popup.
 * @param onLoadEditor - A callback function to be called when the editor is loaded.
 * @param hidden - A boolean indicating whether the popup should be hidden.
 * @param wrapperRef - A ref to the wrapper element of the popup.
 * @param editorRef - A ref to the editor element of the popup.
 * @param onClosePopup - A callback function to be called when the popup is closed.
 * @param popupStyle - An object containing the styles for the popup.
 * @returns A React component that renders the popup.
 */
const FormulaCellPopup = ({
  row,
  trigger,
  // eslint-disable-next-line @typescript-eslint/no-shadow
  onLoadEditor,
  hidden,
  wrapperRef,
  editorRef,
  onClosePopup,
  popupStyle
}: any) => {
  const groupRow = row?.original?.input_type === 'Group';
  return <Popup trigger={<span>{trigger()}</span>} onOpen={() => {
    onLoadEditor();
  }} onClose={onClosePopup} contentStyle={popupStyle} closeOnDocumentClick arrow={false} open={!hidden} position="top left" disabled={groupRow} // disable popup for group row
  nested data-sentry-element="Popup" data-sentry-component="FormulaCellPopup" data-sentry-source-file="formulaCellUtils.tsx">
      <div className={`${styles['formula-cell-wrapper']} ${styles.visible}`} ref={wrapperRef}>
        <Tooltip id="formula-suggestion-tooltip" positionStrategy="fixed" style={{
        zIndex: 9999
      }} data-sentry-element="Tooltip" data-sentry-source-file="formulaCellUtils.tsx" />
        <main className={styles.main}>
          <div className={styles['menu-container']} />
          <div id="editor" ref={editorRef} data-testid="editor" className={styles.editor} />
        </main>
      </div>
    </Popup>;
};

/**
 * Toggles the visibility of the editor and focuses on it.
 * @param setHidden - A function to set the hidden state of the editor.
 * @param editorRef - A reference to the editor element.
 */
const toggleEditorVisibility = (setHidden: any, editorRef: any) => {
  setHidden(false);
  editorRef.current?.focus();
};
export { autocompleteCompart, FormulaCellPopup, formulaCellPopupStyle, generateIconsByTypeForAutocomplete as generateIconsByType, generateNewEditor, generateVariablesFromData, languageCompart, onLoadEditor, toggleEditorVisibility, updateFormulaWrapper };
export const updateFormulasList = (formulas: FormulaExpressionByDate[], newFormula: FormulaExpressionByDate): FormulaExpressionByDate[] => {
  if (!formulas || formulas.length === 0) {
    // return [newFormula]; // this is the correct call adding only 1 formula if there are no expressions defined
    const newFirstFormula = {
      formula: '0',
      start: '',
      end: newFormula.start
    };
    const newLastFormula = {
      formula: '0',
      start: newFormula.end,
      end: ''
    };
    return [newFirstFormula, newFormula, newLastFormula];
  }
  const upperDateBound = createAdjustedUTCDate({
    adjustment: {
      value: 20,
      unit: 'years'
    }
  });
  const lowerDateBound = createAdjustedUTCDate({
    adjustment: {
      value: -3,
      unit: 'years'
    }
  });
  const newFormulaStartDate = parseUTCDateObject(newFormula.start);
  const newFormulaEndDate = parseUTCDateObject(newFormula.end);
  const formulaEditedNow = formulas.find(formula => {
    // getting the formula that is being edited now, that we are editing the cell inside right now.
    const start = formula.start === '' ? lowerDateBound : parseUTCDateObject(formula.start);
    const end = formula.end === '' ? upperDateBound : parseUTCDateObject(formula.end);
    return start <= newFormulaStartDate && newFormulaStartDate < end;
  });
  const curFormulaStart = parseUTCDateObject(formulaEditedNow?.start || lowerDateBound);
  const curFormulaEnd = parseUTCDateObject(formulaEditedNow?.end || upperDateBound);
  if (
  // handle editing the same cell
  formulaEditedNow?.start === newFormula.start && formulaEditedNow?.end === newFormula.end) {
    formulaEditedNow.formula = newFormula.formula;
    return formulas;
  }
  const addFormulaBefore = (formulaEditedNow?.start || formulaEditedNow?.start === '') &&
  // checking if it is the first formula
  curFormulaStart < newFormulaStartDate ? {
    ...formulaEditedNow,
    end: newFormula.start
  } : null;
  const addFormulaAfter = (formulaEditedNow?.end || formulaEditedNow?.end === '') &&
  // checking if it is the last formula
  newFormulaEndDate < curFormulaEnd ? {
    ...formulaEditedNow,
    start: newFormula.end
  } : null;

  /**
   * We need now to return the sorted list as it was before with the changes to the formula. Find the formulaEditedNow index, and add the new formula and before
   * and after formulas in the right place. and remove that formuala
   * we need to remove null object from here, if splitNewFormulaToTwo is null dont add etc.
   */
  const formulaIndex = formulas.findIndex(formula => formula.start === formulaEditedNow?.start);
  const updatedFormulas = [...formulas.slice(0, formulaIndex), ...(addFormulaBefore ? [addFormulaBefore] : []), newFormula, ...(addFormulaAfter ? [addFormulaAfter] : []), ...formulas.slice(formulaIndex + 1)];
  return updatedFormulas;
};

// Redirects the user to the models page when the transaction item is clicked.
export const goToFormulaById = (formulaId: number, router: NextRouter) => {
  const {
    formulaIDToFormulaMap
  } = useZustandStore.getState();
  const currentFormula = formulaIDToFormulaMap[formulaId];
  if (!currentFormula) {
    console.error(`Formula not found while redirecting to formula goToFormulaById:${formulaId}`);
    return;
  }
  const {
    setRowIDToScrollTo
  } = useUtilsStore.getState();
  const formulaParentRowIDs = getFormulaAnscestorsRowIds(formulaIDToFormulaMap, formulaId);
  const modelId = formulaIDToFormulaMap[formulaId]?.model_id;
  if (formulaId && modelId) {
    setRowIDToScrollTo(formulaIDToRowID(currentFormula.id, currentFormula.group), formulaParentRowIDs);
    const {
      company,
      branch,
      model
    } = router.query;
    const url = `/${company}/${branch}/${dynamicRoute.models}/${modelId}`;
    // if url has not changed do not redirect
    if (model !== String(modelId)) {
      router.push(url);
    }
  } else {
    console.error(`Formula not found while redirecting to formula goToFormulaById:${formulaId}`);
  }
};