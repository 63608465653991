import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import ApiUtils from '@/api/ApiUtils';
import { QUERYKEYS } from '@/miscellaneous/constant/reactQueryKeyConfig';
import type { ZustandState } from '@/miscellaneous/store/zustand_store';
import useZustandStore from '@/miscellaneous/store/zustand_store';

export const useEmployeesQuery = () => {
  const { setBranchEmployees, activeBranch, activeCompany } = useZustandStore(
    (state: ZustandState) => state,
  );

  const { data, isLoading, error, refetch, isSuccess } = useQuery({
    queryKey: [QUERYKEYS.ALLEMPLOYEES, activeCompany?.id],
    queryFn: () => ApiUtils.getAllEmployees(activeCompany?.id as number),
  });

  const [filteredEmployees, setFilteredEmployees] = useState([]);
  // Filter employees by branch
  useEffect(() => {
    if (data?.length > 0 && activeBranch && !isLoading) {
      const filtered = data?.filter(
        (employee: any) => employee?.branch_id === activeBranch.id,
      );
      setFilteredEmployees(filtered);
      setBranchEmployees(filtered);
    } else {
      setFilteredEmployees([]);
    }
  }, [data, activeBranch, isLoading]);

  return {
    data,
    employeesByBranch: filteredEmployees,
    isLoading,
    isSuccess,
    error,
    refetch,
  };
};
