/* eslint-disable react/no-unused-prop-types */
import { DragOverlay } from '@dnd-kit/core';
import { GripVertical } from 'lucide-react';
import React from 'react';
import styles from '@/components/table/shared/table/GeneralTable/styles/tableSidebarStyles.module.scss';
import colors from '@/styles/scss/abstracts/_variables.module.scss';
import DnDIdentifierWrapper from './DnDIdentifierWrapper/DnDIdentifierWrapper';
import dndStyles from './styles/DnDStyles.module.scss';
import { useTableContext } from './TableDnDHelpers/TableDNDContext';
import useDragAndDrop from './TableDnDHelpers/useDragAndDrop';
interface TableRowProps {
  rowStyle: React.CSSProperties; // Assuming rowStyle is suitable for React's CSSProperties type
  row: any; // Define a more specific type if possible
  isTableDisabled?: boolean; // Assuming a boolean value
  isEmptyCellsRow?: boolean; // Assuming a boolean value
  renderCellContent: (row: any, cell: any) => React.ReactNode; // Function type definition
  enableDnd: boolean;
}
interface ParentRowProps {
  children?: React.ReactNode;
  row: any;
  rowStyle?: React.CSSProperties;
  enableDnd?: boolean;
  isOverEnabled?: boolean;
}
const gapRowStyle = {
  height: '24px',
  maxHeight: '24px',
  minHeight: '24px'
};

/**
 *  this renders a table row with drag and drop functionality
 * @param children the cells
 * @param row the row data
 * @param rowStyle additional rowstyles to add to the row
 * @returns
 */
export const DndParentRow = ({
  children,
  row,
  rowStyle,
  isOverEnabled = true
}: ParentRowProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    style,
    isDragging
  } = useDragAndDrop({
    id: row.id,
    row,
    isOverEnabled
  });
  const combinedStyle = {
    ...style,
    ...rowStyle,
    ...(isDragging ? {
      height: 'auto',
      color: colors.midBlue,
      backgroundColors: colors.white,
      opacity: 0.8
    } : {})
  };
  return <tr {...attributes} {...listeners} className={isDragging ? styles.draggingRow : ''} style={{
    position: 'relative',
    ...combinedStyle
  }} ref={setNodeRef} data-row-id={row.id} data-sentry-component="DndParentRow" data-sentry-source-file="TableRowsComponents.tsx">
      {children}
    </tr>;
};
export const DnDItemWithGrabber = ({
  children,
  row,
  isOverEnabled = true
}: ParentRowProps) => {
  const {
    attributes,
    listeners,
    setNodeRef
  } = useDragAndDrop({
    id: row.id,
    row,
    isOverEnabled
  });
  return <div {...attributes} {...listeners} style={{
    position: 'relative'
  }} className={dndStyles.dndItemWithGrabber} ref={setNodeRef} data-sentry-component="DnDItemWithGrabber" data-sentry-source-file="TableRowsComponents.tsx">
      <GripVertical size={18} data-sentry-element="GripVertical" data-sentry-source-file="TableRowsComponents.tsx" />
      {children}
    </div>;
};

/**
 * Renders a draggable overlay item for a table row when a row is being dragged.
 * The overlay item displays the sidebar content of the dragged row.
 * This component is used in conjunction with the `useDragAndDrop` hook to provide
 * a visual representation of the dragged item.
 */
export const DnDOverlay = () => {
  const {
    draggedItem
  } = useTableContext();
  if (draggedItem && draggedItem?.original?.baseRowItemParent?.id !== 0) return null;
  return <DragOverlay data-sentry-element="DragOverlay" data-sentry-component="DnDOverlay" data-sentry-source-file="TableRowsComponents.tsx">
      <div style={{
      position: 'relative'
    }} className={dndStyles.dndItemWithGrabber}>
        <GripVertical size={18} color={colors.midBlue} data-sentry-element="GripVertical" data-sentry-source-file="TableRowsComponents.tsx" />
        {draggedItem?.original?.sidebar}
      </div>
    </DragOverlay>;
};

// Assuming rowStyle is suitable for React's CSSProperties type
const SimpleParentRow = ({
  children,
  rowStyle,
  row
}: ParentRowProps) => {
  return <tr data-row-id={row.id} style={{
    ...rowStyle,
    position: 'relative'
  }} data-sentry-component="SimpleParentRow" data-sentry-source-file="TableRowsComponents.tsx">
      <td className={`${styles.stickyColumn} ${styles.emptyRowSidebarCellTr}`} aria-label="empty-row-sidebar-shadow">
        <span />
      </td>
      {children}
    </tr>;
};

/** conditionally render DND parent if dnd otherwise renders just the simple parent */
const ParentRow = (props: ParentRowProps) => {
  return props?.enableDnd ? <DndParentRow {...props} data-sentry-element="DndParentRow" data-sentry-component="ParentRow" data-sentry-source-file="TableRowsComponents.tsx" /> : <SimpleParentRow {...props} data-sentry-element="SimpleParentRow" data-sentry-component="ParentRow" data-sentry-source-file="TableRowsComponents.tsx" />;
};

/**
 *  Renders the table rows, from header to body.
 * @param rowStyle additional rowstyles to add to the row
 * @param row the row data
 * @param isTableDisabled is the table disabled form AI view or other reasons
 * @param isEmptyCellsRow is the row empty, not to render cells
 * @param renderCellContent the function to render the cell content
 * @returns rendered table row, tr wrapping td's
 */
export const TableRow: React.FC<TableRowProps> = ({
  rowStyle,
  row,
  isTableDisabled,
  isEmptyCellsRow,
  renderCellContent,
  enableDnd = false
}) => {
  const renderTRContent = () => {
    return <>
        {row.getAllCells().map((cell: any, i: number) => {
        return <td data-key="hello" key={`row-${row.id}-col-${cell.id}`} className={`
            ${i === 0 ? `${styles.stickyColumn} ${isTableDisabled ? styles.disabledTable : ''}` : styles.bodyCell}

            ${row.depth === 0 && isEmptyCellsRow ? styles.empty : ''}
          `} aria-label="cell">
              {i === 0 && enableDnd ? <DnDIdentifierWrapper row={row} key={`dnd-wrapper-${row.id}`}>
                  {renderCellContent(row, cell)}
                </DnDIdentifierWrapper> : renderCellContent(row, cell)}
            </td>;
      })}
      </>;
  };
  if (row.original.type === 'gap') return (
    // if row type is gap return simple row without children
    <SimpleParentRow row={row} rowStyle={{
      ...rowStyle,
      ...gapRowStyle
    }} />
  );
  return <ParentRow row={row} rowStyle={rowStyle} enableDnd={enableDnd} data-sentry-element="ParentRow" data-sentry-component="TableRow" data-sentry-source-file="TableRowsComponents.tsx">
      {renderTRContent()}
    </ParentRow>;
};
export type StylesVariables = {
  sidebarCellWidth: string;
  sidebarCellAdditionalIdent: string;
};

/**
 * Calculates the width of a table cell based on the depth of the row and the provided variables.
 *
 * @param depth - The depth of the row, used to adjust the width.
 * @param variables - An object containing variables used to calculate the width, such as the base cell width and additional indentation.
 * @returns A CSS width value that adjusts the cell width based on the row depth.
 */
export const calculateWidth = (depth: number, variables: StylesVariables, additionalWidth: number) => {
  const baseWidth = parseInt((variables.sidebarCellWidth as string), 10);
  const depthAdjustment = depth * +(variables.sidebarCellAdditionalIdent as string).replace('px', ''); // decrease width by 15px for each level of depth
  return `calc(${baseWidth}px - ${additionalWidth + depthAdjustment}px)`;
};