export const iconSizes = {
  xs: 10,
  sm: 12,
  md: 15,
  lg: 18,
  xl: 20,
};

export const staticStyles = {
  l_253: 'left-[-253px]',
  l_537: 'left-[537px]',
  l_22: 'left-[-22px]',
  l_17: 'left-[17.2rem]',
  l_11: 'left-[11px]',
  l_80: 'left-[-80px]',
  l_190: 'left-[-190px]',

  m_w_96: 'min-w-[96px]',
  m_h_100: 'min-h-[100px]',
  m_h_300: 'min-h-[300px]',
  w_40vw: 'w-[40vw]',
  w_80vw: 'w-[80vw]',
  w_482: 'w-[482px]',
  w_600: 'w-[600px]',
  w_521: 'w-[521px]',
  t_10: 'top-[10px]',
  t_27: 'top-[-27px]',
  h_800: 'h-[800px]',
  h_500: 'h-[500px]',
  h_80: 'h-[80px]',
  r_2: 'rounded-[2px]',
  t_40: 'top-[40px]',
  h_25: 'h-[25px]',
  max_w_1440: 'max-w-[1440px]',
  min_h_80vh: 'min-h-[80vh]',
  t_85: 'text-[85px]',
  t_30: 'text-[30px]',
  leading_140: 'leading-[140px]',
  72: '72px',
  32: '32px',
  300: '300px',
  250: '250px',
  350: '350px',

  minConstraints: [200, 200],
  maxConstraints: [600, 600],
};
