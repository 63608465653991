/**
 * Flattens a nested array into a single array.
 *
 * @param array - The input array to flatten.
 * @returns The flattened array.
 */
const flattenArray = (array: any): any => {
  if (!Array.isArray(array)) return array;
  const flattenedArray = [];
  for (const item of array) {
    flattenedArray.push(item);
    if (item.subRows) {
      flattenedArray.push(...flattenArray(item.subRows));
    }
  }
  return flattenedArray;
};
export default flattenArray;