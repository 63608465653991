/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/no-array-index-key */
// eslint-disable-next-line simple-import-sort/imports
import { BarChartBig, CircleCheck, Ellipsis, Landmark, LayoutPanelLeft, ListEnd, PieChart, UsersRound } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiPlus } from 'react-icons/fi';
import { HiMagnifyingGlass } from 'react-icons/hi2';
import { MdKeyboardCommandKey } from 'react-icons/md';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import colors from '@/styles/scss/abstracts/_variables.module.scss';
import { getTimeAgoFromUTCDate, parseUTCDateObject } from '@/utils/dateUtils';
import HelpButton from '@/components/common/HelpButton/HelpButton';
import dynamicRoute from '@/miscellaneous/constant';
import type { UtilsStoreState } from '@/miscellaneous/store/utilsStore/utilsStore';
import useUtilsStore from '@/miscellaneous/store/utilsStore/utilsStore';
import type { ZustandState } from '@/miscellaneous/store/zustand_store';
import useZustandStore from '@/miscellaneous/store/zustand_store';
import type { ApiDeckConnector } from '@/utils/hooks/useGetConnectors/useGetConnectors';
import useGetConnectors from '@/utils/hooks/useGetConnectors/useGetConnectors';
import type { Integration } from '@/utils/hooks/useIntegrations/useIntegrations';
import useIntegrations from '@/utils/hooks/useIntegrations/useIntegrations';
import _ from 'lodash';
import { useRouter } from 'next/router';
import { Oval } from 'react-loader-spinner';
import { useShallow } from 'zustand/react/shallow';
import styles from './integrations.module.scss';
// eslint-disable-next-line import/no-cycle
import AddIntegrationsPopup from './IntegrationsPoup/AddIntegrationsPopup';
interface TabContentProps {
  title: string;
  color: string;
  icon: JSX.Element;
  count: number;
}

// TabContent is a component that displays the content of a tab
const TabContent: React.FC<TabContentProps> = ({
  title,
  color,
  icon,
  count
}) => <div className={styles.tabContent} data-sentry-component="TabContent" data-sentry-source-file="Integrations.tsx">
    <div style={{
    backgroundColor: color
  }}>{icon}</div>
    <h1>{title}</h1>
    <span>{count}</span>
  </div>;
interface EmptyIntegrationProps {
  title: string;
  setIsIntegrationPopUpOpen?: (value: boolean) => void;
}

// EmptyIntegration is a component that displays an empty state for integrations
const EmptyIntegration: React.FC<EmptyIntegrationProps> = ({
  title,
  setIsIntegrationPopUpOpen
}) => {
  const {
    t: translate
  } = useTranslation(['integrations']);
  return <div className={styles.emptyIntegration} data-sentry-component="EmptyIntegration" data-sentry-source-file="Integrations.tsx">
      <h1>
        {title} {translate('integrations')}
      </h1>
      <p>{translate('no_integrations_connected')}</p>
      <button onClick={() => {
      // setIsIntegrationPopUpOpen is a function that sets the isIntegrationPopUpOpen state to true
      if (setIsIntegrationPopUpOpen) setIsIntegrationPopUpOpen(true);
    }} type="button" className={styles.addIntegrationButton}>
        <FiPlus className="me-2" size={16} data-sentry-element="FiPlus" data-sentry-source-file="Integrations.tsx" />
        {translate('new_integration')}
      </button>
    </div>;
};
interface IntegrationBoxProps {
  item: any;
  isIntegrationPopUpOpen?: boolean;
  onConnectIntegration?: (item: any) => void;
  isConnected?: boolean;
  disableSettingsIcon?: boolean;
}

// findRelevantIcon is a function that takes an item as input and returns the icon for the item
export const findRelevantIcon = (item: Integration, connectorsData: ApiDeckConnector[]) => {
  // connectorsData is an array of ApiDeckConnector objects
  if (connectorsData && connectorsData.length > 0) {
    // find the icon for the item
    const icon = connectorsData.find((dataItem: ApiDeckConnector) => dataItem.service_id === item.service_id);
    return icon?.icon;
  }
  return null;
};
export const IntegrationConnectButton = ({
  isShow,
  item,
  onAction,
  isConnected = false
}: {
  isShow: boolean;
  item: ApiDeckConnector;
  onAction: (item: ApiDeckConnector) => void;
  isConnected?: boolean;
}) => {
  const {
    t: translate
  } = useTranslation(['integrations']);
  return isShow ? <div className="flex w-full items-center justify-center pt-5" data-sentry-component="IntegrationConnectButton" data-sentry-source-file="Integrations.tsx">
      <button onClick={() => {
      if (onAction) onAction(item);
    }} type="button" className={`${styles.integrationConnectBtn} ${isConnected ? styles.connected : ''}`}>
        {isConnected && <CircleCheck size={16} />}
        {isConnected ? translate('integrations:connected') : translate('connectIntegration')}
      </button>
    </div> : <span data-sentry-component="IntegrationConnectButton" data-sentry-source-file="Integrations.tsx" />;
};

// IntegrationBox is a component that displays an integration box
export const IntegrationBox: React.FC<IntegrationBoxProps> = ({
  item,
  isIntegrationPopUpOpen,
  onConnectIntegration,
  isConnected = false,
  disableSettingsIcon
}) => {
  const router = useRouter();
  const {
    t: translate
  } = useTranslation(['integrations']);
  const {
    connectorsData
  } = useGetConnectors();
  const {
    activeCompany,
    activeBranch
  } = useZustandStore((state: ZustandState) => state);
  return <div onClick={() => {
    if (!isIntegrationPopUpOpen) {
      router.push(`/${activeCompany?.slug}/${activeBranch?.slug}/${dynamicRoute.integrations}/${item.id}`);
    }
  }} className={styles.integrationBox} data-sentry-component="IntegrationBox" data-sentry-source-file="Integrations.tsx">
      <div className={styles.integrationBoxHeader}>
        <div className={styles.integrationBoxIcon}>
          {findRelevantIcon(item, (connectorsData as ApiDeckConnector[])) ? <img src={(findRelevantIcon(item, (connectorsData as ApiDeckConnector[])) as string)} alt="icon" width="50" height="50" /> : <MdKeyboardCommandKey size={50} />}
        </div>
        <div className={styles.integrationBoxTitle}>
          <h1>{_.capitalize(item.service_id)}</h1>
          <p>
            {/* 
              If isIntegrationPopUpOpen is true, display the unified_api and integration name.
              Otherwise, display the latest_fetch_date and a message indicating that no fetch data is available.
             */}

            {isIntegrationPopUpOpen && `${item.unified_api} ${translate('integration')}`}
            {!isIntegrationPopUpOpen && (item?.latest_fetch_date ? getTimeAgoFromUTCDate(parseUTCDateObject(item.latest_fetch_date)) : <span className="flex items-center gap-2">
                  <Oval visible height="16" width="16" strokeWidth={4} color={colors.slateGray} secondaryColor={colors.midBlue} ariaLabel="oval-loading" />
                  {translate('fetching')}
                </span>)}
          </p>
        </div>
        {!disableSettingsIcon && <Ellipsis className={`${styles.integrationSettings} rotate-90`} />}
      </div>
      <IntegrationConnectButton isShow={(isIntegrationPopUpOpen as boolean)} item={item} onAction={(onConnectIntegration as (item: ApiDeckConnector) => void)} isConnected={isConnected} data-sentry-element="IntegrationConnectButton" data-sentry-source-file="Integrations.tsx" />
    </div>;
};
interface TabPanelContentProps {
  data: any;
  title: string;
  isIntegrationPopUpOpen: boolean;
  onConnectIntegration?: (item: any) => void;
  setIsIntegrationPopUpOpen?: (value: boolean) => void;
}

// TabPanelContent is a component that displays the content of a tab panel
const TabPanelContent: React.FC<TabPanelContentProps> = ({
  data,
  title,
  isIntegrationPopUpOpen,
  onConnectIntegration,
  setIsIntegrationPopUpOpen
}) => {
  const {
    t: translate
  } = useTranslation(['integrations']);
  return <div className={styles.tabPanelContent} data-sentry-component="TabPanelContent" data-sentry-source-file="Integrations.tsx">
      {data.length === 0 && !isIntegrationPopUpOpen ? <EmptyIntegration title={title} setIsIntegrationPopUpOpen={setIsIntegrationPopUpOpen} /> : <>
          <div className={styles.tabPanelContentHeader}>
            <h1>
              {title} {translate('integrations')}
            </h1>
          </div>
          <div className={styles.tabPanelContentBody}>
            {data.map((item: any) => <IntegrationBox key={item.id} item={item} isIntegrationPopUpOpen={isIntegrationPopUpOpen} onConnectIntegration={onConnectIntegration} />)}
          </div>
        </>}
    </div>;
};
interface IntegrationPageBodyProps {
  isIntegrationPopUpOpen: boolean;
  apiDeckData?: any;
  onConnectIntegration?: (item: any) => void;
  setIsIntegrationPopUpOpen?: (value: boolean) => void;
}

// IntegrationPageBody is a component that displays the body of the integration page
export const IntegrationPageBody: React.FC<IntegrationPageBodyProps> = ({
  isIntegrationPopUpOpen,
  apiDeckData,
  onConnectIntegration,
  setIsIntegrationPopUpOpen
}) => {
  const {
    t: translate
  } = useTranslation(['integrations']);
  const {
    activeCompany
  } = useZustandStore(useShallow((state: ZustandState) => {
    return {
      activeCompany: state.activeCompany
    };
  }));
  const {
    setAppLoader
  } = useUtilsStore(useShallow((state: UtilsStoreState) => ({
    setAppLoader: state.setAppLoader
  })));
  const {
    data,
    isLoading
  } = useIntegrations();

  // filterDataByType is a function that filters the data by type
  const filterDataByType = (type: string) => {
    return apiDeckData ? apiDeckData?.filter((item: ApiDeckConnector) => item?.unified_api?.toLowerCase() === type.toLowerCase()) || [] : data?.filter((item: Integration) => item?.type?.toLowerCase() === type.toLowerCase()) || [];
  };

  // setAppLoader to true when isLoading is true, else setAppLoader to false
  useEffect(() => {
    if (isLoading) setAppLoader(true);else setAppLoader(false);
  }, [isLoading]);

  // tabList is an array of objects representing the tabs in the integration page
  const tabList = [{
    title: translate('all'),
    color: colors.brightBlueSky,
    icon: <LayoutPanelLeft size={18} />,
    type: null
  }, {
    title: translate('accounting'),
    color: colors.brightBlue,
    icon: <PieChart size={18} />,
    type: 'accounting'
  }, {
    title: translate('banks'),
    color: colors.vividOrange2,
    icon: <Landmark size={18} />,
    type: 'banks'
  }, {
    title: translate('payroll'),
    color: colors.lightPink1,
    icon: <UsersRound size={18} />,
    type: 'payroll'
  }, {
    title: translate('hris'),
    color: colors.lightYellow5,
    icon: <BarChartBig size={18} />,
    type: 'hris'
  }, {
    title: translate('crm'),
    color: colors.strongMintGreen,
    icon: <ListEnd size={18} />,
    type: 'crm'
  }].map(tab => ({
    ...tab,
    count: tab.type ? filterDataByType(tab.type).length : apiDeckData?.length || data?.length || 0
  }));
  return <div className={styles.integrationCard} data-sentry-component="IntegrationPageBody" data-sentry-source-file="Integrations.tsx">
      {!isIntegrationPopUpOpen && <div className={styles.integrationCardHeader}>
          <div className="flex items-center">
            <h1>{activeCompany?.name}</h1>
          </div>
        </div>}
      <div className={styles.integrationCardBody}>
        <Tabs data-sentry-element="Tabs" data-sentry-source-file="Integrations.tsx">
          <TabList data-sentry-element="TabList" data-sentry-source-file="Integrations.tsx">
            {tabList.map((tab, index) => <Tab key={index}>
                <TabContent title={tab.title} color={(tab.color as string)} icon={tab.icon} count={tab.count} />
              </Tab>)}
          </TabList>
          {tabList.map((tab, index) => <TabPanel key={index}>
              <TabPanelContent data={tab.type ? filterDataByType(tab.type) : apiDeckData || data || []} title={tab.title} isIntegrationPopUpOpen={isIntegrationPopUpOpen} onConnectIntegration={onConnectIntegration} setIsIntegrationPopUpOpen={setIsIntegrationPopUpOpen} />
            </TabPanel>)}
        </Tabs>
      </div>
    </div>;
};

// Integrations is a component that displays the integrations page
const Integrations: React.FC = () => {
  const {
    t: translate
  } = useTranslation(['integrations']);
  const [isIntegrationPopUpOpen, setIsIntegrationPopUpOpen] = useState(false);
  return <div className={styles.integrations} data-sentry-component="Integrations" data-sentry-source-file="Integrations.tsx">
      <div className={styles.integrationsHeader}>
        <div className="flex items-center gap-2">
          <h1>{translate('integrations')}</h1>
          <HelpButton widgets={['elfsight-app-84b7f6fe-32db-4e9b-b139-5d7bc078c05a']} data-sentry-element="HelpButton" data-sentry-source-file="Integrations.tsx" />
        </div>

        <div className="flex items-center">
          <HiMagnifyingGlass className={`${styles.searchIcon} hidden`} size={24} data-sentry-element="HiMagnifyingGlass" data-sentry-source-file="Integrations.tsx" />
          <button onClick={() => setIsIntegrationPopUpOpen(true)} type="button" className={styles.addIntegrationButton}>
            <FiPlus className="me-2" size={16} data-sentry-element="FiPlus" data-sentry-source-file="Integrations.tsx" />
            {translate('new_integration')}
          </button>
        </div>
      </div>

      <div className={styles.integrationsContent}>
        <IntegrationPageBody isIntegrationPopUpOpen={isIntegrationPopUpOpen} setIsIntegrationPopUpOpen={setIsIntegrationPopUpOpen} data-sentry-element="IntegrationPageBody" data-sentry-source-file="Integrations.tsx" />
      </div>
      <AddIntegrationsPopup isIntegrationPopUpOpen={isIntegrationPopUpOpen} setIsIntegrationPopUpOpen={setIsIntegrationPopUpOpen} data-sentry-element="AddIntegrationsPopup" data-sentry-source-file="Integrations.tsx" />
    </div>;
};
export default Integrations;