// eslint-disable-next-line import/no-extraneous-dependencies
import { create } from 'zustand';

export type PdfState = {
  tableRef: any;
  dashboardRef: any;
  setTableRef: (ref: any) => void;
  setDashboardRef: (ref: any) => void;
};

const usePdfStore = create<PdfState>((set) => ({
  tableRef: null,
  dashboardRef: null,

  setTableRef: (ref: any) => set({ tableRef: ref }),
  setDashboardRef: (ref: any) => set({ dashboardRef: ref }),
}));

export default usePdfStore;
