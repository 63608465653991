import { useQuery } from '@tanstack/react-query';
import { useShallow } from 'zustand/react/shallow';
import ApiUtils from '@/api/ApiUtils';
import { QUERYKEYS } from '@/miscellaneous/constant/reactQueryKeyConfig';
import type { ZustandState } from '@/miscellaneous/store/zustand_store';
import useZustandStore from '@/miscellaneous/store/zustand_store';
const useActiveTemplatesQuery = () => {
  const {
    activeCompany,
    activeBranchId
  } = useZustandStore(useShallow((state: ZustandState) => ({
    activeCompany: state.activeCompany,
    activeBranchId: state.activeBranch?.id
  })));
  const {
    data,
    isLoading,
    error,
    refetch
  } = useQuery({
    queryKey: [QUERYKEYS.ACTIVETEMPLATES, activeCompany.id, activeBranchId],
    queryFn: () => ApiUtils.getAllActiveTemplates(activeBranchId),
    enabled: !!activeBranchId
  });
  return {
    activeTemplates: data,
    isLoading,
    error,
    refetch
  };
};
const useAllTemplatesQuery = () => {
  const {
    activeCompany
  } = useZustandStore((state: ZustandState) => state);
  const {
    data,
    isLoading,
    error,
    refetch
  } = useQuery({
    queryKey: [QUERYKEYS.ALLTEMPLATES, activeCompany.id],
    queryFn: () => ApiUtils.getAllTemplates()
  });
  return {
    allTemplates: data,
    isLoading,
    error,
    refetch
  };
};
export { useActiveTemplatesQuery, useAllTemplatesQuery };