import { useRef } from 'react';
import { useFocusRing, useSwitch, VisuallyHidden } from 'react-aria';
import { useToggleState } from 'react-stately';
import { SwitchSvg } from '@/miscellaneous/Images/NewChartSvg';

/**
 * A custom Switch component.
 *
 * @param {object} props - Component props.
 * @param {boolean} props.isDisabled - Indicates whether the switch is disabled.
 * @returns {JSX.Element} - The rendered JSX element.
 */
type Props = {
  id?: string;
  defaultSelected?: boolean;
  isDisabled?: boolean;
  bgColor?: string;
  children?: React.ReactNode;
  onSelectionChange?: (isSelected: boolean) => void;
};
function Switch(props: Props) {
  const state: any = useToggleState(props);
  const ref = useRef(null);
  const {
    inputProps
  } = useSwitch({
    ...props,
    'aria-label': 'Switch'
  }, state, ref);
  const {
    isFocusVisible,
    focusProps
  } = useFocusRing();
  const handleChange = (isChecked: boolean) => {
    if (props.onSelectionChange) {
      // update the given selection function
      props.onSelectionChange(isChecked);
    }
    state.setSelected(isChecked); // update the toggle state
  };
  return <label htmlFor={props.id} className={`flex items-center ${props.isDisabled ? 'opacity-40' : 'opacity-100'} cursor-pointer`} aria-label="switch" data-sentry-component="Switch" data-sentry-source-file="Switch.tsx">
      <VisuallyHidden data-sentry-element="VisuallyHidden" data-sentry-source-file="Switch.tsx">
        <input {...inputProps} {...focusProps} ref={ref} onChange={e => handleChange(e.target.checked)} aria-label="Switch" />
      </VisuallyHidden>
      <SwitchSvg isSelected={state.isSelected} bgColor={props?.bgColor} isFocusVisible={isFocusVisible} data-sentry-element="SwitchSvg" data-sentry-source-file="Switch.tsx" />
      {props.children}
    </label>;
}
export default Switch;