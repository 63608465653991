/* eslint-disable import/no-cycle */
import * as formulajs from '@formulajs/formulajs';
import { isNumber } from 'lodash';
import CalculationsStore from '@/miscellaneous/store/CalculationsStore';
import { generateUniqueMonthlyDatesByModels } from '@/utils/dateUtils';
import { SystemFormulaIdsRange } from '@/utils/hooks/formulas/SystemFormulas';
import { type Formula, FormulaInputType, type ISymbol } from '@/utils/types/formulaTypes';
import { createDependentMap } from './modelUtils/calculationUtils';
import type { FormulaEntries } from './modelUtils/modelUtils';
import { correctArithmeticExpression, failValue, getFormulaExpressionForDate, getFormulasParsed, getFormulaValueFromSymbol, handleEdgeCases } from './modelUtils/modelUtils';
import { topologicalSort } from './modelUtils/topologicalSort';
export const enableLogging = false; // to enable logging for the calculations errors

export const customFunctions: Record<string, Function> = {
  FLOOR: formulajs.FLOOR,
  CEILING: Math.ceil,
  AND: formulajs.AND,
  IF: formulajs.IF,
  NOT: formulajs.NOT,
  OR: formulajs.OR,
  ABS: formulajs.ABS,
  POWER: formulajs.POWER,
  ROUND: formulajs.ROUND,
  SUM: formulajs.SUM,
  AVERAGE: formulajs.AVERAGE,
  MAX: formulajs.MAX,
  MIN: formulajs.MIN,
  DATE: (m: number, y: number) => m + y * 100 // this funcition gives bigger number for year, so it will be sorted correctly
};
const enableTimeCalLogging = false; // to enable time logging for the calculations

const functions = customFunctions; // this is used by eval to evaluate the formula
if (!functions) {
  if (enableLogging) console.error('functions not defined');
}
export const calculateFormulaAtDate = (symbols: ISymbol[], date: string, allFormulaEntries: FormulaEntries, currentFormula: Formula) => {
  let expression = '';
  let curValue;
  let failedCalculation = false;
  if (symbols.length === 0) {
    return 0;
  }
  symbols.forEach(symbol => {
    if (symbol.symbolType === 'Formula') {
      curValue = getFormulaValueFromSymbol(symbol, date, currentFormula, allFormulaEntries); // date is modified internally in this function

      if (curValue === undefined || !isNumber(curValue) || Number.isNaN(curValue)) {
        if (enableLogging) {
          console.error(`Error evaluating Formula ${currentFormula.name} id:${currentFormula.id}  date ${date} expression:${expression} symbol ${symbol.symbolName}`, symbols);
        }
        // exit function calculateFormulaAtDateNew and return NaN
        failedCalculation = true;
        // return NaN;
        return failValue;
      }
      expression += String(curValue);
    } else if (symbol.symbolType === 'Function') {
      // get the relevant function from customFunctions
      expression += `functions['${symbol.symbolName}']`;
    } else {
      // If it's purely numeric, parse and re-stringify to handle 01 to 1 conversion
      expression += /^\d+$/.test(symbol.symbolName) ? String(parseInt(symbol.symbolName, 10)) : symbol.symbolName;
    }
  });
  if (failedCalculation) {
    return failValue;
  }
  // add a try catch block to handle invalid expressions
  try {
    const processedExpression = correctArithmeticExpression(expression);
    // eslint-disable-next-line no-eval
    const evalValue = eval(processedExpression);
    const processsedEvalValue = handleEdgeCases(evalValue);

    // check f evalValue is infinity or -infinity and return failValue
    if (!Number.isFinite(processsedEvalValue)) {
      if (enableLogging) console.error(`Error evaluating Formula ${currentFormula.name} id:${currentFormula.id}  date ${date} expression:${expression}`, symbols);
      return failValue;
    }
    // return only 2 decimal places
    return +processsedEvalValue.toFixed(3) || 0;
  } catch (e) {
    if (enableLogging) console.error(`Error evaluating Formula ${currentFormula.name} id:${currentFormula.id}  date ${date} expression:${expression}`, symbols);
    return failValue;
  }
};

// This function generates the timeline for a formula given a start and end date
export const calculateAllFormulasAtDate = (currentDate: string, formulas: Formula[], allFormulasEntries: FormulaEntries, cycleFormulas: Formula[], isFormulaInRange: (f: Formula, date: string) => boolean) => {
  formulas.forEach((f: Formula) => {
    if (!isFormulaInRange(f, currentDate)) {
      return; // checking that the formula is in its model range
    }
    const formulasByDate = getFormulasParsed(f.expression_string);
    const formulaStr = getFormulaExpressionForDate(formulasByDate, currentDate);
    const symbols = f?.symbolMap ? f?.symbolMap[formulaStr || ''] : [];
    const curValue = calculateFormulaAtDate(symbols || [], currentDate, allFormulasEntries, f);
    const curFormulaEntries = allFormulasEntries[f.id];
    if (!curFormulaEntries) {
      // eslint-disable-next-line no-param-reassign
      allFormulasEntries[f.id] = {
        [currentDate]: curValue,
        to_account: f.output_id ?? 0
      };
    } else {
      curFormulaEntries[currentDate] = curValue;
    }
  });
  cycleFormulas.forEach((f: Formula) => {
    // handel cyclic formula rows

    const curFormulaEntries = allFormulasEntries[f.id];
    if (!curFormulaEntries) {
      // eslint-disable-next-line no-param-reassign
      allFormulasEntries[f.id] = {
        [currentDate]: failValue,
        to_account: f.output_id ?? 0
      };
    } else {
      curFormulaEntries[currentDate] = failValue;
    }
  });
};

// Helper function to sort formulas
export function sortFormulas(formulas: Formula[]): {
  sortedFormulasAll: Formula[];
  cycleFormulas: Formula[];
  dependencyMap: Record<number, number[]>;
  formulaParentMap: Record<number, number[]>;
} {
  const formulaParentMap: Record<number, number[]> = {};

  // iterrate over all formulas and make sure each group formula is dependant on all the formulas that set parent_id to be her. Meaning
  // for each formula where input_type = group, add to dependency map the formulas that set parent_id to be her.
  formulas.forEach(f => {
    if (f.input_type === FormulaInputType.Group) {
      const groupFormulas = formulas.filter(f2 => f2.parent_id === f.id);
      formulaParentMap[f.id] = groupFormulas.map(f2 => f2.id);
    }
  });
  // iterate over all formulas
  const dependencyMap: Record<number, number[]> = createDependentMap(formulas, {
    ...formulaParentMap,
    ...{
      0: []
    }
  });
  const [sortedFormulas, cycleFormulas] = topologicalSort(formulas, dependencyMap);
  const sortedFormulasAll = (sortedFormulas.flat() as Formula[]);
  const resCycleFormulas = (cycleFormulas as Formula[]);
  return {
    sortedFormulasAll,
    cycleFormulas: resCycleFormulas,
    dependencyMap,
    formulaParentMap
  };
}
export type IDToDatesMap = {
  [id: number]: {
    startDate: Date;
    endDate: Date;
    startDateString: string;
    endDateString: string;
  };
};
// write a function that uses calculateAllFormulasAtDate to calculate all formulas for all dates, date by date.
export const calculateAllFormulas = (monthlyDates: string[], formulas: Formula[], modelIDToDateMap: IDToDatesMap): void => {
  if (enableTimeCalLogging) console.time('calculateAllFormulas');
  if (!formulas) {
    if (enableTimeCalLogging) console.timeEnd('calculateAllFormulas');
    return;
  }
  const formulaEntries: FormulaEntries = {};
  const {
    setFormulaEntries,
    setFormulaDependencyMap,
    setFormulaParentMap
  } = CalculationsStore.getState();
  let datesList = generateUniqueMonthlyDatesByModels(modelIDToDateMap);
  if (!datesList) datesList = monthlyDates; // TODO remove this
  // Initialize wasCalculated for each formula

  const {
    sortedFormulasAll,
    cycleFormulas,
    dependencyMap,
    formulaParentMap
  } = sortFormulas(formulas);
  setFormulaDependencyMap(dependencyMap);
  setFormulaParentMap(formulaParentMap);
  const isFormulaInRange = (f: Formula, date: string) => {
    const modelDates = modelIDToDateMap[f.model_id];
    if (
    // check is valid formula range
    f.id < SystemFormulaIdsRange || !modelDates || !modelDates.startDateString || !modelDates.endDateString) {
      return true;
    }
    return (
      // check is in date range at all
      modelDates && date >= modelDates.startDateString && date <= modelDates.endDateString || false
    );
  };
  datesList.forEach(date => {
    calculateAllFormulasAtDate(date, sortedFormulasAll, formulaEntries, (cycleFormulas as Formula[]), isFormulaInRange);
  });
  setFormulaEntries(formulaEntries);
  if (enableTimeCalLogging) console.timeEnd('calculateAllFormulas');
};