import { Dot } from 'lucide-react';
import React from 'react';
import { useTableContext } from '../TableDnDHelpers/TableDNDContext';
import styles from './DnDIdentifierWrapper.module.scss';

/**
 * A wrapper component that renders a drop indicator when a row is being dragged over.
 *
 * @param {Object} props - The component props.
 * @param {any} props.row - The row data.
 * @param {React.ReactNode} props.children - The child components to render.
 * @returns {React.ReactElement} The rendered component.
 */
const DnDIdentifierWrapper = ({
  row,
  children
}: {
  row: any;
  children: React.ReactNode;
}) => {
  const {
    overRowState,
    position,
    draggedItem
  } = useTableContext();
  const isDrop = overRowState?.id === row.id && overRowState?.id !== draggedItem?.id;
  return <div className={`${isDrop && position === 'middle' ? styles.dropMiddle : ''}`} data-sentry-component="DnDIdentifierWrapper" data-sentry-source-file="DnDIdentifierWrapper.tsx">
      {isDrop && position === 'top' && <div className={`${styles.topdropline}`}>
          <Dot size={70} />
          <div className={`${styles.dropLine}`} />
          <Dot size={70} />
        </div>}

      {isDrop && position === 'bottom' && <div className={`${styles.bottomDropLine}`}>
          <Dot size={70} />
          <div className={`${styles.dropLine}`} />
          <Dot size={70} />
        </div>}
      {children}
    </div>;
};
export default DnDIdentifierWrapper;