import 'react-calendar/dist/Calendar.css';
import React from 'react';
import Calendar from 'react-calendar';
import arrow from '@/public/assets/icons/Alt Arrow Right.svg';
import { formatDateToMonthAbbreviation } from '@/utils/dateUtils';
interface MonthYearPickerProps {
  value: Date | undefined;
  onChange: any;
  maxDate?: Date;
  minDate?: Date;
  showNavigation?: boolean;
  className?: string;
  tileClassName?: any;
  activeStartDate?: Date | undefined;
}
/**
 * A custom calendar component for selecting a month and year.
 *
 *
 * @param {Object} props - The component properties.
 * @param {Date} props.value - The selected date value.
 * @param {function} props.onChange - The callback function triggered on date change.
 * @returns {JSX.Element} The rendered MonthYearPicker component.
 */

const MonthYearPicker = ({
  value,
  onChange,
  maxDate,
  minDate,
  showNavigation = true,
  className,
  tileClassName,
  activeStartDate
}: MonthYearPickerProps) => {
  return <Calendar onChange={onChange} value={value} activeStartDate={activeStartDate} maxDate={maxDate} minDate={minDate} className={className || ''} view="year" maxDetail="year" minDetail="year" prev2Label={null} next2Label={null} navigationLabel={undefined} tileClassName={tileClassName || 'bg-whiite'} formatMonth={(_locale, date) => formatDateToMonthAbbreviation(date)} showNavigation={showNavigation} nextLabel={<img src={arrow.src} alt="next arrow" height={20} width={20} />} prevLabel={<img src={arrow.src} className="rotate-180" alt="prev arrow" height={20} width={20} />} data-sentry-element="Calendar" data-sentry-component="MonthYearPicker" data-sentry-source-file="MonthYearPicker.tsx" />;
};
export default MonthYearPicker;