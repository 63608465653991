import type { CursorUpdate as _CursorUpdate } from '@ably/spaces';
import { useCursors } from '@ably/spaces/react';
import _ from 'lodash';
import React from 'react';
import type { Member } from '../utils/types';
import styles from './Cursors.module.css';
import CursorSvg from './CursorSvg';
type CursorUpdate = Omit<_CursorUpdate, 'data'> & {
  data: {
    state: 'move' | 'leave';
  };
};
type CurrentUser = {
  currentUser: Member | null;
} | null;

// 💡 This component is used to render the cursors of other users in the space
const MemberCursors = (currentUser: CurrentUser) => {
  const {
    cursors
  } = useCursors({
    returnCursors: true
  });
  const currentUserLocation = currentUser?.currentUser?.location;
  if (!currentUserLocation) return null;
  return <>
      {Object.values(cursors).map(data => {
      const memberLocation = data?.member?.location;
      // lodash compare of objects to see if we are on the same locaton
      if (!_.isEqual(memberLocation, currentUserLocation)) return null;
      const cursorUpdate = (data.cursorUpdate as CursorUpdate);
      const member = (data.member as Member);
      if (cursorUpdate.data.state === 'leave') return;
      const {
        cursorColor
      } = member.profileData.userColors;
      return <div key={member.connectionId} id={`member-cursor-${member.connectionId}`} className={styles.cursor} style={{
        left: `${cursorUpdate.position.x}px`,
        top: `${cursorUpdate.position.y}px`,
        zIndex: 999999
      }}>
            <CursorSvg cursorColor={cursorColor} />
            <div style={{
          backgroundColor: cursorColor
        }} className={`${styles.cursorName} member-cursor`}>
              {member.profileData.name}
            </div>
          </div>;
    })}
    </>;
};
export { MemberCursors };