import { convertToKAndM, stackedMinMax } from '@/utils/ChartUtils';
import type { ChartOptionsType } from '@/utils/types/DashboardAndChartsTypes';

import styles from '../../../components/Graphs/graphs.module.scss';

/**
 * Generates configuration options for a stacked chart.
 * @returns {Object} - An object containing the configuration options for the stacked chart.
 */
export const stackedChartOptions = (
  { dynamicSeries }: ChartOptionsType,
  signForGraph: string,
) => {
  return {
    chart: {
      id: 'basic-bar',
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false, // Set to true if you want horizontal bars
        columnWidth: '80%', // Adjust this value to control the width of bars
        borderRadius: 10,
        borderRadiusApplication: 'end',
      },
    },
    yaxis: {
      min: stackedMinMax(dynamicSeries).minValue,
      max: stackedMinMax(dynamicSeries).maxValue,
      tickAmount: 5,
      labels: {
        maxWidth: 160,
        align: 'left',
        formatter(value: number) {
          return convertToKAndM(value, signForGraph);
        },
        style: {
          padding: '10px',
          fontFamily: styles.fontFamily,
          fontSize: '14px',
          fontWeight: '400',
        },
      },
    },
  };
};
