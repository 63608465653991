import { convertToKAndM } from '@/utils/ChartUtils';
import type { ChartOptionsType } from '@/utils/types/DashboardAndChartsTypes';

import styles from '../../../components/Graphs/graphs.module.scss';

/**
 * Generates configuration options for a bar chart.
 * @param {ChartOptionsType} minValue - The minimum value for the y-axis.
 * @param {ChartOptionsType} maxValue - The maximum value for the y-axis.
 * @returns {Object} - An object containing the configuration options for the bar chart.
 */
export const barChartOptions = (
  { minValue, maxValue }: ChartOptionsType,
  signForGraph: string,
) => {
  return {
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        horizontal: false, // Set to true if you want horizontal bars
        columnWidth: '80%', // Adjust this value to control the width of bars
        borderRadius: 10,
        borderRadiusApplication: 'end',
      },
    },
    yaxis: {
      min: minValue,
      max: maxValue,
      tickAmount: 5,
      labels: {
        maxWidth: 160,
        align: 'left',
        formatter(value: number) {
          return convertToKAndM(value, signForGraph);
        },
        style: {
          padding: '10px',
          fontFamily: styles.fontFamily,
          fontSize: '14px',
          fontWeight: '400',
        },
      },
    },
  };
};
