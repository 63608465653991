import type { AxiosInstance, CancelToken } from 'axios';
import router from 'next/router';
import { toast } from 'react-toastify';
import dynamicRoute from '@/miscellaneous/constant';
import useUtilsStore from '@/miscellaneous/store/utilsStore/utilsStore';
import useZustandStore from '@/miscellaneous/store/zustand_store';
import { errorToaster } from '@/utils/toaster/toasters';

// Define the structure of an API Call
export type ApiCall = {
  method: 'GET' | 'POST' | 'PUT' | 'DELETE';
  endpoint: string;
  data?: any;
  params?: any;
  id?: number | string;
  cancelToken?: CancelToken;
};
// type Method = "GET" | "POST" | "PUT" | "DELETE";
export type AxiosMethod = 'get' | 'post' | 'put' | 'delete';

// Define a mapping type where keys are Axios methods in lowercase
export interface AxiosInstanceMethods {
  get: AxiosInstance['get'];
  post: AxiosInstance['post'];
  put: AxiosInstance['put'];
  delete: AxiosInstance['delete'];
}
type AxiosCallParams = [string, any] | [string, any, {
  params: any;
  cancelToken?: CancelToken;
}];
export type DetermineAxiosCallParamsProps = {
  axiosMethod: AxiosMethod;
  url: string;
  data: any;
  params: any;
  cancelToken?: CancelToken;
};
export const determineAxiosCallParams = ({
  axiosMethod,
  url,
  data,
  params,
  cancelToken
}: DetermineAxiosCallParamsProps): AxiosCallParams => {
  if (axiosMethod === 'delete' && data) {
    // For Bulk DELETE requests, include data in the config
    return [url, {
      data,
      params,
      cancelToken
    }];
  }
  // For other requests, include data as the second parameter
  return [url, data, {
    params,
    cancelToken
  }];
};

/**
 * Handles errors from Axios requests.
 * Logs detailed error messages and notifies the user of a general error.
 * @param error - The Axios error object
 */
export const handleAxiosError = (error: any) => {
  const {
    appLoader,
    setAppLoader
  } = useUtilsStore.getState();
  const {
    logoutApp
  } = useZustandStore.getState();
  if (appLoader) {
    setAppLoader(false);
  }
  let detailedErrorMessage = 'An error occurred.';
  if (error.response) {
    detailedErrorMessage = `Axios error: Server responded with status ${error.response?.status}`;
    console.error(detailedErrorMessage, error.response?.data);
  } else if (error.request) {
    detailedErrorMessage = 'Axios error: No response received';
    console.error(detailedErrorMessage, error.request);
  } else {
    detailedErrorMessage = 'Error setting up Axios request';
    console.error(detailedErrorMessage, error.message);
  }

  /**
   * Handles 401 unauthorized errors from API requests.
   * Checks if error occurred during signin/signup to handle auth specific errors.
   * Deletes cookies and redirects to login if token is invalid.
   * Displays different error toasts for signup vs signin errors.
   * Avoids redirect loops by checking current route before redirecting.
   */
  if (error.response?.status === 401 || error.response?.status === 500) {
    logoutApp();
    if (router.pathname === dynamicRoute.login) {
      return;
    }
    router.push(dynamicRoute.login);
  } else {
    console.error(`Unexpected Error Occurred, The support team has been notified. Clear your cookies and log back in.`);
    console.log('🚀 ~ error:', error);
    if (error.config && error.config.method !== 'get') {
      toast.dismiss();
      errorToaster('Something went wrong, our team has been informed');
    }
  }
};