// editorUtils.js
import { javascript } from '@codemirror/lang-javascript';
import { HighlightStyle, syntaxHighlighting } from '@codemirror/language';
import { drawSelection, keymap } from '@codemirror/view';
import { tags } from '@lezer/highlight';
import { EditorView, minimalSetup } from 'codemirror';
import { spreadsheet } from 'codemirror-lang-spreadsheet';

import {
  indentAndCompletionWithTab,
  preventNewLine,
  tabObservable,
} from './indent-completion-tab';
import { tagExtension } from './placeholders';

export const varaiableRegexAtTheEndOfString = /({{\d+,[^,]+,[^}]+}}|{\w+})$/g;

function deleteCustomWidget(view: EditorView): boolean {
  // Get the doc text
  const docText = view.state.doc.toString();
  // get cursor location from editorView
  const cursor = view.state.selection.main.head;
  // get the text until the cursor location
  const textUntilCursor = docText.slice(0, cursor);
  // check if the end of the text until the cursor location is a variable with the regex
  const variableWidgetAtCursor = textUntilCursor.match(
    varaiableRegexAtTheEndOfString,
  );

  if (!variableWidgetAtCursor) {
    return false; // deleting the last char regularly
  }
  // we matched a variable we need to delete, delete the variable from the text using the match length
  const transaction = view.state.update({
    changes: {
      from: cursor - variableWidgetAtCursor[0].length,
      to: cursor,
      insert: '',
    },
  });
  view.dispatch(transaction);
  return true;
}

/**
 * Generates a new CodeMirror EditorView instance with specified language support and extensions.
 *
 * @param {any} languageCompart - Compartment for language support
 * @param {any} autocompleteCompart - Compartment for autocompletion
 * @param {React.MutableRefObject<HTMLDivElement | null>} editorRef - Reference to the editor DOM element
 * @returns {EditorView} A new EditorView instance
 */
export const generateEditor = (
  languageCompart: any,
  autocompleteCompart: any,
  editorRef: any,
  onChange: (value: string) => void,
  variables: any,
  setHidden: any,
  initialValue: string = '',
  goToFormulaById?: (formulaId: number) => void,
) => {
  /**
   * Extensions to be added to the EditorView instance.
   * @type {any[]} Array of CodeMirror extensions
   */
  const extensions = [
    keymap.of([
      indentAndCompletionWithTab as any,
      preventNewLine(setHidden) as any,
      { key: 'Backspace', run: deleteCustomWidget },
    ]), // Allows using tab for indentation and autocompletion
    syntaxHighlighting(
      // Syntax highlighting definitions
      HighlightStyle.define([
        { tag: tags.name, color: 'green' },
        { tag: tags.bool, color: '#A020F0' },
        { tag: tags.color, color: '#0000FF' },
        { tag: tags.invalid, color: '#FA6F66' },
      ]),
    ),
    tabObservable(), // Enables using tab for indentation and autocompletion
    javascript(), // JavaScript language support
    drawSelection(), // Selection drawing
    minimalSetup, // Minimal setup
  ];

  /**
   * Configurations for the new EditorView instance.
   * @type {Object} Editor configuration object
   */
  const editorConfig = {
    extensions: [
      ...extensions, // Spread the extensions array
      languageCompart.of(spreadsheet()), // Add the spreadsheet language support
      autocompleteCompart.of([]), // Add the autocompletion compartment
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      tagExtension(variables, updateEditorContent, goToFormulaById), // ?INFO: We need to disable this rule because the function is defined below. Function must be defined before it is used. Because we need to use newEditor value in the function.
      EditorView.updateListener.of((update) => {
        if (update.docChanged) {
          const { doc } = update.state;
          const value = doc.toString();
          onChange(value);
        }
      }),
      EditorView.lineWrapping, // Enable line wrapping
    ],
    parent: editorRef.current, // Attach the editor to the provided DOM element
    drawSelection: false, // Enable selection drawing
  };

  /**
   * Create a new EditorView instance.
   * @type {EditorView} A new EditorView instance
   */
  const newEditor: any = new EditorView({
    ...editorConfig, // Spread the editor configuration object,
    doc: initialValue, // Set the initial value of the editor
  });

  /**
   * Updates the content of the EditorView instance with the provided new value.
   *
   * @param {string} newValue - The new value to be set in the EditorView.
   */
  function updateEditorContent(newValue: string) {
    // Update the editor content
    const transaction = newEditor.state.update({
      changes: { from: 0, to: newEditor.state.doc.length, insert: newValue },
    });
    newEditor.dispatch(transaction);
  }

  newEditor.dispatch({
    selection: { anchor: newEditor.state.doc.length },
  });

  newEditor.focus(); // Focus the editor

  return newEditor;
};
