// eslint-disable-next-line simple-import-sort/imports
import { isEqual } from 'lodash';
import { useEffect, useMemo, useRef, type CSSProperties, type ReactNode, type RefObject } from 'react';
import styles from '@/components/table/shared/table/GeneralTable/styles/baseTableStyles.module.scss';
import type { PdfState } from '@/miscellaneous/pdf-store/pdf_store';
import usePdfStore from '@/miscellaneous/pdf-store/pdf_store';
import type { DataStoreState } from '@/miscellaneous/store/DataStore';
import DataStore from '@/miscellaneous/store/DataStore';
import flattenArray from '@/utils/helpers/flattenArray';
import { useRouter } from 'next/router';
import DynamicTableScrollBar from '../dynamicTableScrollBar/DynamicTableScrollBar';
import { TableHeader } from '../table/GeneralTable/TableHeader';
import { TableScrollNavigationButtons } from '../tableHelper';
import FinancialsTableOptionsSection from '../TableOptionsSection/FinancialsTableOptionsSection';
import type { Settings, TableRow } from '../types';
import { isFinancials } from '../utils';
import DnDContextProvider from './TableDnDHelpers/DNDContextProvider';
import { useTableContext } from './TableDnDHelpers/TableDNDContext';
interface DndSortableWrapperProps {
  children: ReactNode | ReactNode[];
  data: Array<TableRow> | null;
  renderDndContext?: boolean;
  hideCogIcon?: boolean;
  headerTitle?: string;
  tableHeadWrapper?: RefObject<HTMLTableSectionElement>;
  settings?: Settings;
  tableRef?: RefObject<HTMLTableElement>;
  isVirtualized?: boolean;
  scrollRef?: RefObject<HTMLDivElement>;
  handleScroll?: () => void;
}
export const useTableScrollingRef = () => {
  const tableRef = useRef<HTMLTableElement>(null);
  const {
    setTableRef
  } = usePdfStore((state: PdfState) => state);
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const isScrolling = useRef(false); // Used to prevent multiple scroll events from firing
  const tableElementRef = useRef<HTMLTableElement | null>(null);
  useEffect(() => {
    if (tableElementRef.current) {
      setTableRef(tableElementRef.current);
    }
  }, [tableElementRef]);

  /**
   * Handles the scrolling behavior of the table component.
   * This function is called when the table is scrolled horizontally.
   * It ensures that the scroll position of the table and the scroll position of the container are synchronized.
   */
  const handleScroll = () => {
    if (!isScrolling.current) {
      isScrolling.current = true;
      requestAnimationFrame(() => {
        if (scrollRef.current && tableRef.current) {
          scrollRef.current.scrollLeft = tableRef.current.scrollLeft;
        }
        isScrolling.current = false;
      });
    }
  };
  return {
    tableRef,
    scrollRef,
    handleScroll,
    tableElementRef
  };
};

/**
 * This wrapper handles the scrolling and dnd and any other context providers needed for the table rendering.
 * @param children the table and other subchildren of his
 * @param data the data to be displayed in the table originally passed as prop
 * @param renderDndContext boolean to render the dnd context or not
 * @returns
 */
const TableWrapper = ({
  children,
  data,
  renderDndContext = false,
  hideCogIcon = false,
  headerTitle,
  tableHeadWrapper,
  settings,
  tableRef,
  scrollRef,
  handleScroll,
  isVirtualized = false
}: DndSortableWrapperProps) => {
  // handleing Scrolling ref to table
  const {
    setCurrentTableRows
  } = DataStore((state: DataStoreState) => state);
  const previousTabRows = useRef<TableRow[]>([]);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (renderDndContext && !isEqual(data, previousTabRows.current)) {
      setCurrentTableRows(data ?? []);
      previousTabRows.current = data ?? [];
    }
  }, [data]); // Dependencies must correctly reflect the actual comparison needs.

  useEffect(() => {
    if (!window) {
      return;
    }
    const onscroll = (_: unknown) => {
      if (!wrapperRef.current) return;
      if (!tableHeadWrapper?.current) return;
      const wrapperRefY = wrapperRef.current.getBoundingClientRect().top;
      const tableHeadWrapperElement = tableHeadWrapper.current;
      const navbarHeight = document.getElementById('myHeader')?.clientHeight ?? 0;
      const toggleOptionsSectionHeight = document.getElementById('toggleOptionsSection')?.clientHeight ?? 0;
      const stickyOffset = (headerRef.current?.clientHeight ?? 0) - navbarHeight + toggleOptionsSectionHeight;
      if (wrapperRefY <= -stickyOffset) {
        tableHeadWrapperElement.style.top = `${-wrapperRefY - stickyOffset}px`;
      } else {
        tableHeadWrapperElement.style.top = '0';
      }
    };
    window.addEventListener('scroll', onscroll);
    return () => {
      window.removeEventListener('scroll', onscroll);
    };
  }, []);
  const {
    draggedItem,
    overRowState,
    setPosition
  } = useTableContext();
  const handleMouseMove = (event: {
    clientY: any;
  }) => {
    // if (!renderDndContext) return;
    if (overRowState && overRowState?.id && draggedItem?.id) {
      const pointerY = event.clientY;
      const {
        rect
      } = overRowState;
      const thirdHeight = rect.height / 3;
      const topThirdEnd = rect.top + thirdHeight;
      const middleThirdEnd = rect.top + 2 * thirdHeight;
      if (pointerY < topThirdEnd) {
        setPosition('top');
      } else if (pointerY >= topThirdEnd && pointerY < middleThirdEnd) {
        setPosition('middle');
      } else {
        setPosition('bottom');
      }
    }
  };
  const {
    financialDrawerTablerows
  } = DataStore((state: DataStoreState) => state);
  const flatData = (useMemo(() => {
    if (!data) return [];
    return flattenArray(data);
  }, [data]) as TableRow[]);
  flatData.concat(financialDrawerTablerows.map(row => (row.original as TableRow)));

  // if isVirtualized is true, set the height of the table to 80vh and set overflowY to auto
  const renderTableContainerStyle = isVirtualized ? {
    height: '80vh',
    overflowY: 'auto'
  } : {};
  const router = useRouter();
  const generateTableOptionMenu = isFinancials(router.pathname) ? <FinancialsTableOptionsSection /> : null;
  const wrappedChild = <div className={styles.tableWrapperContainer} ref={wrapperRef}>
      <TableHeader title={(headerTitle as string)} hideCogIcon={hideCogIcon} navigtionComponent={<TableScrollNavigationButtons tableRef={tableRef && tableRef.current} scrollRef={scrollRef} />} settings={(settings as Settings)} headerRef={headerRef} />
      {generateTableOptionMenu}
      <div ref={tableRef} className={`relative ${styles.tableWrapper} ${styles.hideScrollbar}`} onScroll={handleScroll} onMouseMove={handleMouseMove} style={(renderTableContainerStyle as CSSProperties)}>
        {children}
        <DynamicTableScrollBar tableRef={tableRef} scrollRef={scrollRef} />
      </div>
    </div>;
  if (renderDndContext) {
    return <DnDContextProvider>{wrappedChild} </DnDContextProvider>;
  }
  return wrappedChild;
};
export default TableWrapper;