import { useEffect } from 'react';
import { useShallow } from 'zustand/react/shallow';

import { handleCreateCompany } from '@/components/common/LinkDropdown/CustomComponents/CompaniesDropDown';
import useUtilsStore from '@/miscellaneous/store/utilsStore/utilsStore';
import useZustandStore from '@/miscellaneous/store/zustand_store';
import type { Company } from '@/utils/hooks/company/useCompany';
import useCompany from '@/utils/hooks/company/useCompany';
import useMutations from '@/utils/hooks/mutations/useMutations';
import useShallowRoute from '@/utils/hooks/useShallowRoute';

/**
 * A custom React hook that prepares a company for scraping, it sets the company url on the active company, or creates a new company if it doesn't exist and sets url on it.
 *
 * This hook is responsible for the following tasks:
 * - Interacting with the `useCompany` and `useUtilsStore` hooks to manage the active company.
 * - Handling the creation of a new company, including scraping the company's website and updating the company data.
 * - Updating the company's website URL if the company is in the onboarding process.
 *
 * @param setModalOpen A function to set the state of a modal that is used to display company setup information.
 * @returns An object containing various state and utility functions related to company setup.
 */
const usePrepareCompanyForScraping = (
  setModalOpen: (open: boolean) => void,
) => {
  const { setActiveCompany } = useCompany();
  const { activeCompany } = useZustandStore(
    useShallow((state) => ({ activeCompany: state.activeCompany })),
  );

  const {
    companyUrlObjectForScrape,
    setAppLoader,
    setCompanyUrlObjectForScrape,
    createAndScrapeCompany,
    setCreateAndScrapeCompany,
  } = useUtilsStore(
    useShallow((state) => ({
      companyUrlObjectForScrape: state.companyUrlObjectForScrape,
      setCompanyUrlObjectForScrape: state.setCompanyUrlObjectForScrape,
      setAppLoader: state.setAppLoader,
      createAndScrapeCompany: state.createAndScrapeCompany,
      setCreateAndScrapeCompany: state.setCreateAndScrapeCompany,
    })),
  );

  const { updateCompany, createCompany } = useMutations();
  const shallowRoute = useShallowRoute();

  const cleanUpOperations = () => {
    setAppLoader(false);
    setModalOpen(true);
  };

  const handleCreateAndSetWebsiteUrl = async () => {
    const additionalFields = {
      website: companyUrlObjectForScrape?.url,
    };

    setAppLoader(true);
    setCreateAndScrapeCompany(false);
    setCompanyUrlObjectForScrape(null);

    try {
      const newCompany = await handleCreateCompany(
        createCompany,
        setActiveCompany,
        activeCompany,
        additionalFields,
      );

      if (newCompany) {
        shallowRoute({ company: newCompany.slug });
        setAppLoader(false);
      }
    } catch {
      setAppLoader(false);
    }
  };

  const handleUpdateCompanyWebsite = () => {
    if (!companyUrlObjectForScrape) {
      setModalOpen(true);
      return;
    }

    setAppLoader(true);
    if (companyUrlObjectForScrape.direction === 'signup') {
      setCompanyUrlObjectForScrape(null);
      updateCompany.mutate(
        { ...activeCompany, website: companyUrlObjectForScrape.url },
        {
          onSuccess: (company: Company) => {
            cleanUpOperations();
            setActiveCompany(company);
            shallowRoute({ company: company.slug });
          },
        },
      );
    }
  };

  useEffect(() => {
    if (createAndScrapeCompany && companyUrlObjectForScrape) {
      handleCreateAndSetWebsiteUrl();
    }

    if (activeCompany && activeCompany.onboarding_finished_date === null) {
      handleUpdateCompanyWebsite();
    }
  }, [activeCompany]);
};

export default usePrepareCompanyForScraping;
