import { Settings } from 'lucide-react';
import type { MouseEventHandler, ReactNode } from 'react';
import useMutations from '@/utils/hooks/mutations/useMutations';
import type { ZustandState } from '../../../miscellaneous/store/zustand_store';
import useZustandStore from '../../../miscellaneous/store/zustand_store';
import type { TogglePopoverProps } from '../GenericToggleMenu';
import TogglePopover from '../GenericToggleMenu';
import type { Option } from '../subComponents/ToggleOptions';
import { getOptionsFromReports } from './subComponents/toggleFinancialUtils';

/**
 * Generates props for the TogglePopover component based on the given options and renderReference function.
 *
 * @param options - The options to display in the popover
 * @param renderReference - A function that renders the reference element that triggers the popover. Takes an onClick handler as a parameter.
 * @returns An object containing the sections and other props for the TogglePopover component.
 */
function getPopoverProps(options: Option[], renderReference: (onClick: () => void) => ReactNode): TogglePopoverProps {
  const lastItem = options[options.length - 1];
  const optionsWithoutLast = options.slice(0, -1);
  return {
    sections: [{
      title: 'Column Display Options',
      options: optionsWithoutLast
    }, {
      title: 'Column Display Options',
      options: lastItem ? [lastItem] : []
    }],
    reportSettingsLabel: 'Report Settings',
    // hrefSettings: '/settings',
    renderReference
  };
}

/**
 * A function that uses TogglePopover to generate setting section for Financials
 * @returns Settings Cog icon and popover for Financials
 */

export const FinancialsSettingPopover = () => {
  const {
    updateReport
  } = useMutations();
  const {
    activeReport
  } = useZustandStore((state: ZustandState) => state);
  if (!activeReport || activeReport?.id === undefined) {
    // check that there ar hiring plans otherwise return p tag
    return <p id="popover" data-testid="popover" />;
  }
  const reportOptions = getOptionsFromReports(activeReport, updateReport);
  const renderReference = (onClick // rendering the cog icon button to display the settings above
  : MouseEventHandler<HTMLButtonElement> | undefined) => <button type="button" onClick={onClick} className="button" data-testid="popover" aria-label="Settings" data-sentry-component="renderReference" data-sentry-source-file="FinancialsSettingsTogglePopup.tsx">
      <Settings size={20} data-sentry-element="Settings" data-sentry-source-file="FinancialsSettingsTogglePopup.tsx" />
    </button>;
  return <TogglePopover data-testid="popover" {...getPopoverProps(reportOptions ? ((reportOptions as unknown) as Option[]) : [], renderReference)} data-sentry-element="TogglePopover" data-sentry-component="FinancialsSettingPopover" data-sentry-source-file="FinancialsSettingsTogglePopup.tsx" />;
};