import { BsLightningCharge } from 'react-icons/bs';
import { FiTrendingUp } from 'react-icons/fi';
import { LuLayoutPanelLeft } from 'react-icons/lu';
import { PiDiamondsFour } from 'react-icons/pi';
import { RiBarChartLine } from 'react-icons/ri';
const templateIconGenerator = (tagName: string, color: string | undefined) => {
  switch (tagName) {
    case 'SaaS':
      return <RiBarChartLine color={color} />;
    case 'Charts':
      return <RiBarChartLine color={color} />;
    case 'Formulas':
      return <PiDiamondsFour color={color} />;
    case 'Dashboards':
      return <LuLayoutPanelLeft className="rotate-90" color={color} />;
    case 'Fundraising':
      return <FiTrendingUp color={color} />;
    case 'Models':
      return <FiTrendingUp color={color} />;
    case 'Marketing':
      return <BsLightningCharge color={color} />;
    case 'Variables':
      return <PiDiamondsFour color={color} />;
    default:
      return <RiBarChartLine color={color} />;
  }
};
export { templateIconGenerator };