export const mainColors = {
  themeColor: '#5096fd',
  grey: '#8C8C8C', // rgba(140, 140, 140, 1)
  lightGrey: '#f7f7f7', // rgba(247, 247, 247, 1)
  black: '#131923', // rgba(19, 25, 35, 1)
  tableBorderColor: '#D6D6D6', // rgba(214, 214, 214, 1) grey
  tablePlusButtonText: '#919daf',
  tableInputBg: '#daebff',
  disabledText: '#787878',
  iconHoverColor: '#EEEEEE',
  skyBlue: '#42C2FF', // sky bluegr
  midBlack: '#2C2C2C', // mid black
  darkBlack: '#050505', // dark black
  white: '#FFFFFF', // white
  lightwhite: '#fffefe', // white
  lightwhite2: '#fefffe', // white
  pink: '#FF15C4', // pink
  midPink: '#f572d8', // rgba(245, 114, 216, 1); mid pink
  lightPink: '#FFC7F1', // light pink
  lavender: '#A675E2', // lavender
  darkViolet: '#A1A7F5', // lavender blue
  mediumGray: '# AEAEAE', // mid grey
  lightGrey2: '#F7FAFC', // light grey
  lightGrey3: '#D9D9D9', // light grey
  lightGrey4: '#F5F5F5',
  lightestGrey: '#E6E6E6', // lightest grey
  darkGrey: '#8C8C8C', // dark grey
  darkestGrey: '#6B6B6B', // dark grey
  yellow: '#FF8717', // yellow
  yellow2: '#ffba5e', // rgba(255, 186, 94, 1); yellow
  midYellow: '#f5b054', // rgba(245, 176, 84, 1); mid yellow
  midYellow2: '#eba64a', // rgba(235, 166, 74, 1); mid yellow
  lightYellow: '#fbf3e9', // rgba(251, 243, 233, 1); light yellow
  lightYellow2: '#fbf2e9', // rgba(251, 242, 233, 1); light yellow
  lightYellow3: '#fff8f0', // rgba(255, 248, 240, 1); light yellow
  lightYellow4: '#fff1fc', // rgba(255, 241, 252, 1); light yellow
  blue: '#6CB4F5', // blue
  midBlue: '#7c8bff', // rgba(124, 139, 255, 1); mid blue
  midBlue2: '#7281f5', // rgba(114, 129, 245, 1); mid blue
  midBlue3: '#5463ff', // rgba(84, 99, 255, 1); mid blue
  midBlue4: '#7785f3', // rgba(119, 133, 243, 1); mid blue
  darkBlue: '#6877ff', // rgba(104, 119, 255, 1); dark blue
  lightBlue: '#b8c7ff', // rgba(184, 199, 255, 1); light blue
  lightBlue2: '#eaedff', // rgba(234, 237, 255, 1); light blue
  lightBlue3: '#f5f6ff', // rgba(245, 246, 255, 1); light blue
  lightBlue4: '#f9faff', // rgba(249, 250, 255, 1); light blue-grey
  lightBorderBlue: '#f1f2fb', // rgba(241, 242, 251, 1); light blue
  darkGreen: '#009c06', // rgba(0, 156, 6, 1); dark green
  darkGreen2: '#007e00', // rgba(0, 126, 0, 1); dark green
  lightGreen: '#edf5f1', // rgba(237, 245, 241, 1); light green
  lightGreen2: '#f2faf5', // rgba(242, 250, 245, 1); light green
  red: '#E10815', // red
  darkRed: '#9e0000', // rgba(158, 0, 0, 1); dark red
  lightRed: '#fbeaea', // rgba(251, 234, 234, 1); light red
  midTeal: '#009da9', // rgba(0, 157, 169, 1); mid teal
  lightTeal: '#eafaff', // rgba(234, 250, 255, 1); light teal
  componentBgGray: '#c2cbd9',
};

export const colors = {
  // blue color
  btnBgColor: `bg-[${mainColors.blue}]`,
  textColor: `text-[${mainColors.blue}]`,
  white: `bg-[${mainColors.white}]`,
  textBlack: `text-[${mainColors.black}]`,
  textGrey: `text-[${mainColors.grey}]`,
  lightBlue: `bg-[${mainColors.lightBlue}]`,
  textDarkGray: `text-[${mainColors.darkestGrey}]`,
  actualColor: `bg-[${mainColors.lightGreen}]`,
  darkGrayBg: `bg-[${mainColors.darkestGrey}]`,
  borderTopGrey: `border-t-[${mainColors?.grey}]`,
  borderBottomGrey: `border-b-[${mainColors?.grey}]`,
  borderLeftGrey: `border-l-[${mainColors?.grey}]`,
  borderRightGrey: `border-r-[${mainColors?.grey}]`,
  borderTopLightBlue: `border-t-[${mainColors?.lightBorderBlue}]`,
  borderBottomLightBlue: `border-b-[${mainColors?.lightBorderBlue}]`,
  borderRightLightBlue: `border-r-[${mainColors?.lightBorderBlue}]`,
  borderLeftLightBlue: `border-l-[${mainColors?.lightBorderBlue}]`,
  borderBlue: `border-[${mainColors?.lightBorderBlue}]`,
  cellBorder: `border-[${mainColors?.tableBorderColor}]`,
  borderTableVerticalGrey: `border-y-[[${mainColors?.tableBorderColor}]`,
  borderTableHorizontalLightBlue: `border-x-[${mainColors?.lightBorderBlue}]`,
};

export const backgroundColors = {
  themeColor: `bg-[${mainColors.themeColor}]`,
  focuedListItemColor: `bg-[${mainColors.midBlue}]`,
  selectedListItemColor: `bg-[${mainColors.midBlue}]`,
  lightskyBlueColor: `bg-[${mainColors?.lightBorderBlue}]`,
  lightGreyBackground: `bg-[${mainColors.lightGrey2}]`,
  extraLightGreyBackground: `bg-[${mainColors.lightestGrey}]`,
  darkGreyBackground: `bg-[${mainColors.darkestGrey}]`,
  darkVioletBackground: `bg-[${mainColors.blue}}]`,
};

export const textColors = {
  themeText: `text-[${mainColors.midBlue}]`,
  violetText: `text-[${mainColors.darkViolet}]`,
  blackText: `text-[${mainColors.black}]`,
  greyText: `text-[${mainColors.grey}]`,
  absoluteBlackText: `text-[${mainColors.darkBlack}]`,
  pageBackground: `bg-[${mainColors.lightGrey}]`,
};

export const appColors = {
  main_background: mainColors.lightGrey,
  // main_background: '#F7F8FB',
};
