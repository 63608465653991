import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useShallow } from 'zustand/react/shallow';

import ApiUtils from '@/api/ApiUtils';
import { QUERYKEYS } from '@/miscellaneous/constant/reactQueryKeyConfig';
import type { ZustandState } from '@/miscellaneous/store/zustand_store';
import useZustandStore from '@/miscellaneous/store/zustand_store';
import type { HiringPlan } from '@/utils/types/HiringTypes';

import useShallowRoute from '../useShallowRoute';

/**
 * Handles setting the active hiring plan state based on the URL query params,
 * list of hiring plans, and current route.
 *
 * If a hiring plan ID is specified in the URL query, tries to find a matching plan.
 * If no match, defaults to the first hiring plan.
 * If no hiring plan ID in query, defaults to the first hiring plan.
 * If no match and no plans, does nothing.
 * Also handles updating the shallow route if needed.
 */
const activeHiringPlanHandler = (
  branchPlans: any,
  query: any,
  setActiveHiringPlanState: any,
  shallowRoute: any,
) => {
  const firstHiringPlan =
    branchPlans && branchPlans.length > 0 ? branchPlans?.[0] : null;

  const urlHiringPlan = branchPlans?.find(
    (hiringPlan: any) => hiringPlan.id === Number(query?.hiring_plan_id),
  );

  if (query?.hiring_plan_id && urlHiringPlan) {
    setActiveHiringPlanState(urlHiringPlan);
  } else if (!query?.hiring_plan_id) {
    setActiveHiringPlanState(firstHiringPlan);
  } else {
    setActiveHiringPlanState(firstHiringPlan);
    shallowRoute({
      hiring_plan_id: firstHiringPlan?.id,
    });
  }
};

const useHiringPlan = () => {
  const {
    activeCompanyId,
    activeBranchId,
    activeCompanyCompanyId,
    setActiveHiringPlan,
    activeHiringPlan,
    branchHiringPlans,
    setBranchHiringPlans,
  } = useZustandStore(
    useShallow((state: ZustandState) => ({
      activeCompanyId: state.activeCompany?.id ?? null,
      activeCompanyCompanyId: state.activeCompany?.company_id ?? null,
      activeBranchId: state.activeBranch?.id ?? null,
      setActiveHiringPlan: state.setActiveHiringPlan,
      activeHiringPlan: state.activeHiringPlan,
      branchHiringPlans: state.branchHiringPlans,
      setBranchHiringPlans: state.setBranchHiringPlans,
    })),
  );

  const { data, isLoading, error, isSuccess } = useQuery<HiringPlan[]>({
    queryKey: [QUERYKEYS.HIRING_PLANS, activeCompanyId],
    queryFn: () => ApiUtils.getHiringPlans(0, activeCompanyCompanyId as number), // 0 means all hiring plans by comapny
    enabled: !!activeCompanyId,
  });

  const router = useRouter();
  const { query } = router;

  const shallowRoute = useShallowRoute();

  useEffect(() => {
    if (!isLoading && data && data?.length > 0) {
      const branchPlans = data.filter(
        (hiringPlan: HiringPlan) => hiringPlan.branch_id === activeBranchId,
      );

      setBranchHiringPlans(branchPlans);
      activeHiringPlanHandler(
        branchPlans,
        query,
        setActiveHiringPlan,
        shallowRoute,
      );
    } else {
      setBranchHiringPlans(data!);
    }
  }, [data, isLoading, activeBranchId, query]);

  return {
    hiringPlans: data,
    branchHiringPlans,
    activeHiringPlan,
    isLoading,
    error,
    isSuccess,
  };
};

export default useHiringPlan;
