/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable import/no-extraneous-dependencies */
import Spaces from '@ably/spaces';
import { SpaceProvider, SpacesProvider } from '@ably/spaces/react';
import { Realtime } from 'ably';
import { AblyProvider } from 'ably/react';
import { nanoid } from 'nanoid';
import { useIsLoggedIn } from '@/utils/hooks/useIsLoggedIn';
import LiveCursors from '../components/LiveCursors';
const client = new Realtime.Promise({
  clientId: nanoid(),
  key: process.env.NEXT_PUBLIC_ABLY_API_KEY
});
const spaces = new Spaces(client);
const spaceName = 'nextjs-ably';
const AblyLayout = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const {
    isLoggedIn
  } = useIsLoggedIn();
  if (!isLoggedIn) {
    return <>{children}</>;
  }
  return <div data-sentry-component="AblyLayout" data-sentry-source-file="AblyLayout.tsx">
      <AblyProvider client={client} data-sentry-element="AblyProvider" data-sentry-source-file="AblyLayout.tsx">
        <SpacesProvider client={spaces} data-sentry-element="SpacesProvider" data-sentry-source-file="AblyLayout.tsx">
          <SpaceProvider name={spaceName} data-sentry-element="SpaceProvider" data-sentry-source-file="AblyLayout.tsx">
            <LiveCursors data-sentry-element="LiveCursors" data-sentry-source-file="AblyLayout.tsx">{children}</LiveCursors>
          </SpaceProvider>
        </SpacesProvider>
      </AblyProvider>
    </div>;
};
export default AblyLayout;